import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { FC, useMemo, useState } from 'react'
import { Trans } from 'react-i18next'
import { OccurrencePublicDto } from '../../../store/events/types'
import { Business } from '../../../store/auth/types'
import { UpcomingEventCard } from './UpcomingEventCard'
import { AdminTheme } from '../../../theme/theme'
import { NoServices } from '../ServiceSelector'
import { Flex } from '../../../components/helpers/Flex'
import { SearchToggleInput } from '../../../components/ui-kit/comopnents/InputWithIconsAndAnimation'

const UpcomingEventsContainer = withTheme(
    styled('div')(({ theme }: { theme: AdminTheme }) => ({
        backgroundColor: theme.SurfaceColor,
        color: theme.ContentPrimary,
        padding: theme.Spacing(2.5),
        [theme.BreakPoint]: {
            padding: theme.Spacing(3),
        },
        width: '100%',
        '.list': {
            marginTop: '24px',
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '24px',
            [theme.BreakPoint]: {
                flexDirection: 'row',
                alignItems: 'flex-start',
            },
        },
    }))
)

const EventSelectorTitleContainer = withTheme(
    styled(Flex)(({ theme }: { theme: AdminTheme }) => ({
        position: 'relative',
        width: '100%',
        minHeight: 42,
    }))
)

export const EventSelector: FC<{ business: Business; events: OccurrencePublicDto[] }> = ({ business, events }) => {
    const [searchTerm, setSearchTerm] = useState('')

    const filteredEvents = useMemo(
        () => events.filter((event) => event.title?.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())),
        [events, searchTerm]
    )

    return (
        <UpcomingEventsContainer>
            <EventSelectorTitleContainer justifyContent="space-between">
                <h2>
                    <Trans ns="bookingpage">Book an appointment</Trans>
                </h2>
                <SearchToggleInput searchTerm={searchTerm} setSearchTerm={setSearchTerm} fullWidth />
            </EventSelectorTitleContainer>
            <div className="list">
                {filteredEvents.length ? (
                    filteredEvents.map((event) => (
                        <UpcomingEventCard key={event.id} business={business} event={event} />
                    ))
                ) : (
                    <NoServices searchUsed />
                )}
            </div>
        </UpcomingEventsContainer>
    )
}
