import { FC } from 'react'
import { ContactInfo } from './ContactInfoScreen'
import { DetailsCardContainer } from './DetailsCardContainer'

export const ContactInfoCard: FC<{
    contactInfo: ContactInfo
}> = ({ contactInfo }) => {
    return (
        <DetailsCardContainer spacing={1}>
            <span className="bodymedium">{contactInfo?.clientName}</span>
            <span>✉️ {contactInfo?.clientEmail}</span>
            {contactInfo?.clientPhone && <span>📞 {contactInfo.clientPhone}</span>}
        </DetailsCardContainer>
    )
}
