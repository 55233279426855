import { AdminAction } from '../admin/actions'
import { AuthAction } from '../auth/actions'
import { BookingPageActions } from '../booking-page/actions'
import { CalendarAction } from '../calendar/actions'
import { GettingStartedAction } from '../getting-started/actions'

export interface Action<T, P> {
    readonly type: T
    readonly payload: P
}
export interface ActionWithoutPayload<T> {
    readonly type: T
}

export type Action2 = GettingStartedAction | AuthAction | CalendarAction | BookingPageActions | AdminAction

export function createAction2(action: Action2): Action2 {
    return action
}

export function createAction<T extends string, P>(type: T, payload: P): Action<T, P> {
    return {
        type,
        payload,
    }
}
