import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { AdminTheme } from '../../../theme/theme'
import { SecondaryButton } from '../../../components/ui-kit/button/secondary'
import 'react-day-picker/lib/style.css'

const calendarBreakpoint = '@media (max-width: 374px)'

export const TimeSelectorContainer = withTheme(
    styled.div(({ theme }: { theme: AdminTheme }) => ({
        display: 'flex',
        flexDirection: 'column',
        gap: theme.Spacing(4),
        alignItems: 'center',
        [theme.BreakPoint]: {
            alignItems: 'flex-start',
            flexDirection: 'row',
        },

        '.slots-container': {
            maxWidth: 335,
            '.slots': {
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                '.slots-title': {
                    ...theme._BodyMediumSemibold,
                    fontWeight: 400,
                    fontSize: '1.15em',
                    '&:first-letter': {
                        textTransform: 'capitalize',
                    },
                },
                '.slot-list': {
                    width: '100%',
                    display: 'grid',
                    gridTemplateColumns: 'repeat(4, 1fr)',
                    padding: `${theme.Spacing(2)} 0 ${theme.Spacing(2.5)} 0`,
                    rowGap: theme.Spacing(2),
                    columnGap: theme.Spacing(1.5),
                    [theme.BreakPoint]: {
                        columnGap: theme.Spacing(1),
                        rowGap: theme.Spacing(2),
                    },
                    [calendarBreakpoint]: {
                        columnGap: theme.Spacing(0.5),
                    },
                },
                '.no-slot-list': {
                    gridTemplateColumns: '1fr',
                },
            },
            [calendarBreakpoint]: {
                flexGrow: 1,
                width: '100%',
            },
        },

        '.DayPicker': {
            [theme.BreakPointReverse]: {
                width: '100%',
            },
        },

        '.DayPicker-NavButton': {
            top: 0,
            right: 0,
        },

        '.DayPicker-Caption': {
            textTransform: 'capitalize',
            marginBottom: theme.Spacing(2),
            padding: 0,
            '> div': {
                fontWeight: 400,
            },
        },

        '.DayPicker-Weekdays': {
            margin: 0,
        },

        '.DayPicker-Weekday': {
            width: 36,
            height: 36,
            padding: '6px 0px',
            textAlign: 'center',
            [calendarBreakpoint]: {
                width: 32,
                height: 32,
            },
        },

        '.DayPicker-WeekdaysRow': {
            display: 'flex',
            justifyContent: 'space-between',
        },

        '.DayPicker-Week': {
            display: 'flex',
            justifyContent: 'space-between',
        },

        '.DayPicker-Month': {
            width: '100%',
            margin: 0,
            [theme.BreakPoint]: {
                width: 312,
                margin: `0px 0px 0px ${theme.Spacing(3)}`,
            },
        },

        '.DayPicker-Day': {
            borderRadius: theme.ButtonBorderRadius,
            color: theme.ContentPrimary,

            '&.DayPicker-Day--disabled': {
                color: theme.ContentDisabled,
            },
            width: 36,
            height: 36,
            [calendarBreakpoint]: {
                width: 32,
                height: 32,
            },
            padding: '6px 0px',
            textAlign: 'center',
            '&:hover': {
                backgroundColor: `${theme.InteractiveSecondaryHover} !important`,
            },
            '&.DayPicker-Day--outside:hover': {
                backgroundColor: 'transparent !important',
            },
        },

        '.DayPicker-Day--today': {
            position: 'relative',
            '&::after': {
                display: 'block',
                content: '""',
                position: 'absolute',
                top: 'calc(100% - 9px)',
                [calendarBreakpoint]: {
                    top: 'calc(100% - 5px)',
                },
                left: 'calc(50% - 2.5px)',
                width: 5,
                height: 5,
                borderRadius: '50%',
                background: theme.InteractivePrimary,
            },
        },

        '.DayPicker-Day--selected': {
            backgroundColor: `${theme.InteractivePrimary} !important`,
            color: `${theme.FontColor} !important`,
            '&:hover': {
                backgroundColor: `${theme.InteractivePrimaryHover} !important`,
            },
            '&.DayPicker-Day--today::after': {
                background: theme.FontColor,
            },
        },

        '.DayPicker-NavButton--prev': {
            backgroundImage: "url('/CalendarLeft.png')",
        },
        '.DayPicker-NavButton--next': {
            backgroundImage: "url('/CalendarRight.png')",
        },
    }))
)

export const TimeSlotBox = withTheme(
    styled(SecondaryButton)(({ theme }: { theme: AdminTheme }) => ({
        width: 75,
        [calendarBreakpoint]: {
            width: '100%',
            padding: `${theme.Spacing(1)} 10px`,
        },
        padding: `${theme.Spacing(1)} ${theme.Spacing(2)}`,

        '&.current-slot': {
            backgroundColor: theme.InteractivePrimary,
            color: theme.FontColor,
            '&:hover': {
                backgroundColor: theme.InteractivePrimaryHover,
            },
        },
    }))
)
