import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { FC, useMemo, useState } from 'react'
import { Trans } from 'react-i18next'
import { OccurrencePublicDto } from '../../../store/events/types'
import { AdminTheme } from '../../../theme/theme'
import { Business } from '../../../store/auth/types'
import { classNames } from '../../../services/class-names'
import { UpcomingEventCard } from './UpcomingEventCard'
import { Flex } from '../../../components/helpers/Flex'
import { NoServices } from '../ServiceSelector'
import { SearchToggleInput } from '../../../components/ui-kit/comopnents/InputWithIconsAndAnimation'

const UpcomingEventsContainer = withTheme(
    styled('div')(({ theme }: { theme: AdminTheme }) => ({
        padding: theme.Spacing(3),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        backgroundColor: theme.SurfaceColor,
        color: theme.ContentPrimary,
        '&.embedded': {
            padding: 0,
            boxShadow: 'none',
        },
        [theme.BreakPointReverse]: {
            borderBottom: `1px solid ${theme.BroderSecondary}`,
        },
        [theme.BreakPoint]: {
            ...theme.BookingPageBorderStyle,
            borderRadius: theme.Spacing(1),
            marginBottom: '24px',
            flexGrow: 1,
        },
        [theme.BookingPageBreakPoint]: {
            marginLeft: theme.Spacing(3),
            marginBottom: 0,
            '&.embedded': {
                marginLeft: 0,
            },
        },
        '.list': {
            display: 'flex',
            width: '100%',
            gap: theme.Spacing(3),
            marginTop: theme.Spacing(3),
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            flexWrap: 'wrap',
            [theme.BreakPoint]: {
                justifyContent: 'space-between',
                flexDirection: 'row',
            },
        },
        '&.embedded .list': {
            marginTop: 0,
        },
    }))
)

const EventSelectorTitleContainer = withTheme(
    styled(Flex)(({ theme }: { theme: AdminTheme }) => ({
        position: 'relative',
        width: '100%',
        minHeight: 42,
    }))
)

export const UpcomingEventsColumn: FC<{ business: Business; events: OccurrencePublicDto[]; isEmbed?: boolean }> = ({
    business,
    events,
    isEmbed,
}) => {
    const [searchTerm, setSearchTerm] = useState('')

    const filteredEvents = useMemo(
        () => events.filter((event) => event.title?.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())),
        [events, searchTerm]
    )

    return (
        <UpcomingEventsContainer className={classNames(isEmbed ? 'embedded' : null)}>
            {!isEmbed && (
                <EventSelectorTitleContainer justifyContent="space-between">
                    <h2>
                        <Trans ns="bookingpage">Group services</Trans>{' '}
                    </h2>
                    <SearchToggleInput searchTerm={searchTerm} setSearchTerm={setSearchTerm} fullWidth />
                </EventSelectorTitleContainer>
            )}
            <div className="list">
                {filteredEvents.length ? (
                    filteredEvents.map((event) => (
                        <UpcomingEventCard key={event.id} business={business} event={event} isEmbed={isEmbed} />
                    ))
                ) : (
                    <NoServices searchUsed />
                )}
            </div>
        </UpcomingEventsContainer>
    )
}
