import { EventPublicDto } from '../../../../store/events/types'

export function getEventBookUrl(
    isEmbed: boolean | undefined,
    urlHandle: string,
    event: EventPublicDto,
    occurrenceId: string,
    skipForms = false
): string {
    const base = `/${isEmbed ? 'embed' : 'book'}/${urlHandle}/events/${event.id}/${occurrenceId}/`

    return base + (skipForms || !event.form ? 'checkout' : 'form')
}
