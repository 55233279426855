import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'

export var reactPlugin = new ReactPlugin()

var appInsights = new ApplicationInsights({
    config: {
        connectionString: process.env.REACT_APP_APPINSIGHT_CONNECTION_STRING,
        enableAutoRouteTracking: true,
        extensions: [reactPlugin],
    },
})

appInsights.loadAppInsights()

window.addEventListener('error', (error) => {
    appInsights.trackException(error)
})

window.addEventListener('unhandledrejection', (unhandledrejection) => {
    appInsights.trackException(unhandledrejection.reason)
})

export function captureException(error: any) {
    appInsights.trackException(error)
}

export function captureMessage(message: string) {
    appInsights.trackEvent({ name: message })
}
