import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { Formik, Form, Field } from 'formik'
import { Moment } from 'moment'
import { FC, useCallback, useEffect, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { PrimaryButton } from '../../../components/ui-kit/button/primary'
import { Icon } from '../../../components/ui-kit/comopnents/Icon'
import { FormikInput } from '../../../components/ui-kit/comopnents/Input'
import { InputGroup } from '../../../components/ui-kit/comopnents/InputGroup'
import { RequiredIndicator } from '../../../components/ui-kit/comopnents/RequiredIndicator'
import { Business } from '../../../store/auth/types'
import { Form as IForm, Question, QuestionType } from '../../../store/forms/types'
import { Service } from '../../../store/services/types'
import { SummaryCard } from './SummaryCard'
import { StyledOptionLabel } from '../components/StyledOptionLabel'
import { getTextifiedAnswer } from '../helpers/get-textified-answer'
import { Column } from '../../../components/admin/layout/Column'
import { tryParse } from '../../../store/utils/try-parse'
import styled from '@emotion/styled'
import { FIRST_TIME_CUSTOMER_QUESTION_KEY, FormValues } from '../forms/types'
import { TextWithLink } from '../../../components/ui-kit/comopnents/TextWithLink'
import { useHistory, useParams } from 'react-router-dom'
import { Slot } from './ServicePage'
import { Footer } from '../components/Footer'
import { MinupRibbon } from '../../../components/helpers/MinupRibbon'
import { BookingPageTitle } from '../components/BookingPageTitle'
import { useBeforeUnload } from '../../../hooks/use-before-unload'
import { Content } from '../components/Content'

const StyledInputGroup = styled(InputGroup)(() => ({
    alignItems: 'flex-start',
}))

const StyledFirstTimeOptionLabel = styled(StyledOptionLabel)(() => ({
    marginTop: 0,
}))

export const ServiceForm: FC<{
    service: Service
    additionalServices: Service[]
    business: Business
    day: Moment
    selectedSlot: Slot
    form: IForm
    answers: string
    setAnswers: (answers: string) => void
    isEmbed?: boolean
}> = ({ service, additionalServices, business, day, selectedSlot, form, answers, setAnswers, isEmbed }) => {
    const { handle, serviceId } = useParams<{ handle: string; serviceId: string }>()
    const { t } = useTranslation('bookingpage')
    const history = useHistory()
    const questions = useMemo(() => tryParse(form.questions, []) as Question[], [form.questions])
    useBeforeUnload()

    useEffect(() => {
        if (!selectedSlot) {
            history.push(`/${isEmbed ? 'embed' : 'book'}/${handle}/service/${serviceId}`)
        }
    }, [handle, history, isEmbed, selectedSlot, serviceId])

    const onContinue = useCallback(() => {
        history.push(`/${isEmbed ? 'embed' : 'book'}/${handle}/service/${serviceId}/checkout`)
    }, [history, isEmbed, handle, serviceId])

    const onsubmit = useCallback(
        (answers) => {
            const result =
                !form.onlyForFirstTimeCustomers || answers.firstTime === 'yes'
                    ? questions.map((q) => {
                          const answer = answers[q.id]
                          return {
                              id: q.id,
                              question: q.question,
                              answer: answer,
                              textAnswer: getTextifiedAnswer(q, answer),
                              textified: `${q.question}: ${getTextifiedAnswer(q, answer)}`,
                          }
                      })
                    : [
                          {
                              id: FIRST_TIME_CUSTOMER_QUESTION_KEY,
                              answer: t('Regular client. Form skipped.'),
                              question: t('Is this your first visit here?'),
                              textified: t('Regular client. Form skipped.'),
                          },
                      ]
            setAnswers(JSON.stringify(result))
            onContinue()
        },
        [form.onlyForFirstTimeCustomers, onContinue, questions, setAnswers, t]
    )

    const initialValues: FormValues = useMemo(() => {
        const parsedAnswers = tryParse(answers, [])
        const values: FormValues = { firstTime: 'yes' }
        for (const question of questions) {
            const answer = parsedAnswers.find((a: any) => a.id === question.id)
            values[question.id] = answer?.answer || (question.type === QuestionType.SHORT ? '' : [])
        }
        return values
    }, [answers, questions])

    const validate = useCallback(
        (values: FormValues) => {
            const errors: any = {}
            if (form.onlyForFirstTimeCustomers && values.firstTime === 'no') {
                return errors
            }
            for (const question of questions) {
                if (
                    question.required &&
                    (!values[question.id] || (Array.isArray(values[question.id]) && values[question.id].length === 0))
                ) {
                    errors[question.id] = 'required'
                }
            }
            return errors
        },
        [form.onlyForFirstTimeCustomers, questions]
    )

    return (
        <>
            <Formik
                initialValues={initialValues}
                enableReinitialize
                validate={validate}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={onsubmit}
            >
                {({ isValid, isSubmitting, values }) => (
                    <>
                        <Content>
                            {!business.hideMinupBranding ? <MinupRibbon /> : null}
                            <BookingPageTitle title={<Trans ns="bookingpage">Questions</Trans>} />
                            <SummaryCard
                                service={service}
                                additionalServices={additionalServices}
                                day={day}
                                slot={selectedSlot}
                                business={business}
                            />
                            <Form id="questions">
                                {form.onlyForFirstTimeCustomers && (
                                    <StyledInputGroup className="mb">
                                        <label className="small semibold">
                                            <Trans ns="bookingpage">Is this your first visit here?</Trans>
                                        </label>
                                        <StyledFirstTimeOptionLabel className="bodymedium">
                                            <Field
                                                component={FormikInput}
                                                type={'radio'}
                                                value="yes"
                                                name="firstTime"
                                            />
                                            <Trans ns="bookingpage">Yes, this is my first visit.</Trans>
                                        </StyledFirstTimeOptionLabel>
                                        <StyledFirstTimeOptionLabel className="bodymedium">
                                            <Field component={FormikInput} type={'radio'} value="no" name="firstTime" />
                                            <Trans ns="bookingpage">No, I’ve visited before.</Trans>
                                        </StyledFirstTimeOptionLabel>
                                    </StyledInputGroup>
                                )}
                                {values.firstTime === 'yes' && (
                                    <Column>
                                        {questions.map((question) => (
                                            <InputGroup
                                                key={question.id}
                                                style={
                                                    question.type === QuestionType.MULTI_CHOICE
                                                        ? { alignItems: 'flex-start' }
                                                        : undefined
                                                }
                                            >
                                                <label htmlFor={question.id}>
                                                    <TextWithLink>{question.question}</TextWithLink>
                                                    {question.required && <RequiredIndicator />}
                                                </label>
                                                {question.type === QuestionType.SHORT ? (
                                                    <Field
                                                        component={FormikInput}
                                                        name={question.id}
                                                        id={question.id}
                                                        required={question.required}
                                                    />
                                                ) : (
                                                    question.options?.map((option) => (
                                                        <StyledOptionLabel key={option.id} className="bodymedium">
                                                            <Field
                                                                component={FormikInput}
                                                                type={question.isMulti ? 'checkbox' : 'radio'}
                                                                name={question.id}
                                                                id={option.id}
                                                                value={option.id}
                                                                required={
                                                                    question.isMulti
                                                                        ? (values[question.id] || []).length === 0
                                                                        : question.required
                                                                }
                                                            />
                                                            {option.value}
                                                        </StyledOptionLabel>
                                                    ))
                                                )}
                                            </InputGroup>
                                        ))}
                                    </Column>
                                )}
                            </Form>
                        </Content>
                        <Footer
                            nextButton={
                                <PrimaryButton
                                    className="continue"
                                    form="questions"
                                    type="submit"
                                    disabled={!isValid || isSubmitting}
                                >
                                    {isSubmitting && <Icon icon={faSpinner} spin />}
                                    <Trans ns="bookingpage">Continue</Trans>
                                </PrimaryButton>
                            }
                            back={() => window.history.back()}
                        />
                    </>
                )}
            </Formik>
        </>
    )
}
