import { FC } from 'react'

export const TextWithLink: FC<{ children: string }> = ({ children }) => {
    const regex = /(https:\/\/[a-zA-Z0-9/.?=+#&%\-[\]]+)/gm
    const match = children.match(regex)
    if (!match) {
        return <>{children}</>
    }
    const [firstPart, ...rest] = children.split(match[0])
    return (
        <>
            {firstPart}
            <a href={match[0]} target="_blank" rel="noreferrer">
                {match[0]}
            </a>
            <TextWithLink>{rest.join(match[0])}</TextWithLink>
        </>
    )
}
