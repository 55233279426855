import { CSSProperties } from 'react'

interface ClickableProps {
    onClick: (e: any) => void
    tabIndex?: number
}

export function asClickable<P extends { style?: CSSProperties }>(Component: React.FC<P>) {
    return ({ onClick, tabIndex, style, ...rest }: P & ClickableProps) => {
        const C = Component as any
        return (
            <C
                onClick={onClick}
                tabIndex={tabIndex || 0}
                onKeyPress={(e: any) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                        onClick(e)
                    }
                }}
                {...rest}
                style={{
                    cursor: 'pointer',
                    ...style,
                }}
            />
        )
    }
}
