import { IconDefinition, IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core'

export const LashArtist: IconDefinition = {
    icon: [
        24,
        24,
        [],
        '',
        'M3.5 5.574a1.488 1.488 0 0 0-.93.817 1.633 1.633 0 0 0-.072 1.043c.037.14.059.262.05.271a9.8 9.8 0 0 1-.559.213 9.668 9.668 0 0 0-.675.265c-.525.268-.823.804-.785 1.412.045.717.506 1.222 1.251 1.372.301.06.496.02 1.299-.27.356-.129.625-.206.633-.182.007.022.122.215.255.427s.226.4.207.417c-.019.018-.399.297-.846.62-1.096.794-1.271 1.029-1.264 1.701.004.398.119.692.385.982.436.477 1.12.627 1.679.368.116-.055.617-.392 1.112-.749.495-.358.919-.659.943-.668.024-.009.164.083.313.205.148.122.298.237.334.257.054.03 0 .139-.35.709-.559.908-.645 1.154-.563 1.598.125.675.598 1.15 1.25 1.256.445.071.996-.116 1.285-.438.092-.103.383-.538.645-.967l.476-.779.472.12.471.12.012.843c.012.817.015.85.118 1.07.149.318.395.569.708.723.242.119.296.13.646.13.35 0 .404-.011.646-.13.313-.154.559-.405.708-.723.102-.219.106-.255.118-1.05l.012-.824.408-.09c.224-.049.445-.099.49-.11.07-.016.149.092.54.73.545.893.707 1.085 1.035 1.233.956.428 1.936-.085 2.126-1.114.081-.439-.006-.69-.536-1.549-.213-.344-.387-.641-.387-.66 0-.059.641-.577.683-.551.022.013.44.314.929.667.488.353.984.686 1.1.741.559.259 1.243.109 1.679-.368.488-.534.526-1.345.087-1.9-.102-.129-.451-.41-1.027-.825a99.591 99.591 0 0 1-.903-.657c-.018-.016.068-.218.191-.45l.224-.422.388.141.789.284c.33.119.452.144.7.142.351-.003.658-.106.927-.311.614-.469.732-1.412.258-2.057-.241-.328-.389-.41-1.395-.769-.591-.212-.707-.266-.689-.322a1.61 1.61 0 0 0-.231-1.426c-.495-.652-1.487-.759-2.132-.231-.272.223-.401.468-.56 1.063-.08.296-.206.728-.28.961-.827 2.584-2.484 4.339-4.573 4.843-.5.121-1.478.147-2.065.055-2.074-.324-4.012-1.852-5.101-4.022-.287-.573-.452-.997-.683-1.752-.109-.36-.241-.72-.292-.8a1.684 1.684 0 0 0-.659-.561c-.266-.123-.762-.158-1.025-.072',
    ],
    iconName: 'lash-artist' as IconName,
    prefix: 'fas' as IconPrefix,
}
