import { takeEvery, select, put } from 'redux-saga/effects'
import { createAction2 } from '../../utils/create-action'
import { GettingStartedActionTypes } from '../actions'
import { GettingStartedState } from '../types'
import { captureException } from '../../../appInsights'

export function* finishSubTaskOnAction(action: string, guideId: string, taskId: string, subTaskIds: string[]): any {
    try {
        yield takeEvery(action, function* () {
            const state: GettingStartedState = yield select(
                (state: { gettingStarted: GettingStartedState }) => state.gettingStarted
            )
            if (
                state.currentGuide?.id === guideId &&
                subTaskIds.some(
                    (subTaskId) =>
                        state.progress &&
                        state.progress.tasks.find(
                            (task) => task.taskId === taskId && task.completedSubTasks.includes(subTaskId)
                        ) === undefined
                )
            ) {
                yield put(
                    createAction2({
                        type: GettingStartedActionTypes.FINISH_SUBTASK,
                        payload: {
                            taskId,
                            subTaskIds: [...subTaskIds],
                        },
                    })
                )
            }
        })
    } catch (e) {
        console.error(e)
        captureException(e)
    }
}
