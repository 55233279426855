import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { createAction } from '../../store/utils/create-action'
import { AdminActionTypes } from '../../store/admin/actions'

export function useShowHideNotificationIndicator() {
    const dispatch = useDispatch()
    return useCallback(
        (where: string | string[], show: boolean) => {
            dispatch(createAction(show ? AdminActionTypes.SHOW_NOTIFICIATION_INDICATOR : AdminActionTypes.HIDE_NOTIFICIATION_INDICATOR, where))
        },
        [dispatch]
    )
}
