import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { Column } from '../../../components/admin/layout/Column'
import { AdminTheme } from '../../../theme/theme'

export const DetailsCardContainer = withTheme(
    styled(Column)(({ theme }: { theme: AdminTheme }) => ({
        width: '100%',
        padding: `${theme.Spacing(2.5)} ${theme.Spacing(3)}`,
        borderRadius: theme.Spacing(1.5),
        backgroundColor: theme.BackgroundSecondary,
    }))
)
