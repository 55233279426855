import { useDispatch, useSelector } from 'react-redux'
import { AuthState, Business } from '../store/auth/types'
import { useCallback } from 'react'
import { createAction } from '../store/utils/create-action'
import { AuthActionTypes } from '../store/auth/actions'

export function useBusiness(): Business | undefined {
    return useSelector<{ auth: AuthState }, Business | undefined>((state) => state.auth.business)
}

export function useSetBusiness() {
    const dispatch = useDispatch()
    return useCallback(
        (patch: Partial<Business>) => {
            dispatch(createAction(AuthActionTypes.UPDATE_BUSINESS, patch))
        },
        [dispatch]
    )
}
