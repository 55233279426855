import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { withSpacing } from '../../../helpers/with-spacing'

export const ModalFooter = withTheme(
    withSpacing(
        styled.div({
            display: 'flex',
        }),
        { p: 3 }
    )
)
