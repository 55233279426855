import React, { FC } from 'react'
import styled from '@emotion/styled'
import logoWithText from '../../icons/logo-with-text.svg'
import { PrimaryButtonLink } from '../../components/ui-kit/button/primary'
import { withTheme } from '@emotion/react'
import { fromTheme } from '../../theme/theme'

export interface NotFoundPageProps {}

const NotFoundPageContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    overflow-y: auto;
    overflow-x: hidden;

    background-color: #fbfbfb;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    z-index: 99999;
`

const NotFoundHeader = styled.div`
    position: relative;
    width: 100%;
    box-shadow: inset 0px -1px 0px #e5e5e5;

    background-color: white;

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 20px;

    img {
        height: 28px;
    }
`

const NotFoundBody = withTheme(styled.div`
    width: 530px;
    max-width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    p {
        text-align: center;
        color: ${fromTheme('Neutral_700')};
    }
    h1 {
        ${fromTheme('HeadingLarge')};
        text-align: center;
    }
    img {
        width: 400px;
        max-width: 100%;
    }
    .four-o-four {
        line-height: 200px;
        font-weight: bold;
        font-size: 200px;
        span {
            color: ${fromTheme('Primary_500')};
        }
    }
`)

export const NotFoundPage: FC<NotFoundPageProps> = () => {
    return (
        <NotFoundPageContainer>
            <NotFoundHeader>
                <img src={logoWithText} alt="minup logo" />
            </NotFoundHeader>
            <NotFoundBody>
                <div className="four-o-four">
                    <span>404</span>
                </div>
                <h1 className="mb">Az oldal nem található.</h1>
                <p className="mb">
                    Nézd meg az URL-t, hogy nincs-e benne elírás. Ha segítségre van szükséged írj nekünk a{' '}
                    <a href="mailto:hello@minup.io">hello@minup.io</a> email címre.
                </p>
                <PrimaryButtonLink href="https://minup.io/">Vissza a főoldalra</PrimaryButtonLink>
            </NotFoundBody>
        </NotFoundPageContainer>
    )
}
