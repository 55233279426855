import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { AdminTheme } from '../../../../theme/theme'

export const ModalIllustration = withTheme(
    styled.div(({ theme }: { theme: AdminTheme }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        img: {
            maxWidth: '100%',
        },
    }))
)
