export type ImageVariant =
    | 'public'
    | 'adminsmall'
    | 'bookingpageavatar'
    | 'bookingpageeventcarddesktop'
    | 'bookingpageeventcoverdesktop'
    | 'bookingpageservice'
    | 'bookinpagecoverdesktop'
    | 'admineventcarddesktop'
    | 'social'

export function getImageUrl(imageId: string | undefined, variant: ImageVariant = 'public', defaultImage: string = '') {
    if (imageId) {
        if (imageId.startsWith('icon') || imageId.startsWith('emoji')) {
            return imageId
        } else {
            const accountHash = process.env.REACT_APP_CLOUDFLARE_ACCOUNT_HASH
            return `https://imagedelivery.net/${accountHash}/${imageId}/${variant}`
        }
    } else {
        return defaultImage
    }
}
