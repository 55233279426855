import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { FunctionComponent } from 'react'
import { Trans } from 'react-i18next'
import { AdminTheme } from '../../../theme/theme'

const EmailWarning = withTheme(
    styled.span(({ theme }: { theme: AdminTheme }) => ({
        color: theme.ContentWarning,
    }))
)

const RecommendedEmail = styled.span(() => ({
    cursor: 'pointer',
    textDecoration: 'underline',
}))

export const EmailWarningMessage: FunctionComponent<{
    suggestion: string
    onAccept: (value: string) => void
}> = ({ suggestion, onAccept }) => {
    return (
        <EmailWarning className="small">
            <Trans ns="bookingpage">
                Did you mean:{' '}
                <RecommendedEmail
                    className="semibold"
                    tabIndex={0}
                    onKeyDown={(e: React.KeyboardEvent) => {
                        if (e.key === 'Enter' || e.key === ' ') {
                            onAccept(suggestion)
                        }
                    }}
                    onClick={() => onAccept(suggestion)}
                >
                    {{ recommendedEmail: suggestion }}
                </RecommendedEmail>
                ?
            </Trans>
        </EmailWarning>
    )
}
