import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { Flex } from '../../../components/helpers/Flex'
import { AdminTheme } from '../../../theme/theme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Column } from '../../../components/admin/layout/Column'
import { Business } from '../../../store/auth/types'
import { FC } from 'react'
import { MinupRibbon } from '../../../components/helpers/MinupRibbon'
import { SuccessAnimation } from './SuccessAnimation'
import Checkmark from '../../../icons/lottie/Checkmark.lottie'
import Bell from '../../../icons/lottie/Bell.lottie'
import { faEnvelope, faExclamationTriangle, faTag } from '@fortawesome/free-solid-svg-icons'
import { ReactComponent as DarkThemeLogo } from '../../../icons/DarkThemeLogo.svg'
import { ReactComponent as Logo } from '../../../icons/Logo.svg'
import { Trans } from 'react-i18next'
import { HourDayDisplay } from './HourDayDisplay'

export const SuccessPageContainer = withTheme(
    styled(Flex)(({ theme }: { theme: AdminTheme }) => ({
        position: 'relative',
        flexDirection: 'column',
        padding: theme.Spacing(2.5),
        [theme.BreakPoint]: {
            padding: theme.Spacing(3),
            minHeight: 'auto',
        },
    }))
)

export const SuccessPageCards = withTheme(
    styled(Column)(({ theme }: { theme: AdminTheme }) => ({
        width: '100%',
        marginTop: theme.Spacing(3),
        gap: theme.Spacing(2.5),
        [theme.BreakPoint]: {
            gap: theme.Spacing(3),
        },
    }))
)

export const SuccessPageCard = withTheme(
    styled(Flex)(({ theme }: { theme: AdminTheme }) => ({
        width: '100%',
        padding: `${theme.Spacing(2.5)} ${theme.Spacing(3)}`,
        backgroundColor: theme.BackgroundSecondary,
        borderRadius: theme.Spacing(1.5),
        gap: theme.Spacing(1),
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexDirection: 'column',
        [theme.BreakPoint]: {
            alignItems: 'center',
            flexDirection: 'row',
        },
    }))
)

export const SuccessPageLearnMore = withTheme(
    styled(Flex)(({ theme }: { theme: AdminTheme }) => ({
        borderBottomLeftRadius: theme.Spacing(1),
        borderBottomRightRadius: theme.Spacing(1),
        borderTopWidth: 1,
        borderTopStyle: 'solid',
        borderTopColor: theme.BorderPrimary,
        padding: theme.Spacing(3),
    }))
)

export const SuccessPageCardIconContainer = withTheme(
    styled(Flex)(({ theme }: { theme: AdminTheme }) => ({
        width: 32,
        height: 32,
        borderRadius: theme.Spacing(1),
        padding: '6px 6px',
        backgroundColor: theme.InteractiveSecondaryHover,
    }))
)

export const SuccessPageCardIcon = withTheme(
    styled(FontAwesomeIcon)(({ theme }: { theme: AdminTheme }) => ({
        width: 20,
        height: 20,
        color: theme.InteractivePrimary,
    }))
)

export const SuccessScreen: FC<{
    clientEmail: string
    business: Business
    canCancelUntil: number
    approvalRequired: boolean
}> = ({ clientEmail, business, canCancelUntil, approvalRequired }) => {
    return (
        <>
            <SuccessPageContainer grow={1} justifyContent="flex-start">
                {!business.hideMinupBranding ? <MinupRibbon /> : null}
                <SuccessAnimation src={approvalRequired ? Bell : Checkmark} />
                {approvalRequired ? (
                    <h2 className="mtm">
                        <Trans ns="bookingpage">Booking awaiting approval</Trans>
                    </h2>
                ) : (
                    <h2 className="mtm">
                        <Trans ns="bookingpage">Successful booking</Trans>
                    </h2>
                )}
                <SuccessPageCards>
                    <SuccessPageCard>
                        <SuccessPageCardIconContainer>
                            <SuccessPageCardIcon icon={faEnvelope} />
                        </SuccessPageCardIconContainer>
                        {approvalRequired ? (
                            <p>
                                <Trans ns="bookingpage">
                                    {{ businessName: business.name }} received your appointment request.{' '}
                                    <b>Your booking will be final once the service provider approved it.</b> We’ll
                                    notify you by email.
                                </Trans>
                            </p>
                        ) : (
                            <p>
                                <Trans ns="bookingpage">
                                    A calendar invitation has been sent to <b>{{ clientEmail: clientEmail }}</b>.
                                </Trans>
                            </p>
                        )}
                    </SuccessPageCard>
                    <SuccessPageCard>
                        <SuccessPageCardIconContainer>
                            <SuccessPageCardIcon icon={faTag} />
                        </SuccessPageCardIconContainer>
                        <p>
                            <Trans ns="bookingpage">
                                Please check that the email didn’t land under the <b>Promotions</b> tab!
                            </Trans>
                        </p>
                    </SuccessPageCard>
                    {canCancelUntil > 0 ? (
                        <SuccessPageCard>
                            <SuccessPageCardIconContainer>
                                <SuccessPageCardIcon icon={faExclamationTriangle} />
                            </SuccessPageCardIconContainer>
                            <p>
                                <HourDayDisplay hours={business.canCancelUntil} bold />
                            </p>
                        </SuccessPageCard>
                    ) : null}
                </SuccessPageCards>
            </SuccessPageContainer>
            {!business.hideMinupBranding ? (
                <SuccessPageLearnMore gap={1} flexWrap="wrap">
                    <Flex gap={1}>
                        {business.theme === 'midnight' ||
                        (business.theme === 'custom' && business.customTheme?.IsDarkTheme) ? (
                            <DarkThemeLogo height="20px" width="20px" />
                        ) : (
                            <Logo height="20px" width="20px" />
                        )}
                        <Trans ns="bookingpage">Create your own booking page</Trans>
                    </Flex>
                    <a
                        href={`https://minup.io/${business.bookingPageLanguage}/`}
                        target="_blank"
                        rel="noreferrer"
                        className="underlined"
                    >
                        <Trans ns="bookingpage">Learn more about Minup</Trans>
                    </a>
                </SuccessPageLearnMore>
            ) : null}
        </>
    )
}
