import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { FC, ReactNode, useMemo } from 'react'
import { useIcons } from '../../../hooks/use-icons'
import { useEmojis } from '../../../hooks/use-emojis'
import { EmojiContainer } from '../admin/EmojiContainer'
import { ThemeProvider, withTheme } from '@emotion/react'
import { AdminTheme } from '../../../theme/theme'
import styled from '@emotion/styled'
import { useBusiness } from '../../../hooks/use-business'
import { createTheme } from '../../../theme/ThemeProvider'
import { Flex } from '../../helpers/Flex'
import { faShapes } from '@fortawesome/free-solid-svg-icons'

export const ServiceAvatarContainer = styled(Flex)(() => ({
    height: 56,
}))

const StyledIcon = withTheme(
    styled(FontAwesomeIcon)(({ theme }: { theme: AdminTheme }) => ({
        color: theme.InteractivePrimary,
    }))
)

export const ExtraImageContainer = styled.div(() => ({
    width: 8,
    marginRight: 1,
    height: '100%',
    padding: '4px 0',
    overflowX: 'hidden',
}))

const AvatarImageContainer = withTheme(
    styled(Flex)(({ theme }: { theme: AdminTheme }) => ({
        height: '100%',
        aspectRatio: '1/1',
        borderRadius: theme.Spacing(1),
        backgroundColor: theme.InteractiveSecondaryHover,
        img: {
            borderRadius: theme.Spacing(1),
            aspectRatio: '1/1',
            height: '100%',
            objectFit: 'cover',
        },
    }))
)

export const AvatarImage: FC<{
    src: string
    renderImage: (src: string) => ReactNode
    emojiSize: number
    isCategory?: boolean
}> = ({ src, renderImage, emojiSize, isCategory }) => {
    const icons = useIcons()
    const emojis = useEmojis()
    const Wrapper = useMemo(() => (isCategory ? React.Fragment : AvatarImageContainer), [isCategory])

    const icon = icons[src.split('::')[1] as keyof typeof icons]
    const emoji = emojis[src.split('::')[1] as keyof typeof emojis]

    return (
        <Wrapper>
            {src.startsWith('icon') ? (
                <StyledIcon icon={(icon?.icon || faShapes) as IconProp} style={{ fontSize: 24 }} />
            ) : src.startsWith('emoji') ? (
                <EmojiContainer size={emojiSize}>{emoji?.emoji}</EmojiContainer>
            ) : (
                renderImage(src)
            )}
        </Wrapper>
    )
}

export const AdminAvatarImage: FC<React.ComponentProps<typeof AvatarImage>> = (props) => {
    const business = useBusiness()!
    const theme = createTheme(business.theme || 'minup', business.customTheme)

    return (
        <ThemeProvider theme={theme}>
            <AvatarImage {...props} />
        </ThemeProvider>
    )
}
