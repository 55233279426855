import { FC, ReactNode } from 'react'
import { ModalBackground } from './ModalBackground'
import { ModalContainer } from './ModalBody'
import { Trans } from 'react-i18next'
import { Flex } from '../../../helpers/Flex'
import { ModalIllustration } from './ModalIllustration'
import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { AdminTheme } from '../../../../theme/theme'
import { ReactComponent as Logo } from '../../../../icons/LogoWhite.svg'
import { AdminCurrency } from '../../../helpers/Currency'
import NoiseFilter from '../../../../icons/filters/NoiseFilter.svg'

const StyledModalContainer = withTheme(
    styled(ModalContainer)(({ theme }: { theme: AdminTheme }) => ({
        padding: theme.Spacing(2.5),
        display: 'flex',
        flexDirection: 'column',
        gap: theme.Spacing(2.5),
        [theme.BreakPoint]: {
            minHeight: 555,
            padding: 0,
            gap: 0,
            flexDirection: 'row',
        },
    }))
)

const StyledIllustration = withTheme(
    styled(ModalIllustration)(({ theme }: { theme: AdminTheme }) => ({
        width: '100%',
        padding: theme.Spacing(2.5),
        display: 'flex',
        flexDirection: 'row-reverse',
        alignItems: 'flex-start',
        background: `
            radial-gradient(circle at -40px 10px, #c14af8, transparent 85%), 
            radial-gradient(circle at 280px 0px, #f8a1fd, transparent 90%), 
            radial-gradient(circle at 680px 0px, #f8a1fd, transparent 90%),
            url(${NoiseFilter})`,
        color: theme.Neutral_0,
        borderRadius: 8,
        [theme.BreakPoint]: {
            width: 300,
            flexBasis: 300,
            padding: theme.Spacing(4),
            flexDirection: 'column',
            background: `
                radial-gradient(circle at -100px -10px, #cc5fff, transparent 55%), 
                radial-gradient(circle at 280px 0px, #faefff, transparent 90%), 
                radial-gradient(circle at 30px 349px, #f200ff, transparent 90%), 
                radial-gradient(circle at 390px 720px, #b675ff, transparent 180%),
                url(${NoiseFilter})`,
            borderRadius: 0,
        },
    }))
)

export const PopupWithIllustration: FC<{
    mobileTitle: ReactNode
    trialAvailable?: boolean
    content?: ReactNode
}> = ({ mobileTitle, trialAvailable, content }) => {
    return (
        <ModalBackground fullScreen>
            <StyledModalContainer fullScreen width={752}>
                <h1 className="only-mobile mb0">{mobileTitle}</h1>
                <StyledIllustration>
                    <Logo />
                    <Flex gap={1} flexDirection="column" alignItems="flex-start">
                        <h1 className="large">
                            <Trans ns="admin">Minup Pro</Trans>
                        </h1>
                        {trialAvailable ? (
                            <h1>
                                <Trans ns="admin">Free trial</Trans>
                            </h1>
                        ) : null}
                        <p className={`${trialAvailable && 'line-through'}`}>
                            <AdminCurrency value={8} /> <Trans ns="admin">/ month</Trans>
                        </p>
                    </Flex>
                </StyledIllustration>
                {content}
            </StyledModalContainer>
        </ModalBackground>
    )
}
