import { useCallback } from 'react'
import { TFunction, useTranslation } from 'react-i18next'

export const ERROR_MESSAGE_SEPARATOR = '###'

export async function getErrorMessage(e: any, t: TFunction<'admin'>, params?: object): Promise<string> {
    let response = typeof e === 'string' ? e : e instanceof Response ? await e.text() : e?.response?.data
    if (typeof response === 'object' && typeof response.error === 'string') {
        response = response.error
    }
    if (response) {
        switch (response) {
            case 'INVALID_EMAIL':
                return t("We couldn't send the confirmation email. Please check your information and try again", {
                    ns: 'admin',
                })
            case 'CONFLICT_IN_CALENDAR':
                return t('The selected time has already been taken, please try an other one', { ns: 'admin' })
            case 'INVALID_TIME_DATA':
                return t('Appointment time is invalid', { ns: 'admin' })
            case 'EVENT_IS_FULL':
                return t("There aren't enough free seats for the event. Please refresh the page.", { ns: 'admin' })
            case 'CLIENT_EMAIL_BLOCKED':
                return (
                    t('You can’t book appointments because you’ve been blocked by the service provider.', {
                        ns: 'admin',
                    }) +
                    ERROR_MESSAGE_SEPARATOR +
                    t('If you believe this to be a mistake, please contact the service provider.', { ns: 'admin' })
                )
            case 'COUPON_NOT_APPLICABLE':
                return t('Sorry, this coupon code is not applicable to this subscription plan.', { ns: 'admin' })
            case 'COUPON_NOT_YET_APPLICABLE':
                return t('Sorry, this coupon code is not active yet. Please try again at a later date.', {
                    ns: 'admin',
                })
            case 'COUPON_EXPIRED':
                return t('Sorry, this coupon code has expired.', { ns: 'admin' })
            case 'COUPON_NOT_FOUND':
                return t('Sorry, we couldn’t find a coupon with the code: {{coupon}}', {
                    ns: 'admin',
                    ...params,
                })
            case 'SZAMLA_AGENT_KEY_VALIDATION_FAILED':
                return t('Invalid Számla Agent kulcs, please check and try again.')
        }
    }

    if (typeof e === 'object' || e.isAxiosError) {
        if (e.response && e.response.status === 429) {
            return t('Too many requests, please wait a minute and try again.', { ns: 'admin' })
        }
        if (e.response && e.response.status === 404) {
            return t('Not found', { ns: 'admin' })
        }
    }

    return t('Something went wrong, please try again later.', { ns: 'admin' })
}

export function useGetErrorMessage() {
    const { t } = useTranslation('admin')
    return useCallback((e: any, params?: object) => getErrorMessage(e, t, params), [t])
}
