import Tooltip from 'rc-tooltip'
import { TooltipProps } from 'rc-tooltip/lib/Tooltip'
import { FC, useMemo } from 'react'
import { useAdminTheme } from '../../../hooks/use-admin-theme'

export const StyledTooltip: FC<Omit<TooltipProps, 'overlay'> & { text: string | undefined; isHtml?: boolean }> = ({
    children,
    text,
    isHtml,
    ...props
}) => {
    const theme = useAdminTheme()
    const overlay = useMemo(() => {
        return text && isHtml ? <span dangerouslySetInnerHTML={{ __html: text }} /> : text
    }, [isHtml, text])
    return text ? (
        <Tooltip
            arrowContent
            placement="top"
            trigger={['click', 'hover', 'focus']}
            overlayStyle={{
                opacity: '1',
            }}
            overlayInnerStyle={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '12px',
                lineHeight: '16px',
                backgroundColor: theme.Neutral_800,
                color: theme.Primary_0,
            }}
            overlay={overlay}
            {...props}
        >
            {children}
        </Tooltip>
    ) : (
        <>{children}</>
    )
}
