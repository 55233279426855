import { CustomTheme } from '../auth/types'
import { Action, ActionWithoutPayload } from '../utils/create-action'

export enum AdminActionTypes {
    SET_ADMIN_MENU_OPEN = '[admin] set admin menu open',
    SHOW_NOTIFICIATION_INDICATOR = '[admin] show notification indicator',
    HIDE_NOTIFICIATION_INDICATOR = '[admin] hide notification indicator',
    SET_WIDGET_OPEN = '[admin] set widget open',
    ZENDESK_SCRIPT_LOADED = '[admin] zendesk script loaded',
    SET_ZENDESK_WIDGET_LOADING = '[admin] set zendesk widget loading',
    SET_EDITED_CUSTOM_THEME = '[admin] set edited custom theme',
    SET_LIVE_AGENT_AVAILABLE = '[admin] set live agent available',
    LOAD_ZENDESK = '[admin] load zendesk',
    OPEN_ZENDESK_WIDGET = '[admin] open zendesk widget',
    CLOSE_ZENDESK_WIDGET = '[admin] close zendesk widget',
    TOGGLE_ZENDESK_WIDGET = '[admin] toggle zendesk widget',
}

type SetAdminMenuOpenAction = Action<typeof AdminActionTypes.SET_ADMIN_MENU_OPEN, boolean>
type ShowNotificationIndicatorAction = Action<typeof AdminActionTypes.SHOW_NOTIFICIATION_INDICATOR, string[] | string>
type HideNotificationIndicatorAction = Action<typeof AdminActionTypes.HIDE_NOTIFICIATION_INDICATOR, string[] | string>
type SetWidgetOpenAction = Action<typeof AdminActionTypes.SET_WIDGET_OPEN, boolean>
type SetZendeskScriptLoadedAction = ActionWithoutPayload<typeof AdminActionTypes.ZENDESK_SCRIPT_LOADED>
type SetZendeskWidgetLoadingAction = Action<typeof AdminActionTypes.SET_ZENDESK_WIDGET_LOADING, boolean>
type SetEditedCustomThemeAction = Action<typeof AdminActionTypes.SET_EDITED_CUSTOM_THEME, CustomTheme | undefined>
type SetLiveAgentAvailableAction = Action<typeof AdminActionTypes.SET_LIVE_AGENT_AVAILABLE, boolean>
type LoadZendeskAction = ActionWithoutPayload<typeof AdminActionTypes.LOAD_ZENDESK>
type OpenZendeskWidgetAction = ActionWithoutPayload<typeof AdminActionTypes.OPEN_ZENDESK_WIDGET>
type CloseZendeskWidgetAction = ActionWithoutPayload<typeof AdminActionTypes.CLOSE_ZENDESK_WIDGET>
type ToggleZendeskWidgetAction = ActionWithoutPayload<typeof AdminActionTypes.TOGGLE_ZENDESK_WIDGET>

export type AdminAction =
    | SetAdminMenuOpenAction
    | ShowNotificationIndicatorAction
    | HideNotificationIndicatorAction
    | SetWidgetOpenAction
    | SetZendeskScriptLoadedAction
    | SetZendeskWidgetLoadingAction
    | SetEditedCustomThemeAction
    | SetLiveAgentAvailableAction
    | LoadZendeskAction
    | OpenZendeskWidgetAction
    | CloseZendeskWidgetAction
    | ToggleZendeskWidgetAction
