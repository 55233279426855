import { useEffect, useState } from 'react'

export function useMatchMedia(media: string) {
    const [matches, setMatches] = useState(window.matchMedia(media).matches)

    useEffect(() => {
        const detectChange = () => {
            setMatches(window.matchMedia(media).matches)
        }
        window.addEventListener('resize', detectChange)
        return () => window.removeEventListener('resize', detectChange)
    }, [media])

    return matches
}
