export function copyToClipboard(text: string) {
    const element = document.createElement('textarea')
    element.value = text
    document.body.appendChild(element)

    /* Select the text field */
    element.select()
    element.setSelectionRange(0, 99999) /* For mobile devices */

    /* Copy the text inside the text field */
    document.execCommand('copy')

    document.body.removeChild(element)
}
