import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { AdminTheme } from '../../theme/theme'
import { withSpacing } from './with-spacing'

type FlexDirection = 'row' | 'row-reverse' | 'column' | 'column-reverse'

type FlexAlignment = 'stretch' | 'center' | 'flex-start' | 'flex-end' | 'baseline'

type FlexJustify = 'center' | 'flex-start' | 'flex-end' | 'space-between' | 'space-around' | 'space-evenly'

type FlexWrap = 'wrap' | 'wrap-reverse' | 'nowrap'

interface FlexProps {
    flexDirection?: FlexDirection
    desktopDirection?: FlexDirection
    alignItems?: FlexAlignment
    desktopAlignItems?: FlexAlignment
    alignSelf?: FlexAlignment
    justifyContent?: FlexJustify
    desktopJustifyContent?: FlexJustify
    flexWrap?: FlexWrap
    grow?: number
    growMobile?: number
    shrink?: number
    basis?: string | number
    overflow?: string
    gap?: number
    rowGap?: number
}

const _Flex = styled.div<FlexProps & { theme: AdminTheme }>(
    ({
        flexDirection = 'row',
        desktopDirection,
        alignItems = 'center',
        desktopAlignItems,
        justifyContent = 'center',
        desktopJustifyContent,
        flexWrap,
        grow,
        shrink,
        basis,
        overflow,
        gap,
        rowGap,
        theme,
        growMobile,
        alignSelf,
    }) => ({
        display: 'flex',
        flexDirection,
        alignItems,
        justifyContent,
        flexWrap: flexWrap,
        flexGrow: grow,
        flexShrink: shrink,
        flexBasis: basis,
        overflow,
        gap: gap !== undefined ? theme.Spacing(gap) : undefined,
        rowGap: rowGap !== undefined ? theme.Spacing(rowGap) : undefined,
        [theme.BreakPointReverse]: {
            flexGrow: growMobile,
        },
        alignSelf,
        [theme.BreakPoint]: {
            flexDirection: desktopDirection,
            alignItems: desktopAlignItems,
            justifyContent: desktopJustifyContent,
        },
    })
)

export const Flex = withTheme(withSpacing(_Flex))
