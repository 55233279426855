import { faBan, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FC, FormEvent, Ref, useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Flex } from '../../../../components/helpers/Flex'
import { useModal } from '../../../../hooks/use-modal'
import { useSubscriptionApi } from '../../../../hooks/apis/use-subscription-api'
import { useToast } from '../../../../hooks/use-toast'
import { AuthActionTypes } from '../../../../store/auth/actions'
import { createAction } from '../../../../store/utils/create-action'
import { Icon } from '../../../../components/ui-kit/comopnents/Icon'
import { InputGroup } from '../../../../components/ui-kit/comopnents/InputGroup'
import { Textarea } from '../../../../components/ui-kit/comopnents/Textarea'
import { Input } from '../../../../components/ui-kit/comopnents/Input'
import { classNames } from '../../../../services/class-names'
import React from 'react'
import { useProFeatures } from '../pro-features/ProFeaturesList'
import { Column } from '../../../../components/admin/layout/Column'

const CancellationForm: FC<{ formRef: Ref<HTMLFormElement | undefined> }> = ({ formRef }) => {
    const { t } = useTranslation('admin')
    const reasons = useMemo(() => {
        return [
            { reason: 'missing-feature', text: t('Missed a feature that I needed') },
            { reason: 'buggy', text: t('Encountered an error') },
            { reason: 'expensive', text: t('It was too expensive') },
            { reason: 'unused', text: t("I didn't make use of the Pro features") },
            { reason: 'temporary', text: t('Only used it for a temporary project') },
            { reason: 'expectations', text: t("The Pro package wasn't what it seemed on the website") },
            { reason: 'other', text: t('Other') },
        ]
    }, [t])

    const [reason, setReason] = useState('')
    const formOnChange = useCallback((e: FormEvent) => {
        if (e.currentTarget) {
            const formData = new FormData(e.currentTarget as any)
            setReason(formData.get('reason')?.toString() || '')
        }
    }, [])

    return (
        <div className="mts">
            <p className="mb">
                <Trans ns="admin">
                    We’re looking for ways to improve our product, and your feedback will help tremendously 🙏
                </Trans>
            </p>
            <form ref={formRef as any} onChange={formOnChange}>
                <Flex flexDirection="column">
                    {reasons.map((r) => (
                        <React.Fragment key={r.reason}>
                            <InputGroup className={classNames(r.reason === reason ? 'mbs' : undefined)}>
                                <label className="bodymedium" style={{ verticalAlign: 'middle' }}>
                                    <span style={{ fontSize: '16px' }}>
                                        <Input
                                            style={{ width: '16px', height: '16px' }}
                                            type="radio"
                                            className="mrs"
                                            name="reason"
                                            value={r.reason}
                                            required
                                        />
                                    </span>
                                    {r.text}
                                </label>
                            </InputGroup>
                            {r.reason === reason ? (
                                <InputGroup className="show">
                                    <label htmlFor="details">
                                        <Trans ns="admin">Please give us some details</Trans>
                                    </label>
                                    <Textarea id="details" name="details" required maxLength={2048} />
                                </InputGroup>
                            ) : null}
                        </React.Fragment>
                    ))}
                </Flex>
            </form>
        </div>
    )
}

export function useCancelSubscription(cancelMessage?: string, onDismiss?: () => void) {
    const confirmation = useModal()
    const { cancel } = useSubscriptionApi()
    const dispatch = useDispatch()
    const { success } = useToast()
    const { t } = useTranslation('admin')
    const { error } = useToast()
    const proFeatures = useProFeatures()

    const warningDialogOptions = useMemo(() => {
        return {
            title: <Trans ns="admin">Switch to the Free plan?</Trans>,
            primaryButtonText: (
                <>
                    <Trans ns="admin">Next</Trans> <Icon icon={faChevronRight} className="end-icon" />
                </>
            ),
            primaryButtonClass: 'danger',
            cancelButtonText: <Trans ns="admin">Cancel</Trans>,
            dismissable: true,
            text: (
                <div className="mts">
                    <p className="mb">
                        <Trans ns="admin">The Pro features you have been using will no longer be available.</Trans>
                    </p>
                    <Column spacing={2}>
                        {proFeatures.map((feature, idx) => (
                            <Flex key={idx} justifyContent="flex-start">
                                <FontAwesomeIcon icon={faBan} className="mrs danger" />
                                <div>{feature.cancellationWarning}</div>
                            </Flex>
                        ))}
                    </Column>
                </div>
            ),
        }
    }, [proFeatures])

    const reasonDialogOptions = useMemo(() => {
        return {
            title: <Trans ns="admin">Please share your reason for cancelling</Trans>,
            primaryButtonText: <Trans ns="admin">Switch to Free plan</Trans>,
            primaryButtonClass: 'danger',
            cancelButtonText: <Trans ns="admin">Cancel</Trans>,
            dismissable: true,
            FormComponent: CancellationForm,
        }
    }, [])

    return useCallback(async () => {
        if (await confirmation.show(warningDialogOptions)) {
            const answer = await confirmation.show(reasonDialogOptions)
            if (answer) {
                try {
                    const response = await cancel(answer)
                    if (response.data.subscriptionTier === 'pro' && response.data.isCancelPending) {
                        confirmation.show({
                            title: <Trans ns="admin">Subscription canceled</Trans>,
                            text: (
                                <Trans ns="admin">
                                    You'll still enjoy all the Pro features until the end of the billing period.
                                </Trans>
                            ),

                            primaryButtonText: <Trans ns="admin">Ok</Trans>,
                        })
                    } else {
                        success(cancelMessage || t('Subscription canceled'))
                    }
                    dispatch(createAction(AuthActionTypes.UPDATE_BUSINESS, response.data))
                } catch (e) {
                    error(t('Something went wrong, please try again later.'))
                }
                return
            }
        }
        onDismiss && onDismiss()
    }, [
        cancel,
        cancelMessage,
        confirmation,
        dispatch,
        error,
        onDismiss,
        reasonDialogOptions,
        success,
        t,
        warningDialogOptions,
    ])
}
