import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { AdminTheme } from '../../../theme/theme'

export const AdminPageContent = withTheme(
    styled.div(({ theme, height }: { theme: AdminTheme; height?: string }) => ({
        padding: `0 ${theme.Spacing(2)}`,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        height,
        [theme.BreakPoint]: {
            padding: `0 ${theme.Spacing(8)}`,
        },
    }))
)
