import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FC } from 'react'
import { Trans } from 'react-i18next'
import { Flex } from '../../../../components/helpers/Flex'
import { EmojiContainer } from '../../../../components/ui-kit/admin/EmojiContainer'
import { ModalBackground } from '../../../../components/ui-kit/admin/modal/ModalBackground'
import { ModalContainer } from '../../../../components/ui-kit/admin/modal/ModalBody'
import { ModalContent } from '../../../../components/ui-kit/admin/modal/ModalContent'
import { ModalFooter } from '../../../../components/ui-kit/admin/modal/ModalFooter'
import { ModalHeader } from '../../../../components/ui-kit/admin/modal/ModalHeader'
import { PrimaryButton } from '../../../../components/ui-kit/button/primary'
import { useBusiness } from '../../../../hooks/use-business'
import { ProFeature } from '../pro-features/ProFeaturesList'

export const SubscriptionSuccessPopup: FC<{
    filteredFeatures: ProFeature[]
    onClose: () => void
}> = ({ filteredFeatures, onClose }) => {
    const business = useBusiness()
    return (
        <ModalBackground>
            <ModalContainer width={535} className="text-centered">
                <ModalHeader className="pbm">
                    <Flex flexDirection="column" gap={2} className="w100">
                        <EmojiContainer size={50}>🚀</EmojiContainer>
                        <h1>
                            {business?.proTrialAvailable ? (
                                <Trans ns="admin">Your 14-day free trial has launched</Trans>
                            ) : (
                                <Trans ns="admin">You’ve upgraded to Minup Pro</Trans>
                            )}
                        </h1>
                    </Flex>
                </ModalHeader>
                <ModalContent>
                    <Flex className="w100">
                        <p>
                            <Trans ns="admin">
                                <b>{{ title: filteredFeatures[0].title }}</b> and all the other Pro features are now
                                available for you!
                            </Trans>
                        </p>
                    </Flex>
                </ModalContent>
                <ModalFooter>
                    <Flex className="w100">
                        <PrimaryButton onClick={onClose}>
                            <Trans ns="admin">Save changes</Trans>
                            <FontAwesomeIcon className="mls" icon={faArrowRight} />
                        </PrimaryButton>
                    </Flex>
                </ModalFooter>
            </ModalContainer>
        </ModalBackground>
    )
}
