import styled, { CreateStyledComponent } from '@emotion/styled'
import { NavLink } from 'react-router-dom'
import { AdminTheme } from '../../../theme/theme'
import { withTheme } from '@emotion/react'
import { FaContainer } from '../comopnents/Icon'

function asPrimaryButton<ComponentProps extends {}, SpecificComponentProps extends {}>(
    component: CreateStyledComponent<ComponentProps, SpecificComponentProps>
) {
    return withTheme(
        component(({ theme }: { theme: AdminTheme }) => ({
            display: 'flex',
            textDecoration: 'none',
            outline: 'none',
            ...theme._BodyMedium,
            color: theme.FontColor,
            backgroundColor: theme.InteractivePrimary,
            padding: `${theme.Spacing(1)} ${theme.Spacing(3)}`,
            lineHeight: '22px',
            borderRadius: theme.ButtonBorderRadius,
            border: '1px solid rgba(0, 0, 0, 0)',

            cursor: 'pointer',
            alignItems: 'center',
            justifyContent: 'center',

            '&:hover': {
                color: theme.FontColor,
                backgroundColor: theme.InteractivePrimaryHover,
            },

            '&:focus-visible': {
                boxShadow: `0 0 0 4px ${theme.FocusOutline}`,
            },

            '&:active': {
                boxShadow: 'none',
                backgroundColor: theme.InteractivePrimaryActive,
            },

            '&.danger': {
                color: theme.ContentInverted,
                backgroundColor: theme.ContentDanger,
                '&:hover': {
                    color: theme.Neutral_0,
                    backgroundColor: theme.ContentDanger,
                },

                '&:focus-visible': {
                    color: theme.Neutral_0,
                    boxShadow: `0 0 0 4px ${theme.Opacity_30}`,
                },

                '&:active': {
                    color: theme.Neutral_0,
                    boxShadow: 'none',
                    backgroundColor: theme.ContentDanger,
                },
            },

            '&:disabled, &.disabled': {
                backgroundColor: `${theme.Neutral_300} !important`,
            },

            [FaContainer as any]: {
                height: '22px',
                width: 'auto',
                marginLeft: -2,
                marginRight: theme.Spacing(1),
                '&.end-icon': {
                    marginRight: -2,
                    marginLeft: theme.Spacing(1),
                },
            },
        }))
    )
}

export const PrimaryButton = asPrimaryButton(styled.button)

export const PrimaryButtonLink = asPrimaryButton(styled.a)

export const PrimaryButtonNavLink = asPrimaryButton(styled(NavLink))
