import styled from '@emotion/styled'
import { NeutralIconButton } from '../../button/NeutralIconButton'
import { withTheme } from '@emotion/react'
import { AdminTheme } from '../../../../theme/theme'

export const LegacyCloseModalButton = withTheme(
    styled(NeutralIconButton)(({ theme }: { theme: AdminTheme }) => ({
        position: 'absolute',
        top: theme.Spacing(1),
        right: theme.Spacing(1),
    }))
)

export const CloseModalButton = withTheme(
    styled(NeutralIconButton)(
        ({ top = 2.5, right = 2.5, theme }: { top?: number; right?: number; theme: AdminTheme }) => ({
            position: 'absolute',
            top: theme.Spacing(top),
            right: theme.Spacing(right),
        })
    )
)
