export enum GUIDE_IDS {
    LEARN_THE_BASICS = 'learn-the-basics',
    FINISH_SETUP = 'finish-setup',
}

export enum LEARN_THE_BASICS_TASK_IDS {
    SET_UP_ACCOUNT = 'SET_UP_ACCOUNT',
    CREATE_A_SERVICE = 'CREATE_A_SERVICE',
    COMPLETE_YOUR_PROFILE = 'COMPLETE_YOUR_PROFILE',
    SET_UP_WORKING_HOURS = 'SET_UP_WORKING_HOURS',
    CHOOSE_YOUR_STYLE = 'CHOOSE_YOUR_STYLE',
}

export enum LEARN_THE_BASICS_SUB_TASK_IDS {
    GO_TO_SERVICES = 'GO_TO_SERVICES',
    ADD_A_SERVICE = 'ADD_A_SERVICE',
    GO_TO_PROFILE_SETTINGS = 'GO_TO_PROFILE_SETTINGS',
    UPLOAD_PROFILE_PICTURE = 'UPLOAD_PROFILE_PICTURE',
    WRITE_A_BIO = 'WRITE_A_BIO',
    GO_TO_WORKING_HOURS = 'GO_TO_WORKING_HOURS',
    SELECT_WORKING_HOURS = 'SELECT_WORKING_HOURS',
    GO_TO_APPEARANCE = 'GO_TO_APPEARANCE',
    SELECT_THEME = 'SELECT_THEME',
    UPLOAD_COVER_PICTURE = 'UPLOAD_COVER_PICTURE',
}

export enum FINISH_SETUP_TASK_IDS {
    REVIEW_SCHEDULING_RULES = 'REVIEW_SCHEDULING_RULES',
    BOOK_AN_APPOINTMENT = 'BOOK_AN_APPOINTMENT',
    VIEW_BOOKED_APPOINTMENT = 'VIEW_BOOKED_APPOINTMENT',
    SHARE_YOUR_BOOKINGPAGE = 'SHARE_YOUR_BOOKINGPAGE',
}

export enum FINISH_SETUP_SUB_TASK_IDS {
    GO_TO_SCHEDULING_RULES = 'GO_TO_SCHEDULING_RULES',
    DEFINE_SCHEDULING_RULES = 'DEFINE_SCHEDULING_RULES',
    GO_TO_BOOKING_PAGE = 'GO_TO_BOOKING_PAGE',
    SELECT_AND_BOOK_AN_APPOINTMENT = 'SELECT_AND_BOOK_AN_APPOINTMENT',
    GO_TO_YOUR_CALENDAR = 'GO_TO_YOUR_CALENDAR',
    OPEN_BOOKED_APPOINTMENT = 'OPEN_BOOKED_APPOINTMENT',
    GO_TO_BOOKING_PAGE_SHARE = 'GO_TO_BOOKING_PAGE_SHARE',
    CLICK_ON_SHARE = 'CLICK_ON_SHARE',
    COPY_LINK = 'COPY_LINK',
}
