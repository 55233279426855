import { FC, useEffect, useState } from 'react'
import { Business } from '../../../store/auth/types'
import { OccurrencePublicDto } from '../../../store/events/types'
import axios from 'axios'
import { Trans } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { LoadingScreen } from '../../../components/ui-kit/comopnents/LoadingScreen'
import { UpcomingEventsColumn } from './UpcomingEventsColumn'

export const EventsEmbedList: FC<{ business: Business; hideTitle?: boolean }> = ({ business, hideTitle }) => {
    const params = useParams<{ handle: string }>()
    const [loading, setLoading] = useState(true)
    const [events, setEvents] = useState<OccurrencePublicDto[]>([])

    useEffect(() => {
        ;(async () => {
            try {
                const handle = encodeURIComponent(params.handle)
                if (handle) {
                    const eventsResponse = await axios.get(`/api/book/byhandle/${handle}/events`)
                    setEvents(eventsResponse.data)
                }
            } catch {}
            setLoading(false)
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params])

    return loading ? (
        <LoadingScreen />
    ) : events.length ? (
        <>
            {!hideTitle && (
                <h2 className="centered mb">
                    <Trans ns="bookingpage">Book an appointment</Trans>
                </h2>
            )}
            <UpcomingEventsColumn business={business} events={events} isEmbed />
        </>
    ) : (
        <Trans ns="bookingpage">No upcoming events</Trans>
    )
}
