import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { AdminTheme } from '../../../theme/theme'

export const CardInput = withTheme(
    styled.div(({ theme }: { theme: AdminTheme }) => ({
        marginTop: theme.Spacing(0.5),
        padding: theme.Spacing(1),
        borderRadius: theme.Spacing(1),
        border: `1px solid ${theme.Neutral_400}`,
        minHeight: 42,
        '&.rc-card-field--focused': {
            borderColor: theme.Primary_700,
            boxShadow: `0 0 0 4px ${theme.Opacity_30}`,
        },
        '&.error': {
            borderColor: theme.Secondary4_700,
        },
        '&.error.rc-card-field--focused': {
            boxShadow: `0 0 0 4px ${theme.Secondary4_200}`,
        },
    }))
)
