import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { AdminTheme, fromTheme } from '../../../theme/theme'
import { Input } from './Input'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ComponentProps, CSSProperties, FC, RefObject, useMemo } from 'react'

const DropDownOptionListUl = withTheme(styled.ul`
    box-sizing: border-box;
    position: absolute;
    top: 105%;
    width: 100%;
    background: ${fromTheme('SurfaceColor')};
    border-radius: 8px;
    border: 1px solid ${fromTheme('BorderPrimary')};
    list-style-type: none;
    margin-top: 0;
    padding: 5px 0;
    z-index: 9;
    max-height: 268px;
    overflow-y: auto;
    box-shadow: ${fromTheme('Elevation_200')};
    scrollbar-width: auto;
    & > li {
        padding: 8px 16px;
        cursor: pointer;
        border-radius: 8px;
        &:hover {
            background: ${fromTheme('InteractiveNeutralHover')};
        }
        &:active {
            background: ${fromTheme('InteractiveNeutralActive')};
        }
        &.no-match {
            background: none !important;
            cursor: default;
            color: ${fromTheme('Neutral_500')};
        }
    }
`)

export const DropDownOptionList: FC<
    {
        inputRef?: RefObject<HTMLElement | undefined>
        dropdownMaxHeight?: number
        dropdownWidth?: number
    } & ComponentProps<typeof DropDownOptionListUl>
> = ({ children, inputRef, dropdownWidth, dropdownMaxHeight = 260, style, ...rest }) => {
    const dropdownStyle = useMemo<CSSProperties | undefined>(() => {
        let props = {
            ...style,
        }
        if (inputRef?.current) {
            const bodyRect = document.body.getBoundingClientRect(),
                elemRect = inputRef.current.getBoundingClientRect(),
                offset = elemRect.top - bodyRect.top + elemRect.height + 5
            props = {
                ...props,
                position: 'fixed',
                width: dropdownWidth || inputRef.current.clientWidth,
                left: Math.max(
                    8,
                    Math.min(elemRect.left, dropdownWidth ? bodyRect.width - dropdownWidth - 8 : elemRect.left)
                ),
                maxWidth: bodyRect.width - 16,
                top: Math.min(offset, bodyRect.height - dropdownMaxHeight - 8),
                maxHeight: dropdownMaxHeight,
            }
        }
        return props
    }, [dropdownMaxHeight, dropdownWidth, inputRef, style])

    return (
        <DropDownOptionListUl {...rest} style={dropdownStyle}>
            {children}
        </DropDownOptionListUl>
    )
}

export const StyledInput = withTheme(styled(Input)`
    &.open {
        border: 1px solid ${fromTheme('Primary_700')};
        outline: none;
        box-shadow: 0 0 0 4px rgba(55, 114, 255, 0.3);
    }
`)

export const StyledFontAwesomeIcon = withTheme(
    styled(FontAwesomeIcon)(({ theme }: { theme: AdminTheme }) => ({
        position: 'absolute',
        right: '12px',
        cursor: 'pointer',
        color: theme.Neutral_700,
        fontSize: '14px',
    }))
)
