import { FC } from 'react'
import { useAdminState } from '../../../hooks/admin/use-admin-state'
import { NeutralIconButton } from '../../ui-kit/button/NeutralIconButton'
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'
import { useSetAdminMenuOpen } from '../../../hooks/admin/use-set-admin-menu-open'

export const ToggleMenuButton: FC = () => {
    const setAdminMenuOpen = useSetAdminMenuOpen()
    const { adminMenuOpen } = useAdminState()
    return (
        <NeutralIconButton
            className="only-mobile"
            icon={adminMenuOpen ? faTimes : faBars}
            type="button"
            onClick={() => setAdminMenuOpen(!adminMenuOpen)}
        />
    )
}
