import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { AdminTheme, Variant } from '../../../theme/theme'

export const Callout = withTheme(
    styled.div(({ theme, variant, subtleBorder }: { theme: AdminTheme; variant: Variant; subtleBorder?: boolean }) => ({
        padding: '12px',
        borderRadius: '8px',
        display: 'flex',
        alignItems: 'center',

        '.icon': {
            color: theme[`${variant}_700`],
            marginRight: '12px',
            width: '1rem',
        },

        '.top': {
            alignSelf: 'flex-start',
        },

        border: subtleBorder ? undefined : `1px solid ${theme[`${variant}_700`]}`,
        boxShadow: subtleBorder ? theme.Elevation_100 : undefined,
        backgroundColor: theme[`${variant}_50`],
    }))
)
