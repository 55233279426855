import styled from '@emotion/styled'
import { withTheme } from '@emotion/react'
import { AdminTheme } from '../../../theme/theme'

export const CardContainer = withTheme(
    styled.div(({ theme }: { theme: AdminTheme }) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'flex-start',
        position: 'relative',

        borderRadius: theme.Spacing(1.5),
        border: `1px solid ${theme.BorderPrimary}`,

        backgroundColor: theme.SurfaceColor,
        '&.detached': {
            borderRadius: `0 0 ${theme.Spacing(1.5)} ${theme.Spacing(1.5)}`,
            borderWidth: '0 1px 1px 1px',
        },
    }))
)
