import { createContext, FC, useCallback, useContext, useRef, useState } from 'react'
import { SubscriptionPopup } from './SubscriptionPopup'
import { ProFeature, ProFeatureId, useProFeatures } from '../pro-features/ProFeaturesList'
import { SubscriptionCheckoutPopup } from './SubscriptionCheckoutPopup'
import { SubscriptionSuccessPopup } from './SubscriptionSuccessPopup'

interface ISubscriptionPopupContext {
    show: (features: ProFeatureId[]) => Promise<boolean>
}

const SubscriptionPopupContext = createContext<ISubscriptionPopupContext>({
    show: () => Promise.reject(),
})

type DialogType = 'subscription' | 'checkout' | 'success'

export const SubscriptionPopupContextProvider: FC = ({ children }) => {
    const [dialogState, setDialogState] = useState<DialogType | undefined>(undefined)
    const [filteredFeatures, setFilteredFeatures] = useState<ProFeature[]>([])
    const proFeatures = useProFeatures()

    const promiseRef = useRef<{ resolve: (result: boolean) => void; reject: () => void } | undefined>()

    const show = useCallback(
        (features: ProFeatureId[]) => {
            const filteredFeatures = proFeatures.filter((feature) => features.includes(feature.id))
            filteredFeatures.sort((a, b) => features.indexOf(a.id) - features.indexOf(b.id))
            setFilteredFeatures(filteredFeatures)
            setDialogState('subscription')
            promiseRef.current?.reject()
            return new Promise<boolean>((resolve, reject) => {
                promiseRef.current = { resolve, reject }
            })
        },
        [proFeatures]
    )

    const onClose = useCallback(() => {
        promiseRef.current?.resolve(false)
        promiseRef.current = undefined
        setDialogState(undefined)
    }, [])

    const onContinue = useCallback(() => {
        setDialogState('checkout')
    }, [])

    const onSuccess = useCallback(() => {
        setDialogState('success')
    }, [])

    const onFinish = useCallback(() => {
        promiseRef.current?.resolve(true)
        setDialogState(undefined)
    }, [])

    return (
        <SubscriptionPopupContext.Provider value={{ show }}>
            <>
                {children}
                {dialogState === 'subscription' ? (
                    <SubscriptionPopup onClose={onClose} onContinue={onContinue} filteredFeatures={filteredFeatures} />
                ) : dialogState === 'checkout' ? (
                    <SubscriptionCheckoutPopup onClose={onClose} onSuccess={onSuccess} />
                ) : dialogState === 'success' ? (
                    <SubscriptionSuccessPopup filteredFeatures={filteredFeatures} onClose={onFinish} />
                ) : null}
            </>
        </SubscriptionPopupContext.Provider>
    )
}

export function useSubscriptionPopup() {
    return useContext(SubscriptionPopupContext)
}
