import DOMPurify from 'dompurify'

// Allow target
DOMPurify.setConfig({
    ADD_ATTR: ['target'],
})

// Make sure that target is safe and add noopener and noreferrer if not self
DOMPurify.addHook('afterSanitizeAttributes', function (node) {
    if ('target' in node && node.getAttribute('target') !== '_self') {
        node.setAttribute('rel', 'noopener noreferrer')
    }
})
