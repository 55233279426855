import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import {
    faCalendarAlt,
    faMapMarkerAlt,
    faUserFriends,
    faCreditCard,
    faShareAlt,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DOMPurify from 'dompurify'
import moment from 'moment'
import { FC, useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Flex } from '../../../components/helpers/Flex'
import { NeutralIconButton } from '../../../components/ui-kit/button/NeutralIconButton'
import { PrimaryButton, PrimaryButtonNavLink } from '../../../components/ui-kit/button/primary'
import { useToast } from '../../../hooks/use-toast'
import { classNames } from '../../../services/class-names'
import { copyToClipboard } from '../../../services/copy-to-clipboard'
import { isMobileOS } from '../../../services/is-mobile-os'
import { EventPublicDto, OccurrencePublicDto } from '../../../store/events/types'
import { AdminTheme, fromTheme } from '../../../theme/theme'
import { getEventBookUrl } from './helpers/get-event-book-url'

const Container = withTheme(
    styled.div(({ theme }: { theme: AdminTheme }) => ({
        position: 'relative',
        padding: theme.Spacing(2.5),
        [theme.BreakPoint]: {
            padding: theme.Spacing(3),
        },
    }))
)

const DetailsGrid = withTheme(
    styled.div(({ theme }: { theme: AdminTheme }) => ({
        display: 'grid',
        gap: '24px',
        gridTemplateColumns: '44px auto',
        [theme.BreakPoint]: {
            gridTemplateColumns: '44px auto 44px auto',
        },
    }))
)

const DetailsIconContainer = withTheme(
    styled.div(({ theme }: { theme: AdminTheme }) => ({
        color: theme.ThemeColor,
        backgroundColor: theme.InteractiveSecondaryHover,
        borderRadius: '8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '44px',
    }))
)

const ShareButton = withTheme(
    styled(NeutralIconButton)(({ theme }: { theme: AdminTheme }) => ({
        fontSize: 16,
        div: {
            width: 16,
            height: 16,
        },
    }))
)

const DetailsTitle = withTheme(styled.h4`
    ${fromTheme('BodySmall')};
    color: ${fromTheme('ContentSecondary')};
    margin-bottom: 4px;
`)

const DetailsValue = withTheme(styled.div`
    ${fromTheme('BodySmallSemibold')};
    color: ${fromTheme('ContentPrimary')};
`)

export const EventDetails: FC<{
    event: EventPublicDto
    occurrences: OccurrencePublicDto[]
    isEmbed?: boolean
}> = ({ event, occurrences, isEmbed }) => {
    const params = useParams<{ handle: string; eventId: string }>()
    const description = useMemo(() => {
        return DOMPurify.sanitize(event?.description || '')
    }, [event.description])
    const { t } = useTranslation('bookingpage')

    const toast = useToast()
    const share = useCallback(() => {
        if (isMobileOS()) {
            window.navigator.share({
                url: window.location.href,
            })
        } else {
            copyToClipboard(window.location.href)
            toast.success(t('Link copied to clipboard!'), undefined, 3)
        }
    }, [t, toast])

    const empty = occurrences.length === 0
    const single = occurrences.length === 1
    const full = single && occurrences[0].currentParticipants >= occurrences[0].maxParticipants

    return (
        <Container>
            <Flex justifyContent="flex-start" gap={1}>
                <h1>{event.name}</h1>
                <ShareButton className="round opaque " icon={faShareAlt} onClick={share} />
            </Flex>
            <DetailsGrid className="mt">
                <DetailsIconContainer>
                    <FontAwesomeIcon icon={faCalendarAlt} />
                </DetailsIconContainer>
                <div>
                    <DetailsTitle>
                        <Trans ns="bookingpage">Date & time</Trans>
                    </DetailsTitle>
                    <DetailsValue>
                        {empty ? (
                            <Trans ns="bookingpage">No upcoming dates for this event</Trans>
                        ) : single ? (
                            <>
                                {moment(occurrences[0].from).format(t('date_time_full'))} -{' '}
                                {moment(occurrences[0].to).format(
                                    moment(occurrences[0].to).isSame(moment(occurrences[0].from), 'day')
                                        ? 'HH:mm'
                                        : t('date_time_full')
                                )}
                            </>
                        ) : (
                            <Trans ns="bookingpage">Multiple occurrences</Trans>
                        )}
                    </DetailsValue>
                </div>
                <DetailsIconContainer>
                    <FontAwesomeIcon icon={faMapMarkerAlt} />
                </DetailsIconContainer>
                <div>
                    <DetailsTitle>
                        <Trans ns="bookingpage">Location</Trans>
                    </DetailsTitle>
                    <DetailsValue>{event.location}</DetailsValue>
                </div>
                <DetailsIconContainer>
                    <FontAwesomeIcon icon={faUserFriends} />
                </DetailsIconContainer>
                <div>
                    <DetailsTitle>
                        <Trans ns="bookingpage">Max. attendees</Trans>
                    </DetailsTitle>
                    <DetailsValue>
                        {single ? (
                            occurrences[0].currentParticipants === -1 ? (
                                occurrences[0].maxParticipants
                            ) : (
                                <span
                                    className={classNames(
                                        occurrences[0].maxParticipants - occurrences[0].currentParticipants === 1
                                            ? 'danger'
                                            : undefined
                                    )}
                                >
                                    {Math.max(occurrences[0].maxParticipants - occurrences[0].currentParticipants, 0)}/
                                    {occurrences[0].maxParticipants} <Trans ns="bookingpage">free seats</Trans>
                                </span>
                            )
                        ) : (
                            event.maxParticipants
                        )}
                    </DetailsValue>
                </div>
                <DetailsIconContainer>
                    <FontAwesomeIcon icon={faCreditCard} />
                </DetailsIconContainer>
                <div>
                    <DetailsTitle>
                        <Trans ns="bookingpage">Price</Trans>
                    </DetailsTitle>
                    <DetailsValue>{event.price}</DetailsValue>
                </div>
            </DetailsGrid>
            <Flex className="pt" alignItems="stretch" flexDirection="column">
                {empty ? null : single ? (
                    full ? (
                        <PrimaryButton type="button" disabled>
                            <Trans ns="bookingpage">Sold out</Trans>
                        </PrimaryButton>
                    ) : (
                        <PrimaryButtonNavLink
                            to={getEventBookUrl(isEmbed, params.handle, event, event.occurrences[0].id)}
                        >
                            <Trans ns="bookingpage">Book</Trans>
                        </PrimaryButtonNavLink>
                    )
                ) : (
                    <PrimaryButtonNavLink
                        to={`/${isEmbed ? 'embed' : 'book'}/${params.handle}/events/${event.id}/dates`}
                    >
                        <Trans ns="bookingpage">Select date</Trans>
                    </PrimaryButtonNavLink>
                )}
            </Flex>
            {description && (
                <div className="pt">
                    <h3 className="mbs">
                        <Trans ns="bookingpage">Details</Trans>
                    </h3>
                    <p className="small" dangerouslySetInnerHTML={{ __html: description }} />
                </div>
            )}
        </Container>
    )
}
