import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { Flex } from '../../../components/helpers/Flex'
import { Trans } from 'react-i18next'
import { Column } from '../../../components/admin/layout/Column'
import { Service } from '../../../store/services/types'
import { Business } from '../../../store/auth/types'
import ReCAPTCHA from 'react-google-recaptcha'
import { PrimaryButton } from '../../../components/ui-kit/button/primary'
import { Icon } from '../../../components/ui-kit/comopnents/Icon'
import { faInfoCircle, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { RequiredIndicator } from '../../../components/ui-kit/comopnents/RequiredIndicator'
import i18n from '../../../i18n'
import { Input } from '../../../components/ui-kit/comopnents/Input'
import { useClientTimezone } from '../../../hooks/useClientTimezone'
import styled from '@emotion/styled'
import { captureException } from '../../../appInsights'
import { ContactInfo } from '../components/ContactInfoScreen'
import { Form, Formik } from 'formik'
import { EventPublicDto, OccurrencePublicDto } from '../../../store/events/types'
import { Footer } from './Footer'
import { Slot } from '../services/ServicePage'
import { MinupRibbon } from '../../../components/helpers/MinupRibbon'
import { BookingPageTitle } from './BookingPageTitle'
import { DetailsCard } from './SuccessDetailsCard'
import { HourDayDisplay } from './HourDayDisplay'
import { Content } from './Content'
import { ContactInfoCard } from './SuccessContactInfoCard'
import { EventSummaryCard } from '../events/EventSummaryCard'
import { SummaryCard } from '../services/SummaryCard'
import moment from 'moment'
import { Separator } from './Separator'
import { DetailsCardContainer } from './DetailsCardContainer'

const ColumnForm = styled(Form)(() => ({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
}))

interface CommonProps {
    contactInfo: ContactInfo
    business: Business
    onSubmit: (
        contactInfo: ContactInfo,
        clientTimezone: string,
        recaptchaToken: string | null | undefined
    ) => Promise<void>
}

interface ServiceProps {
    service: Service
    additionalServices: Service[]
    event?: undefined
    selectedSlot?: Slot
}

interface EventProps {
    service?: undefined
    additionalServices?: undefined
    event: EventPublicDto
    selectedSlot?: OccurrencePublicDto
}

type OverviewScreenProps = (ServiceProps | EventProps) & CommonProps

export const OverviewScreen: FC<OverviewScreenProps> = ({
    contactInfo,
    service,
    additionalServices,
    event,
    business,
    selectedSlot,
    onSubmit,
}) => {
    const recaptchaRef = useRef<ReCAPTCHA>(null)
    const [isSubmitting, setIsSubmitting] = useState(false)

    const { clientTimezone } = useClientTimezone()

    const onsubmit = useCallback(
        async (clientInfo) => {
            setIsSubmitting(true)
            let recaptchaToken = null
            if (recaptchaRef.current) {
                recaptchaToken = await recaptchaRef.current.executeAsync()
                recaptchaRef.current.reset()
            }

            if (!process.env.REACT_APP_RECAPTCHA_DISABLE && !recaptchaToken) {
                console.warn('recaptcha', recaptchaRef.current)
                captureException(new Error('No recaptcha token'))
            }

            await onSubmit(clientInfo, clientTimezone, recaptchaToken)
            setIsSubmitting(false)
        },
        [clientTimezone, onSubmit]
    )

    const titleRef = useRef<HTMLElement>()
    useEffect(() => {
        if (titleRef.current) {
            titleRef.current.scrollIntoView({ behavior: 'auto', block: 'center' })
        }
    }, [])

    return (
        <>
            {contactInfo ? (
                <Formik initialValues={contactInfo} onSubmit={onsubmit}>
                    {() => (
                        <ColumnForm>
                            <Content>
                                {!business.hideMinupBranding ? <MinupRibbon /> : null}
                                <BookingPageTitle
                                    ref={titleRef as any}
                                    title={<Trans ns="bookingpage">Booking overview</Trans>}
                                />
                                <Column>
                                    {service ? (
                                        <DetailsCard
                                            selectedSlot={selectedSlot}
                                            name={business.name}
                                            location={service.address}
                                            type={'service'}
                                        />
                                    ) : (
                                        <DetailsCard
                                            selectedSlot={selectedSlot as OccurrencePublicDto}
                                            name={business.name}
                                            location={event?.location!}
                                            type={'event'}
                                        />
                                    )}
                                </Column>
                                {service ? (
                                    <Column>
                                        <SummaryCard
                                            hideDetails
                                            service={service}
                                            additionalServices={[]}
                                            day={moment.unix(selectedSlot!.from)}
                                            slot={selectedSlot}
                                            business={business}
                                        />
                                        {additionalServices.map((service) => (
                                            <SummaryCard
                                                hideDetails
                                                service={service}
                                                additionalServices={[]}
                                                day={moment.unix(selectedSlot!.from)}
                                                slot={selectedSlot}
                                                business={business}
                                            />
                                        ))}
                                    </Column>
                                ) : (
                                    <EventSummaryCard
                                        hideDetails
                                        event={event}
                                        occurrence={selectedSlot!}
                                        business={business}
                                        clientTimezone={clientTimezone}
                                    />
                                )}
                                <Separator>
                                    <Trans ns="bookingpage">My contact info</Trans>
                                </Separator>
                                <ContactInfoCard contactInfo={contactInfo} />
                                {business.invoicingOn ? (
                                    <>
                                        <Separator>
                                            <Trans ns="bookingpage">My billing info</Trans>
                                        </Separator>
                                        <DetailsCardContainer>
                                            <Column spacing={0.5}>
                                                <span>{contactInfo.billingName}</span>
                                                <span>
                                                    {contactInfo.billingCountry} {contactInfo.billingCity}
                                                </span>
                                                <span>
                                                    {contactInfo.billingAddress} {contactInfo.billingZipCode}
                                                </span>
                                                {contactInfo.isBusiness ? (
                                                    <span>
                                                        <Trans ns="bookingpage">Tax ID</Trans>: {contactInfo.taxID}
                                                    </span>
                                                ) : null}
                                            </Column>
                                        </DetailsCardContainer>
                                    </>
                                ) : null}
                                {business.canCancelUntil ? (
                                    <Flex justifyContent="flex-start">
                                        <Icon
                                            icon={faInfoCircle}
                                            className="primary"
                                            style={{
                                                padding: '0 5px 0 4px',
                                            }}
                                        />
                                        <HourDayDisplay hours={business.canCancelUntil} />
                                    </Flex>
                                ) : null}
                                <div>
                                    <label>
                                        <Input className="mrs" type="checkbox" name="tnc" required />
                                        <Trans ns="bookingpage">
                                            I have read and accept the{' '}
                                            <a
                                                href={
                                                    i18n.language === 'hu'
                                                        ? 'https://minup.io/minup-adatkezelesi-tajekoztato/'
                                                        : 'https://minup.io/en/privacy-policy/'
                                                }
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                privacy policy
                                            </a>
                                        </Trans>
                                        <RequiredIndicator />
                                    </label>
                                </div>
                                <p className="small subtle">
                                    <Trans ns="bookingpage">
                                        This site is protected by reCAPTCHA and the Google{' '}
                                        <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">
                                            Privacy Policy
                                        </a>{' '}
                                        and{' '}
                                        <a href="https://policies.google.com/terms" target="_blank" rel="noreferrer">
                                            Terms of Service
                                        </a>{' '}
                                        apply.
                                    </Trans>
                                </p>
                            </Content>
                            {process.env.REACT_APP_RECAPTCHA_SITE_KEY && (
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    size="invisible"
                                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                />
                            )}
                            <Footer
                                nextButton={
                                    <PrimaryButton className="continue" type="submit" disabled={isSubmitting}>
                                        {isSubmitting && <Icon icon={faSpinner} spin />}
                                        <Trans ns="bookingpage">Confirm</Trans>
                                    </PrimaryButton>
                                }
                                back={() => window.history.back()}
                            />
                        </ColumnForm>
                    )}
                </Formik>
            ) : null}
        </>
    )
}
