import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { AdminTheme } from '../../../theme/theme'
import { FC, useEffect, useState } from 'react'
import { DotLottie, DotLottieReact } from '@lottiefiles/dotlottie-react'
import { useAdminTheme } from '../../../hooks/use-admin-theme'

const AnimationContainer = withTheme(
    styled.div(({ theme }: { theme: AdminTheme }) => ({
        width: '80px',
        height: '80px',
        'svg .bg path': {
            fill: theme.ThemeColor,
        },
    }))
)

export const SuccessAnimation: FC<{ src: string }> = ({ src }) => {
    const [dotLottie, setDotLottie] = useState<DotLottie | null>(null)
    const theme = useAdminTheme()

    useEffect(() => {
        if (dotLottie) {
            dotLottie.addEventListener('load', (props) => {
                dotLottie.loadThemeData(`
                        {
                            "main-shape": {
                              "p": {
                                "a": 0,
                                "k": [${theme.AnimationColor.join(',')}]
                              }
                            }
                          }`)
                dotLottie.play()
            })
        }
    }, [dotLottie, theme.AnimationColor])

    return (
        <AnimationContainer>
            <DotLottieReact src={src} dotLottieRefCallback={setDotLottie} />
        </AnimationContainer>
    )
}
