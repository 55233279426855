import { Question, QuestionType } from '../../../store/forms/types'

export function getTextifiedAnswer(question: Question, answer?: string | string[]): string {
    if (answer === undefined) {
        return ''
    }
    if (question.type === QuestionType.SHORT) {
        return answer as string
    }
    if (question.type === QuestionType.MULTI_CHOICE) {
        if (Array.isArray(answer)) {
            return answer
                .map((a) => question.options?.find((o) => o.id === a)?.value)
                .filter((a) => a)
                .join(', ')
        }
        return question.options?.find((o) => o.id === answer)?.value || ''
    }

    return ''
}
