import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { AdminTheme } from '../../../theme/theme'

export const InputWithIcon = withTheme(
    styled.div(({ theme }: { theme: AdminTheme }) => ({
        position: 'relative',
        '.addon': {
            position: 'absolute',
            fontSize: 16,
            top: 'calc(50% - 12px)',
            left: 12,
            color: theme.Neutral_700,
        },
        input: {
            boxSizing: 'border-box',
            paddingLeft: 44,
            width: '100%',
        },
        '.input-icon-button': {
            top: 0,
            right: 0,
            height: '100%',
            position: 'absolute',
            display: 'inline-flex',
            flex: '0 0 auto',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: theme.Spacing(1),
        },
    }))
)
