import styled from '@emotion/styled'
import { AdminTheme } from '../../theme/theme'

interface SpacingProps {
    p?: number
    px?: number
    py?: number
    pt?: number
    pb?: number
    pr?: number
    pl?: number
    dp?: number
    dpx?: number
    dpy?: number
    dpt?: number
    dpb?: number
    dpr?: number
    dpl?: number
    m?: number
    mx?: number
    my?: number
    mt?: number
    mb?: number
    mr?: number
    ml?: number
    dm?: number
    dmx?: number
    dmy?: number
    dmt?: number
    dmb?: number
    dmr?: number
    dml?: number
}

export function withSpacing<P extends {}>(component: React.FC<P>, defaults?: SpacingProps) {
    return styled(component)(
        ({
            theme,
            ...rest
        }: {
            theme: AdminTheme
        } & SpacingProps) => {
            const {
                p,
                px,
                py,
                pt,
                pb,
                pr,
                pl,
                dp,
                dpx,
                dpy,
                dpt,
                dpb,
                dpr,
                dpl,
                m,
                mx,
                my,
                mt,
                mb,
                mr,
                ml,
                dm,
                dmx,
                dmy,
                dmt,
                dmb,
                dmr,
                dml,
            } = {
                ...defaults,
                ...rest,
            }

            return {
                // Paddings
                ...(p !== undefined
                    ? {
                          padding: theme.Spacing(p),
                      }
                    : undefined),
                ...(py !== undefined
                    ? {
                          paddingTop: theme.Spacing(py),
                          paddingBottom: theme.Spacing(py),
                      }
                    : undefined),
                ...(px !== undefined
                    ? {
                          paddingLeft: theme.Spacing(px),
                          paddingRight: theme.Spacing(px),
                      }
                    : undefined),
                ...(pt !== undefined
                    ? {
                          paddingTop: theme.Spacing(pt),
                      }
                    : undefined),
                ...(pb !== undefined
                    ? {
                          paddingBottom: theme.Spacing(pb),
                      }
                    : undefined),
                ...(pl !== undefined
                    ? {
                          paddingLeft: theme.Spacing(pl),
                      }
                    : undefined),
                ...(pr !== undefined
                    ? {
                          paddingRight: theme.Spacing(pr),
                      }
                    : undefined),
                // Margins
                ...(m !== undefined
                    ? {
                          margin: theme.Spacing(m),
                      }
                    : undefined),
                ...(my !== undefined
                    ? {
                          marginTop: theme.Spacing(my),
                          marginBottom: theme.Spacing(my),
                      }
                    : undefined),
                ...(mx !== undefined
                    ? {
                          marginLeft: theme.Spacing(mx),
                          marginRight: theme.Spacing(mx),
                      }
                    : undefined),
                ...(mt !== undefined
                    ? {
                          marginTop: theme.Spacing(mt),
                      }
                    : undefined),
                ...(mb !== undefined
                    ? {
                          marginBottom: theme.Spacing(mb),
                      }
                    : undefined),
                ...(ml !== undefined
                    ? {
                          marginLeft: theme.Spacing(ml),
                      }
                    : undefined),
                ...(mr !== undefined
                    ? {
                          marginRight: theme.Spacing(mr),
                      }
                    : undefined),
                [theme.BreakPoint]: {
                    // Desktop Paddings
                    ...(dp !== undefined
                        ? {
                              padding: theme.Spacing(dp),
                          }
                        : undefined),
                    ...(dpy !== undefined
                        ? {
                              paddingTop: theme.Spacing(dpy),
                              paddingBottom: theme.Spacing(dpy),
                          }
                        : undefined),
                    ...(dpx !== undefined
                        ? {
                              paddingLeft: theme.Spacing(dpx),
                              paddingRight: theme.Spacing(dpx),
                          }
                        : undefined),
                    ...(dpt !== undefined
                        ? {
                              paddingTop: theme.Spacing(dpt),
                          }
                        : undefined),
                    ...(dpb !== undefined
                        ? {
                              paddingBottom: theme.Spacing(dpb),
                          }
                        : undefined),
                    ...(dpl !== undefined
                        ? {
                              paddingLeft: theme.Spacing(dpl),
                          }
                        : undefined),
                    ...(dpr !== undefined
                        ? {
                              paddingRight: theme.Spacing(dpr),
                          }
                        : undefined),
                    // Desktop Margins
                    ...(dm !== undefined
                        ? {
                              margin: theme.Spacing(dm),
                          }
                        : undefined),
                    ...(dmy !== undefined
                        ? {
                              marginTop: theme.Spacing(dmy),
                              marginBottom: theme.Spacing(dmy),
                          }
                        : undefined),
                    ...(dmx !== undefined
                        ? {
                              marginLeft: theme.Spacing(dmx),
                              marginRight: theme.Spacing(dmx),
                          }
                        : undefined),
                    ...(dmt !== undefined
                        ? {
                              marginTop: theme.Spacing(dmt),
                          }
                        : undefined),
                    ...(dmb !== undefined
                        ? {
                              marginBottom: theme.Spacing(dmb),
                          }
                        : undefined),
                    ...(dml !== undefined
                        ? {
                              marginLeft: theme.Spacing(dml),
                          }
                        : undefined),
                    ...(dmr !== undefined
                        ? {
                              marginRight: theme.Spacing(dmr),
                          }
                        : undefined),
                },
            }
        }
    )
}
