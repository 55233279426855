const getLevenshteinDistance = (a: string, b: string) => {
    const matrix = []
    let i
    for (i = 0; i <= b.length; i++) {
        matrix[i] = [i]
    }
    let j
    for (j = 0; j <= a.length; j++) {
        matrix[0][j] = j
    }
    for (i = 1; i <= b.length; i++) {
        for (j = 1; j <= a.length; j++) {
            if (b.charAt(i - 1) === a.charAt(j - 1)) {
                matrix[i][j] = matrix[i - 1][j - 1]
            } else {
                matrix[i][j] = Math.min(matrix[i - 1][j - 1] + 1, matrix[i][j - 1] + 1, matrix[i - 1][j] + 1)
            }
        }
    }
    return matrix[b.length][a.length]
}

export const getEmailSuggestion = (email: string, allowedProviders: string[]) => {
    if (!email.includes('@')) {
        return ''
    }
    const domain = email.split('@')[1]

    if (allowedProviders.includes(domain)) {
        return ''
    }

    let closestProvider = ''
    let minDistance = Infinity

    allowedProviders.forEach((allowedDomain) => {
        const distance = getLevenshteinDistance(allowedDomain, domain)
        if (distance < minDistance) {
            minDistance = distance
            closestProvider = allowedDomain
        }
    })

    if (minDistance <= 3) {
        return email.slice(0, email.indexOf('@') + 1) + closestProvider
    }

    return ''
}
