import styled, { CreateStyledComponent } from '@emotion/styled'
import { NavLink } from 'react-router-dom'
import { AdminTheme } from '../../../theme/theme'
import { withTheme } from '@emotion/react'
import { FaContainer } from '../comopnents/Icon'

type Variant = 'Pro'

function asLightButton<ComponentProps extends {}, SpecificComponentProps extends {}>(
    component: CreateStyledComponent<ComponentProps, SpecificComponentProps>
) {
    return withTheme(
        component(({ theme, variant }: { theme: AdminTheme; variant: Variant }) => ({
            display: 'flex',
            justifyContent: 'center',
            textDecoration: 'none',
            outline: 'none',
            color: theme[`Content${variant}`],
            backgroundColor: theme[`Interactive${variant}`],
            padding: '8px 24px',
            borderRadius: theme.ButtonBorderRadius,
            border: '1px solid rgba(0, 0, 0, 0)',
            fontSize: '16px',
            lineHeight: '22px',

            cursor: 'pointer',

            '&:hover': {
                color: theme[`Content${variant}`],
                backgroundColor: theme[`Interactive${variant}Hover`],
            },

            '&:focus-visible': {
                color: theme[`Content${variant}`],
                boxShadow: `0 0 0 4px ${theme.Opacity_30}`,
            },

            '&:active': {
                color: theme[`Content${variant}`],
                boxShadow: 'none',
                backgroundColor: theme[`Interactive${variant}Active`],
            },

            '&:disabled, &.disabled': {
                backgroundColor: `${theme.Neutral_200} !important`,
                color: theme.Neutral_600,
                cursor: 'default',
            },

            [FaContainer as any]: {
                height: '22px',
                width: 'auto',
                marginLeft: -2,
                marginRight: theme.Spacing(1),
                '&.end-icon': {
                    marginRight: -2,
                    marginLeft: theme.Spacing(1),
                },
            },
        }))
    )
}

export const LightButton = asLightButton(styled.button)

export const LightButtonLink = asLightButton(styled.a)

export const LightButtonNavLink = asLightButton(styled(NavLink))
