import i18n from '../i18n'

const dict: any = {
    hu: {
        hour_single: 'óra',
        hour_plural: 'óra',
        minute_single: 'perc',
        minute_plural: 'perc',
    },
    en: {
        hour_single: 'hour',
        hour_plural: 'hours',
        minute_single: 'minute',
        minute_plural: 'minutes',
    },
}

export function formatMinutes(minutes: number) {
    const hours = Math.floor(minutes / 60)
    const mins = minutes % 60
    let formatted = ''

    if (hours === 1) {
        formatted += `1 ${dict[i18n.language]['hour_single']} `
    } else if (hours > 1) {
        formatted += hours + ` ${dict[i18n.language]['hour_plural']} `
    }

    if (mins === 1) {
        formatted += `1 ${dict[i18n.language]['minute_single']} `
    } else if (mins > 1) {
        formatted += mins + ` ${dict[i18n.language]['minute_plural']} `
    }

    return formatted
}
