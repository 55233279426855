import { call, select } from 'redux-saga/effects'
import { AuthState } from '../auth/types'

const accessTokenSelector = (state: { auth: AuthState }) => state.auth.accessToken

export function* authorizedFetch(url: string, method: 'GET' | 'POST' | 'PUT' | 'DELETE' = 'GET', data?: any): any {
    const token = yield select(accessTokenSelector)
    try {
        const response: Response = yield call(fetch, url, {
            method,
            headers: {
                Authorization: `Bearer ${token}`,
                ...(data
                    ? {
                          'Content-Type': 'application/json',
                      }
                    : undefined),
            },
            body: data ? JSON.stringify(data) : undefined,
        })
        if (response.ok) {
            return response.status !== 204 ? yield call(async () => await response.json()) : undefined
        } else {
            console.error('request failed', response.status, response.statusText)
        }
    } catch (e) {
        console.error(e)
    }
    // TODO: proper error handling
    return undefined
}
