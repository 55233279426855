import { Reducer } from 'react'
import { GettingStartedAction, GettingStartedActionTypes } from './actions'
import {
    EMPTY_PROGRESS,
    GettingStartedProgress,
    GettingStartedState,
    Guide,
    INITIAL_GETTING_STARTED_STATE,
    TaskProgress,
} from './types'
import { LearnTheBasicsGuide } from '../../pages/admin/in-app-messages/guides/LearnTheBasicsGuide'
import { tryParse } from '../utils/try-parse'
import { GUIDE_IDS } from '../../pages/admin/in-app-messages/guides/constants'
import { FinishSetupGuide } from '../../pages/admin/in-app-messages/guides/FinishSetupGuide'
import { getGuideProgress } from './sagas/helpers/get-guide-progress'

export const gettingStartedReducer: Reducer<GettingStartedState, GettingStartedAction> = (state, action) => {
    let nextState = state ? state : INITIAL_GETTING_STARTED_STATE

    switch (action.type) {
        case GettingStartedActionTypes.UPDATE_PROGRESS:
            nextState = {
                ...nextState,
                progress: action.payload,
            }
            break
        case GettingStartedActionTypes.SET_MESSAGES: {
            const messages = action.payload
            const currentMessage = action.payload.find((message) => !message.viewed)
            let currentGuide: Guide | undefined = undefined
            if (currentMessage?.type === 'getting-started') {
                switch (currentMessage.messageId) {
                    case GUIDE_IDS.LEARN_THE_BASICS:
                        currentGuide = LearnTheBasicsGuide()
                        break
                    case GUIDE_IDS.FINISH_SETUP:
                        currentGuide = FinishSetupGuide()
                        break
                }
            }
            let progress: GettingStartedProgress | undefined = undefined
            if (currentMessage && currentGuide) {
                const tasks: TaskProgress[] = tryParse(currentMessage.progress)?.tasks || []
                progress = {
                    tasks,
                    activeTask:
                        currentGuide.tasks.find(
                            (task) =>
                                !tasks.some(
                                    (progress) => progress.taskId === task.id && (progress.done || progress.skipped)
                                )
                        )?.id || '',
                    progress: getGuideProgress(tasks, currentGuide),
                    messageId: currentMessage.id,
                }
            }

            nextState = {
                ...nextState,
                messages,
                currentMessage,
                currentGuide,
                progress,
            }
            break
        }
        case GettingStartedActionTypes.SET_ACTIVE_TASK:
            nextState = {
                ...nextState,
                progress: {
                    ...(nextState.progress || EMPTY_PROGRESS),
                    activeTask: action.payload,
                },
            }
            break
    }

    return nextState
}
