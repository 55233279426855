import { faGraduationCap } from '@fortawesome/free-solid-svg-icons'
import { Trans } from 'react-i18next'
import { GUIDE_IDS, LEARN_THE_BASICS_SUB_TASK_IDS, LEARN_THE_BASICS_TASK_IDS } from './constants'
import { NavLink } from 'react-router-dom'

export const LearnTheBasicsGuide = () => ({
    id: GUIDE_IDS.LEARN_THE_BASICS,
    icon: faGraduationCap,
    title: <Trans ns="admin">Learn the basics</Trans>,
    tasks: [
        {
            id: LEARN_THE_BASICS_TASK_IDS.SET_UP_ACCOUNT,
            number: 1,
            title: <Trans ns="admin">Set up your account</Trans>,
            subTasks: [],
            percentage: 20,
            linkTo: '',
            autocomplete: true,
        },
        {
            id: LEARN_THE_BASICS_TASK_IDS.COMPLETE_YOUR_PROFILE,
            number: 2,
            title: <Trans ns="admin">Complete your profile</Trans>,
            subTasks: [
                {
                    id: LEARN_THE_BASICS_SUB_TASK_IDS.GO_TO_PROFILE_SETTINGS,
                    title: (
                        <Trans ns="admin">
                            Go to Settings <NavLink to="/admin/booking/overview/profile">Profile settings</NavLink>
                        </Trans>
                    ),
                },
                {
                    id: LEARN_THE_BASICS_SUB_TASK_IDS.UPLOAD_PROFILE_PICTURE,
                    title: <Trans ns="admin">Upload a profile picture</Trans>,
                },
                {
                    id: LEARN_THE_BASICS_SUB_TASK_IDS.WRITE_A_BIO,
                    title: <Trans ns="admin">Write a bio</Trans>,
                },
            ],
            note: <Trans ns="admin">Save when you’re done!</Trans>,
            percentage: 20,
            linkTo: '/admin/booking/overview/profile',
        },
        {
            id: LEARN_THE_BASICS_TASK_IDS.CREATE_A_SERVICE,
            number: 3,
            title: <Trans ns="admin">Create a service</Trans>,
            subTasks: [
                {
                    id: LEARN_THE_BASICS_SUB_TASK_IDS.GO_TO_SERVICES,
                    title: (
                        <Trans ns="admin">
                            Go to Settings <NavLink to="/admin/booking/services">Services</NavLink>
                        </Trans>
                    ),
                },
                {
                    id: LEARN_THE_BASICS_SUB_TASK_IDS.ADD_A_SERVICE,
                    title: <Trans ns="admin">Add a service</Trans>,
                },
            ],
            note: <Trans ns="admin">Save when you’re done!</Trans>,
            percentage: 20,
            linkTo: '/admin/booking/services',
        },
        {
            id: LEARN_THE_BASICS_TASK_IDS.SET_UP_WORKING_HOURS,
            number: 4,
            title: <Trans ns="admin">Set up your working hours</Trans>,
            subTasks: [
                {
                    id: LEARN_THE_BASICS_SUB_TASK_IDS.GO_TO_WORKING_HOURS,
                    title: (
                        <Trans ns="admin">
                            Go to Settings <NavLink to="/admin/booking/working-hours">Working hours</NavLink>
                        </Trans>
                    ),
                },
                {
                    id: LEARN_THE_BASICS_SUB_TASK_IDS.SELECT_WORKING_HOURS,
                    title: <Trans ns="admin">Select the days and hours when you work during the week</Trans>,
                },
            ],
            note: <Trans ns="admin">Save when you’re done!</Trans>,
            percentage: 20,
            linkTo: '/admin/booking/working-hours',
        },
        {
            id: LEARN_THE_BASICS_TASK_IDS.CHOOSE_YOUR_STYLE,
            number: 5,
            title: <Trans ns="admin">Choose your style</Trans>,
            subTasks: [
                {
                    id: LEARN_THE_BASICS_SUB_TASK_IDS.GO_TO_APPEARANCE,
                    title: (
                        <Trans ns="admin">
                            Go to Settings <NavLink to="/admin/booking/appearance">Appearance</NavLink>
                        </Trans>
                    ),
                },

                {
                    id: LEARN_THE_BASICS_SUB_TASK_IDS.SELECT_THEME,
                    title: <Trans ns="admin">Select theme</Trans>,
                },
                {
                    id: LEARN_THE_BASICS_SUB_TASK_IDS.UPLOAD_COVER_PICTURE,
                    title: <Trans ns="admin">Upload a cover picture</Trans>,
                },
            ],
            note: <Trans ns="admin">Save when you’re done!</Trans>,
            percentage: 20,
            linkTo: '/admin/booking/appearance',
        },
    ],
})
