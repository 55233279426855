import { Reducer } from 'react'
import { NotificationActionTypes, NotificationsAction } from './actions'
import { NotificationsState } from './types'

import { v4 as uuid } from 'uuid'

const INITIAL_NOTIFICATIONS_STATE: NotificationsState = {
    notifications: [],
}

export const notificationsReducer: Reducer<NotificationsState, NotificationsAction> = (state, action) => {
    let nextState = state ? state : INITIAL_NOTIFICATIONS_STATE

    switch (action.type) {
        case NotificationActionTypes.CREATE_NOTIFICATION:
            return {
                ...nextState,
                notifications: [
                    ...nextState.notifications.filter((n) => n.id !== (action.payload as any).id),
                    {
                        ...action.payload,
                        id: (action.payload as any).id || uuid(),
                    },
                ],
            }
        case NotificationActionTypes.REMOVE_NOTIFICATION:
            return {
                ...nextState,
                notifications: nextState.notifications.filter((n) => n.id !== action.payload),
            }
        default:
            return nextState
    }
}
