import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { AdminTheme } from '../../../theme/theme'

export const AdminMain = withTheme(
    styled.div(({ theme, noPadding }: { theme: AdminTheme; noPadding?: boolean }) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        maxWidth: '100%',

        flexGrow: 1,
        overflowY: 'auto',
        paddingBottom: noPadding ? undefined : theme.Spacing(4),
    }))
)
