import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { AdminTheme } from '../../../theme/theme'

export const Separator = withTheme(
    styled.div(({ theme }: { theme: AdminTheme }) => ({
        color: theme.ContentDisabled,
        flex: '0 1 auto',
        display: 'flex',
        alignItems: 'center',
        '&::before, &::after': {
            display: 'block',
            content: "''",
            flex: 1,
            borderBottomWidth: 1,
            borderBottomStyle: 'solid',
            borderBottomColor: theme.BorderPrimary,
        },
        '&::before': {
            marginRight: theme.Spacing(1),
        },
        '&::after': {
            marginLeft: theme.Spacing(1),
        },
    }))
)
