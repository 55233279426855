import styled from '@emotion/styled'

export const StyledOptionLabel = styled.label({
    marginTop: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '8px',
    cursor: 'pointer',
})
