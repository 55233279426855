import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { AdminTheme, ThemeTokens } from '../../../theme/theme'

export type BadgeVariant = 'Info' | 'Success' | 'Danger' | 'Private' | 'Neutral' | 'Warning' | 'Pro'

function getVariantColor(variant: BadgeVariant): keyof ThemeTokens {
    switch (variant) {
        case 'Success':
        case 'Danger':
        case 'Info':
        case 'Warning':
        case 'Pro':
            return `Content${variant}`
        case 'Neutral':
            return 'ContentPrimary'
        case 'Private':
            return 'ContentInverted'
    }
}

function getVariantBackground(variant: BadgeVariant): keyof ThemeTokens {
    switch (variant) {
        case 'Info':
        case 'Success':
        case 'Danger':
        case 'Neutral':
        case 'Warning':
        case 'Pro':
            return `Background${variant}Secondary`
        case 'Private':
            return 'BackgroundInverted'
    }
}

export const Badge = withTheme(
    styled.span(({ variant = 'Success', theme }: { variant?: BadgeVariant; theme: AdminTheme }) => ({
        minHeight: theme.Spacing(3),
        padding: `${theme.Spacing(0.5)} ${theme.Spacing(2)}`,
        borderRadius: '12px',
        display: 'inline-flex',
        alignItems: 'center',
        whiteSpace: 'nowrap',

        ...theme._CaptionSemibold,

        '& > .remove': {
            marginLeft: '5px',
            marginRight: '-5px',
            cursor: 'pointer',
        },

        'svg.icon': {
            width: '12px',
            height: '12px',
            marginRight: theme.Spacing(1),
        },

        backgroundColor: theme.Primary_100,

        ...(variant
            ? {
                  color: theme[getVariantColor(variant)],
                  backgroundColor: theme[getVariantBackground(variant)],
              }
            : undefined),
    }))
)
