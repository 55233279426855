import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { AdminTheme } from '../../../theme/theme'
import { FC, ReactNode, useCallback } from 'react'
import { faArrowLeft, faCopy, faExternalLinkAlt, faShareAlt } from '@fortawesome/free-solid-svg-icons'
import { NeutralIconButton, NeutralIconButtonNavLink } from '../../ui-kit/button/NeutralIconButton'
import { Flex } from '../../helpers/Flex'
import { useToast } from '../../../hooks/use-toast'
import { copyToClipboard } from '../../../services/copy-to-clipboard'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isMobileOS } from '../../../services/is-mobile-os'
import { ToggleMenuButton } from './ToggleMenuButton'

const StyledLink = withTheme(
    styled.a(({ theme }: { theme: AdminTheme }) => ({
        display: 'flex',
        alignItems: 'center',
        marginLeft: theme.Spacing(0.5),
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        svg: {
            marginLeft: theme.Spacing(0.5),
        },
    }))
)

const AdminPageHeaderContainer = withTheme(
    styled.div(({ theme, wrapHeader, sticky }: { theme: AdminTheme; wrapHeader?: boolean; sticky?: boolean }) => ({
        display: 'flex',
        position: sticky ? 'sticky' : undefined,
        zIndex: sticky ? 1 : undefined,
        top: 0,
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: theme.Spacing(2),
        rowGap: theme.Spacing(1),
        padding: theme.Spacing(2),
        h1: {
            ...theme._HeadingSmall,
        },
        [theme.BreakPoint]: {
            padding: `${theme.Spacing(4)} ${theme.Spacing(8)}`,
            h1: {
                ...theme._HeadingMedium,
            },
            position: 'initial',
            zIndex: 'initial',
        },
        flexWrap: wrapHeader ? 'wrap' : undefined,
        backgroundColor: theme.BackgroundColor,
    }))
)

const AdminPageHeaderTitleBlock = withTheme(
    styled.div(({ theme }: { theme: AdminTheme }) => ({
        minHeight: theme.Spacing(5),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: `${theme.Spacing(1)}`,
    }))
)

const AdminPageHeaderButtonsBlock = withTheme(
    styled.div(({ theme }: { theme: AdminTheme }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: `${theme.Spacing(2.5)}`,
        [theme.BreakPoint]: {
            gap: `${theme.Spacing(2)}`,
        },
    }))
)

interface AdminPageHeaderProps {
    backLink?: string
    pageUrl?: string
    badge?: ReactNode
    title: string | ReactNode
    extra?: ReactNode
    wrap?: boolean
    sticky?: boolean
}

export const AdminPageHeader: FC<AdminPageHeaderProps> = ({ backLink, pageUrl, badge, title, extra, wrap, sticky }) => {
    const toast = useToast()
    const { t } = useTranslation('admin')
    const copyUrl = useCallback(() => {
        if (pageUrl) {
            copyToClipboard(pageUrl)
            toast.success(t('Link copied to clipboard!'), '', 3)
        }
    }, [pageUrl, t, toast])

    return (
        <AdminPageHeaderContainer className="admin-page-header-container" wrapHeader={wrap} sticky={sticky}>
            <AdminPageHeaderTitleBlock>
                {backLink === undefined ? (
                    <ToggleMenuButton />
                ) : (
                    <NeutralIconButtonNavLink icon={faArrowLeft} to={backLink} />
                )}
                <h1>{title}</h1>
                {badge !== undefined && badge}
                {pageUrl !== undefined && (
                    <Flex className="mlxs only-desktop" gap={0.5}>
                        <StyledLink href={pageUrl} target="_blank">
                            {pageUrl}
                        </StyledLink>
                        <NeutralIconButton className="interactive" onClick={copyUrl} type="button" icon={faCopy} />
                    </Flex>
                )}
            </AdminPageHeaderTitleBlock>
            <AdminPageHeaderButtonsBlock className="only-mobile">
                {pageUrl !== undefined && (
                    <>
                        <StyledLink href={pageUrl} target="_blank">
                            <FontAwesomeIcon size="1x" icon={faExternalLinkAlt} />
                        </StyledLink>
                        {isMobileOS() && (
                            <StyledLink
                                href="#"
                                onClick={() =>
                                    window.navigator.share({
                                        url: pageUrl,
                                    })
                                }
                            >
                                <FontAwesomeIcon size="1x" icon={faShareAlt} />
                            </StyledLink>
                        )}
                    </>
                )}
            </AdminPageHeaderButtonsBlock>
            {extra}
        </AdminPageHeaderContainer>
    )
}
