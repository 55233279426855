import { ReactNode } from 'react'

export enum NotificationType {
    Info = 1,
    Success = 2,
    Warning = 3,
    Error = 4,
    Black = 5,
}

export interface Notification {
    id: string
    title: string
    message?: string
    type: NotificationType
    closeAfter?: number
    cta?: ReactNode
    icon?: ReactNode
}

export interface NotificationsState {
    notifications: Notification[]
}
