import { keyframes, withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { AdminTheme } from '../../../theme/theme'
import { InputWithIcon } from './InputWithIcon'
import { FC, useCallback, useRef, useState } from 'react'
import { NeutralIconButton } from '../button/NeutralIconButton'
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons'
import { Icon } from './Icon'
import { Input } from './Input'
import { useTranslation } from 'react-i18next'

const slideIn = keyframes({
    '0%': {
        transform: 'scaleX(0)',
    },
    '100%': {
        transform: 'scaleX(1)',
    },
})

const slideOut = keyframes({
    '0%': {
        transform: 'scaleX(1)',
    },
    '100%': {
        transform: 'scaleX(0)',
    },
})

interface InputWithIconsAndAnimationProps {
    fullWidth?: boolean
}

const InputWithIconsAndAnimation = withTheme(
    styled(InputWithIcon)<{ theme: AdminTheme } & InputWithIconsAndAnimationProps>(({ theme, fullWidth }) => ({
        width: fullWidth ? '100%' : '200px',
        position: 'absolute',
        right: 0,
        transformOrigin: '100% 50%',
        [theme.BreakPointReverse]: {
            width: '100%',
        },
        '.input-icon-button': {
            color: theme.ContentPrimary,
        },
        input: {
            paddingRight: 35,
        },
        '&.slide-in': {
            animation: `${slideIn} 0.25s ease forwards`,
        },
        '&.slide-out': {
            animation: `${slideOut} 0.25s ease forwards`,
        },
    }))
)

interface SearchToggleInputProps {
    searchTerm: string
    setSearchTerm: (searchTerm: string) => void
    fullWidth?: boolean
}

export const SearchToggleInput: FC<SearchToggleInputProps> = ({ searchTerm, setSearchTerm, fullWidth }) => {
    const { t } = useTranslation('bookingpage')
    const [expanded, setExpanded] = useState(false)
    const [inTransition, setInTransition] = useState(false)
    const inputRef = useRef<HTMLInputElement>(null)

    const handleAnimationEnd = useCallback(() => {
        setInTransition(false)
    }, [])

    const toggleInput = useCallback(() => {
        setExpanded((prev) => !prev)
        setInTransition(true)
        if (expanded && searchTerm) {
            setSearchTerm('')
        }
    }, [expanded, searchTerm, setSearchTerm])

    return (
        <>
            {!expanded && !inTransition ? (
                <NeutralIconButton icon={faSearch} onClick={toggleInput} aria-label={t('Search services')} />
            ) : (
                <InputWithIconsAndAnimation
                    fullWidth={fullWidth}
                    onAnimationEnd={handleAnimationEnd}
                    className={expanded ? 'slide-in' : 'slide-out'}
                >
                    <Icon className="addon" icon={faSearch} />
                    <Input
                        type="text"
                        ref={inputRef}
                        autoFocus
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        aria-label={t('Search services')}
                    />
                    <Icon className="input-icon-button" icon={faTimes} onClick={toggleInput} />
                </InputWithIconsAndAnimation>
            )}
        </>
    )
}
