import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useFeaturedIcons } from './use-featured-icons'

export const useFreeSolidIconCategories = () => {
    const { t } = useTranslation('admin')
    return useMemo(
        () => ({
            Featured: t('icon-category-Featured'),
            Accessibility: t('icon-category-Accessibility'),
            Alert: t('icon-category-Alert'),
            Animals: t('icon-category-Animals'),
            Arrows: t('icon-category-Arrows'),
            'Audio & Video': t('icon-category-Audio & video'),
            Automotive: t('icon-category-Automotive'),
            Autumn: t('icon-category-Autumn'),
            Beverage: t('icon-category-Beverage'),
            Buildings: t('icon-category-Buildings'),
            Business: t('icon-category-Business'),
            Camping: t('icon-category-Camping'),
            Charity: t('icon-category-Charity'),
            Chat: t('icon-category-Chat'),
            Chess: t('icon-category-Chess'),
            Childhood: t('icon-category-Childhood'),
            Clothing: t('icon-category-Clothing'),
            Code: t('icon-category-Code'),
            Communication: t('icon-category-Communication'),
            Computers: t('icon-category-Computers'),
            Construction: t('icon-category-Construction'),
            Currency: t('icon-category-Currency'),
            'Date & time': t('icon-category-Date & time'),
            Design: t('icon-category-Design'),
            Editors: t('icon-category-Editors'),
            Education: t('icon-category-Education'),
            Emoji: t('icon-category-Emoji'),
            Energy: t('icon-category-Energy'),
            Files: t('icon-category-Files'),
            Finance: t('icon-category-Finance'),
            Fitness: t('icon-category-Fitness'),
            Food: t('icon-category-Food'),
            'Fruits & Vegetables': t('icon-category-Fruits & vegetables'),
            Games: t('icon-category-Games'),
            'Tabletop Gaming': t('icon-category-Tabletop Gaming'),
            Gender: t('icon-category-Gender'),
            Halloween: t('icon-category-Halloween'),
            Hands: t('icon-category-Hands'),
            Health: t('icon-category-Health'),
            Holiday: t('icon-category-Holiday'),
            Hotel: t('icon-category-Hotel'),
            Household: t('icon-category-Household'),
            Images: t('icon-category-Images'),
            Interfaces: t('icon-category-Interfaces'),
            Logistics: t('icon-category-Logistics'),
            Maps: t('icon-category-Maps'),
            Maritime: t('icon-category-Maritime'),
            Marketing: t('icon-category-Marketing'),
            Mathematics: t('icon-category-Mathematics'),
            Medical: t('icon-category-Medical'),
            Moving: t('icon-category-Moving'),
            Music: t('icon-category-Music'),
            Objects: t('icon-category-Objects'),
            'Payments & Shopping': t('icon-category-Payments & shopping'),
            Pharmacy: t('icon-category-Pharmacy'),
            Political: t('icon-category-Political'),
            Religion: t('icon-category-Religion'),
            Science: t('icon-category-Science'),
            'Science Fiction': t('icon-category-Science fiction'),
            Security: t('icon-category-Security'),
            Shapes: t('icon-category-Shapes'),
            Shopping: t('icon-category-Shopping'),
            Social: t('icon-category-Social'),
            Spinners: t('icon-category-Spinners'),
            Sports: t('icon-category-Sports'),
            Spring: t('icon-category-Spring'),
            Status: t('icon-category-Status'),
            Summer: t('icon-category-Summer'),
            Toggle: t('icon-category-Toggle'),
            Travel: t('icon-category-Travel'),
            'Users & People': t('icon-category-Users & people'),
            Vehicles: t('icon-category-Vehicles'),
            Weather: t('icon-category-Weather'),
            Winter: t('icon-category-Winter'),
            Writing: t('icon-category-Writing'),
            Other: t('icon-category-Other'),
        }),
        [t]
    )
}

export const useIcons = () => {
    const featuredIcons = useFeaturedIcons()
    return useMemo(
        () => ({
            ...featuredIcons,
            Ad: { id: 'Ad', icon: ['fas', 'ad'], category: ['Marketing'] },
            AddressBook: {
                id: 'AddressBook',
                icon: ['fas', 'address-book'],
                category: ['Business', 'Communication', 'Users & People'],
            },
            AddressCard: {
                id: 'AddressCard',
                icon: ['fas', 'address-card'],
                category: ['Business', 'Communication', 'Users & People'],
            },
            Adjust: {
                id: 'Adjust',
                icon: ['fas', 'adjust'],
                category: ['Design', 'Images'],
            },
            AirFreshener: {
                id: 'AirFreshener',
                icon: ['fas', 'air-freshener'],
                category: ['Automotive'],
            },
            AlignCenter: {
                id: 'AlignCenter',
                icon: ['fas', 'align-center'],
                category: ['Editors'],
            },
            AlignJustify: {
                id: 'AlignJustify',
                icon: ['fas', 'align-justify'],
                category: ['Editors'],
            },
            AlignLeft: {
                id: 'AlignLeft',
                icon: ['fas', 'align-left'],
                category: ['Editors'],
            },
            AlignRight: {
                id: 'AlignRight',
                icon: ['fas', 'align-right'],
                category: ['Editors'],
            },
            Allergies: {
                id: 'Allergies',
                icon: ['fas', 'allergies'],
                category: ['Hands', 'Medical', 'Spring'],
            },
            Ambulance: {
                id: 'Ambulance',
                icon: ['fas', 'ambulance'],
                category: ['Automotive', 'Health', 'Maps', 'Medical', 'Objects', 'Vehicles'],
            },
            AmericanSignLanguageInterpreting: {
                id: 'AmericanSignLanguageInterpreting',
                icon: ['fas', 'american-sign-language-interpreting'],
                category: ['Accessibility', 'Communication'],
            },
            Anchor: {
                id: 'Anchor',
                icon: ['fas', 'anchor'],
                category: ['Maps', 'Maritime', 'Objects', 'Summer'],
            },
            AngleDoubleDown: {
                id: 'AngleDoubleDown',
                icon: ['fas', 'angle-double-down'],
                category: ['Arrows'],
            },
            AngleDoubleLeft: {
                id: 'AngleDoubleLeft',
                icon: ['fas', 'angle-double-left'],
                category: ['Arrows'],
            },
            AngleDoubleRight: {
                id: 'AngleDoubleRight',
                icon: ['fas', 'angle-double-right'],
                category: ['Arrows'],
            },
            AngleDoubleUp: {
                id: 'AngleDoubleUp',
                icon: ['fas', 'angle-double-up'],
                category: ['Arrows'],
            },
            AngleDown: {
                id: 'AngleDown',
                icon: ['fas', 'angle-down'],
                category: ['Arrows'],
            },
            AngleLeft: {
                id: 'AngleLeft',
                icon: ['fas', 'angle-left'],
                category: ['Arrows'],
            },
            AngleRight: {
                id: 'AngleRight',
                icon: ['fas', 'angle-right'],
                category: ['Arrows'],
            },
            AngleUp: { id: 'AngleUp', icon: ['fas', 'angle-up'], category: ['Arrows'] },
            Angry: { id: 'Angry', icon: ['fas', 'angry'], category: ['Emoji'] },
            Ankh: { id: 'Ankh', icon: ['fas', 'ankh'], category: ['Religion'] },
            AppleAlt: {
                id: 'AppleAlt',
                icon: ['fas', 'apple-alt'],
                category: ['Autumn', 'Childhood', 'Education', 'Food', 'Fruits & Vegetables'],
            },
            Archive: {
                id: 'Archive',
                icon: ['fas', 'archive'],
                category: ['Business', 'Code', 'Files', 'Moving', 'Objects', 'Writing'],
            },
            Archway: {
                id: 'Archway',
                icon: ['fas', 'archway'],
                category: ['Buildings', 'Travel'],
            },
            ArrowAltCircleDown: {
                id: 'ArrowAltCircleDown',
                icon: ['fas', 'arrow-alt-circle-down'],
                category: ['Arrows'],
            },
            ArrowAltCircleLeft: {
                id: 'ArrowAltCircleLeft',
                icon: ['fas', 'arrow-alt-circle-left'],
                category: ['Arrows'],
            },
            ArrowAltCircleRight: {
                id: 'ArrowAltCircleRight',
                icon: ['fas', 'arrow-alt-circle-right'],
                category: ['Arrows'],
            },
            ArrowAltCircleUp: {
                id: 'ArrowAltCircleUp',
                icon: ['fas', 'arrow-alt-circle-up'],
                category: ['Arrows'],
            },
            ArrowCircleDown: {
                id: 'ArrowCircleDown',
                icon: ['fas', 'arrow-circle-down'],
                category: ['Arrows'],
            },
            ArrowCircleLeft: {
                id: 'ArrowCircleLeft',
                icon: ['fas', 'arrow-circle-left'],
                category: ['Arrows'],
            },
            ArrowCircleRight: {
                id: 'ArrowCircleRight',
                icon: ['fas', 'arrow-circle-right'],
                category: ['Arrows'],
            },
            ArrowCircleUp: {
                id: 'ArrowCircleUp',
                icon: ['fas', 'arrow-circle-up'],
                category: ['Arrows'],
            },
            ArrowDown: {
                id: 'ArrowDown',
                icon: ['fas', 'arrow-down'],
                category: ['Arrows'],
            },
            ArrowLeft: {
                id: 'ArrowLeft',
                icon: ['fas', 'arrow-left'],
                category: ['Arrows'],
            },
            ArrowRight: {
                id: 'ArrowRight',
                icon: ['fas', 'arrow-right'],
                category: ['Arrows'],
            },
            ArrowUp: { id: 'ArrowUp', icon: ['fas', 'arrow-up'], category: ['Arrows'] },
            ArrowsAlt: {
                id: 'ArrowsAlt',
                icon: ['fas', 'arrows-alt'],
                category: ['Arrows'],
            },
            ArrowsAltH: {
                id: 'ArrowsAltH',
                icon: ['fas', 'arrows-alt-h'],
                category: ['Arrows'],
            },
            ArrowsAltV: {
                id: 'ArrowsAltV',
                icon: ['fas', 'arrows-alt-v'],
                category: ['Arrows'],
            },
            AssistiveListeningSystems: {
                id: 'AssistiveListeningSystems',
                icon: ['fas', 'assistive-listening-systems'],
                category: ['Accessibility', 'Communication'],
            },
            Asterisk: {
                id: 'Asterisk',
                icon: ['fas', 'asterisk'],
                category: ['Spinners'],
            },
            At: { id: 'At', icon: ['fas', 'at'], category: ['Communication'] },
            Atlas: { id: 'Atlas', icon: ['fas', 'atlas'], category: ['Travel'] },
            Atom: {
                id: 'Atom',
                icon: ['fas', 'atom'],
                category: ['Education', 'Energy', 'Religion', 'Science', 'Science Fiction', 'Spinners'],
            },
            AudioDescription: {
                id: 'AudioDescription',
                icon: ['fas', 'audio-description'],
                category: ['Accessibility', 'Audio & Video'],
            },
            Award: {
                id: 'Award',
                icon: ['fas', 'award'],
                category: ['Education', 'Interfaces', 'Objects', 'Political'],
            },
            Baby: {
                id: 'Baby',
                icon: ['fas', 'baby'],
                category: ['Childhood', 'Users & People'],
            },
            BabyCarriage: {
                id: 'BabyCarriage',
                icon: ['fas', 'baby-carriage'],
                category: ['Childhood', 'Hotel', 'Objects', 'Vehicles'],
            },
            Backspace: { id: 'Backspace', icon: ['fas', 'backspace'], category: ['Other'] },
            Backward: {
                id: 'Backward',
                icon: ['fas', 'backward'],
                category: ['Audio & Video'],
            },
            Bacon: { id: 'Bacon', icon: ['fas', 'bacon'], category: ['Food'] },
            Bacteria: {
                id: 'Bacteria',
                icon: ['fas', 'bacteria'],
                category: ['Medical'],
            },
            Bacterium: {
                id: 'Bacterium',
                icon: ['fas', 'bacterium'],
                category: ['Medical'],
            },
            Bahai: {
                id: 'Bahai',
                icon: ['fas', 'bahai'],
                category: ['Religion', 'Spinners'],
            },
            BalanceScale: {
                id: 'BalanceScale',
                icon: ['fas', 'balance-scale'],
                category: ['Business', 'Finance', 'Maps', 'Objects', 'Political'],
            },
            BalanceScaleLeft: {
                id: 'BalanceScaleLeft',
                icon: ['fas', 'balance-scale-left'],
                category: ['Business', 'Finance', 'Maps', 'Objects', 'Political'],
            },
            BalanceScaleRight: {
                id: 'BalanceScaleRight',
                icon: ['fas', 'balance-scale-right'],
                category: ['Business', 'Finance', 'Maps', 'Objects', 'Political'],
            },
            Ban: {
                id: 'Ban',
                icon: ['fas', 'ban'],
                category: ['Interfaces', 'Security', 'Status'],
            },
            BandAid: {
                id: 'BandAid',
                icon: ['fas', 'band-aid'],
                category: ['Medical', 'Pharmacy'],
            },
            Barcode: {
                id: 'Barcode',
                icon: ['fas', 'barcode'],
                category: ['Code', 'Interfaces', 'Shopping'],
            },
            Bars: { id: 'Bars', icon: ['fas', 'bars'], category: ['Interfaces'] },
            BaseballBall: {
                id: 'BaseballBall',
                icon: ['fas', 'baseball-ball'],
                category: ['Sports'],
            },
            BasketballBall: {
                id: 'BasketballBall',
                icon: ['fas', 'basketball-ball'],
                category: ['Sports'],
            },
            Bath: {
                id: 'Bath',
                icon: ['fas', 'bath'],
                category: ['Childhood', 'Code', 'Hotel', 'Household', 'Maps', 'Objects'],
            },
            BatteryEmpty: {
                id: 'BatteryEmpty',
                icon: ['fas', 'battery-empty'],
                category: ['Energy', 'Status'],
            },
            BatteryFull: {
                id: 'BatteryFull',
                icon: ['fas', 'battery-full'],
                category: ['Energy', 'Status'],
            },
            BatteryHalf: {
                id: 'BatteryHalf',
                icon: ['fas', 'battery-half'],
                category: ['Energy', 'Status'],
            },
            BatteryQuarter: {
                id: 'BatteryQuarter',
                icon: ['fas', 'battery-quarter'],
                category: ['Energy', 'Status'],
            },
            BatteryThreeQuarters: {
                id: 'BatteryThreeQuarters',
                icon: ['fas', 'battery-three-quarters'],
                category: ['Energy', 'Status'],
            },
            Bed: {
                id: 'Bed',
                icon: ['fas', 'bed'],
                category: ['Hotel', 'Household', 'Maps', 'Objects', 'Travel', 'Users & People'],
            },
            Beer: {
                id: 'Beer',
                icon: ['fas', 'beer'],
                category: ['Beverage', 'Interfaces', 'Maps', 'Objects'],
            },
            Bell: {
                id: 'Bell',
                icon: ['fas', 'bell'],
                category: [
                    'Alert',
                    'Communication',
                    'Date & Time',
                    'Education',
                    'Household',
                    'Interfaces',
                    'Maps',
                    'Objects',
                    'Payments & Shopping',
                    'Social',
                    'Status',
                ],
            },
            BellSlash: {
                id: 'BellSlash',
                icon: ['fas', 'bell-slash'],
                category: ['Alert', 'Communication', 'Date & Time', 'Education', 'Interfaces', 'Maps', 'Status'],
            },
            BezierCurve: {
                id: 'BezierCurve',
                icon: ['fas', 'bezier-curve'],
                category: ['Design'],
            },
            Bible: { id: 'Bible', icon: ['fas', 'bible'], category: ['Religion'] },
            Bicycle: {
                id: 'Bicycle',
                icon: ['fas', 'bicycle'],
                category: ['Fitness', 'Maps', 'Objects', 'Vehicles'],
            },
            Biking: {
                id: 'Biking',
                icon: ['fas', 'biking'],
                category: ['Childhood', 'Fitness', 'Sports', 'Summer', 'Users & People'],
            },
            Binoculars: {
                id: 'Binoculars',
                icon: ['fas', 'binoculars'],
                category: ['Camping', 'Maps', 'Maritime', 'Objects'],
            },
            Biohazard: {
                id: 'Biohazard',
                icon: ['fas', 'biohazard'],
                category: ['Medical', 'Science'],
            },
            BirthdayCake: {
                id: 'BirthdayCake',
                icon: ['fas', 'birthday-cake'],
                category: ['Business', 'Childhood', 'Maps', 'Objects', 'Social'],
            },
            Blender: {
                id: 'Blender',
                icon: ['fas', 'blender'],
                category: ['Beverage', 'Household', 'Objects'],
            },
            BlenderPhone: {
                id: 'BlenderPhone',
                icon: ['fas', 'blender-phone'],
                category: ['Other'],
            },
            Blind: {
                id: 'Blind',
                icon: ['fas', 'blind'],
                category: ['Accessibility', 'Maps', 'Users & People'],
            },
            Blog: {
                id: 'Blog',
                icon: ['fas', 'blog'],
                category: ['Interfaces', 'Writing'],
            },
            Bold: { id: 'Bold', icon: ['fas', 'bold'], category: ['Editors'] },
            Bolt: { id: 'Bolt', icon: ['fas', 'bolt'], category: ['Images', 'Weather'] },
            Bomb: { id: 'Bomb', icon: ['fas', 'bomb'], category: ['Maps', 'Objects'] },
            Bone: { id: 'Bone', icon: ['fas', 'bone'], category: ['Food', 'Medical'] },
            Bong: { id: 'Bong', icon: ['fas', 'bong'], category: ['Medical'] },
            Book: {
                id: 'Book',
                icon: ['fas', 'book'],
                category: ['Business', 'Finance', 'Maps', 'Objects', 'Writing'],
            },
            BookDead: {
                id: 'BookDead',
                icon: ['fas', 'book-dead'],
                category: ['Tabletop Gaming', 'Halloween', 'Objects'],
            },
            BookMedical: {
                id: 'BookMedical',
                icon: ['fas', 'book-medical'],
                category: ['Medical', 'Pharmacy'],
            },
            BookOpen: {
                id: 'BookOpen',
                icon: ['fas', 'book-open'],
                category: ['Education'],
            },
            BookReader: {
                id: 'BookReader',
                icon: ['fas', 'book-reader'],
                category: ['Education'],
            },
            Bookmark: {
                id: 'Bookmark',
                icon: ['fas', 'bookmark'],
                category: ['Maps', 'Objects', 'Payments & Shopping', 'Shapes', 'Writing'],
            },
            BorderAll: {
                id: 'BorderAll',
                icon: ['fas', 'border-all'],
                category: ['Editors'],
            },
            BorderNone: {
                id: 'BorderNone',
                icon: ['fas', 'border-none'],
                category: ['Editors'],
            },
            BorderStyle: {
                id: 'BorderStyle',
                icon: ['fas', 'border-style'],
                category: ['Editors'],
            },
            BowlingBall: {
                id: 'BowlingBall',
                icon: ['fas', 'bowling-ball'],
                category: ['Sports'],
            },
            Box: { id: 'Box', icon: ['fas', 'box'], category: ['Logistics'] },
            BoxOpen: { id: 'BoxOpen', icon: ['fas', 'box-open'], category: ['Moving'] },
            BoxTissue: {
                id: 'BoxTissue',
                icon: ['fas', 'box-tissue'],
                category: ['Household'],
            },
            Boxes: { id: 'Boxes', icon: ['fas', 'boxes'], category: ['Logistics'] },
            Braille: {
                id: 'Braille',
                icon: ['fas', 'braille'],
                category: ['Accessibility'],
            },
            Brain: {
                id: 'Brain',
                icon: ['fas', 'brain'],
                category: ['Medical', 'Science'],
            },
            BreadSlice: {
                id: 'BreadSlice',
                icon: ['fas', 'bread-slice'],
                category: ['Food'],
            },
            Briefcase: {
                id: 'Briefcase',
                icon: ['fas', 'briefcase'],
                category: ['Business', 'Hotel', 'Maps', 'Objects'],
            },
            BriefcaseMedical: {
                id: 'BriefcaseMedical',
                icon: ['fas', 'briefcase-medical'],
                category: ['Medical'],
            },
            BroadcastTower: {
                id: 'BroadcastTower',
                icon: ['fas', 'broadcast-tower'],
                category: ['Audio & Video', 'Communication', 'Energy', 'Objects'],
            },
            Broom: {
                id: 'Broom',
                icon: ['fas', 'broom'],
                category: ['Halloween', 'Spring'],
            },
            Brush: {
                id: 'Brush',
                icon: ['fas', 'brush'],
                category: ['Construction', 'Design'],
            },
            Bug: {
                id: 'Bug',
                icon: ['fas', 'bug'],
                category: ['Code', 'Interfaces', 'Objects', 'Security'],
            },
            Building: {
                id: 'Building',
                icon: ['fas', 'building'],
                category: ['Buildings', 'Business', 'Maps', 'Objects'],
            },
            Bullhorn: {
                id: 'Bullhorn',
                icon: ['fas', 'bullhorn'],
                category: [
                    'Business',
                    'Communication',
                    'Interfaces',
                    'Marketing',
                    'Objects',
                    'Payments & Shopping',
                    'Political',
                ],
            },
            Bullseye: {
                id: 'Bullseye',
                icon: ['fas', 'bullseye'],
                category: ['Business', 'Interfaces', 'Marketing', 'Objects', 'Toggle'],
            },
            Burn: {
                id: 'Burn',
                icon: ['fas', 'burn'],
                category: ['Energy', 'Fitness', 'Medical', 'Science'],
            },
            Bus: {
                id: 'Bus',
                icon: ['fas', 'bus'],
                category: ['Automotive', 'Objects', 'Travel', 'Vehicles'],
            },
            BusAlt: {
                id: 'BusAlt',
                icon: ['fas', 'bus-alt'],
                category: ['Automotive', 'Travel', 'Vehicles'],
            },
            BusinessTime: {
                id: 'BusinessTime',
                icon: ['fas', 'business-time'],
                category: ['Business'],
            },
            Calculator: {
                id: 'Calculator',
                icon: ['fas', 'calculator'],
                category: ['Business', 'Interfaces', 'Mathematics', 'Objects'],
            },
            Calendar: {
                id: 'Calendar',
                icon: ['fas', 'calendar'],
                category: ['Business', 'Date & Time', 'Interfaces', 'Objects', 'Shapes', 'Status'],
            },
            CalendarAlt: {
                id: 'CalendarAlt',
                icon: ['fas', 'calendar-alt'],
                category: ['Business', 'Date & Time', 'Interfaces', 'Objects', 'Status'],
            },
            CalendarCheck: {
                id: 'CalendarCheck',
                icon: ['fas', 'calendar-check'],
                category: ['Date & Time', 'Interfaces', 'Status'],
            },
            CalendarDay: {
                id: 'CalendarDay',
                icon: ['fas', 'calendar-day'],
                category: ['Status'],
            },
            CalendarMinus: {
                id: 'CalendarMinus',
                icon: ['fas', 'calendar-minus'],
                category: ['Date & Time', 'Interfaces', 'Status'],
            },
            CalendarPlus: {
                id: 'CalendarPlus',
                icon: ['fas', 'calendar-plus'],
                category: ['Date & Time', 'Interfaces', 'Status'],
            },
            CalendarTimes: {
                id: 'CalendarTimes',
                icon: ['fas', 'calendar-times'],
                category: ['Date & Time', 'Interfaces', 'Status'],
            },
            CalendarWeek: {
                id: 'CalendarWeek',
                icon: ['fas', 'calendar-week'],
                category: ['Status'],
            },
            Camera: {
                id: 'Camera',
                icon: ['fas', 'camera'],
                category: ['Images', 'Objects', 'Payments & Shopping', 'Social'],
            },
            CameraRetro: {
                id: 'CameraRetro',
                icon: ['fas', 'camera-retro'],
                category: ['Images', 'Objects', 'Payments & Shopping'],
            },
            Campground: {
                id: 'Campground',
                icon: ['fas', 'campground'],
                category: ['Autumn', 'Buildings', 'Camping'],
            },
            CandyCane: {
                id: 'CandyCane',
                icon: ['fas', 'candy-cane'],
                category: ['Food', 'Holiday', 'Objects'],
            },
            Cannabis: {
                id: 'Cannabis',
                icon: ['fas', 'cannabis'],
                category: ['Medical', 'Pharmacy'],
            },
            Capsules: {
                id: 'Capsules',
                icon: ['fas', 'capsules'],
                category: ['Medical', 'Pharmacy', 'Science'],
            },
            Car: {
                id: 'Car',
                icon: ['fas', 'car'],
                category: ['Automotive', 'Hotel', 'Maps', 'Objects', 'Vehicles'],
            },
            CarAlt: {
                id: 'CarAlt',
                icon: ['fas', 'car-alt'],
                category: ['Automotive', 'Vehicles'],
            },
            CarBattery: {
                id: 'CarBattery',
                icon: ['fas', 'car-battery'],
                category: ['Automotive'],
            },
            CarCrash: {
                id: 'CarCrash',
                icon: ['fas', 'car-crash'],
                category: ['Automotive', 'Vehicles'],
            },
            CarSide: {
                id: 'CarSide',
                icon: ['fas', 'car-side'],
                category: ['Automotive', 'Vehicles'],
            },
            Caravan: {
                id: 'Caravan',
                icon: ['fas', 'caravan'],
                category: ['Automotive', 'Camping', 'Moving', 'Travel'],
            },
            CaretDown: {
                id: 'CaretDown',
                icon: ['fas', 'caret-down'],
                category: ['Arrows'],
            },
            CaretLeft: {
                id: 'CaretLeft',
                icon: ['fas', 'caret-left'],
                category: ['Arrows'],
            },
            CaretRight: {
                id: 'CaretRight',
                icon: ['fas', 'caret-right'],
                category: ['Arrows'],
            },
            CaretSquareDown: {
                id: 'CaretSquareDown',
                icon: ['fas', 'caret-square-down'],
                category: ['Arrows'],
            },
            CaretSquareLeft: {
                id: 'CaretSquareLeft',
                icon: ['fas', 'caret-square-left'],
                category: ['Arrows'],
            },
            CaretSquareRight: {
                id: 'CaretSquareRight',
                icon: ['fas', 'caret-square-right'],
                category: ['Arrows'],
            },
            CaretSquareUp: {
                id: 'CaretSquareUp',
                icon: ['fas', 'caret-square-up'],
                category: ['Arrows'],
            },
            CaretUp: { id: 'CaretUp', icon: ['fas', 'caret-up'], category: ['Arrows'] },
            Carrot: {
                id: 'Carrot',
                icon: ['fas', 'carrot'],
                category: ['Food', 'Fruits & Vegetables', 'Holiday', 'Objects'],
            },
            CartArrowDown: {
                id: 'CartArrowDown',
                icon: ['fas', 'cart-arrow-down'],
                category: ['Arrows', 'Payments & Shopping', 'Shopping', 'Status'],
            },
            CartPlus: {
                id: 'CartPlus',
                icon: ['fas', 'cart-plus'],
                category: ['Payments & Shopping', 'Shopping', 'Status'],
            },
            CashRegister: {
                id: 'CashRegister',
                icon: ['fas', 'cash-register'],
                category: ['Finance', 'Shopping'],
            },
            Cat: { id: 'Cat', icon: ['fas', 'cat'], category: ['Animals', 'Halloween'] },
            Certificate: {
                id: 'Certificate',
                icon: ['fas', 'certificate'],
                category: ['Business', 'Interfaces', 'Payments & Shopping', 'Shapes', 'Spinners'],
            },
            Chair: { id: 'Chair', icon: ['fas', 'chair'], category: ['Household'] },
            Chalkboard: {
                id: 'Chalkboard',
                icon: ['fas', 'chalkboard'],
                category: ['Communication', 'Education', 'Images'],
            },
            ChalkboardTeacher: {
                id: 'ChalkboardTeacher',
                icon: ['fas', 'chalkboard-teacher'],
                category: ['Education', 'Users & People'],
            },
            ChargingStation: {
                id: 'ChargingStation',
                icon: ['fas', 'charging-station'],
                category: ['Automotive', 'Energy'],
            },
            ChartArea: {
                id: 'ChartArea',
                icon: ['fas', 'chart-area'],
                category: ['Business'],
            },
            ChartBar: {
                id: 'ChartBar',
                icon: ['fas', 'chart-bar'],
                category: ['Business'],
            },
            ChartLine: {
                id: 'ChartLine',
                icon: ['fas', 'chart-line'],
                category: ['Arrows', 'Business', 'Finance'],
            },
            ChartPie: {
                id: 'ChartPie',
                icon: ['fas', 'chart-pie'],
                category: ['Business', 'Finance'],
            },
            Check: { id: 'Check', icon: ['fas', 'check'], category: ['Interfaces'] },
            CheckCircle: {
                id: 'CheckCircle',
                icon: ['fas', 'check-circle'],
                category: ['Interfaces', 'Toggle'],
            },
            CheckDouble: {
                id: 'CheckDouble',
                icon: ['fas', 'check-double'],
                category: ['Interfaces', 'Political'],
            },
            CheckSquare: {
                id: 'CheckSquare',
                icon: ['fas', 'check-square'],
                category: ['Interfaces'],
            },
            Cheese: { id: 'Cheese', icon: ['fas', 'cheese'], category: ['Food'] },
            Chess: { id: 'Chess', icon: ['fas', 'chess'], category: ['Chess', 'Games'] },
            ChessBishop: {
                id: 'ChessBishop',
                icon: ['fas', 'chess-bishop'],
                category: ['Chess', 'Games'],
            },
            ChessBoard: {
                id: 'ChessBoard',
                icon: ['fas', 'chess-board'],
                category: ['Chess', 'Games'],
            },
            ChessKing: {
                id: 'ChessKing',
                icon: ['fas', 'chess-king'],
                category: ['Chess', 'Games'],
            },
            ChessKnight: {
                id: 'ChessKnight',
                icon: ['fas', 'chess-knight'],
                category: ['Chess', 'Games'],
            },
            ChessPawn: {
                id: 'ChessPawn',
                icon: ['fas', 'chess-pawn'],
                category: ['Chess', 'Games'],
            },
            ChessQueen: {
                id: 'ChessQueen',
                icon: ['fas', 'chess-queen'],
                category: ['Chess', 'Games'],
            },
            ChessRook: {
                id: 'ChessRook',
                icon: ['fas', 'chess-rook'],
                category: ['Chess', 'Games'],
            },
            ChevronCircleDown: {
                id: 'ChevronCircleDown',
                icon: ['fas', 'chevron-circle-down'],
                category: ['Arrows'],
            },
            ChevronCircleLeft: {
                id: 'ChevronCircleLeft',
                icon: ['fas', 'chevron-circle-left'],
                category: ['Arrows'],
            },
            ChevronCircleRight: {
                id: 'ChevronCircleRight',
                icon: ['fas', 'chevron-circle-right'],
                category: ['Arrows'],
            },
            ChevronCircleUp: {
                id: 'ChevronCircleUp',
                icon: ['fas', 'chevron-circle-up'],
                category: ['Arrows'],
            },
            ChevronDown: {
                id: 'ChevronDown',
                icon: ['fas', 'chevron-down'],
                category: ['Arrows'],
            },
            ChevronLeft: {
                id: 'ChevronLeft',
                icon: ['fas', 'chevron-left'],
                category: ['Arrows'],
            },
            ChevronRight: {
                id: 'ChevronRight',
                icon: ['fas', 'chevron-right'],
                category: ['Arrows'],
            },
            ChevronUp: {
                id: 'ChevronUp',
                icon: ['fas', 'chevron-up'],
                category: ['Arrows'],
            },
            Child: { id: 'Child', icon: ['fas', 'child'], category: ['Users & People'] },
            Church: {
                id: 'Church',
                icon: ['fas', 'church'],
                category: ['Buildings', 'Objects', 'Religion'],
            },
            Circle: {
                id: 'Circle',
                icon: ['fas', 'circle'],
                category: ['Audio & Video', 'Interfaces', 'Shapes', 'Toggle'],
            },
            CircleNotch: {
                id: 'CircleNotch',
                icon: ['fas', 'circle-notch'],
                category: ['Spinners'],
            },
            City: {
                id: 'City',
                icon: ['fas', 'city'],
                category: ['Buildings', 'Business'],
            },
            ClinicMedical: {
                id: 'ClinicMedical',
                icon: ['fas', 'clinic-medical'],
                category: ['Buildings', 'Medical', 'Pharmacy'],
            },
            Clipboard: {
                id: 'Clipboard',
                icon: ['fas', 'clipboard'],
                category: ['Business', 'Editors', 'Interfaces', 'Objects'],
            },
            ClipboardCheck: {
                id: 'ClipboardCheck',
                icon: ['fas', 'clipboard-check'],
                category: ['Logistics', 'Science'],
            },
            ClipboardList: {
                id: 'ClipboardList',
                icon: ['fas', 'clipboard-list'],
                category: ['Logistics'],
            },
            Clock: { id: 'Clock', icon: ['fas', 'clock'], category: ['Date & Time'] },
            Clone: {
                id: 'Clone',
                icon: ['fas', 'clone'],
                category: ['Design', 'Editors', 'Files', 'Images', 'Interfaces'],
            },
            ClosedCaptioning: {
                id: 'ClosedCaptioning',
                icon: ['fas', 'closed-captioning'],
                category: ['Accessibility', 'Audio & Video'],
            },
            Cloud: {
                id: 'Cloud',
                icon: ['fas', 'cloud'],
                category: ['Interfaces', 'Objects', 'Shapes', 'Weather'],
            },
            CloudDownloadAlt: {
                id: 'CloudDownloadAlt',
                icon: ['fas', 'cloud-download-alt'],
                category: ['Arrows', 'Interfaces'],
            },
            CloudMeatball: {
                id: 'CloudMeatball',
                icon: ['fas', 'cloud-meatball'],
                category: ['Food', 'Weather'],
            },
            CloudMoon: {
                id: 'CloudMoon',
                icon: ['fas', 'cloud-moon'],
                category: ['Halloween', 'Weather'],
            },
            CloudMoonRain: {
                id: 'CloudMoonRain',
                icon: ['fas', 'cloud-moon-rain'],
                category: ['Weather'],
            },
            CloudRain: {
                id: 'CloudRain',
                icon: ['fas', 'cloud-rain'],
                category: ['Weather'],
            },
            CloudShowersHeavy: {
                id: 'CloudShowersHeavy',
                icon: ['fas', 'cloud-showers-heavy'],
                category: ['Weather'],
            },
            CloudSun: {
                id: 'CloudSun',
                icon: ['fas', 'cloud-sun'],
                category: ['Autumn', 'Spring', 'Weather'],
            },
            CloudSunRain: {
                id: 'CloudSunRain',
                icon: ['fas', 'cloud-sun-rain'],
                category: ['Spring', 'Weather'],
            },
            CloudUploadAlt: {
                id: 'CloudUploadAlt',
                icon: ['fas', 'cloud-upload-alt'],
                category: ['Arrows', 'Interfaces'],
            },
            Cocktail: {
                id: 'Cocktail',
                icon: ['fas', 'cocktail'],
                category: ['Beverage', 'Hotel', 'Travel'],
            },
            Code: { id: 'Code', icon: ['fas', 'code'], category: ['Code'] },
            CodeBranch: {
                id: 'CodeBranch',
                icon: ['fas', 'code-branch'],
                category: ['Code'],
            },
            Coffee: {
                id: 'Coffee',
                icon: ['fas', 'coffee'],
                category: ['Beverage', 'Business', 'Code', 'Hotel', 'Household', 'Interfaces', 'Maps', 'Objects'],
            },
            Cog: {
                id: 'Cog',
                icon: ['fas', 'cog'],
                category: ['Interfaces', 'Objects', 'Spinners'],
            },
            Cogs: {
                id: 'Cogs',
                icon: ['fas', 'cogs'],
                category: ['Interfaces', 'Objects'],
            },
            Coins: { id: 'Coins', icon: ['fas', 'coins'], category: ['Finance'] },
            Columns: {
                id: 'Columns',
                icon: ['fas', 'columns'],
                category: ['Business', 'Editors'],
            },
            Comment: {
                id: 'Comment',
                icon: ['fas', 'comment'],
                category: ['Chat', 'Communication', 'Shapes', 'Social', 'Status'],
            },
            CommentAlt: {
                id: 'CommentAlt',
                icon: ['fas', 'comment-alt'],
                category: ['Chat', 'Communication', 'Social', 'Status'],
            },
            CommentDollar: {
                id: 'CommentDollar',
                icon: ['fas', 'comment-dollar'],
                category: ['Finance', 'Marketing'],
            },
            CommentDots: {
                id: 'CommentDots',
                icon: ['fas', 'comment-dots'],
                category: ['Chat'],
            },
            CommentMedical: {
                id: 'CommentMedical',
                icon: ['fas', 'comment-medical'],
                category: ['Chat', 'Medical'],
            },
            CommentSlash: {
                id: 'CommentSlash',
                icon: ['fas', 'comment-slash'],
                category: ['Chat', 'Status'],
            },
            Comments: {
                id: 'Comments',
                icon: ['fas', 'comments'],
                category: ['Chat', 'Communication'],
            },
            CommentsDollar: {
                id: 'CommentsDollar',
                icon: ['fas', 'comments-dollar'],
                category: ['Finance', 'Marketing'],
            },
            CompactDisc: {
                id: 'CompactDisc',
                icon: ['fas', 'compact-disc'],
                category: ['Computers', 'Music', 'Spinners'],
            },
            Compass: {
                id: 'Compass',
                icon: ['fas', 'compass'],
                category: ['Business', 'Camping', 'Maritime', 'Objects', 'Spinners', 'Status'],
            },
            Compress: {
                id: 'Compress',
                icon: ['fas', 'compress'],
                category: ['Audio & Video', 'Images'],
            },
            CompressAlt: {
                id: 'CompressAlt',
                icon: ['fas', 'compress-alt'],
                category: ['Arrows', 'Audio & Video'],
            },
            CompressArrowsAlt: {
                id: 'CompressArrowsAlt',
                icon: ['fas', 'compress-arrows-alt'],
                category: ['Arrows', 'Audio & Video', 'Images'],
            },
            ConciergeBell: {
                id: 'ConciergeBell',
                icon: ['fas', 'concierge-bell'],
                category: ['Hotel', 'Travel'],
            },
            Cookie: {
                id: 'Cookie',
                icon: ['fas', 'cookie'],
                category: ['Childhood', 'Food', 'Objects'],
            },
            CookieBite: {
                id: 'CookieBite',
                icon: ['fas', 'cookie-bite'],
                category: ['Childhood', 'Holiday', 'Objects'],
            },
            Copy: {
                id: 'Copy',
                icon: ['fas', 'copy'],
                category: ['Business', 'Design', 'Editors', 'Files', 'Interfaces', 'Objects'],
            },
            Copyright: {
                id: 'Copyright',
                icon: ['fas', 'copyright'],
                category: ['Business'],
            },
            Couch: {
                id: 'Couch',
                icon: ['fas', 'couch'],
                category: ['Household', 'Moving'],
            },
            CreditCard: {
                id: 'CreditCard',
                icon: ['fas', 'credit-card'],
                category: ['Finance', 'Payments & Shopping'],
            },
            Crop: { id: 'Crop', icon: ['fas', 'crop'], category: ['Design'] },
            CropAlt: { id: 'CropAlt', icon: ['fas', 'crop-alt'], category: ['Design'] },
            Cross: { id: 'Cross', icon: ['fas', 'cross'], category: ['Religion'] },
            Crosshairs: {
                id: 'Crosshairs',
                icon: ['fas', 'crosshairs'],
                category: ['Design', 'Maps', 'Spinners'],
            },
            Crow: {
                id: 'Crow',
                icon: ['fas', 'crow'],
                category: ['Animals', 'Halloween'],
            },
            Crown: { id: 'Crown', icon: ['fas', 'crown'], category: ['Other'] },
            Crutch: { id: 'Crutch', icon: ['fas', 'crutch'], category: ['Medical'] },
            Cube: { id: 'Cube', icon: ['fas', 'cube'], category: ['Objects'] },
            Cubes: { id: 'Cubes', icon: ['fas', 'cubes'], category: ['Objects'] },
            Cut: {
                id: 'Cut',
                icon: ['fas', 'cut'],
                category: ['Business', 'Design', 'Editors', 'Files', 'Interfaces', 'Objects'],
            },
            Database: {
                id: 'Database',
                icon: ['fas', 'database'],
                category: ['Computers', 'Interfaces'],
            },
            Deaf: { id: 'Deaf', icon: ['fas', 'deaf'], category: ['Accessibility'] },
            Democrat: {
                id: 'Democrat',
                icon: ['fas', 'democrat'],
                category: ['Political'],
            },
            Desktop: { id: 'Desktop', icon: ['fas', 'desktop'], category: ['Computers'] },
            Dharmachakra: {
                id: 'Dharmachakra',
                icon: ['fas', 'dharmachakra'],
                category: ['Maritime', 'Religion', 'Spinners'],
            },
            Diagnoses: {
                id: 'Diagnoses',
                icon: ['fas', 'diagnoses'],
                category: ['Medical'],
            },
            Dice: {
                id: 'Dice',
                icon: ['fas', 'dice'],
                category: ['Games', 'Hotel', 'Objects'],
            },
            DiceD20: {
                id: 'DiceD20',
                icon: ['fas', 'dice-d20'],
                category: ['Games', 'Tabletop Gaming', 'Objects'],
            },
            DiceD6: {
                id: 'DiceD6',
                icon: ['fas', 'dice-d6'],
                category: ['Games', 'Tabletop Gaming', 'Objects'],
            },
            DiceFive: {
                id: 'DiceFive',
                icon: ['fas', 'dice-five'],
                category: ['Games', 'Hotel', 'Objects'],
            },
            DiceFour: {
                id: 'DiceFour',
                icon: ['fas', 'dice-four'],
                category: ['Games', 'Objects'],
            },
            DiceOne: {
                id: 'DiceOne',
                icon: ['fas', 'dice-one'],
                category: ['Games', 'Objects'],
            },
            DiceSix: {
                id: 'DiceSix',
                icon: ['fas', 'dice-six'],
                category: ['Games', 'Objects'],
            },
            DiceThree: {
                id: 'DiceThree',
                icon: ['fas', 'dice-three'],
                category: ['Games', 'Objects'],
            },
            DiceTwo: {
                id: 'DiceTwo',
                icon: ['fas', 'dice-two'],
                category: ['Games', 'Objects'],
            },
            DigitalTachograph: {
                id: 'DigitalTachograph',
                icon: ['fas', 'digital-tachograph'],
                category: ['Objects'],
            },
            Directions: {
                id: 'Directions',
                icon: ['fas', 'directions'],
                category: ['Maps'],
            },
            Disease: {
                id: 'Disease',
                icon: ['fas', 'disease'],
                category: ['Medical', 'Pharmacy', 'Science'],
            },
            Divide: { id: 'Divide', icon: ['fas', 'divide'], category: ['Mathematics'] },
            Dizzy: { id: 'Dizzy', icon: ['fas', 'dizzy'], category: ['Emoji'] },
            Dna: { id: 'Dna', icon: ['fas', 'dna'], category: ['Medical', 'Science'] },
            Dog: { id: 'Dog', icon: ['fas', 'dog'], category: ['Animals'] },
            DollarSign: {
                id: 'DollarSign',
                icon: ['fas', 'dollar-sign'],
                category: ['Charity', 'Currency', 'Maps'],
            },
            Dolly: {
                id: 'Dolly',
                icon: ['fas', 'dolly'],
                category: ['Logistics', 'Moving'],
            },
            DollyFlatbed: {
                id: 'DollyFlatbed',
                icon: ['fas', 'dolly-flatbed'],
                category: ['Logistics'],
            },
            Donate: {
                id: 'Donate',
                icon: ['fas', 'donate'],
                category: ['Charity', 'Finance', 'Political'],
            },
            DoorClosed: {
                id: 'DoorClosed',
                icon: ['fas', 'door-closed'],
                category: ['Hotel', 'Household', 'Objects', 'Security', 'Status'],
            },
            DoorOpen: {
                id: 'DoorOpen',
                icon: ['fas', 'door-open'],
                category: ['Hotel', 'Household', 'Objects', 'Security', 'Status'],
            },
            DotCircle: {
                id: 'DotCircle',
                icon: ['fas', 'dot-circle'],
                category: ['Interfaces', 'Toggle'],
            },
            Dove: {
                id: 'Dove',
                icon: ['fas', 'dove'],
                category: ['Animals', 'Charity', 'Political', 'Religion'],
            },
            Download: {
                id: 'Download',
                icon: ['fas', 'download'],
                category: ['Arrows', 'Computers', 'Interfaces'],
            },
            DraftingCompass: {
                id: 'DraftingCompass',
                icon: ['fas', 'drafting-compass'],
                category: ['Construction', 'Design'],
            },
            Dragon: {
                id: 'Dragon',
                icon: ['fas', 'dragon'],
                category: ['Animals', 'Tabletop Gaming'],
            },
            DrawPolygon: {
                id: 'DrawPolygon',
                icon: ['fas', 'draw-polygon'],
                category: ['Design', 'Maps'],
            },
            Drum: { id: 'Drum', icon: ['fas', 'drum'], category: ['Music', 'Objects'] },
            DrumSteelpan: {
                id: 'DrumSteelpan',
                icon: ['fas', 'drum-steelpan'],
                category: ['Music', 'Objects'],
            },
            DrumstickBite: {
                id: 'DrumstickBite',
                icon: ['fas', 'drumstick-bite'],
                category: ['Autumn', 'Food'],
            },
            Dumbbell: {
                id: 'Dumbbell',
                icon: ['fas', 'dumbbell'],
                category: ['Hotel', 'Sports', 'Travel'],
            },
            Dumpster: {
                id: 'Dumpster',
                icon: ['fas', 'dumpster'],
                category: ['Construction'],
            },
            DumpsterFire: {
                id: 'DumpsterFire',
                icon: ['fas', 'dumpster-fire'],
                category: ['Other'],
            },
            Dungeon: {
                id: 'Dungeon',
                icon: ['fas', 'dungeon'],
                category: ['Buildings', 'Tabletop Gaming', 'Household', 'Security'],
            },
            Edit: {
                id: 'Edit',
                icon: ['fas', 'edit'],
                category: ['Business', 'Design', 'Editors', 'Interfaces', 'Writing'],
            },
            Egg: { id: 'Egg', icon: ['fas', 'egg'], category: ['Food'] },
            Eject: { id: 'Eject', icon: ['fas', 'eject'], category: ['Audio & Video'] },
            EllipsisH: {
                id: 'EllipsisH',
                icon: ['fas', 'ellipsis-h'],
                category: ['Interfaces'],
            },
            EllipsisV: {
                id: 'EllipsisV',
                icon: ['fas', 'ellipsis-v'],
                category: ['Interfaces'],
            },
            Envelope: {
                id: 'Envelope',
                icon: ['fas', 'envelope'],
                category: ['Business', 'Communication', 'Interfaces', 'Objects', 'Social', 'Writing'],
            },
            EnvelopeOpen: {
                id: 'EnvelopeOpen',
                icon: ['fas', 'envelope-open'],
                category: ['Business', 'Communication', 'Interfaces', 'Objects', 'Writing'],
            },
            EnvelopeOpenText: {
                id: 'EnvelopeOpenText',
                icon: ['fas', 'envelope-open-text'],
                category: ['Marketing'],
            },
            EnvelopeSquare: {
                id: 'EnvelopeSquare',
                icon: ['fas', 'envelope-square'],
                category: ['Business', 'Communication'],
            },
            Equals: { id: 'Equals', icon: ['fas', 'equals'], category: ['Mathematics'] },
            Eraser: {
                id: 'Eraser',
                icon: ['fas', 'eraser'],
                category: ['Business', 'Design', 'Editors', 'Interfaces', 'Objects', 'Writing'],
            },
            Ethernet: {
                id: 'Ethernet',
                icon: ['fas', 'ethernet'],
                category: ['Computers'],
            },
            EuroSign: {
                id: 'EuroSign',
                icon: ['fas', 'euro-sign'],
                category: ['Currency'],
            },
            ExchangeAlt: {
                id: 'ExchangeAlt',
                icon: ['fas', 'exchange-alt'],
                category: ['Arrows'],
            },
            Exclamation: {
                id: 'Exclamation',
                icon: ['fas', 'exclamation'],
                category: ['Alert', 'Interfaces', 'Status'],
            },
            ExclamationCircle: {
                id: 'ExclamationCircle',
                icon: ['fas', 'exclamation-circle'],
                category: ['Alert', 'Interfaces', 'Status'],
            },
            ExclamationTriangle: {
                id: 'ExclamationTriangle',
                icon: ['fas', 'exclamation-triangle'],
                category: ['Alert', 'Interfaces', 'Status'],
            },
            Expand: {
                id: 'Expand',
                icon: ['fas', 'expand'],
                category: ['Audio & Video', 'Images'],
            },
            ExpandAlt: {
                id: 'ExpandAlt',
                icon: ['fas', 'expand-alt'],
                category: ['Arrows', 'Audio & Video'],
            },
            ExpandArrowsAlt: {
                id: 'ExpandArrowsAlt',
                icon: ['fas', 'expand-arrows-alt'],
                category: ['Arrows', 'Audio & Video'],
            },
            ExternalLinkAlt: {
                id: 'ExternalLinkAlt',
                icon: ['fas', 'external-link-alt'],
                category: ['Arrows', 'Interfaces'],
            },
            ExternalLinkSquareAlt: {
                id: 'ExternalLinkSquareAlt',
                icon: ['fas', 'external-link-square-alt'],
                category: ['Arrows', 'Interfaces'],
            },
            Eye: {
                id: 'Eye',
                icon: ['fas', 'eye'],
                category: ['Design', 'Images', 'Interfaces', 'Maps', 'Objects', 'Security', 'Status'],
            },
            EyeDropper: {
                id: 'EyeDropper',
                icon: ['fas', 'eye-dropper'],
                category: ['Design', 'Images', 'Objects', 'Pharmacy', 'Science'],
            },
            EyeSlash: {
                id: 'EyeSlash',
                icon: ['fas', 'eye-slash'],
                category: ['Design', 'Images', 'Interfaces', 'Maps', 'Security', 'Status'],
            },
            Fan: {
                id: 'Fan',
                icon: ['fas', 'fan'],
                category: ['Energy', 'Household', 'Spinners'],
            },
            FastBackward: {
                id: 'FastBackward',
                icon: ['fas', 'fast-backward'],
                category: ['Audio & Video'],
            },
            FastForward: {
                id: 'FastForward',
                icon: ['fas', 'fast-forward'],
                category: ['Audio & Video'],
            },
            Faucet: {
                id: 'Faucet',
                icon: ['fas', 'faucet'],
                category: ['Camping', 'Household'],
            },
            Fax: {
                id: 'Fax',
                icon: ['fas', 'fax'],
                category: ['Business', 'Communication', 'Objects'],
            },
            Feather: {
                id: 'Feather',
                icon: ['fas', 'feather'],
                category: ['Animals', 'Objects'],
            },
            FeatherAlt: {
                id: 'FeatherAlt',
                icon: ['fas', 'feather-alt'],
                category: ['Animals', 'Objects'],
            },
            Female: {
                id: 'Female',
                icon: ['fas', 'female'],
                category: ['Users & People'],
            },
            FighterJet: {
                id: 'FighterJet',
                icon: ['fas', 'fighter-jet'],
                category: ['Maps', 'Objects', 'Vehicles'],
            },
            File: {
                id: 'File',
                icon: ['fas', 'file'],
                category: [
                    'Business',
                    'Code',
                    'Editors',
                    'Files',
                    'Interfaces',
                    'Objects',
                    'Shapes',
                    'Status',
                    'Writing',
                ],
            },
            FileAlt: {
                id: 'FileAlt',
                icon: ['fas', 'file-alt'],
                category: ['Business', 'Code', 'Editors', 'Files', 'Interfaces', 'Objects', 'Status', 'Writing'],
            },
            FileArchive: {
                id: 'FileArchive',
                icon: ['fas', 'file-archive'],
                category: ['Files'],
            },
            FileAudio: {
                id: 'FileAudio',
                icon: ['fas', 'file-audio'],
                category: ['Audio & Video', 'Files', 'Music'],
            },
            FileCode: {
                id: 'FileCode',
                icon: ['fas', 'file-code'],
                category: ['Code', 'Files'],
            },
            FileContract: {
                id: 'FileContract',
                icon: ['fas', 'file-contract'],
                category: ['Security'],
            },
            FileCsv: { id: 'FileCsv', icon: ['fas', 'file-csv'], category: ['Other'] },
            FileDownload: {
                id: 'FileDownload',
                icon: ['fas', 'file-download'],
                category: ['Interfaces'],
            },
            FileExcel: {
                id: 'FileExcel',
                icon: ['fas', 'file-excel'],
                category: ['Files'],
            },
            FileExport: {
                id: 'FileExport',
                icon: ['fas', 'file-export'],
                category: ['Interfaces'],
            },
            FileImage: {
                id: 'FileImage',
                icon: ['fas', 'file-image'],
                category: ['Files', 'Images'],
            },
            FileImport: {
                id: 'FileImport',
                icon: ['fas', 'file-import'],
                category: ['Interfaces'],
            },
            FileInvoice: {
                id: 'FileInvoice',
                icon: ['fas', 'file-invoice'],
                category: ['Finance'],
            },
            FileInvoiceDollar: {
                id: 'FileInvoiceDollar',
                icon: ['fas', 'file-invoice-dollar'],
                category: ['Finance'],
            },
            FileMedical: {
                id: 'FileMedical',
                icon: ['fas', 'file-medical'],
                category: ['Medical', 'Pharmacy'],
            },
            FileMedicalAlt: {
                id: 'FileMedicalAlt',
                icon: ['fas', 'file-medical-alt'],
                category: ['Medical'],
            },
            FilePdf: { id: 'FilePdf', icon: ['fas', 'file-pdf'], category: ['Files'] },
            FilePowerpoint: {
                id: 'FilePowerpoint',
                icon: ['fas', 'file-powerpoint'],
                category: ['Files'],
            },
            FilePrescription: {
                id: 'FilePrescription',
                icon: ['fas', 'file-prescription'],
                category: ['Medical', 'Objects', 'Pharmacy'],
            },
            FileSignature: {
                id: 'FileSignature',
                icon: ['fas', 'file-signature'],
                category: ['Security'],
            },
            FileUpload: {
                id: 'FileUpload',
                icon: ['fas', 'file-upload'],
                category: ['Interfaces'],
            },
            FileVideo: {
                id: 'FileVideo',
                icon: ['fas', 'file-video'],
                category: ['Audio & Video', 'Files'],
            },
            FileWord: { id: 'FileWord', icon: ['fas', 'file-word'], category: ['Files'] },
            Fill: { id: 'Fill', icon: ['fas', 'fill'], category: ['Design'] },
            FillDrip: {
                id: 'FillDrip',
                icon: ['fas', 'fill-drip'],
                category: ['Design'],
            },
            Film: {
                id: 'Film',
                icon: ['fas', 'film'],
                category: ['Audio & Video', 'Images', 'Objects'],
            },
            Filter: {
                id: 'Filter',
                icon: ['fas', 'filter'],
                category: ['Code', 'Interfaces', 'Science'],
            },
            Fingerprint: {
                id: 'Fingerprint',
                icon: ['fas', 'fingerprint'],
                category: ['Interfaces', 'Security'],
            },
            Fire: {
                id: 'Fire',
                icon: ['fas', 'fire'],
                category: ['Camping', 'Energy', 'Maps', 'Objects', 'Science'],
            },
            FireAlt: {
                id: 'FireAlt',
                icon: ['fas', 'fire-alt'],
                category: ['Camping', 'Energy', 'Fitness', 'Maps', 'Objects', 'Science'],
            },
            FireExtinguisher: {
                id: 'FireExtinguisher',
                icon: ['fas', 'fire-extinguisher'],
                category: ['Code', 'Maps', 'Objects'],
            },
            FirstAid: {
                id: 'FirstAid',
                icon: ['fas', 'first-aid'],
                category: ['Camping', 'Medical', 'Pharmacy'],
            },
            Fish: {
                id: 'Fish',
                icon: ['fas', 'fish'],
                category: ['Animals', 'Food', 'Summer'],
            },
            FistRaised: {
                id: 'FistRaised',
                icon: ['fas', 'fist-raised'],
                category: ['Tabletop Gaming', 'Hands', 'Political'],
            },
            Flag: {
                id: 'Flag',
                icon: ['fas', 'flag'],
                category: ['Interfaces', 'Maps', 'Objects'],
            },
            FlagCheckered: {
                id: 'FlagCheckered',
                icon: ['fas', 'flag-checkered'],
                category: ['Interfaces', 'Maps', 'Objects'],
            },
            FlagUsa: {
                id: 'FlagUsa',
                icon: ['fas', 'flag-usa'],
                category: ['Political'],
            },
            Flask: {
                id: 'Flask',
                icon: ['fas', 'flask'],
                category: ['Beverage', 'Maps', 'Objects', 'Pharmacy', 'Science'],
            },
            Flushed: { id: 'Flushed', icon: ['fas', 'flushed'], category: ['Emoji'] },
            Folder: {
                id: 'Folder',
                icon: ['fas', 'folder'],
                category: ['Business', 'Code', 'Files', 'Interfaces', 'Shapes', 'Status', 'Writing'],
            },
            FolderMinus: {
                id: 'FolderMinus',
                icon: ['fas', 'folder-minus'],
                category: ['Business'],
            },
            FolderOpen: {
                id: 'FolderOpen',
                icon: ['fas', 'folder-open'],
                category: ['Business', 'Code', 'Files', 'Interfaces', 'Status', 'Writing'],
            },
            FolderPlus: {
                id: 'FolderPlus',
                icon: ['fas', 'folder-plus'],
                category: ['Business'],
            },
            Font: { id: 'Font', icon: ['fas', 'font'], category: ['Editors'] },
            FootballBall: {
                id: 'FootballBall',
                icon: ['fas', 'football-ball'],
                category: ['Autumn', 'Sports'],
            },
            Forward: {
                id: 'Forward',
                icon: ['fas', 'forward'],
                category: ['Audio & Video'],
            },
            Frog: {
                id: 'Frog',
                icon: ['fas', 'frog'],
                category: ['Animals', 'Camping', 'Maritime', 'Science', 'Spring'],
            },
            Frown: {
                id: 'Frown',
                icon: ['fas', 'frown'],
                category: ['Chat', 'Emoji', 'Interfaces', 'Users & People'],
            },
            FrownOpen: {
                id: 'FrownOpen',
                icon: ['fas', 'frown-open'],
                category: ['Emoji'],
            },
            FunnelDollar: {
                id: 'FunnelDollar',
                icon: ['fas', 'funnel-dollar'],
                category: ['Marketing'],
            },
            Futbol: {
                id: 'Futbol',
                icon: ['fas', 'futbol'],
                category: ['Objects', 'Sports'],
            },
            Gamepad: {
                id: 'Gamepad',
                icon: ['fas', 'gamepad'],
                category: ['Childhood', 'Games', 'Maps', 'Objects'],
            },
            GasPump: {
                id: 'GasPump',
                icon: ['fas', 'gas-pump'],
                category: ['Automotive', 'Energy', 'Status'],
            },
            Gavel: { id: 'Gavel', icon: ['fas', 'gavel'], category: ['Maps', 'Objects'] },
            Gem: {
                id: 'Gem',
                icon: ['fas', 'gem'],
                category: ['Objects', 'Payments & Shopping'],
            },
            Genderless: {
                id: 'Genderless',
                icon: ['fas', 'genderless'],
                category: ['Genders'],
            },
            Ghost: {
                id: 'Ghost',
                icon: ['fas', 'ghost'],
                category: ['Games', 'Halloween'],
            },
            Gift: {
                id: 'Gift',
                icon: ['fas', 'gift'],
                category: ['Charity', 'Holiday', 'Maps', 'Objects', 'Payments & Shopping', 'Shopping'],
            },
            Gifts: {
                id: 'Gifts',
                icon: ['fas', 'gifts'],
                category: ['Holiday', 'Objects', 'Shopping'],
            },
            GlassCheers: {
                id: 'GlassCheers',
                icon: ['fas', 'glass-cheers'],
                category: ['Beverage', 'Holiday', 'Objects'],
            },
            GlassMartini: {
                id: 'GlassMartini',
                icon: ['fas', 'glass-martini'],
                category: ['Beverage', 'Hotel', 'Maps', 'Objects', 'Travel'],
            },
            GlassMartiniAlt: {
                id: 'GlassMartiniAlt',
                icon: ['fas', 'glass-martini-alt'],
                category: ['Beverage', 'Hotel', 'Travel'],
            },
            GlassWhiskey: {
                id: 'GlassWhiskey',
                icon: ['fas', 'glass-whiskey'],
                category: ['Beverage', 'Objects', 'Winter'],
            },
            Glasses: {
                id: 'Glasses',
                icon: ['fas', 'glasses'],
                category: ['Business', 'Editors', 'Interfaces', 'Objects'],
            },
            Globe: {
                id: 'Globe',
                icon: ['fas', 'globe'],
                category: ['Business', 'Charity', 'Maps', 'Objects', 'Science Fiction'],
            },
            GlobeAfrica: {
                id: 'GlobeAfrica',
                icon: ['fas', 'globe-africa'],
                category: ['Travel'],
            },
            GlobeAmericas: {
                id: 'GlobeAmericas',
                icon: ['fas', 'globe-americas'],
                category: ['Travel'],
            },
            GlobeAsia: {
                id: 'GlobeAsia',
                icon: ['fas', 'globe-asia'],
                category: ['Travel'],
            },
            GlobeEurope: {
                id: 'GlobeEurope',
                icon: ['fas', 'globe-europe'],
                category: ['Travel'],
            },
            GolfBall: {
                id: 'GolfBall',
                icon: ['fas', 'golf-ball'],
                category: ['Sports'],
            },
            Gopuram: {
                id: 'Gopuram',
                icon: ['fas', 'gopuram'],
                category: ['Buildings', 'Religion'],
            },
            GraduationCap: {
                id: 'GraduationCap',
                icon: ['fas', 'graduation-cap'],
                category: ['Clothing', 'Education', 'Maps', 'Objects'],
            },
            GreaterThan: {
                id: 'GreaterThan',
                icon: ['fas', 'greater-than'],
                category: ['Mathematics'],
            },
            GreaterThanEqual: {
                id: 'GreaterThanEqual',
                icon: ['fas', 'greater-than-equal'],
                category: ['Mathematics'],
            },
            Grimace: { id: 'Grimace', icon: ['fas', 'grimace'], category: ['Emoji'] },
            Grin: { id: 'Grin', icon: ['fas', 'grin'], category: ['Emoji'] },
            GrinAlt: { id: 'GrinAlt', icon: ['fas', 'grin-alt'], category: ['Emoji'] },
            GrinBeam: { id: 'GrinBeam', icon: ['fas', 'grin-beam'], category: ['Emoji'] },
            GrinBeamSweat: {
                id: 'GrinBeamSweat',
                icon: ['fas', 'grin-beam-sweat'],
                category: ['Emoji'],
            },
            GrinHearts: {
                id: 'GrinHearts',
                icon: ['fas', 'grin-hearts'],
                category: ['Emoji'],
            },
            GrinSquint: {
                id: 'GrinSquint',
                icon: ['fas', 'grin-squint'],
                category: ['Emoji'],
            },
            GrinSquintTears: {
                id: 'GrinSquintTears',
                icon: ['fas', 'grin-squint-tears'],
                category: ['Emoji'],
            },
            GrinStars: {
                id: 'GrinStars',
                icon: ['fas', 'grin-stars'],
                category: ['Emoji'],
            },
            GrinTears: {
                id: 'GrinTears',
                icon: ['fas', 'grin-tears'],
                category: ['Emoji'],
            },
            GrinTongue: {
                id: 'GrinTongue',
                icon: ['fas', 'grin-tongue'],
                category: ['Emoji'],
            },
            GrinTongueSquint: {
                id: 'GrinTongueSquint',
                icon: ['fas', 'grin-tongue-squint'],
                category: ['Emoji'],
            },
            GrinTongueWink: {
                id: 'GrinTongueWink',
                icon: ['fas', 'grin-tongue-wink'],
                category: ['Emoji'],
            },
            GrinWink: { id: 'GrinWink', icon: ['fas', 'grin-wink'], category: ['Emoji'] },
            GripHorizontal: {
                id: 'GripHorizontal',
                icon: ['fas', 'grip-horizontal'],
                category: ['Interfaces'],
            },
            GripLines: {
                id: 'GripLines',
                icon: ['fas', 'grip-lines'],
                category: ['Interfaces'],
            },
            GripLinesVertical: {
                id: 'GripLinesVertical',
                icon: ['fas', 'grip-lines-vertical'],
                category: ['Interfaces'],
            },
            GripVertical: {
                id: 'GripVertical',
                icon: ['fas', 'grip-vertical'],
                category: ['Interfaces'],
            },
            Guitar: {
                id: 'Guitar',
                icon: ['fas', 'guitar'],
                category: ['Music', 'Objects'],
            },
            HSquare: {
                id: 'HSquare',
                icon: ['fas', 'h-square'],
                category: ['Health', 'Maps'],
            },
            Hamburger: {
                id: 'Hamburger',
                icon: ['fas', 'hamburger'],
                category: ['Food'],
            },
            Hammer: { id: 'Hammer', icon: ['fas', 'hammer'], category: ['Construction'] },
            Hamsa: { id: 'Hamsa', icon: ['fas', 'hamsa'], category: ['Religion'] },
            HandHolding: {
                id: 'HandHolding',
                icon: ['fas', 'hand-holding'],
                category: ['Hands'],
            },
            HandHoldingHeart: {
                id: 'HandHoldingHeart',
                icon: ['fas', 'hand-holding-heart'],
                category: ['Charity', 'Hands'],
            },
            HandHoldingMedical: {
                id: 'HandHoldingMedical',
                icon: ['fas', 'hand-holding-medical'],
                category: ['Hands', 'Medical'],
            },
            HandHoldingUsd: {
                id: 'HandHoldingUsd',
                icon: ['fas', 'hand-holding-usd'],
                category: ['Charity', 'Finance', 'Hands'],
            },
            HandHoldingWater: {
                id: 'HandHoldingWater',
                icon: ['fas', 'hand-holding-water'],
                category: ['Charity', 'Hands'],
            },
            HandLizard: {
                id: 'HandLizard',
                icon: ['fas', 'hand-lizard'],
                category: ['Hands'],
            },
            HandMiddleFinger: {
                id: 'HandMiddleFinger',
                icon: ['fas', 'hand-middle-finger'],
                category: ['Hands'],
            },
            HandPaper: {
                id: 'HandPaper',
                icon: ['fas', 'hand-paper'],
                category: ['Hands'],
            },
            HandPeace: {
                id: 'HandPeace',
                icon: ['fas', 'hand-peace'],
                category: ['Hands'],
            },
            HandPointDown: {
                id: 'HandPointDown',
                icon: ['fas', 'hand-point-down'],
                category: ['Arrows', 'Hands'],
            },
            HandPointLeft: {
                id: 'HandPointLeft',
                icon: ['fas', 'hand-point-left'],
                category: ['Arrows', 'Hands'],
            },
            HandPointRight: {
                id: 'HandPointRight',
                icon: ['fas', 'hand-point-right'],
                category: ['Arrows', 'Hands'],
            },
            HandPointUp: {
                id: 'HandPointUp',
                icon: ['fas', 'hand-point-up'],
                category: ['Arrows', 'Hands'],
            },
            HandPointer: {
                id: 'HandPointer',
                icon: ['fas', 'hand-pointer'],
                category: ['Arrows', 'Hands'],
            },
            HandRock: { id: 'HandRock', icon: ['fas', 'hand-rock'], category: ['Hands'] },
            HandScissors: {
                id: 'HandScissors',
                icon: ['fas', 'hand-scissors'],
                category: ['Hands'],
            },
            HandSparkles: {
                id: 'HandSparkles',
                icon: ['fas', 'hand-sparkles'],
                category: ['Hands'],
            },
            HandSpock: {
                id: 'HandSpock',
                icon: ['fas', 'hand-spock'],
                category: ['Hands', 'Science Fiction'],
            },
            Hands: { id: 'Hands', icon: ['fas', 'hands'], category: ['Hands'] },
            HandsHelping: {
                id: 'HandsHelping',
                icon: ['fas', 'hands-helping'],
                category: ['Charity', 'Hands'],
            },
            HandsWash: {
                id: 'HandsWash',
                icon: ['fas', 'hands-wash'],
                category: ['Hands'],
            },
            Handshake: {
                id: 'Handshake',
                icon: ['fas', 'handshake'],
                category: ['Charity', 'Hands', 'Payments & Shopping', 'Political'],
            },
            HandshakeAltSlash: {
                id: 'HandshakeAltSlash',
                icon: ['fas', 'handshake-alt-slash'],
                category: ['Hands'],
            },
            HandshakeSlash: {
                id: 'HandshakeSlash',
                icon: ['fas', 'handshake-slash'],
                category: ['Hands'],
            },
            Hanukiah: {
                id: 'Hanukiah',
                icon: ['fas', 'hanukiah'],
                category: ['Religion'],
            },
            HardHat: {
                id: 'HardHat',
                icon: ['fas', 'hard-hat'],
                category: ['Construction', 'Logistics'],
            },
            Hashtag: {
                id: 'Hashtag',
                icon: ['fas', 'hashtag'],
                category: ['Interfaces', 'Social'],
            },
            HatCowboy: {
                id: 'HatCowboy',
                icon: ['fas', 'hat-cowboy'],
                category: ['Clothing'],
            },
            HatCowboySide: {
                id: 'HatCowboySide',
                icon: ['fas', 'hat-cowboy-side'],
                category: ['Clothing'],
            },
            HatWizard: {
                id: 'HatWizard',
                icon: ['fas', 'hat-wizard'],
                category: ['Clothing', 'Tabletop Gaming', 'Halloween', 'Objects'],
            },
            Hdd: { id: 'Hdd', icon: ['fas', 'hdd'], category: ['Computers', 'Objects'] },
            HeadSideCough: {
                id: 'HeadSideCough',
                icon: ['fas', 'head-side-cough'],
                category: ['Medical'],
            },
            HeadSideCoughSlash: {
                id: 'HeadSideCoughSlash',
                icon: ['fas', 'head-side-cough-slash'],
                category: ['Medical'],
            },
            HeadSideMask: {
                id: 'HeadSideMask',
                icon: ['fas', 'head-side-mask'],
                category: ['Medical'],
            },
            HeadSideVirus: {
                id: 'HeadSideVirus',
                icon: ['fas', 'head-side-virus'],
                category: ['Medical'],
            },
            Heading: { id: 'Heading', icon: ['fas', 'heading'], category: ['Editors'] },
            Headphones: {
                id: 'Headphones',
                icon: ['fas', 'headphones'],
                category: ['Audio & Video', 'Computers', 'Music', 'Objects'],
            },
            HeadphonesAlt: {
                id: 'HeadphonesAlt',
                icon: ['fas', 'headphones-alt'],
                category: ['Music', 'Objects'],
            },
            Headset: {
                id: 'Headset',
                icon: ['fas', 'headset'],
                category: ['Games', 'Objects'],
            },
            Heart: {
                id: 'Heart',
                icon: ['fas', 'heart'],
                category: [
                    'Charity',
                    'Fitness',
                    'Games',
                    'Health',
                    'Interfaces',
                    'Maps',
                    'Medical',
                    'Objects',
                    'Payments & Shopping',
                    'Shapes',
                    'Social',
                ],
            },
            HeartBroken: {
                id: 'HeartBroken',
                icon: ['fas', 'heart-broken'],
                category: ['Objects', 'Shapes'],
            },
            Heartbeat: {
                id: 'Heartbeat',
                icon: ['fas', 'heartbeat'],
                category: ['Fitness', 'Health', 'Maps', 'Medical'],
            },
            Helicopter: {
                id: 'Helicopter',
                icon: ['fas', 'helicopter'],
                category: ['Maps', 'Objects', 'Vehicles'],
            },
            Highlighter: {
                id: 'Highlighter',
                icon: ['fas', 'highlighter'],
                category: ['Business', 'Design', 'Editors', 'Objects'],
            },
            Hiking: {
                id: 'Hiking',
                icon: ['fas', 'hiking'],
                category: ['Autumn', 'Camping', 'Fitness', 'Users & People'],
            },
            Hippo: { id: 'Hippo', icon: ['fas', 'hippo'], category: ['Animals'] },
            History: {
                id: 'History',
                icon: ['fas', 'history'],
                category: ['Arrows', 'Interfaces', 'Pharmacy'],
            },
            HockeyPuck: {
                id: 'HockeyPuck',
                icon: ['fas', 'hockey-puck'],
                category: ['Sports'],
            },
            HollyBerry: {
                id: 'HollyBerry',
                icon: ['fas', 'holly-berry'],
                category: ['Holiday', 'Objects'],
            },
            Home: {
                id: 'Home',
                icon: ['fas', 'home'],
                category: ['Buildings', 'Interfaces', 'Maps', 'Objects'],
            },
            Horse: {
                id: 'Horse',
                icon: ['fas', 'horse'],
                category: ['Animals', 'Vehicles'],
            },
            HorseHead: {
                id: 'HorseHead',
                icon: ['fas', 'horse-head'],
                category: ['Animals'],
            },
            Hospital: {
                id: 'Hospital',
                icon: ['fas', 'hospital'],
                category: ['Buildings', 'Health', 'Maps', 'Medical', 'Objects'],
            },
            HospitalAlt: {
                id: 'HospitalAlt',
                icon: ['fas', 'hospital-alt'],
                category: ['Buildings', 'Medical'],
            },
            HospitalSymbol: {
                id: 'HospitalSymbol',
                icon: ['fas', 'hospital-symbol'],
                category: ['Medical'],
            },
            HospitalUser: {
                id: 'HospitalUser',
                icon: ['fas', 'hospital-user'],
                category: ['Buildings', 'Medical'],
            },
            HotTub: {
                id: 'HotTub',
                icon: ['fas', 'hot-tub'],
                category: ['Hotel', 'Travel'],
            },
            Hotdog: {
                id: 'Hotdog',
                icon: ['fas', 'hotdog'],
                category: ['Food', 'Summer'],
            },
            Hotel: {
                id: 'Hotel',
                icon: ['fas', 'hotel'],
                category: ['Buildings', 'Hotel', 'Travel'],
            },
            Hourglass: {
                id: 'Hourglass',
                icon: ['fas', 'hourglass'],
                category: ['Date & Time', 'Objects'],
            },
            HourglassEnd: {
                id: 'HourglassEnd',
                icon: ['fas', 'hourglass-end'],
                category: ['Date & Time'],
            },
            HourglassHalf: {
                id: 'HourglassHalf',
                icon: ['fas', 'hourglass-half'],
                category: ['Date & Time'],
            },
            HourglassStart: {
                id: 'HourglassStart',
                icon: ['fas', 'hourglass-start'],
                category: ['Date & Time'],
            },
            HouseDamage: {
                id: 'HouseDamage',
                icon: ['fas', 'house-damage'],
                category: ['Buildings'],
            },
            HouseUser: {
                id: 'HouseUser',
                icon: ['fas', 'house-user'],
                category: ['Household'],
            },
            Hryvnia: { id: 'Hryvnia', icon: ['fas', 'hryvnia'], category: ['Currency'] },
            ICursor: {
                id: 'ICursor',
                icon: ['fas', 'i-cursor'],
                category: ['Editors', 'Interfaces'],
            },
            IceCream: {
                id: 'IceCream',
                icon: ['fas', 'ice-cream'],
                category: ['Childhood', 'Food', 'Summer'],
            },
            Icicles: { id: 'Icicles', icon: ['fas', 'icicles'], category: ['Winter'] },
            Icons: {
                id: 'Icons',
                icon: ['fas', 'icons'],
                category: ['Chat', 'Design', 'Editors', 'Social'],
            },
            IdBadge: {
                id: 'IdBadge',
                icon: ['fas', 'id-badge'],
                category: ['Images', 'Security', 'Users & People'],
            },
            IdCard: {
                id: 'IdCard',
                icon: ['fas', 'id-card'],
                category: ['Images', 'Security', 'Users & People'],
            },
            IdCardAlt: {
                id: 'IdCardAlt',
                icon: ['fas', 'id-card-alt'],
                category: ['Medical', 'Security', 'Users & People'],
            },
            Igloo: {
                id: 'Igloo',
                icon: ['fas', 'igloo'],
                category: ['Buildings', 'Objects', 'Winter'],
            },
            Image: {
                id: 'Image',
                icon: ['fas', 'image'],
                category: ['Images', 'Maps', 'Objects', 'Social'],
            },
            Images: {
                id: 'Images',
                icon: ['fas', 'images'],
                category: ['Images', 'Maps', 'Objects', 'Social'],
            },
            Inbox: { id: 'Inbox', icon: ['fas', 'inbox'], category: ['Communication'] },
            Indent: { id: 'Indent', icon: ['fas', 'indent'], category: ['Editors'] },
            Industry: {
                id: 'Industry',
                icon: ['fas', 'industry'],
                category: ['Buildings', 'Business', 'Energy', 'Maps', 'Objects'],
            },
            Infinity: {
                id: 'Infinity',
                icon: ['fas', 'infinity'],
                category: ['Hotel', 'Mathematics'],
            },
            Info: {
                id: 'Info',
                icon: ['fas', 'info'],
                category: ['Interfaces', 'Maps', 'Status'],
            },
            InfoCircle: {
                id: 'InfoCircle',
                icon: ['fas', 'info-circle'],
                category: ['Interfaces', 'Maps', 'Status'],
            },
            Italic: { id: 'Italic', icon: ['fas', 'italic'], category: ['Editors'] },
            Jedi: {
                id: 'Jedi',
                icon: ['fas', 'jedi'],
                category: ['Religion', 'Science Fiction'],
            },
            Joint: {
                id: 'Joint',
                icon: ['fas', 'joint'],
                category: ['Medical', 'Pharmacy'],
            },
            JournalWhills: {
                id: 'JournalWhills',
                icon: ['fas', 'journal-whills'],
                category: ['Religion', 'Science Fiction'],
            },
            Kaaba: {
                id: 'Kaaba',
                icon: ['fas', 'kaaba'],
                category: ['Buildings', 'Religion'],
            },
            Key: {
                id: 'Key',
                icon: ['fas', 'key'],
                category: ['Hotel', 'Maps', 'Objects', 'Payments & Shopping', 'Security'],
            },
            Keyboard: {
                id: 'Keyboard',
                icon: ['fas', 'keyboard'],
                category: ['Code', 'Computers', 'Objects', 'Writing'],
            },
            Khanda: { id: 'Khanda', icon: ['fas', 'khanda'], category: ['Religion'] },
            Kiss: { id: 'Kiss', icon: ['fas', 'kiss'], category: ['Emoji'] },
            KissBeam: { id: 'KissBeam', icon: ['fas', 'kiss-beam'], category: ['Emoji'] },
            KissWinkHeart: {
                id: 'KissWinkHeart',
                icon: ['fas', 'kiss-wink-heart'],
                category: ['Emoji'],
            },
            KiwiBird: {
                id: 'KiwiBird',
                icon: ['fas', 'kiwi-bird'],
                category: ['Animals'],
            },
            Landmark: {
                id: 'Landmark',
                icon: ['fas', 'landmark'],
                category: ['Buildings', 'Business', 'Finance', 'Maps'],
            },
            Language: {
                id: 'Language',
                icon: ['fas', 'language'],
                category: ['Communication', 'Interfaces'],
            },
            Laptop: {
                id: 'Laptop',
                icon: ['fas', 'laptop'],
                category: ['Computers', 'Objects'],
            },
            LaptopCode: {
                id: 'LaptopCode',
                icon: ['fas', 'laptop-code'],
                category: ['Code', 'Education'],
            },
            LaptopHouse: {
                id: 'LaptopHouse',
                icon: ['fas', 'laptop-house'],
                category: ['Business', 'Computers', 'Household'],
            },
            LaptopMedical: {
                id: 'LaptopMedical',
                icon: ['fas', 'laptop-medical'],
                category: ['Medical', 'Pharmacy'],
            },
            Laugh: { id: 'Laugh', icon: ['fas', 'laugh'], category: ['Emoji'] },
            LaughBeam: {
                id: 'LaughBeam',
                icon: ['fas', 'laugh-beam'],
                category: ['Emoji'],
            },
            LaughSquint: {
                id: 'LaughSquint',
                icon: ['fas', 'laugh-squint'],
                category: ['Emoji'],
            },
            LaughWink: {
                id: 'LaughWink',
                icon: ['fas', 'laugh-wink'],
                category: ['Emoji'],
            },
            LayerGroup: {
                id: 'LayerGroup',
                icon: ['fas', 'layer-group'],
                category: ['Design', 'Maps'],
            },
            Leaf: {
                id: 'Leaf',
                icon: ['fas', 'leaf'],
                category: ['Charity', 'Energy', 'Fruits & Vegetables', 'Maps', 'Objects'],
            },
            Lemon: {
                id: 'Lemon',
                icon: ['fas', 'lemon'],
                category: ['Food', 'Fruits & Vegetables', 'Maps', 'Objects', 'Summer'],
            },
            LessThan: {
                id: 'LessThan',
                icon: ['fas', 'less-than'],
                category: ['Mathematics'],
            },
            LessThanEqual: {
                id: 'LessThanEqual',
                icon: ['fas', 'less-than-equal'],
                category: ['Mathematics'],
            },
            LevelDownAlt: {
                id: 'LevelDownAlt',
                icon: ['fas', 'level-down-alt'],
                category: ['Arrows'],
            },
            LevelUpAlt: {
                id: 'LevelUpAlt',
                icon: ['fas', 'level-up-alt'],
                category: ['Arrows'],
            },
            LifeRing: {
                id: 'LifeRing',
                icon: ['fas', 'life-ring'],
                category: ['Maps', 'Objects', 'Spinners'],
            },
            Lightbulb: {
                id: 'Lightbulb',
                icon: ['fas', 'lightbulb'],
                category: ['Energy', 'Household', 'Maps', 'Marketing', 'Objects', 'Status'],
            },
            Link: { id: 'Link', icon: ['fas', 'link'], category: ['Editors'] },
            LiraSign: {
                id: 'LiraSign',
                icon: ['fas', 'lira-sign'],
                category: ['Currency'],
            },
            List: { id: 'List', icon: ['fas', 'list'], category: ['Editors'] },
            ListAlt: { id: 'ListAlt', icon: ['fas', 'list-alt'], category: ['Editors'] },
            ListOl: { id: 'ListOl', icon: ['fas', 'list-ol'], category: ['Editors'] },
            ListUl: { id: 'ListUl', icon: ['fas', 'list-ul'], category: ['Editors'] },
            LocationArrow: {
                id: 'LocationArrow',
                icon: ['fas', 'location-arrow'],
                category: ['Arrows', 'Maps'],
            },
            Lock: {
                id: 'Lock',
                icon: ['fas', 'lock'],
                category: ['Objects', 'Security', 'Status'],
            },
            LockOpen: {
                id: 'LockOpen',
                icon: ['fas', 'lock-open'],
                category: ['Objects', 'Security', 'Status'],
            },
            LongArrowAltDown: {
                id: 'LongArrowAltDown',
                icon: ['fas', 'long-arrow-alt-down'],
                category: ['Arrows'],
            },
            LongArrowAltLeft: {
                id: 'LongArrowAltLeft',
                icon: ['fas', 'long-arrow-alt-left'],
                category: ['Arrows'],
            },
            LongArrowAltRight: {
                id: 'LongArrowAltRight',
                icon: ['fas', 'long-arrow-alt-right'],
                category: ['Arrows'],
            },
            LongArrowAltUp: {
                id: 'LongArrowAltUp',
                icon: ['fas', 'long-arrow-alt-up'],
                category: ['Arrows'],
            },
            LowVision: {
                id: 'LowVision',
                icon: ['fas', 'low-vision'],
                category: ['Accessibility', 'Maps'],
            },
            LuggageCart: {
                id: 'LuggageCart',
                icon: ['fas', 'luggage-cart'],
                category: ['Hotel', 'Travel'],
            },
            Lungs: { id: 'Lungs', icon: ['fas', 'lungs'], category: ['Medical'] },
            LungsVirus: {
                id: 'LungsVirus',
                icon: ['fas', 'lungs-virus'],
                category: ['Medical'],
            },
            Magic: {
                id: 'Magic',
                icon: ['fas', 'magic'],
                category: ['Design', 'Interfaces', 'Objects'],
            },
            Magnet: {
                id: 'Magnet',
                icon: ['fas', 'magnet'],
                category: ['Maps', 'Objects', 'Science'],
            },
            MailBulk: {
                id: 'MailBulk',
                icon: ['fas', 'mail-bulk'],
                category: ['Marketing'],
            },
            Male: {
                id: 'Male',
                icon: ['fas', 'male'],
                category: ['Maps', 'Users & People'],
            },
            Map: {
                id: 'Map',
                icon: ['fas', 'map'],
                category: ['Camping', 'Maps', 'Objects', 'Travel'],
            },
            MapMarked: {
                id: 'MapMarked',
                icon: ['fas', 'map-marked'],
                category: ['Camping', 'Travel'],
            },
            MapMarkedAlt: {
                id: 'MapMarkedAlt',
                icon: ['fas', 'map-marked-alt'],
                category: ['Camping', 'Travel'],
            },
            MapMarker: {
                id: 'MapMarker',
                icon: ['fas', 'map-marker'],
                category: ['Maps', 'Objects', 'Shapes', 'Social', 'Status'],
            },
            MapMarkerAlt: {
                id: 'MapMarkerAlt',
                icon: ['fas', 'map-marker-alt'],
                category: ['Maps', 'Objects', 'Social', 'Status'],
            },
            MapPin: {
                id: 'MapPin',
                icon: ['fas', 'map-pin'],
                category: ['Maps', 'Objects'],
            },
            MapSigns: {
                id: 'MapSigns',
                icon: ['fas', 'map-signs'],
                category: ['Camping', 'Maps', 'Objects'],
            },
            Marker: {
                id: 'Marker',
                icon: ['fas', 'marker'],
                category: ['Business', 'Design', 'Editors', 'Interfaces', 'Objects'],
            },
            Mars: { id: 'Mars', icon: ['fas', 'mars'], category: ['Genders'] },
            MarsDouble: {
                id: 'MarsDouble',
                icon: ['fas', 'mars-double'],
                category: ['Genders'],
            },
            MarsStroke: {
                id: 'MarsStroke',
                icon: ['fas', 'mars-stroke'],
                category: ['Genders'],
            },
            MarsStrokeH: {
                id: 'MarsStrokeH',
                icon: ['fas', 'mars-stroke-h'],
                category: ['Genders'],
            },
            MarsStrokeV: {
                id: 'MarsStrokeV',
                icon: ['fas', 'mars-stroke-v'],
                category: ['Genders'],
            },
            Mask: {
                id: 'Mask',
                icon: ['fas', 'mask'],
                category: ['Halloween', 'Security'],
            },
            Medal: {
                id: 'Medal',
                icon: ['fas', 'medal'],
                category: ['Interfaces', 'Objects'],
            },
            Medkit: {
                id: 'Medkit',
                icon: ['fas', 'medkit'],
                category: ['Health', 'Maps', 'Objects'],
            },
            Meh: {
                id: 'Meh',
                icon: ['fas', 'meh'],
                category: ['Chat', 'Emoji', 'Interfaces', 'Users & People'],
            },
            MehBlank: { id: 'MehBlank', icon: ['fas', 'meh-blank'], category: ['Emoji'] },
            MehRollingEyes: {
                id: 'MehRollingEyes',
                icon: ['fas', 'meh-rolling-eyes'],
                category: ['Emoji'],
            },
            Memory: {
                id: 'Memory',
                icon: ['fas', 'memory'],
                category: ['Computers', 'Objects'],
            },
            Menorah: { id: 'Menorah', icon: ['fas', 'menorah'], category: ['Religion'] },
            Mercury: { id: 'Mercury', icon: ['fas', 'mercury'], category: ['Genders'] },
            Meteor: {
                id: 'Meteor',
                icon: ['fas', 'meteor'],
                category: ['Science Fiction', 'Weather'],
            },
            Microchip: {
                id: 'Microchip',
                icon: ['fas', 'microchip'],
                category: ['Code', 'Computers', 'Objects'],
            },
            Microphone: {
                id: 'Microphone',
                icon: ['fas', 'microphone'],
                category: ['Audio & Video', 'Communication', 'Interfaces', 'Music', 'Objects', 'Status', 'Toggle'],
            },
            MicrophoneAlt: {
                id: 'MicrophoneAlt',
                icon: ['fas', 'microphone-alt'],
                category: ['Audio & Video', 'Communication', 'Interfaces', 'Music', 'Objects', 'Status'],
            },
            MicrophoneAltSlash: {
                id: 'MicrophoneAltSlash',
                icon: ['fas', 'microphone-alt-slash'],
                category: ['Audio & Video', 'Communication', 'Music', 'Status'],
            },
            MicrophoneSlash: {
                id: 'MicrophoneSlash',
                icon: ['fas', 'microphone-slash'],
                category: ['Audio & Video', 'Communication', 'Interfaces', 'Music', 'Status', 'Toggle'],
            },
            Microscope: {
                id: 'Microscope',
                icon: ['fas', 'microscope'],
                category: ['Education', 'Medical', 'Science'],
            },
            Minus: {
                id: 'Minus',
                icon: ['fas', 'minus'],
                category: ['Interfaces', 'Mathematics', 'Status'],
            },
            MinusCircle: {
                id: 'MinusCircle',
                icon: ['fas', 'minus-circle'],
                category: ['Interfaces', 'Status'],
            },
            MinusSquare: {
                id: 'MinusSquare',
                icon: ['fas', 'minus-square'],
                category: ['Interfaces', 'Status'],
            },
            Mitten: {
                id: 'Mitten',
                icon: ['fas', 'mitten'],
                category: ['Childhood', 'Clothing', 'Objects', 'Winter'],
            },
            Mobile: {
                id: 'Mobile',
                icon: ['fas', 'mobile'],
                category: ['Communication', 'Computers', 'Objects'],
            },
            MobileAlt: {
                id: 'MobileAlt',
                icon: ['fas', 'mobile-alt'],
                category: ['Communication', 'Computers', 'Objects'],
            },
            MoneyBill: {
                id: 'MoneyBill',
                icon: ['fas', 'money-bill'],
                category: ['Currency', 'Finance', 'Maps', 'Objects'],
            },
            MoneyBillAlt: {
                id: 'MoneyBillAlt',
                icon: ['fas', 'money-bill-alt'],
                category: ['Currency', 'Finance', 'Maps', 'Objects'],
            },
            MoneyBillWave: {
                id: 'MoneyBillWave',
                icon: ['fas', 'money-bill-wave'],
                category: ['Currency', 'Finance'],
            },
            MoneyBillWaveAlt: {
                id: 'MoneyBillWaveAlt',
                icon: ['fas', 'money-bill-wave-alt'],
                category: ['Currency', 'Finance'],
            },
            MoneyCheck: {
                id: 'MoneyCheck',
                icon: ['fas', 'money-check'],
                category: ['Currency', 'Finance', 'Objects', 'Payments & Shopping'],
            },
            MoneyCheckAlt: {
                id: 'MoneyCheckAlt',
                icon: ['fas', 'money-check-alt'],
                category: ['Currency', 'Finance', 'Objects', 'Payments & Shopping'],
            },
            Monument: {
                id: 'Monument',
                icon: ['fas', 'monument'],
                category: ['Buildings', 'Travel'],
            },
            Moon: {
                id: 'Moon',
                icon: ['fas', 'moon'],
                category: ['Objects', 'Science Fiction', 'Weather'],
            },
            MortarPestle: {
                id: 'MortarPestle',
                icon: ['fas', 'mortar-pestle'],
                category: ['Medical', 'Pharmacy', 'Science'],
            },
            Mosque: {
                id: 'Mosque',
                icon: ['fas', 'mosque'],
                category: ['Buildings', 'Religion'],
            },
            Motorcycle: {
                id: 'Motorcycle',
                icon: ['fas', 'motorcycle'],
                category: ['Automotive', 'Maps', 'Objects', 'Vehicles'],
            },
            Mountain: {
                id: 'Mountain',
                icon: ['fas', 'mountain'],
                category: ['Autumn', 'Camping'],
            },
            Mouse: { id: 'Mouse', icon: ['fas', 'mouse'], category: ['Computers'] },
            MousePointer: {
                id: 'MousePointer',
                icon: ['fas', 'mouse-pointer'],
                category: ['Arrows'],
            },
            MugHot: {
                id: 'MugHot',
                icon: ['fas', 'mug-hot'],
                category: ['Beverage', 'Holiday', 'Objects'],
            },
            Music: {
                id: 'Music',
                icon: ['fas', 'music'],
                category: ['Audio & Video', 'Education', 'Maps', 'Music'],
            },
            NetworkWired: {
                id: 'NetworkWired',
                icon: ['fas', 'network-wired'],
                category: ['Other'],
            },
            Neuter: { id: 'Neuter', icon: ['fas', 'neuter'], category: ['Genders'] },
            Newspaper: {
                id: 'Newspaper',
                icon: ['fas', 'newspaper'],
                category: ['Maps', 'Objects', 'Writing'],
            },
            NotEqual: {
                id: 'NotEqual',
                icon: ['fas', 'not-equal'],
                category: ['Mathematics'],
            },
            NotesMedical: {
                id: 'NotesMedical',
                icon: ['fas', 'notes-medical'],
                category: ['Medical', 'Pharmacy'],
            },
            ObjectGroup: {
                id: 'ObjectGroup',
                icon: ['fas', 'object-group'],
                category: ['Design'],
            },
            ObjectUngroup: {
                id: 'ObjectUngroup',
                icon: ['fas', 'object-ungroup'],
                category: ['Design'],
            },
            OilCan: { id: 'OilCan', icon: ['fas', 'oil-can'], category: ['Automotive'] },
            Om: { id: 'Om', icon: ['fas', 'om'], category: ['Religion'] },
            Otter: { id: 'Otter', icon: ['fas', 'otter'], category: ['Animals'] },
            Outdent: { id: 'Outdent', icon: ['fas', 'outdent'], category: ['Editors'] },
            Pager: { id: 'Pager', icon: ['fas', 'pager'], category: ['Medical'] },
            PaintBrush: {
                id: 'PaintBrush',
                icon: ['fas', 'paint-brush'],
                category: ['Design', 'Objects'],
            },
            PaintRoller: {
                id: 'PaintRoller',
                icon: ['fas', 'paint-roller'],
                category: ['Construction', 'Design'],
            },
            Palette: {
                id: 'Palette',
                icon: ['fas', 'palette'],
                category: ['Design', 'Spinners'],
            },
            Pallet: { id: 'Pallet', icon: ['fas', 'pallet'], category: ['Logistics'] },
            PaperPlane: {
                id: 'PaperPlane',
                icon: ['fas', 'paper-plane'],
                category: ['Communication', 'Editors', 'Objects', 'Vehicles', 'Writing'],
            },
            Paperclip: {
                id: 'Paperclip',
                icon: ['fas', 'paperclip'],
                category: ['Business', 'Editors', 'Objects', 'Writing'],
            },
            ParachuteBox: {
                id: 'ParachuteBox',
                icon: ['fas', 'parachute-box'],
                category: ['Charity'],
            },
            Paragraph: {
                id: 'Paragraph',
                icon: ['fas', 'paragraph'],
                category: ['Editors', 'Writing'],
            },
            Parking: {
                id: 'Parking',
                icon: ['fas', 'parking'],
                category: ['Maps', 'Status'],
            },
            Passport: {
                id: 'Passport',
                icon: ['fas', 'passport'],
                category: ['Security', 'Travel'],
            },
            Pastafarianism: {
                id: 'Pastafarianism',
                icon: ['fas', 'pastafarianism'],
                category: ['Religion'],
            },
            Paste: {
                id: 'Paste',
                icon: ['fas', 'paste'],
                category: ['Business', 'Design', 'Editors', 'Files', 'Interfaces', 'Objects'],
            },
            Pause: { id: 'Pause', icon: ['fas', 'pause'], category: ['Audio & Video'] },
            PauseCircle: {
                id: 'PauseCircle',
                icon: ['fas', 'pause-circle'],
                category: ['Audio & Video'],
            },
            Paw: {
                id: 'Paw',
                icon: ['fas', 'paw'],
                category: ['Animals', 'Maps', 'Objects'],
            },
            Peace: { id: 'Peace', icon: ['fas', 'peace'], category: ['Religion'] },
            Pen: {
                id: 'Pen',
                icon: ['fas', 'pen'],
                category: ['Business', 'Design', 'Editors', 'Interfaces', 'Objects', 'Writing'],
            },
            PenAlt: {
                id: 'PenAlt',
                icon: ['fas', 'pen-alt'],
                category: ['Business', 'Design', 'Editors', 'Interfaces', 'Objects', 'Writing'],
            },
            PenFancy: {
                id: 'PenFancy',
                icon: ['fas', 'pen-fancy'],
                category: ['Business', 'Design', 'Editors', 'Interfaces', 'Objects'],
            },
            PenNib: {
                id: 'PenNib',
                icon: ['fas', 'pen-nib'],
                category: ['Business', 'Design', 'Editors', 'Objects'],
            },
            PenSquare: {
                id: 'PenSquare',
                icon: ['fas', 'pen-square'],
                category: ['Business', 'Writing'],
            },
            PencilAlt: {
                id: 'PencilAlt',
                icon: ['fas', 'pencil-alt'],
                category: ['Business', 'Construction', 'Design', 'Editors', 'Interfaces', 'Objects', 'Writing'],
            },
            PencilRuler: {
                id: 'PencilRuler',
                icon: ['fas', 'pencil-ruler'],
                category: ['Construction', 'Design'],
            },
            PeopleArrows: {
                id: 'PeopleArrows',
                icon: ['fas', 'people-arrows'],
                category: ['Users & People'],
            },
            PeopleCarry: {
                id: 'PeopleCarry',
                icon: ['fas', 'people-carry'],
                category: ['Moving', 'Users & People'],
            },
            PepperHot: {
                id: 'PepperHot',
                icon: ['fas', 'pepper-hot'],
                category: ['Food', 'Fruits & Vegetables'],
            },
            Percent: { id: 'Percent', icon: ['fas', 'percent'], category: ['Business'] },
            Percentage: {
                id: 'Percentage',
                icon: ['fas', 'percentage'],
                category: ['Finance', 'Mathematics'],
            },
            PersonBooth: {
                id: 'PersonBooth',
                icon: ['fas', 'person-booth'],
                category: ['Political', 'Shopping', 'Users & People'],
            },
            Phone: {
                id: 'Phone',
                icon: ['fas', 'phone'],
                category: ['Business', 'Chat', 'Communication', 'Maps', 'Objects', 'Status'],
            },
            PhoneAlt: {
                id: 'PhoneAlt',
                icon: ['fas', 'phone-alt'],
                category: ['Business', 'Chat', 'Communication', 'Maps', 'Objects', 'Status'],
            },
            PhoneSlash: {
                id: 'PhoneSlash',
                icon: ['fas', 'phone-slash'],
                category: ['Business', 'Chat', 'Communication', 'Status'],
            },
            PhoneSquare: {
                id: 'PhoneSquare',
                icon: ['fas', 'phone-square'],
                category: ['Business', 'Communication', 'Maps'],
            },
            PhoneSquareAlt: {
                id: 'PhoneSquareAlt',
                icon: ['fas', 'phone-square-alt'],
                category: ['Business', 'Communication', 'Maps'],
            },
            PhoneVolume: {
                id: 'PhoneVolume',
                icon: ['fas', 'phone-volume'],
                category: ['Accessibility', 'Audio & Video', 'Business', 'Communication', 'Maps'],
            },
            PhotoVideo: {
                id: 'PhotoVideo',
                icon: ['fas', 'photo-video'],
                category: ['Audio & Video', 'Files', 'Images', 'Social'],
            },
            PiggyBank: {
                id: 'PiggyBank',
                icon: ['fas', 'piggy-bank'],
                category: ['Charity', 'Finance', 'Political'],
            },
            Pills: {
                id: 'Pills',
                icon: ['fas', 'pills'],
                category: ['Medical', 'Pharmacy', 'Science'],
            },
            PizzaSlice: {
                id: 'PizzaSlice',
                icon: ['fas', 'pizza-slice'],
                category: ['Food'],
            },
            PlaceOfWorship: {
                id: 'PlaceOfWorship',
                icon: ['fas', 'place-of-worship'],
                category: ['Buildings', 'Religion'],
            },
            Plane: {
                id: 'Plane',
                icon: ['fas', 'plane'],
                category: ['Maps', 'Objects', 'Travel', 'Vehicles'],
            },
            PlaneArrival: {
                id: 'PlaneArrival',
                icon: ['fas', 'plane-arrival'],
                category: ['Travel'],
            },
            PlaneDeparture: {
                id: 'PlaneDeparture',
                icon: ['fas', 'plane-departure'],
                category: ['Travel'],
            },
            PlaneSlash: { id: 'PlaneSlash', icon: ['fas', 'plane-slash'], category: ['Other'] },
            Play: {
                id: 'Play',
                icon: ['fas', 'play'],
                category: ['Arrows', 'Audio & Video', 'Music', 'Shapes'],
            },
            PlayCircle: {
                id: 'PlayCircle',
                icon: ['fas', 'play-circle'],
                category: ['Audio & Video'],
            },
            Plug: {
                id: 'Plug',
                icon: ['fas', 'plug'],
                category: ['Computers', 'Energy', 'Household', 'Maps', 'Objects'],
            },
            Plus: {
                id: 'Plus',
                icon: ['fas', 'plus'],
                category: ['Interfaces', 'Maps', 'Mathematics', 'Medical', 'Status'],
            },
            PlusCircle: {
                id: 'PlusCircle',
                icon: ['fas', 'plus-circle'],
                category: ['Interfaces', 'Status'],
            },
            PlusSquare: {
                id: 'PlusSquare',
                icon: ['fas', 'plus-square'],
                category: ['Health', 'Interfaces', 'Maps', 'Status'],
            },
            Podcast: {
                id: 'Podcast',
                icon: ['fas', 'podcast'],
                category: ['Audio & Video'],
            },
            Poll: {
                id: 'Poll',
                icon: ['fas', 'poll'],
                category: ['Marketing', 'Social'],
            },
            PollH: {
                id: 'PollH',
                icon: ['fas', 'poll-h'],
                category: ['Marketing', 'Social'],
            },
            Poo: {
                id: 'Poo',
                icon: ['fas', 'poo'],
                category: ['Chat', 'Interfaces', 'Users & People'],
            },
            PooStorm: {
                id: 'PooStorm',
                icon: ['fas', 'poo-storm'],
                category: ['Weather'],
            },
            Poop: { id: 'Poop', icon: ['fas', 'poop'], category: ['Energy', 'Medical'] },
            Portrait: {
                id: 'Portrait',
                icon: ['fas', 'portrait'],
                category: ['Images', 'Users & People'],
            },
            PoundSign: {
                id: 'PoundSign',
                icon: ['fas', 'pound-sign'],
                category: ['Currency'],
            },
            PowerOff: {
                id: 'PowerOff',
                icon: ['fas', 'power-off'],
                category: ['Computers', 'Energy', 'Users & People'],
            },
            Pray: {
                id: 'Pray',
                icon: ['fas', 'pray'],
                category: ['Religion', 'Users & People'],
            },
            PrayingHands: {
                id: 'PrayingHands',
                icon: ['fas', 'praying-hands'],
                category: ['Hands', 'Religion'],
            },
            Prescription: {
                id: 'Prescription',
                icon: ['fas', 'prescription'],
                category: ['Health', 'Medical', 'Pharmacy'],
            },
            PrescriptionBottle: {
                id: 'PrescriptionBottle',
                icon: ['fas', 'prescription-bottle'],
                category: ['Medical', 'Pharmacy', 'Science'],
            },
            PrescriptionBottleAlt: {
                id: 'PrescriptionBottleAlt',
                icon: ['fas', 'prescription-bottle-alt'],
                category: ['Medical', 'Pharmacy'],
            },
            Print: {
                id: 'Print',
                icon: ['fas', 'print'],
                category: ['Business', 'Computers', 'Editors', 'Maps', 'Objects', 'Status'],
            },
            Procedures: {
                id: 'Procedures',
                icon: ['fas', 'procedures'],
                category: ['Medical'],
            },
            ProjectDiagram: {
                id: 'ProjectDiagram',
                icon: ['fas', 'project-diagram'],
                category: ['Business', 'Code'],
            },
            PumpMedical: {
                id: 'PumpMedical',
                icon: ['fas', 'pump-medical'],
                category: ['Medical'],
            },
            PumpSoap: {
                id: 'PumpSoap',
                icon: ['fas', 'pump-soap'],
                category: ['Household'],
            },
            PuzzlePiece: {
                id: 'PuzzlePiece',
                icon: ['fas', 'puzzle-piece'],
                category: ['Games', 'Objects'],
            },
            Qrcode: {
                id: 'Qrcode',
                icon: ['fas', 'qrcode'],
                category: ['Code', 'Interfaces'],
            },
            Question: {
                id: 'Question',
                icon: ['fas', 'question'],
                category: ['Interfaces', 'Status'],
            },
            QuestionCircle: {
                id: 'QuestionCircle',
                icon: ['fas', 'question-circle'],
                category: ['Accessibility', 'Interfaces', 'Status'],
            },
            Quidditch: {
                id: 'Quidditch',
                icon: ['fas', 'quidditch'],
                category: ['Sports'],
            },
            QuoteLeft: {
                id: 'QuoteLeft',
                icon: ['fas', 'quote-left'],
                category: ['Chat', 'Editors', 'Interfaces', 'Writing'],
            },
            QuoteRight: {
                id: 'QuoteRight',
                icon: ['fas', 'quote-right'],
                category: ['Chat', 'Editors', 'Interfaces', 'Writing'],
            },
            Quran: { id: 'Quran', icon: ['fas', 'quran'], category: ['Religion'] },
            Radiation: {
                id: 'Radiation',
                icon: ['fas', 'radiation'],
                category: ['Alert', 'Energy', 'Medical', 'Science'],
            },
            RadiationAlt: {
                id: 'RadiationAlt',
                icon: ['fas', 'radiation-alt'],
                category: ['Alert', 'Energy', 'Medical', 'Science'],
            },
            Rainbow: {
                id: 'Rainbow',
                icon: ['fas', 'rainbow'],
                category: ['Spring', 'Weather'],
            },
            Random: {
                id: 'Random',
                icon: ['fas', 'random'],
                category: ['Arrows', 'Audio & Video'],
            },
            Receipt: {
                id: 'Receipt',
                icon: ['fas', 'receipt'],
                category: ['Finance', 'Payments & Shopping', 'Pharmacy', 'Shopping'],
            },
            RecordVinyl: {
                id: 'RecordVinyl',
                icon: ['fas', 'record-vinyl'],
                category: ['Music'],
            },
            Recycle: {
                id: 'Recycle',
                icon: ['fas', 'recycle'],
                category: ['Arrows', 'Maps'],
            },
            Redo: {
                id: 'Redo',
                icon: ['fas', 'redo'],
                category: ['Arrows', 'Audio & Video', 'Editors', 'Interfaces'],
            },
            RedoAlt: {
                id: 'RedoAlt',
                icon: ['fas', 'redo-alt'],
                category: ['Arrows', 'Audio & Video', 'Editors', 'Interfaces'],
            },
            Registered: {
                id: 'Registered',
                icon: ['fas', 'registered'],
                category: ['Business'],
            },
            RemoveFormat: {
                id: 'RemoveFormat',
                icon: ['fas', 'remove-format'],
                category: ['Editors'],
            },
            Reply: {
                id: 'Reply',
                icon: ['fas', 'reply'],
                category: ['Arrows', 'Editors', 'Interfaces'],
            },
            ReplyAll: {
                id: 'ReplyAll',
                icon: ['fas', 'reply-all'],
                category: ['Arrows', 'Editors', 'Interfaces'],
            },
            Republican: {
                id: 'Republican',
                icon: ['fas', 'republican'],
                category: ['Political'],
            },
            Restroom: {
                id: 'Restroom',
                icon: ['fas', 'restroom'],
                category: ['Maps', 'Users & People'],
            },
            Retweet: {
                id: 'Retweet',
                icon: ['fas', 'retweet'],
                category: ['Arrows', 'Social'],
            },
            Ribbon: { id: 'Ribbon', icon: ['fas', 'ribbon'], category: ['Charity'] },
            Ring: {
                id: 'Ring',
                icon: ['fas', 'ring'],
                category: ['Tabletop Gaming', 'Objects', 'Spinners'],
            },
            Road: { id: 'Road', icon: ['fas', 'road'], category: ['Maps', 'Objects'] },
            Robot: {
                id: 'Robot',
                icon: ['fas', 'robot'],
                category: ['Childhood', 'Science Fiction'],
            },
            Rocket: {
                id: 'Rocket',
                icon: ['fas', 'rocket'],
                category: ['Maps', 'Objects', 'Science Fiction', 'Vehicles'],
            },
            Route: {
                id: 'Route',
                icon: ['fas', 'route'],
                category: ['Camping', 'Maps', 'Moving'],
            },
            Rss: {
                id: 'Rss',
                icon: ['fas', 'rss'],
                category: ['Audio & Video', 'Communication', 'Interfaces'],
            },
            RssSquare: {
                id: 'RssSquare',
                icon: ['fas', 'rss-square'],
                category: ['Audio & Video', 'Communication', 'Interfaces'],
            },
            RubleSign: {
                id: 'RubleSign',
                icon: ['fas', 'ruble-sign'],
                category: ['Currency'],
            },
            Ruler: { id: 'Ruler', icon: ['fas', 'ruler'], category: ['Construction'] },
            RulerCombined: {
                id: 'RulerCombined',
                icon: ['fas', 'ruler-combined'],
                category: ['Construction', 'Design', 'Objects'],
            },
            RulerHorizontal: {
                id: 'RulerHorizontal',
                icon: ['fas', 'ruler-horizontal'],
                category: ['Construction', 'Design', 'Objects'],
            },
            RulerVertical: {
                id: 'RulerVertical',
                icon: ['fas', 'ruler-vertical'],
                category: ['Construction', 'Design', 'Objects'],
            },
            Running: {
                id: 'Running',
                icon: ['fas', 'running'],
                category: ['Fitness', 'Sports', 'Users & People'],
            },
            RupeeSign: {
                id: 'RupeeSign',
                icon: ['fas', 'rupee-sign'],
                category: ['Currency'],
            },
            SadCry: { id: 'SadCry', icon: ['fas', 'sad-cry'], category: ['Emoji'] },
            SadTear: { id: 'SadTear', icon: ['fas', 'sad-tear'], category: ['Emoji'] },
            Satellite: {
                id: 'Satellite',
                icon: ['fas', 'satellite'],
                category: ['Computers', 'Objects', 'Science Fiction'],
            },
            SatelliteDish: {
                id: 'SatelliteDish',
                icon: ['fas', 'satellite-dish'],
                category: ['Computers', 'Objects', 'Science Fiction'],
            },
            Save: {
                id: 'Save',
                icon: ['fas', 'save'],
                category: ['Business', 'Computers', 'Design', 'Files', 'Interfaces', 'Objects'],
            },
            School: {
                id: 'School',
                icon: ['fas', 'school'],
                category: ['Buildings', 'Childhood', 'Education', 'Objects'],
            },
            Screwdriver: {
                id: 'Screwdriver',
                icon: ['fas', 'screwdriver'],
                category: ['Construction', 'Editors', 'Interfaces', 'Objects'],
            },
            Scroll: {
                id: 'Scroll',
                icon: ['fas', 'scroll'],
                category: ['Tabletop Gaming', 'Objects'],
            },
            SdCard: {
                id: 'SdCard',
                icon: ['fas', 'sd-card'],
                category: ['Computers', 'Objects'],
            },
            Search: {
                id: 'Search',
                icon: ['fas', 'search'],
                category: ['Interfaces', 'Maps', 'Objects'],
            },
            SearchDollar: {
                id: 'SearchDollar',
                icon: ['fas', 'search-dollar'],
                category: ['Marketing'],
            },
            SearchLocation: {
                id: 'SearchLocation',
                icon: ['fas', 'search-location'],
                category: ['Marketing'],
            },
            SearchMinus: {
                id: 'SearchMinus',
                icon: ['fas', 'search-minus'],
                category: ['Interfaces', 'Maps'],
            },
            SearchPlus: {
                id: 'SearchPlus',
                icon: ['fas', 'search-plus'],
                category: ['Interfaces', 'Maps'],
            },
            Seedling: {
                id: 'Seedling',
                icon: ['fas', 'seedling'],
                category: ['Charity', 'Energy', 'Food', 'Fruits & Vegetables', 'Science', 'Spring'],
            },
            Server: { id: 'Server', icon: ['fas', 'server'], category: ['Computers'] },
            Shapes: {
                id: 'Shapes',
                icon: ['fas', 'shapes'],
                category: ['Childhood', 'Education', 'Shapes'],
            },
            Share: {
                id: 'Share',
                icon: ['fas', 'share'],
                category: ['Arrows', 'Editors', 'Interfaces', 'Social'],
            },
            ShareAlt: {
                id: 'ShareAlt',
                icon: ['fas', 'share-alt'],
                category: ['Interfaces', 'Social'],
            },
            ShareAltSquare: {
                id: 'ShareAltSquare',
                icon: ['fas', 'share-alt-square'],
                category: ['Interfaces'],
            },
            ShareSquare: {
                id: 'ShareSquare',
                icon: ['fas', 'share-square'],
                category: ['Arrows', 'Interfaces', 'Social'],
            },
            ShekelSign: {
                id: 'ShekelSign',
                icon: ['fas', 'shekel-sign'],
                category: ['Currency'],
            },
            ShieldAlt: {
                id: 'ShieldAlt',
                icon: ['fas', 'shield-alt'],
                category: ['Code', 'Interfaces', 'Objects', 'Security', 'Status'],
            },
            ShieldVirus: {
                id: 'ShieldVirus',
                icon: ['fas', 'shield-virus'],
                category: ['Medical'],
            },
            Ship: {
                id: 'Ship',
                icon: ['fas', 'ship'],
                category: ['Maps', 'Maritime', 'Vehicles'],
            },
            ShippingFast: {
                id: 'ShippingFast',
                icon: ['fas', 'shipping-fast'],
                category: ['Logistics', 'Shopping'],
            },
            ShoePrints: {
                id: 'ShoePrints',
                icon: ['fas', 'shoe-prints'],
                category: ['Clothing', 'Fitness', 'Maps'],
            },
            ShoppingBag: {
                id: 'ShoppingBag',
                icon: ['fas', 'shopping-bag'],
                category: ['Maps', 'Objects', 'Payments & Shopping', 'Shopping'],
            },
            ShoppingBasket: {
                id: 'ShoppingBasket',
                icon: ['fas', 'shopping-basket'],
                category: ['Maps', 'Objects', 'Payments & Shopping', 'Shopping'],
            },
            ShoppingCart: {
                id: 'ShoppingCart',
                icon: ['fas', 'shopping-cart'],
                category: ['Maps', 'Objects', 'Payments & Shopping', 'Shopping', 'Status', 'Vehicles'],
            },
            Shower: {
                id: 'Shower',
                icon: ['fas', 'shower'],
                category: ['Hotel', 'Household', 'Maps', 'Objects'],
            },
            ShuttleVan: {
                id: 'ShuttleVan',
                icon: ['fas', 'shuttle-van'],
                category: ['Automotive', 'Hotel', 'Travel', 'Vehicles'],
            },
            Sign: { id: 'Sign', icon: ['fas', 'sign'], category: ['Moving'] },
            SignInAlt: {
                id: 'SignInAlt',
                icon: ['fas', 'sign-in-alt'],
                category: ['Arrows', 'Interfaces', 'Status'],
            },
            SignLanguage: {
                id: 'SignLanguage',
                icon: ['fas', 'sign-language'],
                category: ['Accessibility'],
            },
            SignOutAlt: {
                id: 'SignOutAlt',
                icon: ['fas', 'sign-out-alt'],
                category: ['Arrows', 'Interfaces', 'Status'],
            },
            Signal: {
                id: 'Signal',
                icon: ['fas', 'signal'],
                category: ['Interfaces', 'Status'],
            },
            Signature: { id: 'Signature', icon: ['fas', 'signature'], category: ['Other'] },
            SimCard: {
                id: 'SimCard',
                icon: ['fas', 'sim-card'],
                category: ['Computers', 'Objects'],
            },
            Sink: { id: 'Sink', icon: ['fas', 'sink'], category: ['Household'] },
            Sitemap: {
                id: 'Sitemap',
                icon: ['fas', 'sitemap'],
                category: ['Business', 'Code', 'Interfaces'],
            },
            Skating: {
                id: 'Skating',
                icon: ['fas', 'skating'],
                category: ['Fitness', 'Sports', 'Users & People', 'Winter'],
            },
            Skiing: {
                id: 'Skiing',
                icon: ['fas', 'skiing'],
                category: ['Fitness', 'Sports', 'Users & People', 'Winter'],
            },
            SkiingNordic: {
                id: 'SkiingNordic',
                icon: ['fas', 'skiing-nordic'],
                category: ['Fitness', 'Sports', 'Users & People', 'Winter'],
            },
            Skull: { id: 'Skull', icon: ['fas', 'skull'], category: ['Other'] },
            SkullCrossbones: {
                id: 'SkullCrossbones',
                icon: ['fas', 'skull-crossbones'],
                category: ['Alert', 'Tabletop Gaming', 'Halloween', 'Maritime', 'Objects', 'Pharmacy', 'Science'],
            },
            Slash: { id: 'Slash', icon: ['fas', 'slash'], category: ['Spinners'] },
            Sleigh: {
                id: 'Sleigh',
                icon: ['fas', 'sleigh'],
                category: ['Holiday', 'Objects', 'Vehicles'],
            },
            SlidersH: {
                id: 'SlidersH',
                icon: ['fas', 'sliders-h'],
                category: ['Images', 'Interfaces', 'Music'],
            },
            Smile: {
                id: 'Smile',
                icon: ['fas', 'smile'],
                category: ['Chat', 'Emoji', 'Interfaces', 'Users & People'],
            },
            SmileBeam: {
                id: 'SmileBeam',
                icon: ['fas', 'smile-beam'],
                category: ['Emoji'],
            },
            SmileWink: {
                id: 'SmileWink',
                icon: ['fas', 'smile-wink'],
                category: ['Emoji'],
            },
            Smog: { id: 'Smog', icon: ['fas', 'smog'], category: ['Weather'] },
            Smoking: {
                id: 'Smoking',
                icon: ['fas', 'smoking'],
                category: ['Hotel', 'Medical'],
            },
            SmokingBan: {
                id: 'SmokingBan',
                icon: ['fas', 'smoking-ban'],
                category: ['Hotel', 'Medical', 'Status'],
            },
            Sms: { id: 'Sms', icon: ['fas', 'sms'], category: ['Chat'] },
            Snowboarding: {
                id: 'Snowboarding',
                icon: ['fas', 'snowboarding'],
                category: ['Fitness', 'Sports', 'Users & People', 'Winter'],
            },
            Snowflake: {
                id: 'Snowflake',
                icon: ['fas', 'snowflake'],
                category: ['Hotel', 'Household', 'Objects', 'Spinners', 'Weather'],
            },
            Snowman: {
                id: 'Snowman',
                icon: ['fas', 'snowman'],
                category: ['Childhood', 'Holiday'],
            },
            Snowplow: {
                id: 'Snowplow',
                icon: ['fas', 'snowplow'],
                category: ['Maps', 'Objects', 'Vehicles', 'Winter'],
            },
            Soap: { id: 'Soap', icon: ['fas', 'soap'], category: ['Household'] },
            Socks: {
                id: 'Socks',
                icon: ['fas', 'socks'],
                category: ['Business', 'Clothing'],
            },
            SolarPanel: {
                id: 'SolarPanel',
                icon: ['fas', 'solar-panel'],
                category: ['Energy'],
            },
            Sort: {
                id: 'Sort',
                icon: ['fas', 'sort'],
                category: ['Arrows', 'Interfaces'],
            },
            SortAlphaDown: {
                id: 'SortAlphaDown',
                icon: ['fas', 'sort-alpha-down'],
                category: ['Arrows', 'Interfaces'],
            },
            SortAlphaDownAlt: {
                id: 'SortAlphaDownAlt',
                icon: ['fas', 'sort-alpha-down-alt'],
                category: ['Arrows', 'Interfaces'],
            },
            SortAlphaUp: {
                id: 'SortAlphaUp',
                icon: ['fas', 'sort-alpha-up'],
                category: ['Arrows', 'Interfaces'],
            },
            SortAlphaUpAlt: {
                id: 'SortAlphaUpAlt',
                icon: ['fas', 'sort-alpha-up-alt'],
                category: ['Arrows', 'Interfaces'],
            },
            SortAmountDown: {
                id: 'SortAmountDown',
                icon: ['fas', 'sort-amount-down'],
                category: ['Arrows', 'Interfaces'],
            },
            SortAmountDownAlt: {
                id: 'SortAmountDownAlt',
                icon: ['fas', 'sort-amount-down-alt'],
                category: ['Arrows', 'Interfaces'],
            },
            SortAmountUp: {
                id: 'SortAmountUp',
                icon: ['fas', 'sort-amount-up'],
                category: ['Arrows', 'Interfaces'],
            },
            SortAmountUpAlt: {
                id: 'SortAmountUpAlt',
                icon: ['fas', 'sort-amount-up-alt'],
                category: ['Arrows', 'Interfaces'],
            },
            SortDown: {
                id: 'SortDown',
                icon: ['fas', 'sort-down'],
                category: ['Arrows', 'Interfaces'],
            },
            SortNumericDown: {
                id: 'SortNumericDown',
                icon: ['fas', 'sort-numeric-down'],
                category: ['Arrows', 'Interfaces'],
            },
            SortNumericDownAlt: {
                id: 'SortNumericDownAlt',
                icon: ['fas', 'sort-numeric-down-alt'],
                category: ['Arrows', 'Interfaces'],
            },
            SortNumericUp: {
                id: 'SortNumericUp',
                icon: ['fas', 'sort-numeric-up'],
                category: ['Arrows', 'Interfaces'],
            },
            SortNumericUpAlt: {
                id: 'SortNumericUpAlt',
                icon: ['fas', 'sort-numeric-up-alt'],
                category: ['Arrows', 'Interfaces'],
            },
            SortUp: {
                id: 'SortUp',
                icon: ['fas', 'sort-up'],
                category: ['Arrows', 'Interfaces'],
            },
            Spa: {
                id: 'Spa',
                icon: ['fas', 'spa'],
                category: ['Fitness', 'Hotel', 'Travel'],
            },
            SpaceShuttle: {
                id: 'SpaceShuttle',
                icon: ['fas', 'space-shuttle'],
                category: ['Objects', 'Science Fiction', 'Vehicles'],
            },
            SpellCheck: {
                id: 'SpellCheck',
                icon: ['fas', 'spell-check'],
                category: ['Editors'],
            },
            Spider: {
                id: 'Spider',
                icon: ['fas', 'spider'],
                category: ['Animals', 'Halloween'],
            },
            Spinner: { id: 'Spinner', icon: ['fas', 'spinner'], category: ['Spinners'] },
            Splotch: { id: 'Splotch', icon: ['fas', 'splotch'], category: ['Design'] },
            SprayCan: {
                id: 'SprayCan',
                icon: ['fas', 'spray-can'],
                category: ['Design'],
            },
            Square: { id: 'Square', icon: ['fas', 'square'], category: ['Shapes'] },
            SquareFull: {
                id: 'SquareFull',
                icon: ['fas', 'square-full'],
                category: ['Chess'],
            },
            SquareRootAlt: {
                id: 'SquareRootAlt',
                icon: ['fas', 'square-root-alt'],
                category: ['Mathematics'],
            },
            Stamp: {
                id: 'Stamp',
                icon: ['fas', 'stamp'],
                category: ['Design', 'Finance'],
            },
            Star: {
                id: 'Star',
                icon: ['fas', 'star'],
                category: ['Interfaces', 'Objects', 'Payments & Shopping', 'Shapes', 'Social', 'Status', 'Toggle'],
            },
            StarAndCrescent: {
                id: 'StarAndCrescent',
                icon: ['fas', 'star-and-crescent'],
                category: ['Religion'],
            },
            StarHalf: {
                id: 'StarHalf',
                icon: ['fas', 'star-half'],
                category: ['Interfaces', 'Status', 'Toggle'],
            },
            StarHalfAlt: {
                id: 'StarHalfAlt',
                icon: ['fas', 'star-half-alt'],
                category: ['Status', 'Toggle'],
            },
            StarOfDavid: {
                id: 'StarOfDavid',
                icon: ['fas', 'star-of-david'],
                category: ['Religion'],
            },
            StarOfLife: {
                id: 'StarOfLife',
                icon: ['fas', 'star-of-life'],
                category: ['Medical'],
            },
            StepBackward: {
                id: 'StepBackward',
                icon: ['fas', 'step-backward'],
                category: ['Audio & Video'],
            },
            StepForward: {
                id: 'StepForward',
                icon: ['fas', 'step-forward'],
                category: ['Audio & Video'],
            },
            Stethoscope: {
                id: 'Stethoscope',
                icon: ['fas', 'stethoscope'],
                category: ['Health', 'Medical'],
            },
            StickyNote: {
                id: 'StickyNote',
                icon: ['fas', 'sticky-note'],
                category: ['Business', 'Files', 'Objects', 'Writing'],
            },
            Stop: { id: 'Stop', icon: ['fas', 'stop'], category: ['Audio & Video'] },
            StopCircle: {
                id: 'StopCircle',
                icon: ['fas', 'stop-circle'],
                category: ['Audio & Video'],
            },
            Stopwatch: {
                id: 'Stopwatch',
                icon: ['fas', 'stopwatch'],
                category: ['Date & Time', 'Objects'],
            },
            Stopwatch20: {
                id: 'Stopwatch20',
                icon: ['fas', 'stopwatch-20'],
                category: ['Other'],
            },
            Store: {
                id: 'Store',
                icon: ['fas', 'store'],
                category: ['Buildings', 'Shopping'],
            },
            StoreAlt: {
                id: 'StoreAlt',
                icon: ['fas', 'store-alt'],
                category: ['Buildings', 'Shopping'],
            },
            StoreAltSlash: {
                id: 'StoreAltSlash',
                icon: ['fas', 'store-alt-slash'],
                category: ['Shopping'],
            },
            StoreSlash: {
                id: 'StoreSlash',
                icon: ['fas', 'store-slash'],
                category: ['Shopping'],
            },
            Stream: {
                id: 'Stream',
                icon: ['fas', 'stream'],
                category: ['Business', 'Code', 'Computers', 'Status'],
            },
            StreetView: {
                id: 'StreetView',
                icon: ['fas', 'street-view'],
                category: ['Maps', 'Users & People'],
            },
            Strikethrough: {
                id: 'Strikethrough',
                icon: ['fas', 'strikethrough'],
                category: ['Editors'],
            },
            Stroopwafel: {
                id: 'Stroopwafel',
                icon: ['fas', 'stroopwafel'],
                category: ['Food', 'Objects', 'Spinners'],
            },
            Subscript: {
                id: 'Subscript',
                icon: ['fas', 'subscript'],
                category: ['Editors', 'Mathematics'],
            },
            Subway: {
                id: 'Subway',
                icon: ['fas', 'subway'],
                category: ['Maps', 'Objects', 'Vehicles'],
            },
            Suitcase: {
                id: 'Suitcase',
                icon: ['fas', 'suitcase'],
                category: ['Hotel', 'Maps', 'Moving', 'Objects', 'Travel'],
            },
            SuitcaseRolling: {
                id: 'SuitcaseRolling',
                icon: ['fas', 'suitcase-rolling'],
                category: ['Hotel', 'Travel'],
            },
            Sun: {
                id: 'Sun',
                icon: ['fas', 'sun'],
                category: ['Energy', 'Objects', 'Spinners', 'Summer', 'Weather'],
            },
            Superscript: {
                id: 'Superscript',
                icon: ['fas', 'superscript'],
                category: ['Editors', 'Mathematics'],
            },
            Surprise: { id: 'Surprise', icon: ['fas', 'surprise'], category: ['Emoji'] },
            Swatchbook: {
                id: 'Swatchbook',
                icon: ['fas', 'swatchbook'],
                category: ['Design'],
            },
            Swimmer: {
                id: 'Swimmer',
                icon: ['fas', 'swimmer'],
                category: ['Fitness', 'Hotel', 'Maritime', 'Sports', 'Summer', 'Travel', 'Users & People'],
            },
            SwimmingPool: {
                id: 'SwimmingPool',
                icon: ['fas', 'swimming-pool'],
                category: ['Hotel', 'Summer', 'Travel'],
            },
            Synagogue: {
                id: 'Synagogue',
                icon: ['fas', 'synagogue'],
                category: ['Buildings', 'Religion'],
            },
            Sync: {
                id: 'Sync',
                icon: ['fas', 'sync'],
                category: ['Arrows', 'Audio & Video', 'Editors', 'Interfaces', 'Spinners'],
            },
            SyncAlt: {
                id: 'SyncAlt',
                icon: ['fas', 'sync-alt'],
                category: ['Arrows', 'Audio & Video', 'Editors', 'Interfaces', 'Spinners'],
            },
            Syringe: {
                id: 'Syringe',
                icon: ['fas', 'syringe'],
                category: ['Medical', 'Pharmacy', 'Science'],
            },
            Table: {
                id: 'Table',
                icon: ['fas', 'table'],
                category: ['Business', 'Editors'],
            },
            TableTennis: {
                id: 'TableTennis',
                icon: ['fas', 'table-tennis'],
                category: ['Sports'],
            },
            Tablet: {
                id: 'Tablet',
                icon: ['fas', 'tablet'],
                category: ['Computers', 'Objects'],
            },
            TabletAlt: {
                id: 'TabletAlt',
                icon: ['fas', 'tablet-alt'],
                category: ['Computers', 'Objects'],
            },
            Tablets: {
                id: 'Tablets',
                icon: ['fas', 'tablets'],
                category: ['Medical', 'Pharmacy', 'Science'],
            },
            TachometerAlt: {
                id: 'TachometerAlt',
                icon: ['fas', 'tachometer-alt'],
                category: ['Automotive', 'Objects'],
            },
            Tag: {
                id: 'Tag',
                icon: ['fas', 'tag'],
                category: ['Business', 'Maps', 'Objects', 'Payments & Shopping'],
            },
            Tags: {
                id: 'Tags',
                icon: ['fas', 'tags'],
                category: ['Business', 'Maps', 'Objects', 'Payments & Shopping'],
            },
            Tape: { id: 'Tape', icon: ['fas', 'tape'], category: ['Moving'] },
            Tasks: {
                id: 'Tasks',
                icon: ['fas', 'tasks'],
                category: ['Business', 'Editors'],
            },
            Taxi: {
                id: 'Taxi',
                icon: ['fas', 'taxi'],
                category: ['Automotive', 'Maps', 'Objects', 'Travel', 'Vehicles'],
            },
            Teeth: { id: 'Teeth', icon: ['fas', 'teeth'], category: ['Medical'] },
            TeethOpen: {
                id: 'TeethOpen',
                icon: ['fas', 'teeth-open'],
                category: ['Medical'],
            },
            TemperatureHigh: {
                id: 'TemperatureHigh',
                icon: ['fas', 'temperature-high'],
                category: ['Science', 'Weather'],
            },
            TemperatureLow: {
                id: 'TemperatureLow',
                icon: ['fas', 'temperature-low'],
                category: ['Science', 'Weather'],
            },
            Tenge: { id: 'Tenge', icon: ['fas', 'tenge'], category: ['Currency'] },
            Terminal: { id: 'Terminal', icon: ['fas', 'terminal'], category: ['Code'] },
            TextHeight: {
                id: 'TextHeight',
                icon: ['fas', 'text-height'],
                category: ['Arrows', 'Editors'],
            },
            TextWidth: {
                id: 'TextWidth',
                icon: ['fas', 'text-width'],
                category: ['Arrows', 'Editors'],
            },
            Th: { id: 'Th', icon: ['fas', 'th'], category: ['Editors'] },
            ThLarge: { id: 'ThLarge', icon: ['fas', 'th-large'], category: ['Editors'] },
            ThList: { id: 'ThList', icon: ['fas', 'th-list'], category: ['Editors'] },
            TheaterMasks: {
                id: 'TheaterMasks',
                icon: ['fas', 'theater-masks'],
                category: ['Education'],
            },
            Thermometer: {
                id: 'Thermometer',
                icon: ['fas', 'thermometer'],
                category: ['Medical', 'Pharmacy'],
            },
            ThermometerEmpty: {
                id: 'ThermometerEmpty',
                icon: ['fas', 'thermometer-empty'],
                category: ['Status'],
            },
            ThermometerFull: {
                id: 'ThermometerFull',
                icon: ['fas', 'thermometer-full'],
                category: ['Status'],
            },
            ThermometerHalf: {
                id: 'ThermometerHalf',
                icon: ['fas', 'thermometer-half'],
                category: ['Status'],
            },
            ThermometerQuarter: {
                id: 'ThermometerQuarter',
                icon: ['fas', 'thermometer-quarter'],
                category: ['Status'],
            },
            ThermometerThreeQuarters: {
                id: 'ThermometerThreeQuarters',
                icon: ['fas', 'thermometer-three-quarters'],
                category: ['Status'],
            },
            ThumbsDown: {
                id: 'ThumbsDown',
                icon: ['fas', 'thumbs-down'],
                category: ['Hands', 'Interfaces', 'Payments & Shopping', 'Social', 'Status'],
            },
            ThumbsUp: {
                id: 'ThumbsUp',
                icon: ['fas', 'thumbs-up'],
                category: ['Hands', 'Interfaces', 'Payments & Shopping', 'Social', 'Status'],
            },
            Thumbtack: {
                id: 'Thumbtack',
                icon: ['fas', 'thumbtack'],
                category: ['Business', 'Maps', 'Objects', 'Social', 'Writing'],
            },
            TicketAlt: {
                id: 'TicketAlt',
                icon: ['fas', 'ticket-alt'],
                category: ['Maps', 'Objects'],
            },
            Times: {
                id: 'Times',
                icon: ['fas', 'times'],
                category: ['Interfaces', 'Mathematics'],
            },
            TimesCircle: {
                id: 'TimesCircle',
                icon: ['fas', 'times-circle'],
                category: ['Interfaces'],
            },
            Tint: {
                id: 'Tint',
                icon: ['fas', 'tint'],
                category: ['Design', 'Images', 'Maps', 'Status'],
            },
            TintSlash: {
                id: 'TintSlash',
                icon: ['fas', 'tint-slash'],
                category: ['Design', 'Status'],
            },
            Tired: { id: 'Tired', icon: ['fas', 'tired'], category: ['Emoji'] },
            ToggleOff: {
                id: 'ToggleOff',
                icon: ['fas', 'toggle-off'],
                category: ['Interfaces', 'Status', 'Toggle'],
            },
            ToggleOn: {
                id: 'ToggleOn',
                icon: ['fas', 'toggle-on'],
                category: ['Interfaces', 'Status', 'Toggle'],
            },
            Toilet: { id: 'Toilet', icon: ['fas', 'toilet'], category: ['Objects'] },
            ToiletPaper: {
                id: 'ToiletPaper',
                icon: ['fas', 'toilet-paper'],
                category: ['Camping', 'Halloween', 'Household'],
            },
            ToiletPaperSlash: {
                id: 'ToiletPaperSlash',
                icon: ['fas', 'toilet-paper-slash'],
                category: ['Household'],
            },
            Toolbox: {
                id: 'Toolbox',
                icon: ['fas', 'toolbox'],
                category: ['Construction', 'Objects'],
            },
            Tools: {
                id: 'Tools',
                icon: ['fas', 'tools'],
                category: ['Construction', 'Editors', 'Interfaces', 'Objects'],
            },
            Tooth: { id: 'Tooth', icon: ['fas', 'tooth'], category: ['Medical'] },
            Torah: { id: 'Torah', icon: ['fas', 'torah'], category: ['Religion'] },
            ToriiGate: {
                id: 'ToriiGate',
                icon: ['fas', 'torii-gate'],
                category: ['Buildings', 'Religion'],
            },
            Tractor: {
                id: 'Tractor',
                icon: ['fas', 'tractor'],
                category: ['Autumn', 'Vehicles'],
            },
            Trademark: {
                id: 'Trademark',
                icon: ['fas', 'trademark'],
                category: ['Business'],
            },
            TrafficLight: {
                id: 'TrafficLight',
                icon: ['fas', 'traffic-light'],
                category: ['Maps'],
            },
            Trailer: {
                id: 'Trailer',
                icon: ['fas', 'trailer'],
                category: ['Automotive', 'Camping', 'Moving'],
            },
            Train: {
                id: 'Train',
                icon: ['fas', 'train'],
                category: ['Maps', 'Objects', 'Vehicles'],
            },
            Tram: {
                id: 'Tram',
                icon: ['fas', 'tram'],
                category: ['Maps', 'Objects', 'Travel', 'Vehicles', 'Winter'],
            },
            Transgender: {
                id: 'Transgender',
                icon: ['fas', 'transgender'],
                category: ['Genders'],
            },
            TransgenderAlt: {
                id: 'TransgenderAlt',
                icon: ['fas', 'transgender-alt'],
                category: ['Genders'],
            },
            Trash: {
                id: 'Trash',
                icon: ['fas', 'trash'],
                category: ['Editors', 'Interfaces', 'Objects'],
            },
            TrashAlt: {
                id: 'TrashAlt',
                icon: ['fas', 'trash-alt'],
                category: ['Editors', 'Interfaces', 'Objects'],
            },
            TrashRestore: {
                id: 'TrashRestore',
                icon: ['fas', 'trash-restore'],
                category: ['Editors', 'Interfaces'],
            },
            TrashRestoreAlt: {
                id: 'TrashRestoreAlt',
                icon: ['fas', 'trash-restore-alt'],
                category: ['Editors', 'Interfaces'],
            },
            Tree: {
                id: 'Tree',
                icon: ['fas', 'tree'],
                category: ['Autumn', 'Camping', 'Maps', 'Objects'],
            },
            Trophy: {
                id: 'Trophy',
                icon: ['fas', 'trophy'],
                category: ['Interfaces', 'Maps', 'Objects', 'Payments & Shopping'],
            },
            Truck: {
                id: 'Truck',
                icon: ['fas', 'truck'],
                category: ['Automotive', 'Logistics', 'Maps', 'Objects', 'Shopping', 'Vehicles'],
            },
            TruckLoading: {
                id: 'TruckLoading',
                icon: ['fas', 'truck-loading'],
                category: ['Moving'],
            },
            TruckMonster: {
                id: 'TruckMonster',
                icon: ['fas', 'truck-monster'],
                category: ['Automotive', 'Vehicles'],
            },
            TruckMoving: {
                id: 'TruckMoving',
                icon: ['fas', 'truck-moving'],
                category: ['Moving'],
            },
            TruckPickup: {
                id: 'TruckPickup',
                icon: ['fas', 'truck-pickup'],
                category: ['Automotive', 'Construction', 'Vehicles'],
            },
            Tshirt: {
                id: 'Tshirt',
                icon: ['fas', 'tshirt'],
                category: ['Clothing', 'Shopping'],
            },
            Tty: {
                id: 'Tty',
                icon: ['fas', 'tty'],
                category: ['Accessibility', 'Communication', 'Maps'],
            },
            Tv: {
                id: 'Tv',
                icon: ['fas', 'tv'],
                category: ['Audio & Video', 'Computers', 'Hotel', 'Household', 'Objects', 'Travel'],
            },
            Umbrella: {
                id: 'Umbrella',
                icon: ['fas', 'umbrella'],
                category: ['Maps', 'Objects', 'Spring', 'Weather'],
            },
            UmbrellaBeach: {
                id: 'UmbrellaBeach',
                icon: ['fas', 'umbrella-beach'],
                category: ['Hotel', 'Summer', 'Travel'],
            },
            Underline: {
                id: 'Underline',
                icon: ['fas', 'underline'],
                category: ['Editors'],
            },
            Undo: {
                id: 'Undo',
                icon: ['fas', 'undo'],
                category: ['Arrows', 'Audio & Video', 'Editors', 'Interfaces'],
            },
            UndoAlt: {
                id: 'UndoAlt',
                icon: ['fas', 'undo-alt'],
                category: ['Arrows', 'Audio & Video', 'Editors', 'Interfaces'],
            },
            UniversalAccess: {
                id: 'UniversalAccess',
                icon: ['fas', 'universal-access'],
                category: ['Accessibility'],
            },
            University: {
                id: 'University',
                icon: ['fas', 'university'],
                category: ['Buildings', 'Maps', 'Objects'],
            },
            Unlink: { id: 'Unlink', icon: ['fas', 'unlink'], category: ['Editors'] },
            Unlock: {
                id: 'Unlock',
                icon: ['fas', 'unlock'],
                category: ['Objects', 'Security', 'Status'],
            },
            UnlockAlt: {
                id: 'UnlockAlt',
                icon: ['fas', 'unlock-alt'],
                category: ['Objects', 'Security', 'Status'],
            },
            Upload: {
                id: 'Upload',
                icon: ['fas', 'upload'],
                category: ['Arrows', 'Computers', 'Interfaces'],
            },
            User: {
                id: 'User',
                icon: ['fas', 'user'],
                category: ['Interfaces', 'Social', 'Status', 'Users & People'],
            },
            UserAlt: {
                id: 'UserAlt',
                icon: ['fas', 'user-alt'],
                category: ['Interfaces', 'Status', 'Users & People'],
            },
            UserAltSlash: {
                id: 'UserAltSlash',
                icon: ['fas', 'user-alt-slash'],
                category: ['Status', 'Users & People'],
            },
            UserAstronaut: {
                id: 'UserAstronaut',
                icon: ['fas', 'user-astronaut'],
                category: ['Science Fiction', 'Users & People'],
            },
            UserCheck: {
                id: 'UserCheck',
                icon: ['fas', 'user-check'],
                category: ['Users & People'],
            },
            UserCircle: {
                id: 'UserCircle',
                icon: ['fas', 'user-circle'],
                category: ['Interfaces', 'Social', 'Users & People'],
            },
            UserClock: {
                id: 'UserClock',
                icon: ['fas', 'user-clock'],
                category: ['Users & People'],
            },
            UserCog: {
                id: 'UserCog',
                icon: ['fas', 'user-cog'],
                category: ['Users & People'],
            },
            UserEdit: {
                id: 'UserEdit',
                icon: ['fas', 'user-edit'],
                category: ['Users & People'],
            },
            UserFriends: {
                id: 'UserFriends',
                icon: ['fas', 'user-friends'],
                category: ['Social', 'Users & People'],
            },
            UserGraduate: {
                id: 'UserGraduate',
                icon: ['fas', 'user-graduate'],
                category: ['Education', 'Users & People'],
            },
            UserInjured: {
                id: 'UserInjured',
                icon: ['fas', 'user-injured'],
                category: ['Users & People'],
            },
            UserLock: {
                id: 'UserLock',
                icon: ['fas', 'user-lock'],
                category: ['Security', 'Users & People'],
            },
            UserMd: {
                id: 'UserMd',
                icon: ['fas', 'user-md'],
                category: ['Health', 'Medical', 'Users & People'],
            },
            UserMinus: {
                id: 'UserMinus',
                icon: ['fas', 'user-minus'],
                category: ['Users & People'],
            },
            UserNinja: {
                id: 'UserNinja',
                icon: ['fas', 'user-ninja'],
                category: ['Users & People'],
            },
            UserNurse: {
                id: 'UserNurse',
                icon: ['fas', 'user-nurse'],
                category: ['Medical', 'Users & People'],
            },
            UserPlus: {
                id: 'UserPlus',
                icon: ['fas', 'user-plus'],
                category: ['Social', 'Users & People'],
            },
            UserSecret: {
                id: 'UserSecret',
                icon: ['fas', 'user-secret'],
                category: ['Code', 'Security', 'Users & People'],
            },
            UserShield: {
                id: 'UserShield',
                icon: ['fas', 'user-shield'],
                category: ['Security', 'Users & People'],
            },
            UserSlash: {
                id: 'UserSlash',
                icon: ['fas', 'user-slash'],
                category: ['Status', 'Users & People'],
            },
            UserTag: {
                id: 'UserTag',
                icon: ['fas', 'user-tag'],
                category: ['Users & People'],
            },
            UserTie: {
                id: 'UserTie',
                icon: ['fas', 'user-tie'],
                category: ['Clothing', 'Users & People'],
            },
            UserTimes: {
                id: 'UserTimes',
                icon: ['fas', 'user-times'],
                category: ['Users & People'],
            },
            Users: {
                id: 'Users',
                icon: ['fas', 'users'],
                category: ['Social', 'Users & People'],
            },
            UsersCog: {
                id: 'UsersCog',
                icon: ['fas', 'users-cog'],
                category: ['Users & People'],
            },
            UsersSlash: {
                id: 'UsersSlash',
                icon: ['fas', 'users-slash'],
                category: ['Users & People'],
            },
            UtensilSpoon: {
                id: 'UtensilSpoon',
                icon: ['fas', 'utensil-spoon'],
                category: ['Maps', 'Objects'],
            },
            Utensils: {
                id: 'Utensils',
                icon: ['fas', 'utensils'],
                category: ['Hotel', 'Maps', 'Objects'],
            },
            VectorSquare: {
                id: 'VectorSquare',
                icon: ['fas', 'vector-square'],
                category: ['Design'],
            },
            Venus: { id: 'Venus', icon: ['fas', 'venus'], category: ['Genders'] },
            VenusDouble: {
                id: 'VenusDouble',
                icon: ['fas', 'venus-double'],
                category: ['Genders'],
            },
            VenusMars: {
                id: 'VenusMars',
                icon: ['fas', 'venus-mars'],
                category: ['Genders'],
            },
            Vest: { id: 'Vest', icon: ['fas', 'vest'], category: ['Maps'] },
            VestPatches: {
                id: 'VestPatches',
                icon: ['fas', 'vest-patches'],
                category: ['Maps'],
            },
            Vial: {
                id: 'Vial',
                icon: ['fas', 'vial'],
                category: ['Medical', 'Pharmacy', 'Science'],
            },
            Vials: {
                id: 'Vials',
                icon: ['fas', 'vials'],
                category: ['Medical', 'Pharmacy', 'Science'],
            },
            Video: {
                id: 'Video',
                icon: ['fas', 'video'],
                category: ['Audio & Video', 'Chat', 'Social', 'Status'],
            },
            VideoSlash: {
                id: 'VideoSlash',
                icon: ['fas', 'video-slash'],
                category: ['Chat', 'Status'],
            },
            Vihara: {
                id: 'Vihara',
                icon: ['fas', 'vihara'],
                category: ['Buildings', 'Religion'],
            },
            Virus: { id: 'Virus', icon: ['fas', 'virus'], category: ['Medical'] },
            VirusSlash: {
                id: 'VirusSlash',
                icon: ['fas', 'virus-slash'],
                category: ['Medical'],
            },
            Viruses: { id: 'Viruses', icon: ['fas', 'viruses'], category: ['Medical'] },
            Voicemail: {
                id: 'Voicemail',
                icon: ['fas', 'voicemail'],
                category: ['Communication'],
            },
            VolleyballBall: {
                id: 'VolleyballBall',
                icon: ['fas', 'volleyball-ball'],
                category: ['Sports', 'Summer'],
            },
            VolumeDown: {
                id: 'VolumeDown',
                icon: ['fas', 'volume-down'],
                category: ['Audio & Video', 'Interfaces', 'Music', 'Status'],
            },
            VolumeMute: {
                id: 'VolumeMute',
                icon: ['fas', 'volume-mute'],
                category: ['Audio & Video', 'Interfaces', 'Music', 'Status'],
            },
            VolumeOff: {
                id: 'VolumeOff',
                icon: ['fas', 'volume-off'],
                category: ['Audio & Video', 'Interfaces', 'Music', 'Status'],
            },
            VolumeUp: {
                id: 'VolumeUp',
                icon: ['fas', 'volume-up'],
                category: ['Audio & Video', 'Interfaces', 'Music', 'Status'],
            },
            VoteYea: {
                id: 'VoteYea',
                icon: ['fas', 'vote-yea'],
                category: ['Political'],
            },
            VrCardboard: {
                id: 'VrCardboard',
                icon: ['fas', 'vr-cardboard'],
                category: ['Other'],
            },
            Walking: {
                id: 'Walking',
                icon: ['fas', 'walking'],
                category: ['Fitness', 'Users & People'],
            },
            Wallet: {
                id: 'Wallet',
                icon: ['fas', 'wallet'],
                category: ['Business', 'Finance', 'Objects'],
            },
            Warehouse: {
                id: 'Warehouse',
                icon: ['fas', 'warehouse'],
                category: ['Buildings', 'Logistics'],
            },
            Water: {
                id: 'Water',
                icon: ['fas', 'water'],
                category: ['Energy', 'Maritime', 'Summer', 'Weather'],
            },
            WaveSquare: {
                id: 'WaveSquare',
                icon: ['fas', 'wave-square'],
                category: ['Mathematics'],
            },
            Weight: {
                id: 'Weight',
                icon: ['fas', 'weight'],
                category: ['Medical', 'Objects'],
            },
            WeightHanging: {
                id: 'WeightHanging',
                icon: ['fas', 'weight-hanging'],
                category: ['Other'],
            },
            Wheelchair: {
                id: 'Wheelchair',
                icon: ['fas', 'wheelchair'],
                category: ['Accessibility', 'Health', 'Hotel', 'Maps', 'Objects', 'Users & People', 'Vehicles'],
            },
            Wifi: {
                id: 'Wifi',
                icon: ['fas', 'wifi'],
                category: ['Communication', 'Hotel', 'Interfaces', 'Maps', 'Status', 'Toggle'],
            },
            Wind: {
                id: 'Wind',
                icon: ['fas', 'wind'],
                category: ['Autumn', 'Energy', 'Maritime', 'Weather'],
            },
            WindowClose: {
                id: 'WindowClose',
                icon: ['fas', 'window-close'],
                category: ['Code'],
            },
            WindowMaximize: {
                id: 'WindowMaximize',
                icon: ['fas', 'window-maximize'],
                category: ['Code'],
            },
            WindowMinimize: {
                id: 'WindowMinimize',
                icon: ['fas', 'window-minimize'],
                category: ['Code'],
            },
            WindowRestore: {
                id: 'WindowRestore',
                icon: ['fas', 'window-restore'],
                category: ['Code'],
            },
            WineBottle: {
                id: 'WineBottle',
                icon: ['fas', 'wine-bottle'],
                category: ['Autumn', 'Beverage'],
            },
            WineGlass: {
                id: 'WineGlass',
                icon: ['fas', 'wine-glass'],
                category: ['Beverage', 'Maps', 'Moving', 'Objects', 'Travel'],
            },
            WineGlassAlt: {
                id: 'WineGlassAlt',
                icon: ['fas', 'wine-glass-alt'],
                category: ['Beverage', 'Travel'],
            },
            WonSign: { id: 'WonSign', icon: ['fas', 'won-sign'], category: ['Currency'] },
            Wrench: {
                id: 'Wrench',
                icon: ['fas', 'wrench'],
                category: ['Construction', 'Editors', 'Interfaces', 'Maps', 'Objects'],
            },
            XRay: { id: 'XRay', icon: ['fas', 'x-ray'], category: ['Medical'] },
            YenSign: { id: 'YenSign', icon: ['fas', 'yen-sign'], category: ['Currency'] },
            YinYang: {
                id: 'YinYang',
                icon: ['fas', 'yin-yang'],
                category: ['Religion', 'Spinners'],
            },
        }),
        [featuredIcons]
    )
}
