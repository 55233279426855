import { useMemo } from 'react'

export const useFeaturedIcons = () => {
    return useMemo(
        () => ({
            other: { id: 'other', icon: ['fas', 'shapes'], category: 'Featured' },
            'nail-salon': {
                id: 'nail-salon',
                icon: ['fas', 'american-sign-language-interpreting'],
                category: 'Featured',
            },
            consulting: { id: 'consulting', icon: ['fas', 'briefcase'], category: 'Featured' },
            'beauty-salon': { id: 'beauty-salon', icon: ['fas', 'spray-can'], category: 'Featured' },
            'lash-artist': { id: 'lash-artist', icon: ['fas', 'lash-artist'], category: 'Featured' },
            massage: { id: 'massage', icon: ['fas', 'spa'], category: 'Featured' },
            coaching: { id: 'coaching', icon: ['fas', 'life-ring'], category: 'Featured' },
            education: { id: 'education', icon: ['fas', 'graduation-cap'], category: 'Featured' },
            barbershop: { id: 'barbershop', icon: ['fas', 'cut'], category: 'Featured' },
            fitness: { id: 'fitness', icon: ['fas', 'dumbbell'], category: 'Featured' },
            'tattoo-artist': { id: 'tattoo-artist', icon: ['fas', 'dragon'], category: 'Featured' },
            photography: { id: 'photography', icon: ['fas', 'camera'], category: 'Featured' },
            healthcare: { id: 'healthcare', icon: ['fas', 'heartbeat'], category: 'Featured' },
            automotive: { id: 'automotive', icon: ['fas', 'car'], category: 'Featured' },
            store: { id: 'store', icon: ['fas', 'store'], category: 'Featured' },
        }),
        []
    )
}
