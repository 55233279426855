import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { withSpacing } from '../../../helpers/with-spacing'

export const ModalHeader = withTheme(
    withSpacing(
        styled.div({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        }),
        { p: 3 }
    )
)
