import { keyframes, withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { AdminTheme } from '../../../theme/theme'

const OutlinePulse = keyframes({
    '0%': {
        outlineWidth: 0,
        outlineColor: 'hsla(222, 100%, 61%, 0.4)',
    },
    '100%': {
        outlineWidth: 6,
        outlineColor: 'hsla(222, 100%, 61%, 0.1)',
    },
})

export const NotificationDot = withTheme(
    styled.div(({ theme }: { theme: AdminTheme }) => ({
        display: 'inline-block',
        borderRadius: '100%',
        minWidth: theme.Spacing(1.5),
        aspectRatio: '1',
        backgroundColor: theme.ThemeColor,
        outlineColor: theme.FocusOutline,
        outlineWidth: 4,
        outlineStyle: 'solid',
        animationName: OutlinePulse,
        animationDuration: '1s',
        animationDelay: '0',
        animationIterationCount: 'infinite',
        animationDirection: 'forward',
        animationTimingFunction: 'linear',
    }))
)
