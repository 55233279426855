import styled, { CreateStyledComponent } from '@emotion/styled'
import { NavLink } from 'react-router-dom'
import { AdminTheme } from '../../../theme/theme'
import { withTheme } from '@emotion/react'
import { FaContainer } from '../comopnents/Icon'

function asNeutralButton<ComponentProps extends {}, SpecificComponentProps extends {}>(
    component: CreateStyledComponent<ComponentProps, SpecificComponentProps>
) {
    return withTheme(
        component(({ theme }: { theme: AdminTheme }) => ({
            textDecoration: 'none',
            outline: 'none',
            ...theme._BodyMedium,
            color: theme.Neutral_700,
            backgroundColor: theme.Neutral_0,
            padding: `${theme.Spacing(1)} ${theme.Spacing(3)}`,
            lineHeight: '22px',
            borderRadius: theme.ButtonBorderRadius,
            border: `1px solid ${theme.Neutral_400}`,

            cursor: 'pointer',
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',

            '&.badge': {
                padding: '6px 12px',
            },

            '&:hover': {
                color: theme.Neutral_800,
                backgroundColor: theme.Neutral_100,
            },

            '&:focus-visible': {
                color: theme.Neutral_800,
                boxShadow: `0 0 0 4px ${theme.Opacity_30}`,
            },

            '&:active': {
                color: theme.Neutral_800,
                boxShadow: 'none',
                backgroundColor: theme.Neutral_200,
            },

            '&.danger': {
                color: theme.Secondary4_700,
                borderColor: theme.Secondary4_700,
                '&:hover': {
                    borderColor: theme.Secondary4_800,
                    backgroundColor: theme.Secondary4_100,
                },
                '&:focus-visible': {
                    color: theme.Secondary4_800,
                    boxShadow: `0 0 0 4px ${theme.Secondary4_200}`,
                },
                '&:active': {
                    backgroundColor: theme.Secondary4_200,
                },
            },

            '&:disabled, &.disabled': {
                backgroundColor: theme.Neutral_0,
                color: theme.Neutral_200,
                borderColor: theme.BorderPrimary,
            },

            '&.small': {
                padding: `6px ${theme.Spacing(1.5)}`,
                ...theme._BodySmall,
            },

            [FaContainer as any]: {
                height: 22,
                width: 'auto',
                marginLeft: -2,
                marginRight: theme.Spacing(1),
                '&.end-icon': {
                    marginRight: -2,
                    marginLeft: theme.Spacing(1),
                },
            },
        }))
    )
}

export const NeutralButton = asNeutralButton(styled.button)

export const NeutralButtonLink = asNeutralButton(styled.a)

export const NeutralButtonNavLink = asNeutralButton(styled(NavLink))
