import { useCallback } from 'react'

export function useFormatCurrency(languageCode: string) {
    return useCallback(
        (amount: number, currency: string = 'EUR') =>
            Intl.NumberFormat(languageCode || 'en', {
                style: 'currency',
                currency,
                trailingZeroDisplay: 'stripIfInteger',
            } as any).format(amount),
        [languageCode]
    )
}
