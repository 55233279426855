import { defaultTheme } from './default-theme'
import { AdminTheme } from './theme'

export const darkTheme: AdminTheme = {
    ...defaultTheme,
    BrandPrimary: '#85A9FF',
    // Background
    BackgroundColor: '#25282D',
    BackgroundSecondary: '#18181B',
    BackgroundInfoSecondary: '#4B507C',
    BackgroundOnboarding: 'linear-gradient(270deg, #727DFF 0%, #6FE2FD 100%)',
    BackgroundSuccessSecondary: '#115E4B',
    // Content
    ContentBrand: '#D6E2FF',
    ContentPrimary: '#F7F8FA',
    ContentSecondary: '#B3BFCF',
    ContentTertiary: '#9AA8BB',
    ContentSuccess: '#B1FCE9',
    ContentDisabled: '#6B7582',
    // Interactive
    //    Primary
    InteractivePrimary: '#85A9FF',
    InteractivePrimaryHover: '#ADC6FF',
    InteractivePrimaryActive: '#85A9FF',
    //    Secondary
    InteractiveSecondary: '#DEE4EC',
    InteractiveSecondaryHover: '#4B507C',
    InteractiveSecondaryActive: '#30334E',
    //    Neutral
    InteractiveNeutral: '#4C535D',
    InteractiveNeutralHover: '#6B7582',
    InteractiveNeutralActive: '#25282D',
    //    Booking page card
    InteractiveBookingPageCard: '#9AA8BB1A',
    InteractiveBookingPageCardHover: '#4C535D',
    InteractiveBookingPageCardActive: '#4C535D',
}
