import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import Backend from 'i18next-xhr-backend'

export const ADMIN_LANG_LOCAL_STORAGE_KEY = 'admin_lang'

export const preferred_lang = window.localStorage.getItem(ADMIN_LANG_LOCAL_STORAGE_KEY)
export const default_lang =
    preferred_lang && ['hu', 'en'].includes(preferred_lang)
        ? preferred_lang
        : navigator.language.toLowerCase().startsWith('hu')
          ? 'hu'
          : 'en'

i18n
    // learn more: https://github.com/i18next/i18next-xhr-backend
    .use(Backend)
    // connect with React
    .use(initReactI18next)
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        lng: default_lang,
        fallbackLng: 'en',
        supportedLngs: ['en', 'hu'],
        ns: ['admin'],
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        backend: {
            loadPath: '/locales/{{lng}}-{{ns}}.json?buildNumber=' + process.env.REACT_APP_BUILD_NUMBER,
        },
        keySeparator: false,
        nsSeparator: false,
        pluralSeparator: '__',
        contextSeparator: '__',
        ignoreJSONStructure: true,
    })

export function changeLanguage(language: string) {
    window.localStorage.setItem(ADMIN_LANG_LOCAL_STORAGE_KEY, language)
    i18n.changeLanguage(language)
}

export default i18n
