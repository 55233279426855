import { call, put, select } from 'redux-saga/effects'
import { GettingStartedActionTypes, SetMessageViewedAction } from '../actions'
import { GettingStartedState, InAppMessage } from '../types'
import { createAction2 } from '../../utils/create-action'
import { authorizedFetch } from '../../utils/authorized-fetch'

export function* setMessageViewed(action: SetMessageViewedAction): any {
    const messages: InAppMessage[] = yield select(
        (state: { gettingStarted: GettingStartedState }) => state.gettingStarted.messages
    )
    yield put(
        createAction2({
            type: GettingStartedActionTypes.SET_MESSAGES,
            payload: messages.map((message) =>
                message.id === action.payload.messageId ? { ...message, viewed: true } : message
            ),
        })
    )
    if (action.payload.sync) {
        yield call(
            authorizedFetch,
            `/api/admin/inAppMessages/setViewed/${encodeURIComponent(action.payload.messageId)}`,
            'POST'
        )
    }
}
