import { put, select, take } from 'redux-saga/effects'
import { captureException } from '../../../appInsights'
import { GettingStartedActionTypes } from '../actions'
import { GettingStartedState } from '../types'
import { FINISH_SETUP_SUB_TASK_IDS, FINISH_SETUP_TASK_IDS } from '../../../pages/admin/in-app-messages/guides/constants'
import { createAction2 } from '../../utils/create-action'
import { AdminActionTypes } from '../../admin/actions'
import { ADMIN_BAR_SHARE_BUTTON_INDICATOR_ID } from '../../../components/ui-kit/comopnents/ShareButton'

export function* showNotificationDotForGuide(): any {
    try {
        while (true) {
            yield take([
                GettingStartedActionTypes.UPDATE_PROGRESS,
                GettingStartedActionTypes.SET_MESSAGES,
                GettingStartedActionTypes.SET_ACTIVE_TASK,
            ])
            let state: GettingStartedState | undefined = yield select((state) => state.gettingStarted)
            if (state?.progress?.activeTask === FINISH_SETUP_TASK_IDS.SHARE_YOUR_BOOKINGPAGE) {
                const completedSubTasks = state.progress.tasks.find(
                    (task) => task.taskId === FINISH_SETUP_TASK_IDS.SHARE_YOUR_BOOKINGPAGE
                )?.completedSubTasks
                if (!completedSubTasks?.includes(FINISH_SETUP_SUB_TASK_IDS.CLICK_ON_SHARE)) {
                    yield put(
                        createAction2({
                            type: AdminActionTypes.SHOW_NOTIFICIATION_INDICATOR,
                            payload: ADMIN_BAR_SHARE_BUTTON_INDICATOR_ID,
                        })
                    )
                }
            }
        }
    } catch (e) {
        captureException(e)
    }
}
