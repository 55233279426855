import { FC } from 'react'
import { useFormatCurrency } from '../../hooks/use-format-currency'
import { useBusiness } from '../../hooks/use-business'

interface CurrencyProps {
    value: number
    currency?: string
    languageCode: string
}

export const Currency: FC<CurrencyProps> = ({ value, currency = 'EUR', languageCode }) => {
    const formatCurrency = useFormatCurrency(languageCode)
    return <>{formatCurrency(value, currency)}</>
}

export const AdminCurrency: FC<Omit<CurrencyProps, 'languageCode'>> = (props) => {
    const business = useBusiness()
    return <Currency {...props} languageCode={business?.languageCode || 'en'} />
}
