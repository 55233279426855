import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useFeaturedEmojis } from './use-featured-emojis'

export function useEmojiGroups() {
    const { t } = useTranslation('admin')
    return useMemo(
        () => ({
            '0': t('emoji-group-Featured'),
            '1': t('emoji-group-Smileys & Emotion'),
            '2': t('emoji-group-People & Body'),
            '3': t('emoji-group-Component'),
            '4': t('emoji-group-Animals & Nature'),
            '5': t('emoji-group-Food & Drink'),
            '6': t('emoji-group-Travel & Places'),
            '7': t('emoji-group-Activities'),
            '8': t('emoji-group-Objects'),
            '9': t('emoji-group-Symbols'),
            '10': t('emoji-group-Flags'),
        }),
        [t]
    )
}

export function useEmojis() {
    const featuredEmojis = useFeaturedEmojis()

    return useMemo(
        () => ({
            ...featuredEmojis,
            '1F600': {
                id: '1F600',
                emoji: '😀',
                group: '1',
            },
            '1F603': {
                id: '1F603',
                emoji: '😃',
                group: '1',
            },
            '1F604': {
                id: '1F604',
                emoji: '😄',
                group: '1',
            },
            '1F601': {
                id: '1F601',
                emoji: '😁',
                group: '1',
            },
            '1F606': {
                id: '1F606',
                emoji: '😆',
                group: '1',
            },
            '1F605': {
                id: '1F605',
                emoji: '😅',
                group: '1',
            },
            '1F923': {
                id: '1F923',
                emoji: '🤣',
                group: '1',
            },
            '1F602': {
                id: '1F602',
                emoji: '😂',
                group: '1',
            },
            '1F642': {
                id: '1F642',
                emoji: '🙂',
                group: '1',
            },
            '1F643': {
                id: '1F643',
                emoji: '🙃',
                group: '1',
            },
            '1FAE0': {
                id: '1FAE0',
                emoji: '🫠',
                group: '1',
            },
            '1F609': {
                id: '1F609',
                emoji: '😉',
                group: '1',
            },
            '1F60A': {
                id: '1F60A',
                emoji: '😊',
                group: '1',
            },
            '1F607': {
                id: '1F607',
                emoji: '😇',
                group: '1',
            },
            '1F970': {
                id: '1F970',
                emoji: '🥰',
                group: '1',
            },
            '1F60D': {
                id: '1F60D',
                emoji: '😍',
                group: '1',
            },
            '1F929': {
                id: '1F929',
                emoji: '🤩',
                group: '1',
            },
            '1F618': {
                id: '1F618',
                emoji: '😘',
                group: '1',
            },
            '1F617': {
                id: '1F617',
                emoji: '😗',
                group: '1',
            },
            '263A_FE0F': {
                id: '263A_FE0F',
                emoji: '☺️',
                group: '1',
            },
            '1F61A': {
                id: '1F61A',
                emoji: '😚',
                group: '1',
            },
            '1F619': {
                id: '1F619',
                emoji: '😙',
                group: '1',
            },
            '1F972': {
                id: '1F972',
                emoji: '🥲',
                group: '1',
            },
            '1F60B': {
                id: '1F60B',
                emoji: '😋',
                group: '1',
            },
            '1F61B': {
                id: '1F61B',
                emoji: '😛',
                group: '1',
            },
            '1F61C': {
                id: '1F61C',
                emoji: '😜',
                group: '1',
            },
            '1F92A': {
                id: '1F92A',
                emoji: '🤪',
                group: '1',
            },
            '1F61D': {
                id: '1F61D',
                emoji: '😝',
                group: '1',
            },
            '1F911': {
                id: '1F911',
                emoji: '🤑',
                group: '1',
            },
            '1F917': {
                id: '1F917',
                emoji: '🤗',
                group: '1',
            },
            '1F92D': {
                id: '1F92D',
                emoji: '🤭',
                group: '1',
            },
            '1FAE2': {
                id: '1FAE2',
                emoji: '🫢',
                group: '1',
            },
            '1FAE3': {
                id: '1FAE3',
                emoji: '🫣',
                group: '1',
            },
            '1F92B': {
                id: '1F92B',
                emoji: '🤫',
                group: '1',
            },
            '1F914': {
                id: '1F914',
                emoji: '🤔',
                group: '1',
            },
            '1FAE1': {
                id: '1FAE1',
                emoji: '🫡',
                group: '1',
            },
            '1F910': {
                id: '1F910',
                emoji: '🤐',
                group: '1',
            },
            '1F928': {
                id: '1F928',
                emoji: '🤨',
                group: '1',
            },
            '1F610': {
                id: '1F610',
                emoji: '😐',
                group: '1',
            },
            '1F611': {
                id: '1F611',
                emoji: '😑',
                group: '1',
            },
            '1F636': {
                id: '1F636',
                emoji: '😶',
                group: '1',
            },
            '1FAE5': {
                id: '1FAE5',
                emoji: '🫥',
                group: '1',
            },
            '1F636_200D_1F32B_FE0F': {
                id: '1F636_200D_1F32B_FE0F',
                emoji: '😶‍🌫️',
                group: '1',
            },
            '1F60F': {
                id: '1F60F',
                emoji: '😏',
                group: '1',
            },
            '1F612': {
                id: '1F612',
                emoji: '😒',
                group: '1',
            },
            '1F644': {
                id: '1F644',
                emoji: '🙄',
                group: '1',
            },
            '1F62C': {
                id: '1F62C',
                emoji: '😬',
                group: '1',
            },
            '1F62E_200D_1F4A8': {
                id: '1F62E_200D_1F4A8',
                emoji: '😮‍💨',
                group: '1',
            },
            '1F925': {
                id: '1F925',
                emoji: '🤥',
                group: '1',
            },
            '1FAE8': {
                id: '1FAE8',
                emoji: '🫨',
                group: '1',
            },
            '1F60C': {
                id: '1F60C',
                emoji: '😌',
                group: '1',
            },
            '1F614': {
                id: '1F614',
                emoji: '😔',
                group: '1',
            },
            '1F62A': {
                id: '1F62A',
                emoji: '😪',
                group: '1',
            },
            '1F924': {
                id: '1F924',
                emoji: '🤤',
                group: '1',
            },
            '1F634': {
                id: '1F634',
                emoji: '😴',
                group: '1',
            },
            '1F637': {
                id: '1F637',
                emoji: '😷',
                group: '1',
            },
            '1F912': {
                id: '1F912',
                emoji: '🤒',
                group: '1',
            },
            '1F915': {
                id: '1F915',
                emoji: '🤕',
                group: '1',
            },
            '1F922': {
                id: '1F922',
                emoji: '🤢',
                group: '1',
            },
            '1F92E': {
                id: '1F92E',
                emoji: '🤮',
                group: '1',
            },
            '1F927': {
                id: '1F927',
                emoji: '🤧',
                group: '1',
            },
            '1F975': {
                id: '1F975',
                emoji: '🥵',
                group: '1',
            },
            '1F976': {
                id: '1F976',
                emoji: '🥶',
                group: '1',
            },
            '1F974': {
                id: '1F974',
                emoji: '🥴',
                group: '1',
            },
            '1F635': {
                id: '1F635',
                emoji: '😵',
                group: '1',
            },
            '1F635_200D_1F4AB': {
                id: '1F635_200D_1F4AB',
                emoji: '😵‍💫',
                group: '1',
            },
            '1F92F': {
                id: '1F92F',
                emoji: '🤯',
                group: '1',
            },
            '1F920': {
                id: '1F920',
                emoji: '🤠',
                group: '1',
            },
            '1F973': {
                id: '1F973',
                emoji: '🥳',
                group: '1',
            },
            '1F978': {
                id: '1F978',
                emoji: '🥸',
                group: '1',
            },
            '1F60E': {
                id: '1F60E',
                emoji: '😎',
                group: '1',
            },
            '1F913': {
                id: '1F913',
                emoji: '🤓',
                group: '1',
            },
            '1F9D0': {
                id: '1F9D0',
                emoji: '🧐',
                group: '1',
            },
            '1F615': {
                id: '1F615',
                emoji: '😕',
                group: '1',
            },
            '1FAE4': {
                id: '1FAE4',
                emoji: '🫤',
                group: '1',
            },
            '1F61F': {
                id: '1F61F',
                emoji: '😟',
                group: '1',
            },
            '1F641': {
                id: '1F641',
                emoji: '🙁',
                group: '1',
            },
            '2639_FE0F': {
                id: '2639_FE0F',
                emoji: '☹️',
                group: '1',
            },
            '1F62E': {
                id: '1F62E',
                emoji: '😮',
                group: '1',
            },
            '1F62F': {
                id: '1F62F',
                emoji: '😯',
                group: '1',
            },
            '1F632': {
                id: '1F632',
                emoji: '😲',
                group: '1',
            },
            '1F633': {
                id: '1F633',
                emoji: '😳',
                group: '1',
            },
            '1F97A': {
                id: '1F97A',
                emoji: '🥺',
                group: '1',
            },
            '1F979': {
                id: '1F979',
                emoji: '🥹',
                group: '1',
            },
            '1F626': {
                id: '1F626',
                emoji: '😦',
                group: '1',
            },
            '1F627': {
                id: '1F627',
                emoji: '😧',
                group: '1',
            },
            '1F628': {
                id: '1F628',
                emoji: '😨',
                group: '1',
            },
            '1F630': {
                id: '1F630',
                emoji: '😰',
                group: '1',
            },
            '1F625': {
                id: '1F625',
                emoji: '😥',
                group: '1',
            },
            '1F622': {
                id: '1F622',
                emoji: '😢',
                group: '1',
            },
            '1F62D': {
                id: '1F62D',
                emoji: '😭',
                group: '1',
            },
            '1F631': {
                id: '1F631',
                emoji: '😱',
                group: '1',
            },
            '1F616': {
                id: '1F616',
                emoji: '😖',
                group: '1',
            },
            '1F623': {
                id: '1F623',
                emoji: '😣',
                group: '1',
            },
            '1F61E': {
                id: '1F61E',
                emoji: '😞',
                group: '1',
            },
            '1F613': {
                id: '1F613',
                emoji: '😓',
                group: '1',
            },
            '1F629': {
                id: '1F629',
                emoji: '😩',
                group: '1',
            },
            '1F62B': {
                id: '1F62B',
                emoji: '😫',
                group: '1',
            },
            '1F971': {
                id: '1F971',
                emoji: '🥱',
                group: '1',
            },
            '1F624': {
                id: '1F624',
                emoji: '😤',
                group: '1',
            },
            '1F621': {
                id: '1F621',
                emoji: '😡',
                group: '1',
            },
            '1F620': {
                id: '1F620',
                emoji: '😠',
                group: '1',
            },
            '1F92C': {
                id: '1F92C',
                emoji: '🤬',
                group: '1',
            },
            '1F608': {
                id: '1F608',
                emoji: '😈',
                group: '1',
            },
            '1F47F': {
                id: '1F47F',
                emoji: '👿',
                group: '1',
            },
            '1F480': {
                id: '1F480',
                emoji: '💀',
                group: '1',
            },
            '2620_FE0F': {
                id: '2620_FE0F',
                emoji: '☠️',
                group: '1',
            },
            '1F4A9': {
                id: '1F4A9',
                emoji: '💩',
                group: '1',
            },
            '1F921': {
                id: '1F921',
                emoji: '🤡',
                group: '1',
            },
            '1F479': {
                id: '1F479',
                emoji: '👹',
                group: '1',
            },
            '1F47A': {
                id: '1F47A',
                emoji: '👺',
                group: '1',
            },
            '1F47B': {
                id: '1F47B',
                emoji: '👻',
                group: '1',
            },
            '1F47D': {
                id: '1F47D',
                emoji: '👽',
                group: '1',
            },
            '1F47E': {
                id: '1F47E',
                emoji: '👾',
                group: '1',
            },
            '1F916': {
                id: '1F916',
                emoji: '🤖',
                group: '1',
            },
            '1F63A': {
                id: '1F63A',
                emoji: '😺',
                group: '1',
            },
            '1F638': {
                id: '1F638',
                emoji: '😸',
                group: '1',
            },
            '1F639': {
                id: '1F639',
                emoji: '😹',
                group: '1',
            },
            '1F63B': {
                id: '1F63B',
                emoji: '😻',
                group: '1',
            },
            '1F63C': {
                id: '1F63C',
                emoji: '😼',
                group: '1',
            },
            '1F63D': {
                id: '1F63D',
                emoji: '😽',
                group: '1',
            },
            '1F640': {
                id: '1F640',
                emoji: '🙀',
                group: '1',
            },
            '1F63F': {
                id: '1F63F',
                emoji: '😿',
                group: '1',
            },
            '1F63E': {
                id: '1F63E',
                emoji: '😾',
                group: '1',
            },
            '1F648': {
                id: '1F648',
                emoji: '🙈',
                group: '1',
            },
            '1F649': {
                id: '1F649',
                emoji: '🙉',
                group: '1',
            },
            '1F64A': {
                id: '1F64A',
                emoji: '🙊',
                group: '1',
            },
            '1F48C': {
                id: '1F48C',
                emoji: '💌',
                group: '1',
            },
            '1F498': {
                id: '1F498',
                emoji: '💘',
                group: '1',
            },
            '1F49D': {
                id: '1F49D',
                emoji: '💝',
                group: '1',
            },
            '1F496': {
                id: '1F496',
                emoji: '💖',
                group: '1',
            },
            '1F497': {
                id: '1F497',
                emoji: '💗',
                group: '1',
            },
            '1F493': {
                id: '1F493',
                emoji: '💓',
                group: '1',
            },
            '1F49E': {
                id: '1F49E',
                emoji: '💞',
                group: '1',
            },
            '1F495': {
                id: '1F495',
                emoji: '💕',
                group: '1',
            },
            '1F49F': {
                id: '1F49F',
                emoji: '💟',
                group: '1',
            },
            '2763_FE0F': {
                id: '2763_FE0F',
                emoji: '❣️',
                group: '1',
            },
            '1F494': {
                id: '1F494',
                emoji: '💔',
                group: '1',
            },
            '2764_FE0F_200D_1F525': {
                id: '2764_FE0F_200D_1F525',
                emoji: '❤️‍🔥',
                group: '1',
            },
            '2764_FE0F_200D_1FA79': {
                id: '2764_FE0F_200D_1FA79',
                emoji: '❤️‍🩹',
                group: '1',
            },
            '2764_FE0F': {
                id: '2764_FE0F',
                emoji: '❤️',
                group: '1',
            },
            '1FA77': {
                id: '1FA77',
                emoji: '🩷',
                group: '1',
            },
            '1F9E1': {
                id: '1F9E1',
                emoji: '🧡',
                group: '1',
            },
            '1F49B': {
                id: '1F49B',
                emoji: '💛',
                group: '1',
            },
            '1F49A': {
                id: '1F49A',
                emoji: '💚',
                group: '1',
            },
            '1F499': {
                id: '1F499',
                emoji: '💙',
                group: '1',
            },
            '1FA75': {
                id: '1FA75',
                emoji: '🩵',
                group: '1',
            },
            '1F49C': {
                id: '1F49C',
                emoji: '💜',
                group: '1',
            },
            '1F90E': {
                id: '1F90E',
                emoji: '🤎',
                group: '1',
            },
            '1F5A4': {
                id: '1F5A4',
                emoji: '🖤',
                group: '1',
            },
            '1FA76': {
                id: '1FA76',
                emoji: '🩶',
                group: '1',
            },
            '1F90D': {
                id: '1F90D',
                emoji: '🤍',
                group: '1',
            },
            '1F48B': {
                id: '1F48B',
                emoji: '💋',
                group: '1',
            },
            '1F4AF': {
                id: '1F4AF',
                emoji: '💯',
                group: '1',
            },
            '1F4A2': {
                id: '1F4A2',
                emoji: '💢',
                group: '1',
            },
            '1F4A5': {
                id: '1F4A5',
                emoji: '💥',
                group: '1',
            },
            '1F4AB': {
                id: '1F4AB',
                emoji: '💫',
                group: '1',
            },
            '1F4A6': {
                id: '1F4A6',
                emoji: '💦',
                group: '1',
            },
            '1F4A8': {
                id: '1F4A8',
                emoji: '💨',
                group: '1',
            },
            '1F573_FE0F': {
                id: '1F573_FE0F',
                emoji: '🕳️',
                group: '1',
            },
            '1F4AC': {
                id: '1F4AC',
                emoji: '💬',
                group: '1',
            },
            '1F441_FE0F_200D_1F5E8_FE0F': {
                id: '1F441_FE0F_200D_1F5E8_FE0F',
                emoji: '👁️‍🗨️',
                group: '1',
            },
            '1F5E8_FE0F': {
                id: '1F5E8_FE0F',
                emoji: '🗨️',
                group: '1',
            },
            '1F5EF_FE0F': {
                id: '1F5EF_FE0F',
                emoji: '🗯️',
                group: '1',
            },
            '1F4AD': {
                id: '1F4AD',
                emoji: '💭',
                group: '1',
            },
            '1F4A4': {
                id: '1F4A4',
                emoji: '💤',
                group: '1',
            },
            '1F44B': {
                id: '1F44B',
                emoji: '👋',
                group: '2',
            },
            '1F91A': {
                id: '1F91A',
                emoji: '🤚',
                group: '2',
            },
            '1F590_FE0F': {
                id: '1F590_FE0F',
                emoji: '🖐️',
                group: '2',
            },
            '270B': {
                id: '270B',
                emoji: '✋',
                group: '2',
            },
            '1F596': {
                id: '1F596',
                emoji: '🖖',
                group: '2',
            },
            '1FAF1': {
                id: '1FAF1',
                emoji: '🫱',
                group: '2',
            },
            '1FAF2': {
                id: '1FAF2',
                emoji: '🫲',
                group: '2',
            },
            '1FAF3': {
                id: '1FAF3',
                emoji: '🫳',
                group: '2',
            },
            '1FAF4': {
                id: '1FAF4',
                emoji: '🫴',
                group: '2',
            },
            '1FAF7': {
                id: '1FAF7',
                emoji: '🫷',
                group: '2',
            },
            '1FAF8': {
                id: '1FAF8',
                emoji: '🫸',
                group: '2',
            },
            '1F44C': {
                id: '1F44C',
                emoji: '👌',
                group: '2',
            },
            '1F90C': {
                id: '1F90C',
                emoji: '🤌',
                group: '2',
            },
            '1F90F': {
                id: '1F90F',
                emoji: '🤏',
                group: '2',
            },
            '270C_FE0F': {
                id: '270C_FE0F',
                emoji: '✌️',
                group: '2',
            },
            '1F91E': {
                id: '1F91E',
                emoji: '🤞',
                group: '2',
            },
            '1FAF0': {
                id: '1FAF0',
                emoji: '🫰',
                group: '2',
            },
            '1F91F': {
                id: '1F91F',
                emoji: '🤟',
                group: '2',
            },
            '1F918': {
                id: '1F918',
                emoji: '🤘',
                group: '2',
            },
            '1F919': {
                id: '1F919',
                emoji: '🤙',
                group: '2',
            },
            '1F448': {
                id: '1F448',
                emoji: '👈',
                group: '2',
            },
            '1F449': {
                id: '1F449',
                emoji: '👉',
                group: '2',
            },
            '1F446': {
                id: '1F446',
                emoji: '👆',
                group: '2',
            },
            '1F595': {
                id: '1F595',
                emoji: '🖕',
                group: '2',
            },
            '1F447': {
                id: '1F447',
                emoji: '👇',
                group: '2',
            },
            '261D_FE0F': {
                id: '261D_FE0F',
                emoji: '☝️',
                group: '2',
            },
            '1FAF5': {
                id: '1FAF5',
                emoji: '🫵',
                group: '2',
            },
            '1F44D': {
                id: '1F44D',
                emoji: '👍',
                group: '2',
            },
            '1F44E': {
                id: '1F44E',
                emoji: '👎',
                group: '2',
            },
            '270A': {
                id: '270A',
                emoji: '✊',
                group: '2',
            },
            '1F44A': {
                id: '1F44A',
                emoji: '👊',
                group: '2',
            },
            '1F91B': {
                id: '1F91B',
                emoji: '🤛',
                group: '2',
            },
            '1F91C': {
                id: '1F91C',
                emoji: '🤜',
                group: '2',
            },
            '1F44F': {
                id: '1F44F',
                emoji: '👏',
                group: '2',
            },
            '1F64C': {
                id: '1F64C',
                emoji: '🙌',
                group: '2',
            },
            '1FAF6': {
                id: '1FAF6',
                emoji: '🫶',
                group: '2',
            },
            '1F450': {
                id: '1F450',
                emoji: '👐',
                group: '2',
            },
            '1F932': {
                id: '1F932',
                emoji: '🤲',
                group: '2',
            },
            '1F91D': {
                id: '1F91D',
                emoji: '🤝',
                group: '2',
            },
            '1F64F': {
                id: '1F64F',
                emoji: '🙏',
                group: '2',
            },
            '270D_FE0F': {
                id: '270D_FE0F',
                emoji: '✍️',
                group: '2',
            },
            '1F485': {
                id: '1F485',
                emoji: '💅',
                group: '2',
            },
            '1F933': {
                id: '1F933',
                emoji: '🤳',
                group: '2',
            },
            '1F4AA': {
                id: '1F4AA',
                emoji: '💪',
                group: '2',
            },
            '1F9BE': {
                id: '1F9BE',
                emoji: '🦾',
                group: '2',
            },
            '1F9BF': {
                id: '1F9BF',
                emoji: '🦿',
                group: '2',
            },
            '1F9B5': {
                id: '1F9B5',
                emoji: '🦵',
                group: '2',
            },
            '1F9B6': {
                id: '1F9B6',
                emoji: '🦶',
                group: '2',
            },
            '1F442': {
                id: '1F442',
                emoji: '👂',
                group: '2',
            },
            '1F9BB': {
                id: '1F9BB',
                emoji: '🦻',
                group: '2',
            },
            '1F443': {
                id: '1F443',
                emoji: '👃',
                group: '2',
            },
            '1F9E0': {
                id: '1F9E0',
                emoji: '🧠',
                group: '2',
            },
            '1FAC0': {
                id: '1FAC0',
                emoji: '🫀',
                group: '2',
            },
            '1FAC1': {
                id: '1FAC1',
                emoji: '🫁',
                group: '2',
            },
            '1F9B7': {
                id: '1F9B7',
                emoji: '🦷',
                group: '2',
            },
            '1F9B4': {
                id: '1F9B4',
                emoji: '🦴',
                group: '2',
            },
            '1F440': {
                id: '1F440',
                emoji: '👀',
                group: '2',
            },
            '1F441_FE0F': {
                id: '1F441_FE0F',
                emoji: '👁️',
                group: '2',
            },
            '1F445': {
                id: '1F445',
                emoji: '👅',
                group: '2',
            },
            '1F444': {
                id: '1F444',
                emoji: '👄',
                group: '2',
            },
            '1FAE6': {
                id: '1FAE6',
                emoji: '🫦',
                group: '2',
            },
            '1F476': {
                id: '1F476',
                emoji: '👶',
                group: '2',
            },
            '1F9D2': {
                id: '1F9D2',
                emoji: '🧒',
                group: '2',
            },
            '1F466': {
                id: '1F466',
                emoji: '👦',
                group: '2',
            },
            '1F467': {
                id: '1F467',
                emoji: '👧',
                group: '2',
            },
            '1F9D1': {
                id: '1F9D1',
                emoji: '🧑',
                group: '2',
            },
            '1F471': {
                id: '1F471',
                emoji: '👱',
                group: '2',
            },
            '1F468': {
                id: '1F468',
                emoji: '👨',
                group: '2',
            },
            '1F9D4': {
                id: '1F9D4',
                emoji: '🧔',
                group: '2',
            },
            '1F9D4_200D_2642_FE0F': {
                id: '1F9D4_200D_2642_FE0F',
                emoji: '🧔‍♂️',
                group: '2',
            },
            '1F9D4_200D_2640_FE0F': {
                id: '1F9D4_200D_2640_FE0F',
                emoji: '🧔‍♀️',
                group: '2',
            },
            '1F468_200D_1F9B0': {
                id: '1F468_200D_1F9B0',
                emoji: '👨‍🦰',
                group: '2',
            },
            '1F468_200D_1F9B1': {
                id: '1F468_200D_1F9B1',
                emoji: '👨‍🦱',
                group: '2',
            },
            '1F468_200D_1F9B3': {
                id: '1F468_200D_1F9B3',
                emoji: '👨‍🦳',
                group: '2',
            },
            '1F468_200D_1F9B2': {
                id: '1F468_200D_1F9B2',
                emoji: '👨‍🦲',
                group: '2',
            },
            '1F469': {
                id: '1F469',
                emoji: '👩',
                group: '2',
            },
            '1F469_200D_1F9B0': {
                id: '1F469_200D_1F9B0',
                emoji: '👩‍🦰',
                group: '2',
            },
            '1F9D1_200D_1F9B0': {
                id: '1F9D1_200D_1F9B0',
                emoji: '🧑‍🦰',
                group: '2',
            },
            '1F469_200D_1F9B1': {
                id: '1F469_200D_1F9B1',
                emoji: '👩‍🦱',
                group: '2',
            },
            '1F9D1_200D_1F9B1': {
                id: '1F9D1_200D_1F9B1',
                emoji: '🧑‍🦱',
                group: '2',
            },
            '1F469_200D_1F9B3': {
                id: '1F469_200D_1F9B3',
                emoji: '👩‍🦳',
                group: '2',
            },
            '1F9D1_200D_1F9B3': {
                id: '1F9D1_200D_1F9B3',
                emoji: '🧑‍🦳',
                group: '2',
            },
            '1F469_200D_1F9B2': {
                id: '1F469_200D_1F9B2',
                emoji: '👩‍🦲',
                group: '2',
            },
            '1F9D1_200D_1F9B2': {
                id: '1F9D1_200D_1F9B2',
                emoji: '🧑‍🦲',
                group: '2',
            },
            '1F471_200D_2640_FE0F': {
                id: '1F471_200D_2640_FE0F',
                emoji: '👱‍♀️',
                group: '2',
            },
            '1F471_200D_2642_FE0F': {
                id: '1F471_200D_2642_FE0F',
                emoji: '👱‍♂️',
                group: '2',
            },
            '1F9D3': {
                id: '1F9D3',
                emoji: '🧓',
                group: '2',
            },
            '1F474': {
                id: '1F474',
                emoji: '👴',
                group: '2',
            },
            '1F475': {
                id: '1F475',
                emoji: '👵',
                group: '2',
            },
            '1F64D': {
                id: '1F64D',
                emoji: '🙍',
                group: '2',
            },
            '1F64D_200D_2642_FE0F': {
                id: '1F64D_200D_2642_FE0F',
                emoji: '🙍‍♂️',
                group: '2',
            },
            '1F64D_200D_2640_FE0F': {
                id: '1F64D_200D_2640_FE0F',
                emoji: '🙍‍♀️',
                group: '2',
            },
            '1F64E': {
                id: '1F64E',
                emoji: '🙎',
                group: '2',
            },
            '1F64E_200D_2642_FE0F': {
                id: '1F64E_200D_2642_FE0F',
                emoji: '🙎‍♂️',
                group: '2',
            },
            '1F64E_200D_2640_FE0F': {
                id: '1F64E_200D_2640_FE0F',
                emoji: '🙎‍♀️',
                group: '2',
            },
            '1F645': {
                id: '1F645',
                emoji: '🙅',
                group: '2',
            },
            '1F645_200D_2642_FE0F': {
                id: '1F645_200D_2642_FE0F',
                emoji: '🙅‍♂️',
                group: '2',
            },
            '1F645_200D_2640_FE0F': {
                id: '1F645_200D_2640_FE0F',
                emoji: '🙅‍♀️',
                group: '2',
            },
            '1F646': {
                id: '1F646',
                emoji: '🙆',
                group: '2',
            },
            '1F646_200D_2642_FE0F': {
                id: '1F646_200D_2642_FE0F',
                emoji: '🙆‍♂️',
                group: '2',
            },
            '1F646_200D_2640_FE0F': {
                id: '1F646_200D_2640_FE0F',
                emoji: '🙆‍♀️',
                group: '2',
            },
            '1F481': {
                id: '1F481',
                emoji: '💁',
                group: '2',
            },
            '1F481_200D_2642_FE0F': {
                id: '1F481_200D_2642_FE0F',
                emoji: '💁‍♂️',
                group: '2',
            },
            '1F481_200D_2640_FE0F': {
                id: '1F481_200D_2640_FE0F',
                emoji: '💁‍♀️',
                group: '2',
            },
            '1F64B': {
                id: '1F64B',
                emoji: '🙋',
                group: '2',
            },
            '1F64B_200D_2642_FE0F': {
                id: '1F64B_200D_2642_FE0F',
                emoji: '🙋‍♂️',
                group: '2',
            },
            '1F64B_200D_2640_FE0F': {
                id: '1F64B_200D_2640_FE0F',
                emoji: '🙋‍♀️',
                group: '2',
            },
            '1F9CF': {
                id: '1F9CF',
                emoji: '🧏',
                group: '2',
            },
            '1F9CF_200D_2642_FE0F': {
                id: '1F9CF_200D_2642_FE0F',
                emoji: '🧏‍♂️',
                group: '2',
            },
            '1F9CF_200D_2640_FE0F': {
                id: '1F9CF_200D_2640_FE0F',
                emoji: '🧏‍♀️',
                group: '2',
            },
            '1F647': {
                id: '1F647',
                emoji: '🙇',
                group: '2',
            },
            '1F647_200D_2642_FE0F': {
                id: '1F647_200D_2642_FE0F',
                emoji: '🙇‍♂️',
                group: '2',
            },
            '1F647_200D_2640_FE0F': {
                id: '1F647_200D_2640_FE0F',
                emoji: '🙇‍♀️',
                group: '2',
            },
            '1F926': {
                id: '1F926',
                emoji: '🤦',
                group: '2',
            },
            '1F926_200D_2642_FE0F': {
                id: '1F926_200D_2642_FE0F',
                emoji: '🤦‍♂️',
                group: '2',
            },
            '1F926_200D_2640_FE0F': {
                id: '1F926_200D_2640_FE0F',
                emoji: '🤦‍♀️',
                group: '2',
            },
            '1F937': {
                id: '1F937',
                emoji: '🤷',
                group: '2',
            },
            '1F937_200D_2642_FE0F': {
                id: '1F937_200D_2642_FE0F',
                emoji: '🤷‍♂️',
                group: '2',
            },
            '1F937_200D_2640_FE0F': {
                id: '1F937_200D_2640_FE0F',
                emoji: '🤷‍♀️',
                group: '2',
            },
            '1F9D1_200D_2695_FE0F': {
                id: '1F9D1_200D_2695_FE0F',
                emoji: '🧑‍⚕️',
                group: '2',
            },
            '1F468_200D_2695_FE0F': {
                id: '1F468_200D_2695_FE0F',
                emoji: '👨‍⚕️',
                group: '2',
            },
            '1F469_200D_2695_FE0F': {
                id: '1F469_200D_2695_FE0F',
                emoji: '👩‍⚕️',
                group: '2',
            },
            '1F9D1_200D_1F393': {
                id: '1F9D1_200D_1F393',
                emoji: '🧑‍🎓',
                group: '2',
            },
            '1F468_200D_1F393': {
                id: '1F468_200D_1F393',
                emoji: '👨‍🎓',
                group: '2',
            },
            '1F469_200D_1F393': {
                id: '1F469_200D_1F393',
                emoji: '👩‍🎓',
                group: '2',
            },
            '1F9D1_200D_1F3EB': {
                id: '1F9D1_200D_1F3EB',
                emoji: '🧑‍🏫',
                group: '2',
            },
            '1F468_200D_1F3EB': {
                id: '1F468_200D_1F3EB',
                emoji: '👨‍🏫',
                group: '2',
            },
            '1F469_200D_1F3EB': {
                id: '1F469_200D_1F3EB',
                emoji: '👩‍🏫',
                group: '2',
            },
            '1F9D1_200D_2696_FE0F': {
                id: '1F9D1_200D_2696_FE0F',
                emoji: '🧑‍⚖️',
                group: '2',
            },
            '1F468_200D_2696_FE0F': {
                id: '1F468_200D_2696_FE0F',
                emoji: '👨‍⚖️',
                group: '2',
            },
            '1F469_200D_2696_FE0F': {
                id: '1F469_200D_2696_FE0F',
                emoji: '👩‍⚖️',
                group: '2',
            },
            '1F9D1_200D_1F33E': {
                id: '1F9D1_200D_1F33E',
                emoji: '🧑‍🌾',
                group: '2',
            },
            '1F468_200D_1F33E': {
                id: '1F468_200D_1F33E',
                emoji: '👨‍🌾',
                group: '2',
            },
            '1F469_200D_1F33E': {
                id: '1F469_200D_1F33E',
                emoji: '👩‍🌾',
                group: '2',
            },
            '1F9D1_200D_1F373': {
                id: '1F9D1_200D_1F373',
                emoji: '🧑‍🍳',
                group: '2',
            },
            '1F468_200D_1F373': {
                id: '1F468_200D_1F373',
                emoji: '👨‍🍳',
                group: '2',
            },
            '1F469_200D_1F373': {
                id: '1F469_200D_1F373',
                emoji: '👩‍🍳',
                group: '2',
            },
            '1F9D1_200D_1F527': {
                id: '1F9D1_200D_1F527',
                emoji: '🧑‍🔧',
                group: '2',
            },
            '1F468_200D_1F527': {
                id: '1F468_200D_1F527',
                emoji: '👨‍🔧',
                group: '2',
            },
            '1F469_200D_1F527': {
                id: '1F469_200D_1F527',
                emoji: '👩‍🔧',
                group: '2',
            },
            '1F9D1_200D_1F3ED': {
                id: '1F9D1_200D_1F3ED',
                emoji: '🧑‍🏭',
                group: '2',
            },
            '1F468_200D_1F3ED': {
                id: '1F468_200D_1F3ED',
                emoji: '👨‍🏭',
                group: '2',
            },
            '1F469_200D_1F3ED': {
                id: '1F469_200D_1F3ED',
                emoji: '👩‍🏭',
                group: '2',
            },
            '1F9D1_200D_1F4BC': {
                id: '1F9D1_200D_1F4BC',
                emoji: '🧑‍💼',
                group: '2',
            },
            '1F468_200D_1F4BC': {
                id: '1F468_200D_1F4BC',
                emoji: '👨‍💼',
                group: '2',
            },
            '1F469_200D_1F4BC': {
                id: '1F469_200D_1F4BC',
                emoji: '👩‍💼',
                group: '2',
            },
            '1F9D1_200D_1F52C': {
                id: '1F9D1_200D_1F52C',
                emoji: '🧑‍🔬',
                group: '2',
            },
            '1F468_200D_1F52C': {
                id: '1F468_200D_1F52C',
                emoji: '👨‍🔬',
                group: '2',
            },
            '1F469_200D_1F52C': {
                id: '1F469_200D_1F52C',
                emoji: '👩‍🔬',
                group: '2',
            },
            '1F9D1_200D_1F4BB': {
                id: '1F9D1_200D_1F4BB',
                emoji: '🧑‍💻',
                group: '2',
            },
            '1F468_200D_1F4BB': {
                id: '1F468_200D_1F4BB',
                emoji: '👨‍💻',
                group: '2',
            },
            '1F469_200D_1F4BB': {
                id: '1F469_200D_1F4BB',
                emoji: '👩‍💻',
                group: '2',
            },
            '1F9D1_200D_1F3A4': {
                id: '1F9D1_200D_1F3A4',
                emoji: '🧑‍🎤',
                group: '2',
            },
            '1F468_200D_1F3A4': {
                id: '1F468_200D_1F3A4',
                emoji: '👨‍🎤',
                group: '2',
            },
            '1F469_200D_1F3A4': {
                id: '1F469_200D_1F3A4',
                emoji: '👩‍🎤',
                group: '2',
            },
            '1F9D1_200D_1F3A8': {
                id: '1F9D1_200D_1F3A8',
                emoji: '🧑‍🎨',
                group: '2',
            },
            '1F468_200D_1F3A8': {
                id: '1F468_200D_1F3A8',
                emoji: '👨‍🎨',
                group: '2',
            },
            '1F469_200D_1F3A8': {
                id: '1F469_200D_1F3A8',
                emoji: '👩‍🎨',
                group: '2',
            },
            '1F9D1_200D_2708_FE0F': {
                id: '1F9D1_200D_2708_FE0F',
                emoji: '🧑‍✈️',
                group: '2',
            },
            '1F468_200D_2708_FE0F': {
                id: '1F468_200D_2708_FE0F',
                emoji: '👨‍✈️',
                group: '2',
            },
            '1F469_200D_2708_FE0F': {
                id: '1F469_200D_2708_FE0F',
                emoji: '👩‍✈️',
                group: '2',
            },
            '1F9D1_200D_1F680': {
                id: '1F9D1_200D_1F680',
                emoji: '🧑‍🚀',
                group: '2',
            },
            '1F468_200D_1F680': {
                id: '1F468_200D_1F680',
                emoji: '👨‍🚀',
                group: '2',
            },
            '1F469_200D_1F680': {
                id: '1F469_200D_1F680',
                emoji: '👩‍🚀',
                group: '2',
            },
            '1F9D1_200D_1F692': {
                id: '1F9D1_200D_1F692',
                emoji: '🧑‍🚒',
                group: '2',
            },
            '1F468_200D_1F692': {
                id: '1F468_200D_1F692',
                emoji: '👨‍🚒',
                group: '2',
            },
            '1F469_200D_1F692': {
                id: '1F469_200D_1F692',
                emoji: '👩‍🚒',
                group: '2',
            },
            '1F46E': {
                id: '1F46E',
                emoji: '👮',
                group: '2',
            },
            '1F46E_200D_2642_FE0F': {
                id: '1F46E_200D_2642_FE0F',
                emoji: '👮‍♂️',
                group: '2',
            },
            '1F46E_200D_2640_FE0F': {
                id: '1F46E_200D_2640_FE0F',
                emoji: '👮‍♀️',
                group: '2',
            },
            '1F575_FE0F': {
                id: '1F575_FE0F',
                emoji: '🕵️',
                group: '2',
            },
            '1F575_FE0F_200D_2642_FE0F': {
                id: '1F575_FE0F_200D_2642_FE0F',
                emoji: '🕵️‍♂️',
                group: '2',
            },
            '1F575_FE0F_200D_2640_FE0F': {
                id: '1F575_FE0F_200D_2640_FE0F',
                emoji: '🕵️‍♀️',
                group: '2',
            },
            '1F482': {
                id: '1F482',
                emoji: '💂',
                group: '2',
            },
            '1F482_200D_2642_FE0F': {
                id: '1F482_200D_2642_FE0F',
                emoji: '💂‍♂️',
                group: '2',
            },
            '1F482_200D_2640_FE0F': {
                id: '1F482_200D_2640_FE0F',
                emoji: '💂‍♀️',
                group: '2',
            },
            '1F977': {
                id: '1F977',
                emoji: '🥷',
                group: '2',
            },
            '1F477': {
                id: '1F477',
                emoji: '👷',
                group: '2',
            },
            '1F477_200D_2642_FE0F': {
                id: '1F477_200D_2642_FE0F',
                emoji: '👷‍♂️',
                group: '2',
            },
            '1F477_200D_2640_FE0F': {
                id: '1F477_200D_2640_FE0F',
                emoji: '👷‍♀️',
                group: '2',
            },
            '1FAC5': {
                id: '1FAC5',
                emoji: '🫅',
                group: '2',
            },
            '1F934': {
                id: '1F934',
                emoji: '🤴',
                group: '2',
            },
            '1F478': {
                id: '1F478',
                emoji: '👸',
                group: '2',
            },
            '1F473': {
                id: '1F473',
                emoji: '👳',
                group: '2',
            },
            '1F473_200D_2642_FE0F': {
                id: '1F473_200D_2642_FE0F',
                emoji: '👳‍♂️',
                group: '2',
            },
            '1F473_200D_2640_FE0F': {
                id: '1F473_200D_2640_FE0F',
                emoji: '👳‍♀️',
                group: '2',
            },
            '1F472': {
                id: '1F472',
                emoji: '👲',
                group: '2',
            },
            '1F9D5': {
                id: '1F9D5',
                emoji: '🧕',
                group: '2',
            },
            '1F935': {
                id: '1F935',
                emoji: '🤵',
                group: '2',
            },
            '1F935_200D_2642_FE0F': {
                id: '1F935_200D_2642_FE0F',
                emoji: '🤵‍♂️',
                group: '2',
            },
            '1F935_200D_2640_FE0F': {
                id: '1F935_200D_2640_FE0F',
                emoji: '🤵‍♀️',
                group: '2',
            },
            '1F470': {
                id: '1F470',
                emoji: '👰',
                group: '2',
            },
            '1F470_200D_2642_FE0F': {
                id: '1F470_200D_2642_FE0F',
                emoji: '👰‍♂️',
                group: '2',
            },
            '1F470_200D_2640_FE0F': {
                id: '1F470_200D_2640_FE0F',
                emoji: '👰‍♀️',
                group: '2',
            },
            '1F930': {
                id: '1F930',
                emoji: '🤰',
                group: '2',
            },
            '1FAC3': {
                id: '1FAC3',
                emoji: '🫃',
                group: '2',
            },
            '1FAC4': {
                id: '1FAC4',
                emoji: '🫄',
                group: '2',
            },
            '1F931': {
                id: '1F931',
                emoji: '🤱',
                group: '2',
            },
            '1F469_200D_1F37C': {
                id: '1F469_200D_1F37C',
                emoji: '👩‍🍼',
                group: '2',
            },
            '1F468_200D_1F37C': {
                id: '1F468_200D_1F37C',
                emoji: '👨‍🍼',
                group: '2',
            },
            '1F9D1_200D_1F37C': {
                id: '1F9D1_200D_1F37C',
                emoji: '🧑‍🍼',
                group: '2',
            },
            '1F47C': {
                id: '1F47C',
                emoji: '👼',
                group: '2',
            },
            '1F385': {
                id: '1F385',
                emoji: '🎅',
                group: '2',
            },
            '1F936': {
                id: '1F936',
                emoji: '🤶',
                group: '2',
            },
            '1F9D1_200D_1F384': {
                id: '1F9D1_200D_1F384',
                emoji: '🧑‍🎄',
                group: '2',
            },
            '1F9B8': {
                id: '1F9B8',
                emoji: '🦸',
                group: '2',
            },
            '1F9B8_200D_2642_FE0F': {
                id: '1F9B8_200D_2642_FE0F',
                emoji: '🦸‍♂️',
                group: '2',
            },
            '1F9B8_200D_2640_FE0F': {
                id: '1F9B8_200D_2640_FE0F',
                emoji: '🦸‍♀️',
                group: '2',
            },
            '1F9B9': {
                id: '1F9B9',
                emoji: '🦹',
                group: '2',
            },
            '1F9B9_200D_2642_FE0F': {
                id: '1F9B9_200D_2642_FE0F',
                emoji: '🦹‍♂️',
                group: '2',
            },
            '1F9B9_200D_2640_FE0F': {
                id: '1F9B9_200D_2640_FE0F',
                emoji: '🦹‍♀️',
                group: '2',
            },
            '1F9D9': {
                id: '1F9D9',
                emoji: '🧙',
                group: '2',
            },
            '1F9D9_200D_2642_FE0F': {
                id: '1F9D9_200D_2642_FE0F',
                emoji: '🧙‍♂️',
                group: '2',
            },
            '1F9D9_200D_2640_FE0F': {
                id: '1F9D9_200D_2640_FE0F',
                emoji: '🧙‍♀️',
                group: '2',
            },
            '1F9DA': {
                id: '1F9DA',
                emoji: '🧚',
                group: '2',
            },
            '1F9DA_200D_2642_FE0F': {
                id: '1F9DA_200D_2642_FE0F',
                emoji: '🧚‍♂️',
                group: '2',
            },
            '1F9DA_200D_2640_FE0F': {
                id: '1F9DA_200D_2640_FE0F',
                emoji: '🧚‍♀️',
                group: '2',
            },
            '1F9DB': {
                id: '1F9DB',
                emoji: '🧛',
                group: '2',
            },
            '1F9DB_200D_2642_FE0F': {
                id: '1F9DB_200D_2642_FE0F',
                emoji: '🧛‍♂️',
                group: '2',
            },
            '1F9DB_200D_2640_FE0F': {
                id: '1F9DB_200D_2640_FE0F',
                emoji: '🧛‍♀️',
                group: '2',
            },
            '1F9DC': {
                id: '1F9DC',
                emoji: '🧜',
                group: '2',
            },
            '1F9DC_200D_2642_FE0F': {
                id: '1F9DC_200D_2642_FE0F',
                emoji: '🧜‍♂️',
                group: '2',
            },
            '1F9DC_200D_2640_FE0F': {
                id: '1F9DC_200D_2640_FE0F',
                emoji: '🧜‍♀️',
                group: '2',
            },
            '1F9DD': {
                id: '1F9DD',
                emoji: '🧝',
                group: '2',
            },
            '1F9DD_200D_2642_FE0F': {
                id: '1F9DD_200D_2642_FE0F',
                emoji: '🧝‍♂️',
                group: '2',
            },
            '1F9DD_200D_2640_FE0F': {
                id: '1F9DD_200D_2640_FE0F',
                emoji: '🧝‍♀️',
                group: '2',
            },
            '1F9DE': {
                id: '1F9DE',
                emoji: '🧞',
                group: '2',
            },
            '1F9DE_200D_2642_FE0F': {
                id: '1F9DE_200D_2642_FE0F',
                emoji: '🧞‍♂️',
                group: '2',
            },
            '1F9DE_200D_2640_FE0F': {
                id: '1F9DE_200D_2640_FE0F',
                emoji: '🧞‍♀️',
                group: '2',
            },
            '1F9DF': {
                id: '1F9DF',
                emoji: '🧟',
                group: '2',
            },
            '1F9DF_200D_2642_FE0F': {
                id: '1F9DF_200D_2642_FE0F',
                emoji: '🧟‍♂️',
                group: '2',
            },
            '1F9DF_200D_2640_FE0F': {
                id: '1F9DF_200D_2640_FE0F',
                emoji: '🧟‍♀️',
                group: '2',
            },
            '1F9CC': {
                id: '1F9CC',
                emoji: '🧌',
                group: '2',
            },
            '1F486': {
                id: '1F486',
                emoji: '💆',
                group: '2',
            },
            '1F486_200D_2642_FE0F': {
                id: '1F486_200D_2642_FE0F',
                emoji: '💆‍♂️',
                group: '2',
            },
            '1F486_200D_2640_FE0F': {
                id: '1F486_200D_2640_FE0F',
                emoji: '💆‍♀️',
                group: '2',
            },
            '1F487': {
                id: '1F487',
                emoji: '💇',
                group: '2',
            },
            '1F487_200D_2642_FE0F': {
                id: '1F487_200D_2642_FE0F',
                emoji: '💇‍♂️',
                group: '2',
            },
            '1F487_200D_2640_FE0F': {
                id: '1F487_200D_2640_FE0F',
                emoji: '💇‍♀️',
                group: '2',
            },
            '1F6B6': {
                id: '1F6B6',
                emoji: '🚶',
                group: '2',
            },
            '1F6B6_200D_2642_FE0F': {
                id: '1F6B6_200D_2642_FE0F',
                emoji: '🚶‍♂️',
                group: '2',
            },
            '1F6B6_200D_2640_FE0F': {
                id: '1F6B6_200D_2640_FE0F',
                emoji: '🚶‍♀️',
                group: '2',
            },
            '1F9CD': {
                id: '1F9CD',
                emoji: '🧍',
                group: '2',
            },
            '1F9CD_200D_2642_FE0F': {
                id: '1F9CD_200D_2642_FE0F',
                emoji: '🧍‍♂️',
                group: '2',
            },
            '1F9CD_200D_2640_FE0F': {
                id: '1F9CD_200D_2640_FE0F',
                emoji: '🧍‍♀️',
                group: '2',
            },
            '1F9CE': {
                id: '1F9CE',
                emoji: '🧎',
                group: '2',
            },
            '1F9CE_200D_2642_FE0F': {
                id: '1F9CE_200D_2642_FE0F',
                emoji: '🧎‍♂️',
                group: '2',
            },
            '1F9CE_200D_2640_FE0F': {
                id: '1F9CE_200D_2640_FE0F',
                emoji: '🧎‍♀️',
                group: '2',
            },
            '1F9D1_200D_1F9AF': {
                id: '1F9D1_200D_1F9AF',
                emoji: '🧑‍🦯',
                group: '2',
            },
            '1F468_200D_1F9AF': {
                id: '1F468_200D_1F9AF',
                emoji: '👨‍🦯',
                group: '2',
            },
            '1F469_200D_1F9AF': {
                id: '1F469_200D_1F9AF',
                emoji: '👩‍🦯',
                group: '2',
            },
            '1F9D1_200D_1F9BC': {
                id: '1F9D1_200D_1F9BC',
                emoji: '🧑‍🦼',
                group: '2',
            },
            '1F468_200D_1F9BC': {
                id: '1F468_200D_1F9BC',
                emoji: '👨‍🦼',
                group: '2',
            },
            '1F469_200D_1F9BC': {
                id: '1F469_200D_1F9BC',
                emoji: '👩‍🦼',
                group: '2',
            },
            '1F9D1_200D_1F9BD': {
                id: '1F9D1_200D_1F9BD',
                emoji: '🧑‍🦽',
                group: '2',
            },
            '1F468_200D_1F9BD': {
                id: '1F468_200D_1F9BD',
                emoji: '👨‍🦽',
                group: '2',
            },
            '1F469_200D_1F9BD': {
                id: '1F469_200D_1F9BD',
                emoji: '👩‍🦽',
                group: '2',
            },
            '1F3C3': {
                id: '1F3C3',
                emoji: '🏃',
                group: '2',
            },
            '1F3C3_200D_2642_FE0F': {
                id: '1F3C3_200D_2642_FE0F',
                emoji: '🏃‍♂️',
                group: '2',
            },
            '1F3C3_200D_2640_FE0F': {
                id: '1F3C3_200D_2640_FE0F',
                emoji: '🏃‍♀️',
                group: '2',
            },
            '1F483': {
                id: '1F483',
                emoji: '💃',
                group: '2',
            },
            '1F57A': {
                id: '1F57A',
                emoji: '🕺',
                group: '2',
            },
            '1F574_FE0F': {
                id: '1F574_FE0F',
                emoji: '🕴️',
                group: '2',
            },
            '1F46F': {
                id: '1F46F',
                emoji: '👯',
                group: '2',
            },
            '1F46F_200D_2642_FE0F': {
                id: '1F46F_200D_2642_FE0F',
                emoji: '👯‍♂️',
                group: '2',
            },
            '1F46F_200D_2640_FE0F': {
                id: '1F46F_200D_2640_FE0F',
                emoji: '👯‍♀️',
                group: '2',
            },
            '1F9D6': {
                id: '1F9D6',
                emoji: '🧖',
                group: '2',
            },
            '1F9D6_200D_2642_FE0F': {
                id: '1F9D6_200D_2642_FE0F',
                emoji: '🧖‍♂️',
                group: '2',
            },
            '1F9D6_200D_2640_FE0F': {
                id: '1F9D6_200D_2640_FE0F',
                emoji: '🧖‍♀️',
                group: '2',
            },
            '1F9D7': {
                id: '1F9D7',
                emoji: '🧗',
                group: '2',
            },
            '1F9D7_200D_2642_FE0F': {
                id: '1F9D7_200D_2642_FE0F',
                emoji: '🧗‍♂️',
                group: '2',
            },
            '1F9D7_200D_2640_FE0F': {
                id: '1F9D7_200D_2640_FE0F',
                emoji: '🧗‍♀️',
                group: '2',
            },
            '1F93A': {
                id: '1F93A',
                emoji: '🤺',
                group: '2',
            },
            '1F3C7': {
                id: '1F3C7',
                emoji: '🏇',
                group: '2',
            },
            '26F7_FE0F': {
                id: '26F7_FE0F',
                emoji: '⛷️',
                group: '2',
            },
            '1F3C2': {
                id: '1F3C2',
                emoji: '🏂',
                group: '2',
            },
            '1F3CC_FE0F': {
                id: '1F3CC_FE0F',
                emoji: '🏌️',
                group: '2',
            },
            '1F3CC_FE0F_200D_2642_FE0F': {
                id: '1F3CC_FE0F_200D_2642_FE0F',
                emoji: '🏌️‍♂️',
                group: '2',
            },
            '1F3CC_FE0F_200D_2640_FE0F': {
                id: '1F3CC_FE0F_200D_2640_FE0F',
                emoji: '🏌️‍♀️',
                group: '2',
            },
            '1F3C4': {
                id: '1F3C4',
                emoji: '🏄',
                group: '2',
            },
            '1F3C4_200D_2642_FE0F': {
                id: '1F3C4_200D_2642_FE0F',
                emoji: '🏄‍♂️',
                group: '2',
            },
            '1F3C4_200D_2640_FE0F': {
                id: '1F3C4_200D_2640_FE0F',
                emoji: '🏄‍♀️',
                group: '2',
            },
            '1F6A3': {
                id: '1F6A3',
                emoji: '🚣',
                group: '2',
            },
            '1F6A3_200D_2642_FE0F': {
                id: '1F6A3_200D_2642_FE0F',
                emoji: '🚣‍♂️',
                group: '2',
            },
            '1F6A3_200D_2640_FE0F': {
                id: '1F6A3_200D_2640_FE0F',
                emoji: '🚣‍♀️',
                group: '2',
            },
            '1F3CA': {
                id: '1F3CA',
                emoji: '🏊',
                group: '2',
            },
            '1F3CA_200D_2642_FE0F': {
                id: '1F3CA_200D_2642_FE0F',
                emoji: '🏊‍♂️',
                group: '2',
            },
            '1F3CA_200D_2640_FE0F': {
                id: '1F3CA_200D_2640_FE0F',
                emoji: '🏊‍♀️',
                group: '2',
            },
            '26F9_FE0F': {
                id: '26F9_FE0F',
                emoji: '⛹️',
                group: '2',
            },
            '26F9_FE0F_200D_2642_FE0F': {
                id: '26F9_FE0F_200D_2642_FE0F',
                emoji: '⛹️‍♂️',
                group: '2',
            },
            '26F9_FE0F_200D_2640_FE0F': {
                id: '26F9_FE0F_200D_2640_FE0F',
                emoji: '⛹️‍♀️',
                group: '2',
            },
            '1F3CB_FE0F': {
                id: '1F3CB_FE0F',
                emoji: '🏋️',
                group: '2',
            },
            '1F3CB_FE0F_200D_2642_FE0F': {
                id: '1F3CB_FE0F_200D_2642_FE0F',
                emoji: '🏋️‍♂️',
                group: '2',
            },
            '1F3CB_FE0F_200D_2640_FE0F': {
                id: '1F3CB_FE0F_200D_2640_FE0F',
                emoji: '🏋️‍♀️',
                group: '2',
            },
            '1F6B4': {
                id: '1F6B4',
                emoji: '🚴',
                group: '2',
            },
            '1F6B4_200D_2642_FE0F': {
                id: '1F6B4_200D_2642_FE0F',
                emoji: '🚴‍♂️',
                group: '2',
            },
            '1F6B4_200D_2640_FE0F': {
                id: '1F6B4_200D_2640_FE0F',
                emoji: '🚴‍♀️',
                group: '2',
            },
            '1F6B5': {
                id: '1F6B5',
                emoji: '🚵',
                group: '2',
            },
            '1F6B5_200D_2642_FE0F': {
                id: '1F6B5_200D_2642_FE0F',
                emoji: '🚵‍♂️',
                group: '2',
            },
            '1F6B5_200D_2640_FE0F': {
                id: '1F6B5_200D_2640_FE0F',
                emoji: '🚵‍♀️',
                group: '2',
            },
            '1F938': {
                id: '1F938',
                emoji: '🤸',
                group: '2',
            },
            '1F938_200D_2642_FE0F': {
                id: '1F938_200D_2642_FE0F',
                emoji: '🤸‍♂️',
                group: '2',
            },
            '1F938_200D_2640_FE0F': {
                id: '1F938_200D_2640_FE0F',
                emoji: '🤸‍♀️',
                group: '2',
            },
            '1F93C': {
                id: '1F93C',
                emoji: '🤼',
                group: '2',
            },
            '1F93C_200D_2642_FE0F': {
                id: '1F93C_200D_2642_FE0F',
                emoji: '🤼‍♂️',
                group: '2',
            },
            '1F93C_200D_2640_FE0F': {
                id: '1F93C_200D_2640_FE0F',
                emoji: '🤼‍♀️',
                group: '2',
            },
            '1F93D': {
                id: '1F93D',
                emoji: '🤽',
                group: '2',
            },
            '1F93D_200D_2642_FE0F': {
                id: '1F93D_200D_2642_FE0F',
                emoji: '🤽‍♂️',
                group: '2',
            },
            '1F93D_200D_2640_FE0F': {
                id: '1F93D_200D_2640_FE0F',
                emoji: '🤽‍♀️',
                group: '2',
            },
            '1F93E': {
                id: '1F93E',
                emoji: '🤾',
                group: '2',
            },
            '1F93E_200D_2642_FE0F': {
                id: '1F93E_200D_2642_FE0F',
                emoji: '🤾‍♂️',
                group: '2',
            },
            '1F93E_200D_2640_FE0F': {
                id: '1F93E_200D_2640_FE0F',
                emoji: '🤾‍♀️',
                group: '2',
            },
            '1F939': {
                id: '1F939',
                emoji: '🤹',
                group: '2',
            },
            '1F939_200D_2642_FE0F': {
                id: '1F939_200D_2642_FE0F',
                emoji: '🤹‍♂️',
                group: '2',
            },
            '1F939_200D_2640_FE0F': {
                id: '1F939_200D_2640_FE0F',
                emoji: '🤹‍♀️',
                group: '2',
            },
            '1F9D8': {
                id: '1F9D8',
                emoji: '🧘',
                group: '2',
            },
            '1F9D8_200D_2642_FE0F': {
                id: '1F9D8_200D_2642_FE0F',
                emoji: '🧘‍♂️',
                group: '2',
            },
            '1F9D8_200D_2640_FE0F': {
                id: '1F9D8_200D_2640_FE0F',
                emoji: '🧘‍♀️',
                group: '2',
            },
            '1F6C0': {
                id: '1F6C0',
                emoji: '🛀',
                group: '2',
            },
            '1F6CC': {
                id: '1F6CC',
                emoji: '🛌',
                group: '2',
            },
            '1F9D1_200D_1F91D_200D_1F9D1': {
                id: '1F9D1_200D_1F91D_200D_1F9D1',
                emoji: '🧑‍🤝‍🧑',
                group: '2',
            },
            '1F46D': {
                id: '1F46D',
                emoji: '👭',
                group: '2',
            },
            '1F46B': {
                id: '1F46B',
                emoji: '👫',
                group: '2',
            },
            '1F46C': {
                id: '1F46C',
                emoji: '👬',
                group: '2',
            },
            '1F48F': {
                id: '1F48F',
                emoji: '💏',
                group: '2',
            },
            '1F469_200D_2764_FE0F_200D_1F48B_200D_1F468': {
                id: '1F469_200D_2764_FE0F_200D_1F48B_200D_1F468',
                emoji: '👩‍❤️‍💋‍👨',
                group: '2',
            },
            '1F468_200D_2764_FE0F_200D_1F48B_200D_1F468': {
                id: '1F468_200D_2764_FE0F_200D_1F48B_200D_1F468',
                emoji: '👨‍❤️‍💋‍👨',
                group: '2',
            },
            '1F469_200D_2764_FE0F_200D_1F48B_200D_1F469': {
                id: '1F469_200D_2764_FE0F_200D_1F48B_200D_1F469',
                emoji: '👩‍❤️‍💋‍👩',
                group: '2',
            },
            '1F491': {
                id: '1F491',
                emoji: '💑',
                group: '2',
            },
            '1F469_200D_2764_FE0F_200D_1F468': {
                id: '1F469_200D_2764_FE0F_200D_1F468',
                emoji: '👩‍❤️‍👨',
                group: '2',
            },
            '1F468_200D_2764_FE0F_200D_1F468': {
                id: '1F468_200D_2764_FE0F_200D_1F468',
                emoji: '👨‍❤️‍👨',
                group: '2',
            },
            '1F469_200D_2764_FE0F_200D_1F469': {
                id: '1F469_200D_2764_FE0F_200D_1F469',
                emoji: '👩‍❤️‍👩',
                group: '2',
            },
            '1F468_200D_1F469_200D_1F466': {
                id: '1F468_200D_1F469_200D_1F466',
                emoji: '👨‍👩‍👦',
                group: '2',
            },
            '1F468_200D_1F469_200D_1F467': {
                id: '1F468_200D_1F469_200D_1F467',
                emoji: '👨‍👩‍👧',
                group: '2',
            },
            '1F468_200D_1F469_200D_1F467_200D_1F466': {
                id: '1F468_200D_1F469_200D_1F467_200D_1F466',
                emoji: '👨‍👩‍👧‍👦',
                group: '2',
            },
            '1F468_200D_1F469_200D_1F466_200D_1F466': {
                id: '1F468_200D_1F469_200D_1F466_200D_1F466',
                emoji: '👨‍👩‍👦‍👦',
                group: '2',
            },
            '1F468_200D_1F469_200D_1F467_200D_1F467': {
                id: '1F468_200D_1F469_200D_1F467_200D_1F467',
                emoji: '👨‍👩‍👧‍👧',
                group: '2',
            },
            '1F468_200D_1F468_200D_1F466': {
                id: '1F468_200D_1F468_200D_1F466',
                emoji: '👨‍👨‍👦',
                group: '2',
            },
            '1F468_200D_1F468_200D_1F467': {
                id: '1F468_200D_1F468_200D_1F467',
                emoji: '👨‍👨‍👧',
                group: '2',
            },
            '1F468_200D_1F468_200D_1F467_200D_1F466': {
                id: '1F468_200D_1F468_200D_1F467_200D_1F466',
                emoji: '👨‍👨‍👧‍👦',
                group: '2',
            },
            '1F468_200D_1F468_200D_1F466_200D_1F466': {
                id: '1F468_200D_1F468_200D_1F466_200D_1F466',
                emoji: '👨‍👨‍👦‍👦',
                group: '2',
            },
            '1F468_200D_1F468_200D_1F467_200D_1F467': {
                id: '1F468_200D_1F468_200D_1F467_200D_1F467',
                emoji: '👨‍👨‍👧‍👧',
                group: '2',
            },
            '1F469_200D_1F469_200D_1F466': {
                id: '1F469_200D_1F469_200D_1F466',
                emoji: '👩‍👩‍👦',
                group: '2',
            },
            '1F469_200D_1F469_200D_1F467': {
                id: '1F469_200D_1F469_200D_1F467',
                emoji: '👩‍👩‍👧',
                group: '2',
            },
            '1F469_200D_1F469_200D_1F467_200D_1F466': {
                id: '1F469_200D_1F469_200D_1F467_200D_1F466',
                emoji: '👩‍👩‍👧‍👦',
                group: '2',
            },
            '1F469_200D_1F469_200D_1F466_200D_1F466': {
                id: '1F469_200D_1F469_200D_1F466_200D_1F466',
                emoji: '👩‍👩‍👦‍👦',
                group: '2',
            },
            '1F469_200D_1F469_200D_1F467_200D_1F467': {
                id: '1F469_200D_1F469_200D_1F467_200D_1F467',
                emoji: '👩‍👩‍👧‍👧',
                group: '2',
            },
            '1F468_200D_1F466': {
                id: '1F468_200D_1F466',
                emoji: '👨‍👦',
                group: '2',
            },
            '1F468_200D_1F466_200D_1F466': {
                id: '1F468_200D_1F466_200D_1F466',
                emoji: '👨‍👦‍👦',
                group: '2',
            },
            '1F468_200D_1F467': {
                id: '1F468_200D_1F467',
                emoji: '👨‍👧',
                group: '2',
            },
            '1F468_200D_1F467_200D_1F466': {
                id: '1F468_200D_1F467_200D_1F466',
                emoji: '👨‍👧‍👦',
                group: '2',
            },
            '1F468_200D_1F467_200D_1F467': {
                id: '1F468_200D_1F467_200D_1F467',
                emoji: '👨‍👧‍👧',
                group: '2',
            },
            '1F469_200D_1F466': {
                id: '1F469_200D_1F466',
                emoji: '👩‍👦',
                group: '2',
            },
            '1F469_200D_1F466_200D_1F466': {
                id: '1F469_200D_1F466_200D_1F466',
                emoji: '👩‍👦‍👦',
                group: '2',
            },
            '1F469_200D_1F467': {
                id: '1F469_200D_1F467',
                emoji: '👩‍👧',
                group: '2',
            },
            '1F469_200D_1F467_200D_1F466': {
                id: '1F469_200D_1F467_200D_1F466',
                emoji: '👩‍👧‍👦',
                group: '2',
            },
            '1F469_200D_1F467_200D_1F467': {
                id: '1F469_200D_1F467_200D_1F467',
                emoji: '👩‍👧‍👧',
                group: '2',
            },
            '1F5E3_FE0F': {
                id: '1F5E3_FE0F',
                emoji: '🗣️',
                group: '2',
            },
            '1F464': {
                id: '1F464',
                emoji: '👤',
                group: '2',
            },
            '1F465': {
                id: '1F465',
                emoji: '👥',
                group: '2',
            },
            '1FAC2': {
                id: '1FAC2',
                emoji: '🫂',
                group: '2',
            },
            '1F46A': {
                id: '1F46A',
                emoji: '👪',
                group: '2',
            },
            '1F9D1_200D_1F9D1_200D_1F9D2': {
                id: '1F9D1_200D_1F9D1_200D_1F9D2',
                emoji: '🧑‍🧑‍🧒',
                group: '2',
            },
            '1F9D1_200D_1F9D1_200D_1F9D2_200D_1F9D2': {
                id: '1F9D1_200D_1F9D1_200D_1F9D2_200D_1F9D2',
                emoji: '🧑‍🧑‍🧒‍🧒',
                group: '2',
            },
            '1F9D1_200D_1F9D2': {
                id: '1F9D1_200D_1F9D2',
                emoji: '🧑‍🧒',
                group: '2',
            },
            '1F9D1_200D_1F9D2_200D_1F9D2': {
                id: '1F9D1_200D_1F9D2_200D_1F9D2',
                emoji: '🧑‍🧒‍🧒',
                group: '2',
            },
            '1F463': {
                id: '1F463',
                emoji: '👣',
                group: '2',
            },
            '1F435': {
                id: '1F435',
                emoji: '🐵',
                group: '4',
            },
            '1F412': {
                id: '1F412',
                emoji: '🐒',
                group: '4',
            },
            '1F98D': {
                id: '1F98D',
                emoji: '🦍',
                group: '4',
            },
            '1F9A7': {
                id: '1F9A7',
                emoji: '🦧',
                group: '4',
            },
            '1F436': {
                id: '1F436',
                emoji: '🐶',
                group: '4',
            },
            '1F415': {
                id: '1F415',
                emoji: '🐕',
                group: '4',
            },
            '1F9AE': {
                id: '1F9AE',
                emoji: '🦮',
                group: '4',
            },
            '1F415_200D_1F9BA': {
                id: '1F415_200D_1F9BA',
                emoji: '🐕‍🦺',
                group: '4',
            },
            '1F429': {
                id: '1F429',
                emoji: '🐩',
                group: '4',
            },
            '1F43A': {
                id: '1F43A',
                emoji: '🐺',
                group: '4',
            },
            '1F98A': {
                id: '1F98A',
                emoji: '🦊',
                group: '4',
            },
            '1F99D': {
                id: '1F99D',
                emoji: '🦝',
                group: '4',
            },
            '1F431': {
                id: '1F431',
                emoji: '🐱',
                group: '4',
            },
            '1F408': {
                id: '1F408',
                emoji: '🐈',
                group: '4',
            },
            '1F408_200D_2B1B': {
                id: '1F408_200D_2B1B',
                emoji: '🐈‍⬛',
                group: '4',
            },
            '1F981': {
                id: '1F981',
                emoji: '🦁',
                group: '4',
            },
            '1F42F': {
                id: '1F42F',
                emoji: '🐯',
                group: '4',
            },
            '1F405': {
                id: '1F405',
                emoji: '🐅',
                group: '4',
            },
            '1F406': {
                id: '1F406',
                emoji: '🐆',
                group: '4',
            },
            '1F434': {
                id: '1F434',
                emoji: '🐴',
                group: '4',
            },
            '1FACE': {
                id: '1FACE',
                emoji: '🫎',
                group: '4',
            },
            '1FACF': {
                id: '1FACF',
                emoji: '🫏',
                group: '4',
            },
            '1F40E': {
                id: '1F40E',
                emoji: '🐎',
                group: '4',
            },
            '1F984': {
                id: '1F984',
                emoji: '🦄',
                group: '4',
            },
            '1F993': {
                id: '1F993',
                emoji: '🦓',
                group: '4',
            },
            '1F98C': {
                id: '1F98C',
                emoji: '🦌',
                group: '4',
            },
            '1F9AC': {
                id: '1F9AC',
                emoji: '🦬',
                group: '4',
            },
            '1F42E': {
                id: '1F42E',
                emoji: '🐮',
                group: '4',
            },
            '1F402': {
                id: '1F402',
                emoji: '🐂',
                group: '4',
            },
            '1F403': {
                id: '1F403',
                emoji: '🐃',
                group: '4',
            },
            '1F404': {
                id: '1F404',
                emoji: '🐄',
                group: '4',
            },
            '1F437': {
                id: '1F437',
                emoji: '🐷',
                group: '4',
            },
            '1F416': {
                id: '1F416',
                emoji: '🐖',
                group: '4',
            },
            '1F417': {
                id: '1F417',
                emoji: '🐗',
                group: '4',
            },
            '1F43D': {
                id: '1F43D',
                emoji: '🐽',
                group: '4',
            },
            '1F40F': {
                id: '1F40F',
                emoji: '🐏',
                group: '4',
            },
            '1F411': {
                id: '1F411',
                emoji: '🐑',
                group: '4',
            },
            '1F410': {
                id: '1F410',
                emoji: '🐐',
                group: '4',
            },
            '1F42A': {
                id: '1F42A',
                emoji: '🐪',
                group: '4',
            },
            '1F42B': {
                id: '1F42B',
                emoji: '🐫',
                group: '4',
            },
            '1F999': {
                id: '1F999',
                emoji: '🦙',
                group: '4',
            },
            '1F992': {
                id: '1F992',
                emoji: '🦒',
                group: '4',
            },
            '1F418': {
                id: '1F418',
                emoji: '🐘',
                group: '4',
            },
            '1F9A3': {
                id: '1F9A3',
                emoji: '🦣',
                group: '4',
            },
            '1F98F': {
                id: '1F98F',
                emoji: '🦏',
                group: '4',
            },
            '1F99B': {
                id: '1F99B',
                emoji: '🦛',
                group: '4',
            },
            '1F42D': {
                id: '1F42D',
                emoji: '🐭',
                group: '4',
            },
            '1F401': {
                id: '1F401',
                emoji: '🐁',
                group: '4',
            },
            '1F400': {
                id: '1F400',
                emoji: '🐀',
                group: '4',
            },
            '1F439': {
                id: '1F439',
                emoji: '🐹',
                group: '4',
            },
            '1F430': {
                id: '1F430',
                emoji: '🐰',
                group: '4',
            },
            '1F407': {
                id: '1F407',
                emoji: '🐇',
                group: '4',
            },
            '1F43F_FE0F': {
                id: '1F43F_FE0F',
                emoji: '🐿️',
                group: '4',
            },
            '1F9AB': {
                id: '1F9AB',
                emoji: '🦫',
                group: '4',
            },
            '1F994': {
                id: '1F994',
                emoji: '🦔',
                group: '4',
            },
            '1F987': {
                id: '1F987',
                emoji: '🦇',
                group: '4',
            },
            '1F43B': {
                id: '1F43B',
                emoji: '🐻',
                group: '4',
            },
            '1F43B_200D_2744_FE0F': {
                id: '1F43B_200D_2744_FE0F',
                emoji: '🐻‍❄️',
                group: '4',
            },
            '1F428': {
                id: '1F428',
                emoji: '🐨',
                group: '4',
            },
            '1F43C': {
                id: '1F43C',
                emoji: '🐼',
                group: '4',
            },
            '1F9A5': {
                id: '1F9A5',
                emoji: '🦥',
                group: '4',
            },
            '1F9A6': {
                id: '1F9A6',
                emoji: '🦦',
                group: '4',
            },
            '1F9A8': {
                id: '1F9A8',
                emoji: '🦨',
                group: '4',
            },
            '1F998': {
                id: '1F998',
                emoji: '🦘',
                group: '4',
            },
            '1F9A1': {
                id: '1F9A1',
                emoji: '🦡',
                group: '4',
            },
            '1F43E': {
                id: '1F43E',
                emoji: '🐾',
                group: '4',
            },
            '1F983': {
                id: '1F983',
                emoji: '🦃',
                group: '4',
            },
            '1F414': {
                id: '1F414',
                emoji: '🐔',
                group: '4',
            },
            '1F413': {
                id: '1F413',
                emoji: '🐓',
                group: '4',
            },
            '1F423': {
                id: '1F423',
                emoji: '🐣',
                group: '4',
            },
            '1F424': {
                id: '1F424',
                emoji: '🐤',
                group: '4',
            },
            '1F425': {
                id: '1F425',
                emoji: '🐥',
                group: '4',
            },
            '1F426': {
                id: '1F426',
                emoji: '🐦',
                group: '4',
            },
            '1F427': {
                id: '1F427',
                emoji: '🐧',
                group: '4',
            },
            '1F54A_FE0F': {
                id: '1F54A_FE0F',
                emoji: '🕊️',
                group: '4',
            },
            '1F985': {
                id: '1F985',
                emoji: '🦅',
                group: '4',
            },
            '1F986': {
                id: '1F986',
                emoji: '🦆',
                group: '4',
            },
            '1F9A2': {
                id: '1F9A2',
                emoji: '🦢',
                group: '4',
            },
            '1F989': {
                id: '1F989',
                emoji: '🦉',
                group: '4',
            },
            '1F9A4': {
                id: '1F9A4',
                emoji: '🦤',
                group: '4',
            },
            '1FAB6': {
                id: '1FAB6',
                emoji: '🪶',
                group: '4',
            },
            '1F9A9': {
                id: '1F9A9',
                emoji: '🦩',
                group: '4',
            },
            '1F99A': {
                id: '1F99A',
                emoji: '🦚',
                group: '4',
            },
            '1F99C': {
                id: '1F99C',
                emoji: '🦜',
                group: '4',
            },
            '1FABD': {
                id: '1FABD',
                emoji: '🪽',
                group: '4',
            },
            '1F426_200D_2B1B': {
                id: '1F426_200D_2B1B',
                emoji: '🐦‍⬛',
                group: '4',
            },
            '1FABF': {
                id: '1FABF',
                emoji: '🪿',
                group: '4',
            },
            '1F426_200D_1F525': {
                id: '1F426_200D_1F525',
                emoji: '🐦‍🔥',
                group: '4',
            },
            '1F438': {
                id: '1F438',
                emoji: '🐸',
                group: '4',
            },
            '1F40A': {
                id: '1F40A',
                emoji: '🐊',
                group: '4',
            },
            '1F422': {
                id: '1F422',
                emoji: '🐢',
                group: '4',
            },
            '1F98E': {
                id: '1F98E',
                emoji: '🦎',
                group: '4',
            },
            '1F40D': {
                id: '1F40D',
                emoji: '🐍',
                group: '4',
            },
            '1F432': {
                id: '1F432',
                emoji: '🐲',
                group: '4',
            },
            '1F409': {
                id: '1F409',
                emoji: '🐉',
                group: '4',
            },
            '1F995': {
                id: '1F995',
                emoji: '🦕',
                group: '4',
            },
            '1F996': {
                id: '1F996',
                emoji: '🦖',
                group: '4',
            },
            '1F433': {
                id: '1F433',
                emoji: '🐳',
                group: '4',
            },
            '1F40B': {
                id: '1F40B',
                emoji: '🐋',
                group: '4',
            },
            '1F42C': {
                id: '1F42C',
                emoji: '🐬',
                group: '4',
            },
            '1F9AD': {
                id: '1F9AD',
                emoji: '🦭',
                group: '4',
            },
            '1F41F': {
                id: '1F41F',
                emoji: '🐟',
                group: '4',
            },
            '1F420': {
                id: '1F420',
                emoji: '🐠',
                group: '4',
            },
            '1F421': {
                id: '1F421',
                emoji: '🐡',
                group: '4',
            },
            '1F988': {
                id: '1F988',
                emoji: '🦈',
                group: '4',
            },
            '1F419': {
                id: '1F419',
                emoji: '🐙',
                group: '4',
            },
            '1F41A': {
                id: '1F41A',
                emoji: '🐚',
                group: '4',
            },
            '1FAB8': {
                id: '1FAB8',
                emoji: '🪸',
                group: '4',
            },
            '1FABC': {
                id: '1FABC',
                emoji: '🪼',
                group: '4',
            },
            '1F40C': {
                id: '1F40C',
                emoji: '🐌',
                group: '4',
            },
            '1F98B': {
                id: '1F98B',
                emoji: '🦋',
                group: '4',
            },
            '1F41B': {
                id: '1F41B',
                emoji: '🐛',
                group: '4',
            },
            '1F41C': {
                id: '1F41C',
                emoji: '🐜',
                group: '4',
            },
            '1F41D': {
                id: '1F41D',
                emoji: '🐝',
                group: '4',
            },
            '1FAB2': {
                id: '1FAB2',
                emoji: '🪲',
                group: '4',
            },
            '1F41E': {
                id: '1F41E',
                emoji: '🐞',
                group: '4',
            },
            '1F997': {
                id: '1F997',
                emoji: '🦗',
                group: '4',
            },
            '1FAB3': {
                id: '1FAB3',
                emoji: '🪳',
                group: '4',
            },
            '1F577_FE0F': {
                id: '1F577_FE0F',
                emoji: '🕷️',
                group: '4',
            },
            '1F578_FE0F': {
                id: '1F578_FE0F',
                emoji: '🕸️',
                group: '4',
            },
            '1F982': {
                id: '1F982',
                emoji: '🦂',
                group: '4',
            },
            '1F99F': {
                id: '1F99F',
                emoji: '🦟',
                group: '4',
            },
            '1FAB0': {
                id: '1FAB0',
                emoji: '🪰',
                group: '4',
            },
            '1FAB1': {
                id: '1FAB1',
                emoji: '🪱',
                group: '4',
            },
            '1F9A0': {
                id: '1F9A0',
                emoji: '🦠',
                group: '4',
            },
            '1F490': {
                id: '1F490',
                emoji: '💐',
                group: '4',
            },
            '1F338': {
                id: '1F338',
                emoji: '🌸',
                group: '4',
            },
            '1F4AE': {
                id: '1F4AE',
                emoji: '💮',
                group: '4',
            },
            '1FAB7': {
                id: '1FAB7',
                emoji: '🪷',
                group: '4',
            },
            '1F3F5_FE0F': {
                id: '1F3F5_FE0F',
                emoji: '🏵️',
                group: '4',
            },
            '1F339': {
                id: '1F339',
                emoji: '🌹',
                group: '4',
            },
            '1F940': {
                id: '1F940',
                emoji: '🥀',
                group: '4',
            },
            '1F33A': {
                id: '1F33A',
                emoji: '🌺',
                group: '4',
            },
            '1F33B': {
                id: '1F33B',
                emoji: '🌻',
                group: '4',
            },
            '1F33C': {
                id: '1F33C',
                emoji: '🌼',
                group: '4',
            },
            '1F337': {
                id: '1F337',
                emoji: '🌷',
                group: '4',
            },
            '1FABB': {
                id: '1FABB',
                emoji: '🪻',
                group: '4',
            },
            '1F331': {
                id: '1F331',
                emoji: '🌱',
                group: '4',
            },
            '1FAB4': {
                id: '1FAB4',
                emoji: '🪴',
                group: '4',
            },
            '1F332': {
                id: '1F332',
                emoji: '🌲',
                group: '4',
            },
            '1F333': {
                id: '1F333',
                emoji: '🌳',
                group: '4',
            },
            '1F334': {
                id: '1F334',
                emoji: '🌴',
                group: '4',
            },
            '1F335': {
                id: '1F335',
                emoji: '🌵',
                group: '4',
            },
            '1F33E': {
                id: '1F33E',
                emoji: '🌾',
                group: '4',
            },
            '1F33F': {
                id: '1F33F',
                emoji: '🌿',
                group: '4',
            },
            '2618_FE0F': {
                id: '2618_FE0F',
                emoji: '☘️',
                group: '4',
            },
            '1F340': {
                id: '1F340',
                emoji: '🍀',
                group: '4',
            },
            '1F341': {
                id: '1F341',
                emoji: '🍁',
                group: '4',
            },
            '1F342': {
                id: '1F342',
                emoji: '🍂',
                group: '4',
            },
            '1F343': {
                id: '1F343',
                emoji: '🍃',
                group: '4',
            },
            '1FAB9': {
                id: '1FAB9',
                emoji: '🪹',
                group: '4',
            },
            '1FABA': {
                id: '1FABA',
                emoji: '🪺',
                group: '4',
            },
            '1F344': {
                id: '1F344',
                emoji: '🍄',
                group: '4',
            },
            '1F347': {
                id: '1F347',
                emoji: '🍇',
                group: '5',
            },
            '1F348': {
                id: '1F348',
                emoji: '🍈',
                group: '5',
            },
            '1F349': {
                id: '1F349',
                emoji: '🍉',
                group: '5',
            },
            '1F34A': {
                id: '1F34A',
                emoji: '🍊',
                group: '5',
            },
            '1F34B': {
                id: '1F34B',
                emoji: '🍋',
                group: '5',
            },
            '1F34B_200D_1F7E9': {
                id: '1F34B_200D_1F7E9',
                emoji: '🍋‍🟩',
                group: '5',
            },
            '1F34C': {
                id: '1F34C',
                emoji: '🍌',
                group: '5',
            },
            '1F34D': {
                id: '1F34D',
                emoji: '🍍',
                group: '5',
            },
            '1F96D': {
                id: '1F96D',
                emoji: '🥭',
                group: '5',
            },
            '1F34E': {
                id: '1F34E',
                emoji: '🍎',
                group: '5',
            },
            '1F34F': {
                id: '1F34F',
                emoji: '🍏',
                group: '5',
            },
            '1F350': {
                id: '1F350',
                emoji: '🍐',
                group: '5',
            },
            '1F351': {
                id: '1F351',
                emoji: '🍑',
                group: '5',
            },
            '1F352': {
                id: '1F352',
                emoji: '🍒',
                group: '5',
            },
            '1F353': {
                id: '1F353',
                emoji: '🍓',
                group: '5',
            },
            '1FAD0': {
                id: '1FAD0',
                emoji: '🫐',
                group: '5',
            },
            '1F95D': {
                id: '1F95D',
                emoji: '🥝',
                group: '5',
            },
            '1F345': {
                id: '1F345',
                emoji: '🍅',
                group: '5',
            },
            '1FAD2': {
                id: '1FAD2',
                emoji: '🫒',
                group: '5',
            },
            '1F965': {
                id: '1F965',
                emoji: '🥥',
                group: '5',
            },
            '1F951': {
                id: '1F951',
                emoji: '🥑',
                group: '5',
            },
            '1F346': {
                id: '1F346',
                emoji: '🍆',
                group: '5',
            },
            '1F954': {
                id: '1F954',
                emoji: '🥔',
                group: '5',
            },
            '1F955': {
                id: '1F955',
                emoji: '🥕',
                group: '5',
            },
            '1F33D': {
                id: '1F33D',
                emoji: '🌽',
                group: '5',
            },
            '1F336_FE0F': {
                id: '1F336_FE0F',
                emoji: '🌶️',
                group: '5',
            },
            '1FAD1': {
                id: '1FAD1',
                emoji: '🫑',
                group: '5',
            },
            '1F952': {
                id: '1F952',
                emoji: '🥒',
                group: '5',
            },
            '1F96C': {
                id: '1F96C',
                emoji: '🥬',
                group: '5',
            },
            '1F966': {
                id: '1F966',
                emoji: '🥦',
                group: '5',
            },
            '1F9C4': {
                id: '1F9C4',
                emoji: '🧄',
                group: '5',
            },
            '1F9C5': {
                id: '1F9C5',
                emoji: '🧅',
                group: '5',
            },
            '1F95C': {
                id: '1F95C',
                emoji: '🥜',
                group: '5',
            },
            '1FAD8': {
                id: '1FAD8',
                emoji: '🫘',
                group: '5',
            },
            '1F330': {
                id: '1F330',
                emoji: '🌰',
                group: '5',
            },
            '1FADA': {
                id: '1FADA',
                emoji: '🫚',
                group: '5',
            },
            '1FADB': {
                id: '1FADB',
                emoji: '🫛',
                group: '5',
            },
            '1F344_200D_1F7EB': {
                id: '1F344_200D_1F7EB',
                emoji: '🍄‍🟫',
                group: '5',
            },
            '1F35E': {
                id: '1F35E',
                emoji: '🍞',
                group: '5',
            },
            '1F950': {
                id: '1F950',
                emoji: '🥐',
                group: '5',
            },
            '1F956': {
                id: '1F956',
                emoji: '🥖',
                group: '5',
            },
            '1FAD3': {
                id: '1FAD3',
                emoji: '🫓',
                group: '5',
            },
            '1F968': {
                id: '1F968',
                emoji: '🥨',
                group: '5',
            },
            '1F96F': {
                id: '1F96F',
                emoji: '🥯',
                group: '5',
            },
            '1F95E': {
                id: '1F95E',
                emoji: '🥞',
                group: '5',
            },
            '1F9C7': {
                id: '1F9C7',
                emoji: '🧇',
                group: '5',
            },
            '1F9C0': {
                id: '1F9C0',
                emoji: '🧀',
                group: '5',
            },
            '1F356': {
                id: '1F356',
                emoji: '🍖',
                group: '5',
            },
            '1F357': {
                id: '1F357',
                emoji: '🍗',
                group: '5',
            },
            '1F969': {
                id: '1F969',
                emoji: '🥩',
                group: '5',
            },
            '1F953': {
                id: '1F953',
                emoji: '🥓',
                group: '5',
            },
            '1F354': {
                id: '1F354',
                emoji: '🍔',
                group: '5',
            },
            '1F35F': {
                id: '1F35F',
                emoji: '🍟',
                group: '5',
            },
            '1F355': {
                id: '1F355',
                emoji: '🍕',
                group: '5',
            },
            '1F32D': {
                id: '1F32D',
                emoji: '🌭',
                group: '5',
            },
            '1F96A': {
                id: '1F96A',
                emoji: '🥪',
                group: '5',
            },
            '1F32E': {
                id: '1F32E',
                emoji: '🌮',
                group: '5',
            },
            '1F32F': {
                id: '1F32F',
                emoji: '🌯',
                group: '5',
            },
            '1FAD4': {
                id: '1FAD4',
                emoji: '🫔',
                group: '5',
            },
            '1F959': {
                id: '1F959',
                emoji: '🥙',
                group: '5',
            },
            '1F9C6': {
                id: '1F9C6',
                emoji: '🧆',
                group: '5',
            },
            '1F95A': {
                id: '1F95A',
                emoji: '🥚',
                group: '5',
            },
            '1F373': {
                id: '1F373',
                emoji: '🍳',
                group: '5',
            },
            '1F958': {
                id: '1F958',
                emoji: '🥘',
                group: '5',
            },
            '1F372': {
                id: '1F372',
                emoji: '🍲',
                group: '5',
            },
            '1FAD5': {
                id: '1FAD5',
                emoji: '🫕',
                group: '5',
            },
            '1F963': {
                id: '1F963',
                emoji: '🥣',
                group: '5',
            },
            '1F957': {
                id: '1F957',
                emoji: '🥗',
                group: '5',
            },
            '1F37F': {
                id: '1F37F',
                emoji: '🍿',
                group: '5',
            },
            '1F9C8': {
                id: '1F9C8',
                emoji: '🧈',
                group: '5',
            },
            '1F9C2': {
                id: '1F9C2',
                emoji: '🧂',
                group: '5',
            },
            '1F96B': {
                id: '1F96B',
                emoji: '🥫',
                group: '5',
            },
            '1F371': {
                id: '1F371',
                emoji: '🍱',
                group: '5',
            },
            '1F358': {
                id: '1F358',
                emoji: '🍘',
                group: '5',
            },
            '1F359': {
                id: '1F359',
                emoji: '🍙',
                group: '5',
            },
            '1F35A': {
                id: '1F35A',
                emoji: '🍚',
                group: '5',
            },
            '1F35B': {
                id: '1F35B',
                emoji: '🍛',
                group: '5',
            },
            '1F35C': {
                id: '1F35C',
                emoji: '🍜',
                group: '5',
            },
            '1F35D': {
                id: '1F35D',
                emoji: '🍝',
                group: '5',
            },
            '1F360': {
                id: '1F360',
                emoji: '🍠',
                group: '5',
            },
            '1F362': {
                id: '1F362',
                emoji: '🍢',
                group: '5',
            },
            '1F363': {
                id: '1F363',
                emoji: '🍣',
                group: '5',
            },
            '1F364': {
                id: '1F364',
                emoji: '🍤',
                group: '5',
            },
            '1F365': {
                id: '1F365',
                emoji: '🍥',
                group: '5',
            },
            '1F96E': {
                id: '1F96E',
                emoji: '🥮',
                group: '5',
            },
            '1F361': {
                id: '1F361',
                emoji: '🍡',
                group: '5',
            },
            '1F95F': {
                id: '1F95F',
                emoji: '🥟',
                group: '5',
            },
            '1F960': {
                id: '1F960',
                emoji: '🥠',
                group: '5',
            },
            '1F961': {
                id: '1F961',
                emoji: '🥡',
                group: '5',
            },
            '1F980': {
                id: '1F980',
                emoji: '🦀',
                group: '5',
            },
            '1F99E': {
                id: '1F99E',
                emoji: '🦞',
                group: '5',
            },
            '1F990': {
                id: '1F990',
                emoji: '🦐',
                group: '5',
            },
            '1F991': {
                id: '1F991',
                emoji: '🦑',
                group: '5',
            },
            '1F9AA': {
                id: '1F9AA',
                emoji: '🦪',
                group: '5',
            },
            '1F366': {
                id: '1F366',
                emoji: '🍦',
                group: '5',
            },
            '1F367': {
                id: '1F367',
                emoji: '🍧',
                group: '5',
            },
            '1F368': {
                id: '1F368',
                emoji: '🍨',
                group: '5',
            },
            '1F369': {
                id: '1F369',
                emoji: '🍩',
                group: '5',
            },
            '1F36A': {
                id: '1F36A',
                emoji: '🍪',
                group: '5',
            },
            '1F382': {
                id: '1F382',
                emoji: '🎂',
                group: '5',
            },
            '1F370': {
                id: '1F370',
                emoji: '🍰',
                group: '5',
            },
            '1F9C1': {
                id: '1F9C1',
                emoji: '🧁',
                group: '5',
            },
            '1F967': {
                id: '1F967',
                emoji: '🥧',
                group: '5',
            },
            '1F36B': {
                id: '1F36B',
                emoji: '🍫',
                group: '5',
            },
            '1F36C': {
                id: '1F36C',
                emoji: '🍬',
                group: '5',
            },
            '1F36D': {
                id: '1F36D',
                emoji: '🍭',
                group: '5',
            },
            '1F36E': {
                id: '1F36E',
                emoji: '🍮',
                group: '5',
            },
            '1F36F': {
                id: '1F36F',
                emoji: '🍯',
                group: '5',
            },
            '1F37C': {
                id: '1F37C',
                emoji: '🍼',
                group: '5',
            },
            '1F95B': {
                id: '1F95B',
                emoji: '🥛',
                group: '5',
            },
            '2615': {
                id: '2615',
                emoji: '☕',
                group: '5',
            },
            '1FAD6': {
                id: '1FAD6',
                emoji: '🫖',
                group: '5',
            },
            '1F375': {
                id: '1F375',
                emoji: '🍵',
                group: '5',
            },
            '1F376': {
                id: '1F376',
                emoji: '🍶',
                group: '5',
            },
            '1F37E': {
                id: '1F37E',
                emoji: '🍾',
                group: '5',
            },
            '1F377': {
                id: '1F377',
                emoji: '🍷',
                group: '5',
            },
            '1F378': {
                id: '1F378',
                emoji: '🍸',
                group: '5',
            },
            '1F379': {
                id: '1F379',
                emoji: '🍹',
                group: '5',
            },
            '1F37A': {
                id: '1F37A',
                emoji: '🍺',
                group: '5',
            },
            '1F37B': {
                id: '1F37B',
                emoji: '🍻',
                group: '5',
            },
            '1F942': {
                id: '1F942',
                emoji: '🥂',
                group: '5',
            },
            '1F943': {
                id: '1F943',
                emoji: '🥃',
                group: '5',
            },
            '1FAD7': {
                id: '1FAD7',
                emoji: '🫗',
                group: '5',
            },
            '1F964': {
                id: '1F964',
                emoji: '🥤',
                group: '5',
            },
            '1F9CB': {
                id: '1F9CB',
                emoji: '🧋',
                group: '5',
            },
            '1F9C3': {
                id: '1F9C3',
                emoji: '🧃',
                group: '5',
            },
            '1F9C9': {
                id: '1F9C9',
                emoji: '🧉',
                group: '5',
            },
            '1F9CA': {
                id: '1F9CA',
                emoji: '🧊',
                group: '5',
            },
            '1F962': {
                id: '1F962',
                emoji: '🥢',
                group: '5',
            },
            '1F37D_FE0F': {
                id: '1F37D_FE0F',
                emoji: '🍽️',
                group: '5',
            },
            '1F374': {
                id: '1F374',
                emoji: '🍴',
                group: '5',
            },
            '1F944': {
                id: '1F944',
                emoji: '🥄',
                group: '5',
            },
            '1F52A': {
                id: '1F52A',
                emoji: '🔪',
                group: '5',
            },
            '1FAD9': {
                id: '1FAD9',
                emoji: '🫙',
                group: '5',
            },
            '1F3FA': {
                id: '1F3FA',
                emoji: '🏺',
                group: '5',
            },
            '1F30D': {
                id: '1F30D',
                emoji: '🌍',
                group: '6',
            },
            '1F30E': {
                id: '1F30E',
                emoji: '🌎',
                group: '6',
            },
            '1F30F': {
                id: '1F30F',
                emoji: '🌏',
                group: '6',
            },
            '1F310': {
                id: '1F310',
                emoji: '🌐',
                group: '6',
            },
            '1F5FA_FE0F': {
                id: '1F5FA_FE0F',
                emoji: '🗺️',
                group: '6',
            },
            '1F5FE': {
                id: '1F5FE',
                emoji: '🗾',
                group: '6',
            },
            '1F9ED': {
                id: '1F9ED',
                emoji: '🧭',
                group: '6',
            },
            '1F3D4_FE0F': {
                id: '1F3D4_FE0F',
                emoji: '🏔️',
                group: '6',
            },
            '26F0_FE0F': {
                id: '26F0_FE0F',
                emoji: '⛰️',
                group: '6',
            },
            '1F30B': {
                id: '1F30B',
                emoji: '🌋',
                group: '6',
            },
            '1F5FB': {
                id: '1F5FB',
                emoji: '🗻',
                group: '6',
            },
            '1F3D5_FE0F': {
                id: '1F3D5_FE0F',
                emoji: '🏕️',
                group: '6',
            },
            '1F3D6_FE0F': {
                id: '1F3D6_FE0F',
                emoji: '🏖️',
                group: '6',
            },
            '1F3DC_FE0F': {
                id: '1F3DC_FE0F',
                emoji: '🏜️',
                group: '6',
            },
            '1F3DD_FE0F': {
                id: '1F3DD_FE0F',
                emoji: '🏝️',
                group: '6',
            },
            '1F3DE_FE0F': {
                id: '1F3DE_FE0F',
                emoji: '🏞️',
                group: '6',
            },
            '1F3DF_FE0F': {
                id: '1F3DF_FE0F',
                emoji: '🏟️',
                group: '6',
            },
            '1F3DB_FE0F': {
                id: '1F3DB_FE0F',
                emoji: '🏛️',
                group: '6',
            },
            '1F3D7_FE0F': {
                id: '1F3D7_FE0F',
                emoji: '🏗️',
                group: '6',
            },
            '1F9F1': {
                id: '1F9F1',
                emoji: '🧱',
                group: '6',
            },
            '1FAA8': {
                id: '1FAA8',
                emoji: '🪨',
                group: '6',
            },
            '1FAB5': {
                id: '1FAB5',
                emoji: '🪵',
                group: '6',
            },
            '1F6D6': {
                id: '1F6D6',
                emoji: '🛖',
                group: '6',
            },
            '1F3D8_FE0F': {
                id: '1F3D8_FE0F',
                emoji: '🏘️',
                group: '6',
            },
            '1F3DA_FE0F': {
                id: '1F3DA_FE0F',
                emoji: '🏚️',
                group: '6',
            },
            '1F3E0': {
                id: '1F3E0',
                emoji: '🏠',
                group: '6',
            },
            '1F3E1': {
                id: '1F3E1',
                emoji: '🏡',
                group: '6',
            },
            '1F3E2': {
                id: '1F3E2',
                emoji: '🏢',
                group: '6',
            },
            '1F3E3': {
                id: '1F3E3',
                emoji: '🏣',
                group: '6',
            },
            '1F3E4': {
                id: '1F3E4',
                emoji: '🏤',
                group: '6',
            },
            '1F3E5': {
                id: '1F3E5',
                emoji: '🏥',
                group: '6',
            },
            '1F3E6': {
                id: '1F3E6',
                emoji: '🏦',
                group: '6',
            },
            '1F3E8': {
                id: '1F3E8',
                emoji: '🏨',
                group: '6',
            },
            '1F3E9': {
                id: '1F3E9',
                emoji: '🏩',
                group: '6',
            },
            '1F3EA': {
                id: '1F3EA',
                emoji: '🏪',
                group: '6',
            },
            '1F3EB': {
                id: '1F3EB',
                emoji: '🏫',
                group: '6',
            },
            '1F3EC': {
                id: '1F3EC',
                emoji: '🏬',
                group: '6',
            },
            '1F3ED': {
                id: '1F3ED',
                emoji: '🏭',
                group: '6',
            },
            '1F3EF': {
                id: '1F3EF',
                emoji: '🏯',
                group: '6',
            },
            '1F3F0': {
                id: '1F3F0',
                emoji: '🏰',
                group: '6',
            },
            '1F492': {
                id: '1F492',
                emoji: '💒',
                group: '6',
            },
            '1F5FC': {
                id: '1F5FC',
                emoji: '🗼',
                group: '6',
            },
            '1F5FD': {
                id: '1F5FD',
                emoji: '🗽',
                group: '6',
            },
            '26EA': {
                id: '26EA',
                emoji: '⛪',
                group: '6',
            },
            '1F54C': {
                id: '1F54C',
                emoji: '🕌',
                group: '6',
            },
            '1F6D5': {
                id: '1F6D5',
                emoji: '🛕',
                group: '6',
            },
            '1F54D': {
                id: '1F54D',
                emoji: '🕍',
                group: '6',
            },
            '26E9_FE0F': {
                id: '26E9_FE0F',
                emoji: '⛩️',
                group: '6',
            },
            '1F54B': {
                id: '1F54B',
                emoji: '🕋',
                group: '6',
            },
            '26F2': {
                id: '26F2',
                emoji: '⛲',
                group: '6',
            },
            '26FA': {
                id: '26FA',
                emoji: '⛺',
                group: '6',
            },
            '1F301': {
                id: '1F301',
                emoji: '🌁',
                group: '6',
            },
            '1F303': {
                id: '1F303',
                emoji: '🌃',
                group: '6',
            },
            '1F3D9_FE0F': {
                id: '1F3D9_FE0F',
                emoji: '🏙️',
                group: '6',
            },
            '1F304': {
                id: '1F304',
                emoji: '🌄',
                group: '6',
            },
            '1F305': {
                id: '1F305',
                emoji: '🌅',
                group: '6',
            },
            '1F306': {
                id: '1F306',
                emoji: '🌆',
                group: '6',
            },
            '1F307': {
                id: '1F307',
                emoji: '🌇',
                group: '6',
            },
            '1F309': {
                id: '1F309',
                emoji: '🌉',
                group: '6',
            },
            '2668_FE0F': {
                id: '2668_FE0F',
                emoji: '♨️',
                group: '6',
            },
            '1F3A0': {
                id: '1F3A0',
                emoji: '🎠',
                group: '6',
            },
            '1F6DD': {
                id: '1F6DD',
                emoji: '🛝',
                group: '6',
            },
            '1F3A1': {
                id: '1F3A1',
                emoji: '🎡',
                group: '6',
            },
            '1F3A2': {
                id: '1F3A2',
                emoji: '🎢',
                group: '6',
            },
            '1F488': {
                id: '1F488',
                emoji: '💈',
                group: '6',
            },
            '1F3AA': {
                id: '1F3AA',
                emoji: '🎪',
                group: '6',
            },
            '1F682': {
                id: '1F682',
                emoji: '🚂',
                group: '6',
            },
            '1F683': {
                id: '1F683',
                emoji: '🚃',
                group: '6',
            },
            '1F684': {
                id: '1F684',
                emoji: '🚄',
                group: '6',
            },
            '1F685': {
                id: '1F685',
                emoji: '🚅',
                group: '6',
            },
            '1F686': {
                id: '1F686',
                emoji: '🚆',
                group: '6',
            },
            '1F687': {
                id: '1F687',
                emoji: '🚇',
                group: '6',
            },
            '1F688': {
                id: '1F688',
                emoji: '🚈',
                group: '6',
            },
            '1F689': {
                id: '1F689',
                emoji: '🚉',
                group: '6',
            },
            '1F68A': {
                id: '1F68A',
                emoji: '🚊',
                group: '6',
            },
            '1F69D': {
                id: '1F69D',
                emoji: '🚝',
                group: '6',
            },
            '1F69E': {
                id: '1F69E',
                emoji: '🚞',
                group: '6',
            },
            '1F68B': {
                id: '1F68B',
                emoji: '🚋',
                group: '6',
            },
            '1F68C': {
                id: '1F68C',
                emoji: '🚌',
                group: '6',
            },
            '1F68D': {
                id: '1F68D',
                emoji: '🚍',
                group: '6',
            },
            '1F68E': {
                id: '1F68E',
                emoji: '🚎',
                group: '6',
            },
            '1F690': {
                id: '1F690',
                emoji: '🚐',
                group: '6',
            },
            '1F691': {
                id: '1F691',
                emoji: '🚑',
                group: '6',
            },
            '1F692': {
                id: '1F692',
                emoji: '🚒',
                group: '6',
            },
            '1F693': {
                id: '1F693',
                emoji: '🚓',
                group: '6',
            },
            '1F694': {
                id: '1F694',
                emoji: '🚔',
                group: '6',
            },
            '1F695': {
                id: '1F695',
                emoji: '🚕',
                group: '6',
            },
            '1F696': {
                id: '1F696',
                emoji: '🚖',
                group: '6',
            },
            '1F697': {
                id: '1F697',
                emoji: '🚗',
                group: '6',
            },
            '1F698': {
                id: '1F698',
                emoji: '🚘',
                group: '6',
            },
            '1F699': {
                id: '1F699',
                emoji: '🚙',
                group: '6',
            },
            '1F6FB': {
                id: '1F6FB',
                emoji: '🛻',
                group: '6',
            },
            '1F69A': {
                id: '1F69A',
                emoji: '🚚',
                group: '6',
            },
            '1F69B': {
                id: '1F69B',
                emoji: '🚛',
                group: '6',
            },
            '1F69C': {
                id: '1F69C',
                emoji: '🚜',
                group: '6',
            },
            '1F3CE_FE0F': {
                id: '1F3CE_FE0F',
                emoji: '🏎️',
                group: '6',
            },
            '1F3CD_FE0F': {
                id: '1F3CD_FE0F',
                emoji: '🏍️',
                group: '6',
            },
            '1F6F5': {
                id: '1F6F5',
                emoji: '🛵',
                group: '6',
            },
            '1F9BD': {
                id: '1F9BD',
                emoji: '🦽',
                group: '6',
            },
            '1F9BC': {
                id: '1F9BC',
                emoji: '🦼',
                group: '6',
            },
            '1F6FA': {
                id: '1F6FA',
                emoji: '🛺',
                group: '6',
            },
            '1F6B2': {
                id: '1F6B2',
                emoji: '🚲',
                group: '6',
            },
            '1F6F4': {
                id: '1F6F4',
                emoji: '🛴',
                group: '6',
            },
            '1F6F9': {
                id: '1F6F9',
                emoji: '🛹',
                group: '6',
            },
            '1F6FC': {
                id: '1F6FC',
                emoji: '🛼',
                group: '6',
            },
            '1F68F': {
                id: '1F68F',
                emoji: '🚏',
                group: '6',
            },
            '1F6E3_FE0F': {
                id: '1F6E3_FE0F',
                emoji: '🛣️',
                group: '6',
            },
            '1F6E4_FE0F': {
                id: '1F6E4_FE0F',
                emoji: '🛤️',
                group: '6',
            },
            '1F6E2_FE0F': {
                id: '1F6E2_FE0F',
                emoji: '🛢️',
                group: '6',
            },
            '26FD': {
                id: '26FD',
                emoji: '⛽',
                group: '6',
            },
            '1F6DE': {
                id: '1F6DE',
                emoji: '🛞',
                group: '6',
            },
            '1F6A8': {
                id: '1F6A8',
                emoji: '🚨',
                group: '6',
            },
            '1F6A5': {
                id: '1F6A5',
                emoji: '🚥',
                group: '6',
            },
            '1F6A6': {
                id: '1F6A6',
                emoji: '🚦',
                group: '6',
            },
            '1F6D1': {
                id: '1F6D1',
                emoji: '🛑',
                group: '6',
            },
            '1F6A7': {
                id: '1F6A7',
                emoji: '🚧',
                group: '6',
            },
            '2693': {
                id: '2693',
                emoji: '⚓',
                group: '6',
            },
            '1F6DF': {
                id: '1F6DF',
                emoji: '🛟',
                group: '6',
            },
            '26F5': {
                id: '26F5',
                emoji: '⛵',
                group: '6',
            },
            '1F6F6': {
                id: '1F6F6',
                emoji: '🛶',
                group: '6',
            },
            '1F6A4': {
                id: '1F6A4',
                emoji: '🚤',
                group: '6',
            },
            '1F6F3_FE0F': {
                id: '1F6F3_FE0F',
                emoji: '🛳️',
                group: '6',
            },
            '26F4_FE0F': {
                id: '26F4_FE0F',
                emoji: '⛴️',
                group: '6',
            },
            '1F6E5_FE0F': {
                id: '1F6E5_FE0F',
                emoji: '🛥️',
                group: '6',
            },
            '1F6A2': {
                id: '1F6A2',
                emoji: '🚢',
                group: '6',
            },
            '2708_FE0F': {
                id: '2708_FE0F',
                emoji: '✈️',
                group: '6',
            },
            '1F6E9_FE0F': {
                id: '1F6E9_FE0F',
                emoji: '🛩️',
                group: '6',
            },
            '1F6EB': {
                id: '1F6EB',
                emoji: '🛫',
                group: '6',
            },
            '1F6EC': {
                id: '1F6EC',
                emoji: '🛬',
                group: '6',
            },
            '1FA82': {
                id: '1FA82',
                emoji: '🪂',
                group: '6',
            },
            '1F4BA': {
                id: '1F4BA',
                emoji: '💺',
                group: '6',
            },
            '1F681': {
                id: '1F681',
                emoji: '🚁',
                group: '6',
            },
            '1F69F': {
                id: '1F69F',
                emoji: '🚟',
                group: '6',
            },
            '1F6A0': {
                id: '1F6A0',
                emoji: '🚠',
                group: '6',
            },
            '1F6A1': {
                id: '1F6A1',
                emoji: '🚡',
                group: '6',
            },
            '1F6F0_FE0F': {
                id: '1F6F0_FE0F',
                emoji: '🛰️',
                group: '6',
            },
            '1F680': {
                id: '1F680',
                emoji: '🚀',
                group: '6',
            },
            '1F6F8': {
                id: '1F6F8',
                emoji: '🛸',
                group: '6',
            },
            '1F6CE_FE0F': {
                id: '1F6CE_FE0F',
                emoji: '🛎️',
                group: '6',
            },
            '1F9F3': {
                id: '1F9F3',
                emoji: '🧳',
                group: '6',
            },
            '231B': {
                id: '231B',
                emoji: '⌛',
                group: '6',
            },
            '23F3': {
                id: '23F3',
                emoji: '⏳',
                group: '6',
            },
            '231A': {
                id: '231A',
                emoji: '⌚',
                group: '6',
            },
            '23F0': {
                id: '23F0',
                emoji: '⏰',
                group: '6',
            },
            '23F1_FE0F': {
                id: '23F1_FE0F',
                emoji: '⏱️',
                group: '6',
            },
            '23F2_FE0F': {
                id: '23F2_FE0F',
                emoji: '⏲️',
                group: '6',
            },
            '1F570_FE0F': {
                id: '1F570_FE0F',
                emoji: '🕰️',
                group: '6',
            },
            '1F55B': {
                id: '1F55B',
                emoji: '🕛',
                group: '6',
            },
            '1F567': {
                id: '1F567',
                emoji: '🕧',
                group: '6',
            },
            '1F550': {
                id: '1F550',
                emoji: '🕐',
                group: '6',
            },
            '1F55C': {
                id: '1F55C',
                emoji: '🕜',
                group: '6',
            },
            '1F551': {
                id: '1F551',
                emoji: '🕑',
                group: '6',
            },
            '1F55D': {
                id: '1F55D',
                emoji: '🕝',
                group: '6',
            },
            '1F552': {
                id: '1F552',
                emoji: '🕒',
                group: '6',
            },
            '1F55E': {
                id: '1F55E',
                emoji: '🕞',
                group: '6',
            },
            '1F553': {
                id: '1F553',
                emoji: '🕓',
                group: '6',
            },
            '1F55F': {
                id: '1F55F',
                emoji: '🕟',
                group: '6',
            },
            '1F554': {
                id: '1F554',
                emoji: '🕔',
                group: '6',
            },
            '1F560': {
                id: '1F560',
                emoji: '🕠',
                group: '6',
            },
            '1F555': {
                id: '1F555',
                emoji: '🕕',
                group: '6',
            },
            '1F561': {
                id: '1F561',
                emoji: '🕡',
                group: '6',
            },
            '1F556': {
                id: '1F556',
                emoji: '🕖',
                group: '6',
            },
            '1F562': {
                id: '1F562',
                emoji: '🕢',
                group: '6',
            },
            '1F557': {
                id: '1F557',
                emoji: '🕗',
                group: '6',
            },
            '1F563': {
                id: '1F563',
                emoji: '🕣',
                group: '6',
            },
            '1F558': {
                id: '1F558',
                emoji: '🕘',
                group: '6',
            },
            '1F564': {
                id: '1F564',
                emoji: '🕤',
                group: '6',
            },
            '1F559': {
                id: '1F559',
                emoji: '🕙',
                group: '6',
            },
            '1F565': {
                id: '1F565',
                emoji: '🕥',
                group: '6',
            },
            '1F55A': {
                id: '1F55A',
                emoji: '🕚',
                group: '6',
            },
            '1F566': {
                id: '1F566',
                emoji: '🕦',
                group: '6',
            },
            '1F311': {
                id: '1F311',
                emoji: '🌑',
                group: '6',
            },
            '1F312': {
                id: '1F312',
                emoji: '🌒',
                group: '6',
            },
            '1F313': {
                id: '1F313',
                emoji: '🌓',
                group: '6',
            },
            '1F314': {
                id: '1F314',
                emoji: '🌔',
                group: '6',
            },
            '1F315': {
                id: '1F315',
                emoji: '🌕',
                group: '6',
            },
            '1F316': {
                id: '1F316',
                emoji: '🌖',
                group: '6',
            },
            '1F317': {
                id: '1F317',
                emoji: '🌗',
                group: '6',
            },
            '1F318': {
                id: '1F318',
                emoji: '🌘',
                group: '6',
            },
            '1F319': {
                id: '1F319',
                emoji: '🌙',
                group: '6',
            },
            '1F31A': {
                id: '1F31A',
                emoji: '🌚',
                group: '6',
            },
            '1F31B': {
                id: '1F31B',
                emoji: '🌛',
                group: '6',
            },
            '1F31C': {
                id: '1F31C',
                emoji: '🌜',
                group: '6',
            },
            '1F321_FE0F': {
                id: '1F321_FE0F',
                emoji: '🌡️',
                group: '6',
            },
            '2600_FE0F': {
                id: '2600_FE0F',
                emoji: '☀️',
                group: '6',
            },
            '1F31D': {
                id: '1F31D',
                emoji: '🌝',
                group: '6',
            },
            '1F31E': {
                id: '1F31E',
                emoji: '🌞',
                group: '6',
            },
            '1FA90': {
                id: '1FA90',
                emoji: '🪐',
                group: '6',
            },
            '2B50': {
                id: '2B50',
                emoji: '⭐',
                group: '6',
            },
            '1F31F': {
                id: '1F31F',
                emoji: '🌟',
                group: '6',
            },
            '1F320': {
                id: '1F320',
                emoji: '🌠',
                group: '6',
            },
            '1F30C': {
                id: '1F30C',
                emoji: '🌌',
                group: '6',
            },
            '2601_FE0F': {
                id: '2601_FE0F',
                emoji: '☁️',
                group: '6',
            },
            '26C5': {
                id: '26C5',
                emoji: '⛅',
                group: '6',
            },
            '26C8_FE0F': {
                id: '26C8_FE0F',
                emoji: '⛈️',
                group: '6',
            },
            '1F324_FE0F': {
                id: '1F324_FE0F',
                emoji: '🌤️',
                group: '6',
            },
            '1F325_FE0F': {
                id: '1F325_FE0F',
                emoji: '🌥️',
                group: '6',
            },
            '1F326_FE0F': {
                id: '1F326_FE0F',
                emoji: '🌦️',
                group: '6',
            },
            '1F327_FE0F': {
                id: '1F327_FE0F',
                emoji: '🌧️',
                group: '6',
            },
            '1F328_FE0F': {
                id: '1F328_FE0F',
                emoji: '🌨️',
                group: '6',
            },
            '1F329_FE0F': {
                id: '1F329_FE0F',
                emoji: '🌩️',
                group: '6',
            },
            '1F32A_FE0F': {
                id: '1F32A_FE0F',
                emoji: '🌪️',
                group: '6',
            },
            '1F32B_FE0F': {
                id: '1F32B_FE0F',
                emoji: '🌫️',
                group: '6',
            },
            '1F32C_FE0F': {
                id: '1F32C_FE0F',
                emoji: '🌬️',
                group: '6',
            },
            '1F300': {
                id: '1F300',
                emoji: '🌀',
                group: '6',
            },
            '1F308': {
                id: '1F308',
                emoji: '🌈',
                group: '6',
            },
            '1F302': {
                id: '1F302',
                emoji: '🌂',
                group: '6',
            },
            '2602_FE0F': {
                id: '2602_FE0F',
                emoji: '☂️',
                group: '6',
            },
            '2614': {
                id: '2614',
                emoji: '☔',
                group: '6',
            },
            '26F1_FE0F': {
                id: '26F1_FE0F',
                emoji: '⛱️',
                group: '6',
            },
            '26A1': {
                id: '26A1',
                emoji: '⚡',
                group: '6',
            },
            '2744_FE0F': {
                id: '2744_FE0F',
                emoji: '❄️',
                group: '6',
            },
            '2603_FE0F': {
                id: '2603_FE0F',
                emoji: '☃️',
                group: '6',
            },
            '26C4': {
                id: '26C4',
                emoji: '⛄',
                group: '6',
            },
            '2604_FE0F': {
                id: '2604_FE0F',
                emoji: '☄️',
                group: '6',
            },
            '1F525': {
                id: '1F525',
                emoji: '🔥',
                group: '6',
            },
            '1F4A7': {
                id: '1F4A7',
                emoji: '💧',
                group: '6',
            },
            '1F30A': {
                id: '1F30A',
                emoji: '🌊',
                group: '6',
            },
            '1F383': {
                id: '1F383',
                emoji: '🎃',
                group: '7',
            },
            '1F384': {
                id: '1F384',
                emoji: '🎄',
                group: '7',
            },
            '1F386': {
                id: '1F386',
                emoji: '🎆',
                group: '7',
            },
            '1F387': {
                id: '1F387',
                emoji: '🎇',
                group: '7',
            },
            '1F9E8': {
                id: '1F9E8',
                emoji: '🧨',
                group: '7',
            },
            '2728': {
                id: '2728',
                emoji: '✨',
                group: '7',
            },
            '1F388': {
                id: '1F388',
                emoji: '🎈',
                group: '7',
            },
            '1F389': {
                id: '1F389',
                emoji: '🎉',
                group: '7',
            },
            '1F38A': {
                id: '1F38A',
                emoji: '🎊',
                group: '7',
            },
            '1F38B': {
                id: '1F38B',
                emoji: '🎋',
                group: '7',
            },
            '1F38D': {
                id: '1F38D',
                emoji: '🎍',
                group: '7',
            },
            '1F38E': {
                id: '1F38E',
                emoji: '🎎',
                group: '7',
            },
            '1F38F': {
                id: '1F38F',
                emoji: '🎏',
                group: '7',
            },
            '1F390': {
                id: '1F390',
                emoji: '🎐',
                group: '7',
            },
            '1F391': {
                id: '1F391',
                emoji: '🎑',
                group: '7',
            },
            '1F9E7': {
                id: '1F9E7',
                emoji: '🧧',
                group: '7',
            },
            '1F380': {
                id: '1F380',
                emoji: '🎀',
                group: '7',
            },
            '1F381': {
                id: '1F381',
                emoji: '🎁',
                group: '7',
            },
            '1F397_FE0F': {
                id: '1F397_FE0F',
                emoji: '🎗️',
                group: '7',
            },
            '1F39F_FE0F': {
                id: '1F39F_FE0F',
                emoji: '🎟️',
                group: '7',
            },
            '1F3AB': {
                id: '1F3AB',
                emoji: '🎫',
                group: '7',
            },
            '1F396_FE0F': {
                id: '1F396_FE0F',
                emoji: '🎖️',
                group: '7',
            },
            '1F3C6': {
                id: '1F3C6',
                emoji: '🏆',
                group: '7',
            },
            '1F3C5': {
                id: '1F3C5',
                emoji: '🏅',
                group: '7',
            },
            '1F947': {
                id: '1F947',
                emoji: '🥇',
                group: '7',
            },
            '1F948': {
                id: '1F948',
                emoji: '🥈',
                group: '7',
            },
            '1F949': {
                id: '1F949',
                emoji: '🥉',
                group: '7',
            },
            '26BD': {
                id: '26BD',
                emoji: '⚽',
                group: '7',
            },
            '26BE': {
                id: '26BE',
                emoji: '⚾',
                group: '7',
            },
            '1F94E': {
                id: '1F94E',
                emoji: '🥎',
                group: '7',
            },
            '1F3C0': {
                id: '1F3C0',
                emoji: '🏀',
                group: '7',
            },
            '1F3D0': {
                id: '1F3D0',
                emoji: '🏐',
                group: '7',
            },
            '1F3C8': {
                id: '1F3C8',
                emoji: '🏈',
                group: '7',
            },
            '1F3C9': {
                id: '1F3C9',
                emoji: '🏉',
                group: '7',
            },
            '1F3BE': {
                id: '1F3BE',
                emoji: '🎾',
                group: '7',
            },
            '1F94F': {
                id: '1F94F',
                emoji: '🥏',
                group: '7',
            },
            '1F3B3': {
                id: '1F3B3',
                emoji: '🎳',
                group: '7',
            },
            '1F3CF': {
                id: '1F3CF',
                emoji: '🏏',
                group: '7',
            },
            '1F3D1': {
                id: '1F3D1',
                emoji: '🏑',
                group: '7',
            },
            '1F3D2': {
                id: '1F3D2',
                emoji: '🏒',
                group: '7',
            },
            '1F94D': {
                id: '1F94D',
                emoji: '🥍',
                group: '7',
            },
            '1F3D3': {
                id: '1F3D3',
                emoji: '🏓',
                group: '7',
            },
            '1F3F8': {
                id: '1F3F8',
                emoji: '🏸',
                group: '7',
            },
            '1F94A': {
                id: '1F94A',
                emoji: '🥊',
                group: '7',
            },
            '1F94B': {
                id: '1F94B',
                emoji: '🥋',
                group: '7',
            },
            '1F945': {
                id: '1F945',
                emoji: '🥅',
                group: '7',
            },
            '26F3': {
                id: '26F3',
                emoji: '⛳',
                group: '7',
            },
            '26F8_FE0F': {
                id: '26F8_FE0F',
                emoji: '⛸️',
                group: '7',
            },
            '1F3A3': {
                id: '1F3A3',
                emoji: '🎣',
                group: '7',
            },
            '1F93F': {
                id: '1F93F',
                emoji: '🤿',
                group: '7',
            },
            '1F3BD': {
                id: '1F3BD',
                emoji: '🎽',
                group: '7',
            },
            '1F3BF': {
                id: '1F3BF',
                emoji: '🎿',
                group: '7',
            },
            '1F6F7': {
                id: '1F6F7',
                emoji: '🛷',
                group: '7',
            },
            '1F94C': {
                id: '1F94C',
                emoji: '🥌',
                group: '7',
            },
            '1F3AF': {
                id: '1F3AF',
                emoji: '🎯',
                group: '7',
            },
            '1FA80': {
                id: '1FA80',
                emoji: '🪀',
                group: '7',
            },
            '1FA81': {
                id: '1FA81',
                emoji: '🪁',
                group: '7',
            },
            '1F52B': {
                id: '1F52B',
                emoji: '🔫',
                group: '7',
            },
            '1F3B1': {
                id: '1F3B1',
                emoji: '🎱',
                group: '7',
            },
            '1F52E': {
                id: '1F52E',
                emoji: '🔮',
                group: '7',
            },
            '1FA84': {
                id: '1FA84',
                emoji: '🪄',
                group: '7',
            },
            '1F3AE': {
                id: '1F3AE',
                emoji: '🎮',
                group: '7',
            },
            '1F579_FE0F': {
                id: '1F579_FE0F',
                emoji: '🕹️',
                group: '7',
            },
            '1F3B0': {
                id: '1F3B0',
                emoji: '🎰',
                group: '7',
            },
            '1F3B2': {
                id: '1F3B2',
                emoji: '🎲',
                group: '7',
            },
            '1F9E9': {
                id: '1F9E9',
                emoji: '🧩',
                group: '7',
            },
            '1F9F8': {
                id: '1F9F8',
                emoji: '🧸',
                group: '7',
            },
            '1FA85': {
                id: '1FA85',
                emoji: '🪅',
                group: '7',
            },
            '1FAA9': {
                id: '1FAA9',
                emoji: '🪩',
                group: '7',
            },
            '1FA86': {
                id: '1FA86',
                emoji: '🪆',
                group: '7',
            },
            '2660_FE0F': {
                id: '2660_FE0F',
                emoji: '♠️',
                group: '7',
            },
            '2665_FE0F': {
                id: '2665_FE0F',
                emoji: '♥️',
                group: '7',
            },
            '2666_FE0F': {
                id: '2666_FE0F',
                emoji: '♦️',
                group: '7',
            },
            '2663_FE0F': {
                id: '2663_FE0F',
                emoji: '♣️',
                group: '7',
            },
            '265F_FE0F': {
                id: '265F_FE0F',
                emoji: '♟️',
                group: '7',
            },
            '1F0CF': {
                id: '1F0CF',
                emoji: '🃏',
                group: '7',
            },
            '1F004': {
                id: '1F004',
                emoji: '🀄',
                group: '7',
            },
            '1F3B4': {
                id: '1F3B4',
                emoji: '🎴',
                group: '7',
            },
            '1F3AD': {
                id: '1F3AD',
                emoji: '🎭',
                group: '7',
            },
            '1F5BC_FE0F': {
                id: '1F5BC_FE0F',
                emoji: '🖼️',
                group: '7',
            },
            '1F3A8': {
                id: '1F3A8',
                emoji: '🎨',
                group: '7',
            },
            '1F9F5': {
                id: '1F9F5',
                emoji: '🧵',
                group: '7',
            },
            '1FAA1': {
                id: '1FAA1',
                emoji: '🪡',
                group: '7',
            },
            '1F9F6': {
                id: '1F9F6',
                emoji: '🧶',
                group: '7',
            },
            '1FAA2': {
                id: '1FAA2',
                emoji: '🪢',
                group: '7',
            },
            '1F453': {
                id: '1F453',
                emoji: '👓',
                group: '8',
            },
            '1F576_FE0F': {
                id: '1F576_FE0F',
                emoji: '🕶️',
                group: '8',
            },
            '1F97D': {
                id: '1F97D',
                emoji: '🥽',
                group: '8',
            },
            '1F97C': {
                id: '1F97C',
                emoji: '🥼',
                group: '8',
            },
            '1F9BA': {
                id: '1F9BA',
                emoji: '🦺',
                group: '8',
            },
            '1F454': {
                id: '1F454',
                emoji: '👔',
                group: '8',
            },
            '1F455': {
                id: '1F455',
                emoji: '👕',
                group: '8',
            },
            '1F456': {
                id: '1F456',
                emoji: '👖',
                group: '8',
            },
            '1F9E3': {
                id: '1F9E3',
                emoji: '🧣',
                group: '8',
            },
            '1F9E4': {
                id: '1F9E4',
                emoji: '🧤',
                group: '8',
            },
            '1F9E5': {
                id: '1F9E5',
                emoji: '🧥',
                group: '8',
            },
            '1F9E6': {
                id: '1F9E6',
                emoji: '🧦',
                group: '8',
            },
            '1F457': {
                id: '1F457',
                emoji: '👗',
                group: '8',
            },
            '1F458': {
                id: '1F458',
                emoji: '👘',
                group: '8',
            },
            '1F97B': {
                id: '1F97B',
                emoji: '🥻',
                group: '8',
            },
            '1FA71': {
                id: '1FA71',
                emoji: '🩱',
                group: '8',
            },
            '1FA72': {
                id: '1FA72',
                emoji: '🩲',
                group: '8',
            },
            '1FA73': {
                id: '1FA73',
                emoji: '🩳',
                group: '8',
            },
            '1F459': {
                id: '1F459',
                emoji: '👙',
                group: '8',
            },
            '1F45A': {
                id: '1F45A',
                emoji: '👚',
                group: '8',
            },
            '1FAAD': {
                id: '1FAAD',
                emoji: '🪭',
                group: '8',
            },
            '1F45B': {
                id: '1F45B',
                emoji: '👛',
                group: '8',
            },
            '1F45C': {
                id: '1F45C',
                emoji: '👜',
                group: '8',
            },
            '1F45D': {
                id: '1F45D',
                emoji: '👝',
                group: '8',
            },
            '1F6CD_FE0F': {
                id: '1F6CD_FE0F',
                emoji: '🛍️',
                group: '8',
            },
            '1F392': {
                id: '1F392',
                emoji: '🎒',
                group: '8',
            },
            '1FA74': {
                id: '1FA74',
                emoji: '🩴',
                group: '8',
            },
            '1F45E': {
                id: '1F45E',
                emoji: '👞',
                group: '8',
            },
            '1F45F': {
                id: '1F45F',
                emoji: '👟',
                group: '8',
            },
            '1F97E': {
                id: '1F97E',
                emoji: '🥾',
                group: '8',
            },
            '1F97F': {
                id: '1F97F',
                emoji: '🥿',
                group: '8',
            },
            '1F460': {
                id: '1F460',
                emoji: '👠',
                group: '8',
            },
            '1F461': {
                id: '1F461',
                emoji: '👡',
                group: '8',
            },
            '1FA70': {
                id: '1FA70',
                emoji: '🩰',
                group: '8',
            },
            '1F462': {
                id: '1F462',
                emoji: '👢',
                group: '8',
            },
            '1FAAE': {
                id: '1FAAE',
                emoji: '🪮',
                group: '8',
            },
            '1F451': {
                id: '1F451',
                emoji: '👑',
                group: '8',
            },
            '1F452': {
                id: '1F452',
                emoji: '👒',
                group: '8',
            },
            '1F3A9': {
                id: '1F3A9',
                emoji: '🎩',
                group: '8',
            },
            '1F393': {
                id: '1F393',
                emoji: '🎓',
                group: '8',
            },
            '1F9E2': {
                id: '1F9E2',
                emoji: '🧢',
                group: '8',
            },
            '1FA96': {
                id: '1FA96',
                emoji: '🪖',
                group: '8',
            },
            '26D1_FE0F': {
                id: '26D1_FE0F',
                emoji: '⛑️',
                group: '8',
            },
            '1F4FF': {
                id: '1F4FF',
                emoji: '📿',
                group: '8',
            },
            '1F484': {
                id: '1F484',
                emoji: '💄',
                group: '8',
            },
            '1F48D': {
                id: '1F48D',
                emoji: '💍',
                group: '8',
            },
            '1F48E': {
                id: '1F48E',
                emoji: '💎',
                group: '8',
            },
            '1F507': {
                id: '1F507',
                emoji: '🔇',
                group: '8',
            },
            '1F508': {
                id: '1F508',
                emoji: '🔈',
                group: '8',
            },
            '1F509': {
                id: '1F509',
                emoji: '🔉',
                group: '8',
            },
            '1F50A': {
                id: '1F50A',
                emoji: '🔊',
                group: '8',
            },
            '1F4E2': {
                id: '1F4E2',
                emoji: '📢',
                group: '8',
            },
            '1F4E3': {
                id: '1F4E3',
                emoji: '📣',
                group: '8',
            },
            '1F4EF': {
                id: '1F4EF',
                emoji: '📯',
                group: '8',
            },
            '1F514': {
                id: '1F514',
                emoji: '🔔',
                group: '8',
            },
            '1F515': {
                id: '1F515',
                emoji: '🔕',
                group: '8',
            },
            '1F3BC': {
                id: '1F3BC',
                emoji: '🎼',
                group: '8',
            },
            '1F3B5': {
                id: '1F3B5',
                emoji: '🎵',
                group: '8',
            },
            '1F3B6': {
                id: '1F3B6',
                emoji: '🎶',
                group: '8',
            },
            '1F399_FE0F': {
                id: '1F399_FE0F',
                emoji: '🎙️',
                group: '8',
            },
            '1F39A_FE0F': {
                id: '1F39A_FE0F',
                emoji: '🎚️',
                group: '8',
            },
            '1F39B_FE0F': {
                id: '1F39B_FE0F',
                emoji: '🎛️',
                group: '8',
            },
            '1F3A4': {
                id: '1F3A4',
                emoji: '🎤',
                group: '8',
            },
            '1F3A7': {
                id: '1F3A7',
                emoji: '🎧',
                group: '8',
            },
            '1F4FB': {
                id: '1F4FB',
                emoji: '📻',
                group: '8',
            },
            '1F3B7': {
                id: '1F3B7',
                emoji: '🎷',
                group: '8',
            },
            '1FA97': {
                id: '1FA97',
                emoji: '🪗',
                group: '8',
            },
            '1F3B8': {
                id: '1F3B8',
                emoji: '🎸',
                group: '8',
            },
            '1F3B9': {
                id: '1F3B9',
                emoji: '🎹',
                group: '8',
            },
            '1F3BA': {
                id: '1F3BA',
                emoji: '🎺',
                group: '8',
            },
            '1F3BB': {
                id: '1F3BB',
                emoji: '🎻',
                group: '8',
            },
            '1FA95': {
                id: '1FA95',
                emoji: '🪕',
                group: '8',
            },
            '1F941': {
                id: '1F941',
                emoji: '🥁',
                group: '8',
            },
            '1FA98': {
                id: '1FA98',
                emoji: '🪘',
                group: '8',
            },
            '1FA87': {
                id: '1FA87',
                emoji: '🪇',
                group: '8',
            },
            '1FA88': {
                id: '1FA88',
                emoji: '🪈',
                group: '8',
            },
            '1F4F1': {
                id: '1F4F1',
                emoji: '📱',
                group: '8',
            },
            '1F4F2': {
                id: '1F4F2',
                emoji: '📲',
                group: '8',
            },
            '260E_FE0F': {
                id: '260E_FE0F',
                emoji: '☎️',
                group: '8',
            },
            '1F4DE': {
                id: '1F4DE',
                emoji: '📞',
                group: '8',
            },
            '1F4DF': {
                id: '1F4DF',
                emoji: '📟',
                group: '8',
            },
            '1F4E0': {
                id: '1F4E0',
                emoji: '📠',
                group: '8',
            },
            '1F50B': {
                id: '1F50B',
                emoji: '🔋',
                group: '8',
            },
            '1FAAB': {
                id: '1FAAB',
                emoji: '🪫',
                group: '8',
            },
            '1F50C': {
                id: '1F50C',
                emoji: '🔌',
                group: '8',
            },
            '1F4BB': {
                id: '1F4BB',
                emoji: '💻',
                group: '8',
            },
            '1F5A5_FE0F': {
                id: '1F5A5_FE0F',
                emoji: '🖥️',
                group: '8',
            },
            '1F5A8_FE0F': {
                id: '1F5A8_FE0F',
                emoji: '🖨️',
                group: '8',
            },
            '2328_FE0F': {
                id: '2328_FE0F',
                emoji: '⌨️',
                group: '8',
            },
            '1F5B1_FE0F': {
                id: '1F5B1_FE0F',
                emoji: '🖱️',
                group: '8',
            },
            '1F5B2_FE0F': {
                id: '1F5B2_FE0F',
                emoji: '🖲️',
                group: '8',
            },
            '1F4BD': {
                id: '1F4BD',
                emoji: '💽',
                group: '8',
            },
            '1F4BE': {
                id: '1F4BE',
                emoji: '💾',
                group: '8',
            },
            '1F4BF': {
                id: '1F4BF',
                emoji: '💿',
                group: '8',
            },
            '1F4C0': {
                id: '1F4C0',
                emoji: '📀',
                group: '8',
            },
            '1F9EE': {
                id: '1F9EE',
                emoji: '🧮',
                group: '8',
            },
            '1F3A5': {
                id: '1F3A5',
                emoji: '🎥',
                group: '8',
            },
            '1F39E_FE0F': {
                id: '1F39E_FE0F',
                emoji: '🎞️',
                group: '8',
            },
            '1F4FD_FE0F': {
                id: '1F4FD_FE0F',
                emoji: '📽️',
                group: '8',
            },
            '1F3AC': {
                id: '1F3AC',
                emoji: '🎬',
                group: '8',
            },
            '1F4FA': {
                id: '1F4FA',
                emoji: '📺',
                group: '8',
            },
            '1F4F7': {
                id: '1F4F7',
                emoji: '📷',
                group: '8',
            },
            '1F4F8': {
                id: '1F4F8',
                emoji: '📸',
                group: '8',
            },
            '1F4F9': {
                id: '1F4F9',
                emoji: '📹',
                group: '8',
            },
            '1F4FC': {
                id: '1F4FC',
                emoji: '📼',
                group: '8',
            },
            '1F50D': {
                id: '1F50D',
                emoji: '🔍',
                group: '8',
            },
            '1F50E': {
                id: '1F50E',
                emoji: '🔎',
                group: '8',
            },
            '1F56F_FE0F': {
                id: '1F56F_FE0F',
                emoji: '🕯️',
                group: '8',
            },
            '1F4A1': {
                id: '1F4A1',
                emoji: '💡',
                group: '8',
            },
            '1F526': {
                id: '1F526',
                emoji: '🔦',
                group: '8',
            },
            '1F3EE': {
                id: '1F3EE',
                emoji: '🏮',
                group: '8',
            },
            '1FA94': {
                id: '1FA94',
                emoji: '🪔',
                group: '8',
            },
            '1F4D4': {
                id: '1F4D4',
                emoji: '📔',
                group: '8',
            },
            '1F4D5': {
                id: '1F4D5',
                emoji: '📕',
                group: '8',
            },
            '1F4D6': {
                id: '1F4D6',
                emoji: '📖',
                group: '8',
            },
            '1F4D7': {
                id: '1F4D7',
                emoji: '📗',
                group: '8',
            },
            '1F4D8': {
                id: '1F4D8',
                emoji: '📘',
                group: '8',
            },
            '1F4D9': {
                id: '1F4D9',
                emoji: '📙',
                group: '8',
            },
            '1F4DA': {
                id: '1F4DA',
                emoji: '📚',
                group: '8',
            },
            '1F4D3': {
                id: '1F4D3',
                emoji: '📓',
                group: '8',
            },
            '1F4D2': {
                id: '1F4D2',
                emoji: '📒',
                group: '8',
            },
            '1F4C3': {
                id: '1F4C3',
                emoji: '📃',
                group: '8',
            },
            '1F4DC': {
                id: '1F4DC',
                emoji: '📜',
                group: '8',
            },
            '1F4C4': {
                id: '1F4C4',
                emoji: '📄',
                group: '8',
            },
            '1F4F0': {
                id: '1F4F0',
                emoji: '📰',
                group: '8',
            },
            '1F5DE_FE0F': {
                id: '1F5DE_FE0F',
                emoji: '🗞️',
                group: '8',
            },
            '1F4D1': {
                id: '1F4D1',
                emoji: '📑',
                group: '8',
            },
            '1F516': {
                id: '1F516',
                emoji: '🔖',
                group: '8',
            },
            '1F3F7_FE0F': {
                id: '1F3F7_FE0F',
                emoji: '🏷️',
                group: '8',
            },
            '1F4B0': {
                id: '1F4B0',
                emoji: '💰',
                group: '8',
            },
            '1FA99': {
                id: '1FA99',
                emoji: '🪙',
                group: '8',
            },
            '1F4B4': {
                id: '1F4B4',
                emoji: '💴',
                group: '8',
            },
            '1F4B5': {
                id: '1F4B5',
                emoji: '💵',
                group: '8',
            },
            '1F4B6': {
                id: '1F4B6',
                emoji: '💶',
                group: '8',
            },
            '1F4B7': {
                id: '1F4B7',
                emoji: '💷',
                group: '8',
            },
            '1F4B8': {
                id: '1F4B8',
                emoji: '💸',
                group: '8',
            },
            '1F4B3': {
                id: '1F4B3',
                emoji: '💳',
                group: '8',
            },
            '1F9FE': {
                id: '1F9FE',
                emoji: '🧾',
                group: '8',
            },
            '1F4B9': {
                id: '1F4B9',
                emoji: '💹',
                group: '8',
            },
            '2709_FE0F': {
                id: '2709_FE0F',
                emoji: '✉️',
                group: '8',
            },
            '1F4E7': {
                id: '1F4E7',
                emoji: '📧',
                group: '8',
            },
            '1F4E8': {
                id: '1F4E8',
                emoji: '📨',
                group: '8',
            },
            '1F4E9': {
                id: '1F4E9',
                emoji: '📩',
                group: '8',
            },
            '1F4E4': {
                id: '1F4E4',
                emoji: '📤',
                group: '8',
            },
            '1F4E5': {
                id: '1F4E5',
                emoji: '📥',
                group: '8',
            },
            '1F4E6': {
                id: '1F4E6',
                emoji: '📦',
                group: '8',
            },
            '1F4EB': {
                id: '1F4EB',
                emoji: '📫',
                group: '8',
            },
            '1F4EA': {
                id: '1F4EA',
                emoji: '📪',
                group: '8',
            },
            '1F4EC': {
                id: '1F4EC',
                emoji: '📬',
                group: '8',
            },
            '1F4ED': {
                id: '1F4ED',
                emoji: '📭',
                group: '8',
            },
            '1F4EE': {
                id: '1F4EE',
                emoji: '📮',
                group: '8',
            },
            '1F5F3_FE0F': {
                id: '1F5F3_FE0F',
                emoji: '🗳️',
                group: '8',
            },
            '270F_FE0F': {
                id: '270F_FE0F',
                emoji: '✏️',
                group: '8',
            },
            '2712_FE0F': {
                id: '2712_FE0F',
                emoji: '✒️',
                group: '8',
            },
            '1F58B_FE0F': {
                id: '1F58B_FE0F',
                emoji: '🖋️',
                group: '8',
            },
            '1F58A_FE0F': {
                id: '1F58A_FE0F',
                emoji: '🖊️',
                group: '8',
            },
            '1F58C_FE0F': {
                id: '1F58C_FE0F',
                emoji: '🖌️',
                group: '8',
            },
            '1F58D_FE0F': {
                id: '1F58D_FE0F',
                emoji: '🖍️',
                group: '8',
            },
            '1F4DD': {
                id: '1F4DD',
                emoji: '📝',
                group: '8',
            },
            '1F4BC': {
                id: '1F4BC',
                emoji: '💼',
                group: '8',
            },
            '1F4C1': {
                id: '1F4C1',
                emoji: '📁',
                group: '8',
            },
            '1F4C2': {
                id: '1F4C2',
                emoji: '📂',
                group: '8',
            },
            '1F5C2_FE0F': {
                id: '1F5C2_FE0F',
                emoji: '🗂️',
                group: '8',
            },
            '1F4C5': {
                id: '1F4C5',
                emoji: '📅',
                group: '8',
            },
            '1F4C6': {
                id: '1F4C6',
                emoji: '📆',
                group: '8',
            },
            '1F5D2_FE0F': {
                id: '1F5D2_FE0F',
                emoji: '🗒️',
                group: '8',
            },
            '1F5D3_FE0F': {
                id: '1F5D3_FE0F',
                emoji: '🗓️',
                group: '8',
            },
            '1F4C7': {
                id: '1F4C7',
                emoji: '📇',
                group: '8',
            },
            '1F4C8': {
                id: '1F4C8',
                emoji: '📈',
                group: '8',
            },
            '1F4C9': {
                id: '1F4C9',
                emoji: '📉',
                group: '8',
            },
            '1F4CA': {
                id: '1F4CA',
                emoji: '📊',
                group: '8',
            },
            '1F4CB': {
                id: '1F4CB',
                emoji: '📋',
                group: '8',
            },
            '1F4CC': {
                id: '1F4CC',
                emoji: '📌',
                group: '8',
            },
            '1F4CD': {
                id: '1F4CD',
                emoji: '📍',
                group: '8',
            },
            '1F4CE': {
                id: '1F4CE',
                emoji: '📎',
                group: '8',
            },
            '1F587_FE0F': {
                id: '1F587_FE0F',
                emoji: '🖇️',
                group: '8',
            },
            '1F4CF': {
                id: '1F4CF',
                emoji: '📏',
                group: '8',
            },
            '1F4D0': {
                id: '1F4D0',
                emoji: '📐',
                group: '8',
            },
            '2702_FE0F': {
                id: '2702_FE0F',
                emoji: '✂️',
                group: '8',
            },
            '1F5C3_FE0F': {
                id: '1F5C3_FE0F',
                emoji: '🗃️',
                group: '8',
            },
            '1F5C4_FE0F': {
                id: '1F5C4_FE0F',
                emoji: '🗄️',
                group: '8',
            },
            '1F5D1_FE0F': {
                id: '1F5D1_FE0F',
                emoji: '🗑️',
                group: '8',
            },
            '1F512': {
                id: '1F512',
                emoji: '🔒',
                group: '8',
            },
            '1F513': {
                id: '1F513',
                emoji: '🔓',
                group: '8',
            },
            '1F50F': {
                id: '1F50F',
                emoji: '🔏',
                group: '8',
            },
            '1F510': {
                id: '1F510',
                emoji: '🔐',
                group: '8',
            },
            '1F511': {
                id: '1F511',
                emoji: '🔑',
                group: '8',
            },
            '1F5DD_FE0F': {
                id: '1F5DD_FE0F',
                emoji: '🗝️',
                group: '8',
            },
            '1F528': {
                id: '1F528',
                emoji: '🔨',
                group: '8',
            },
            '1FA93': {
                id: '1FA93',
                emoji: '🪓',
                group: '8',
            },
            '26CF_FE0F': {
                id: '26CF_FE0F',
                emoji: '⛏️',
                group: '8',
            },
            '2692_FE0F': {
                id: '2692_FE0F',
                emoji: '⚒️',
                group: '8',
            },
            '1F6E0_FE0F': {
                id: '1F6E0_FE0F',
                emoji: '🛠️',
                group: '8',
            },
            '1F5E1_FE0F': {
                id: '1F5E1_FE0F',
                emoji: '🗡️',
                group: '8',
            },
            '2694_FE0F': {
                id: '2694_FE0F',
                emoji: '⚔️',
                group: '8',
            },
            '1F4A3': {
                id: '1F4A3',
                emoji: '💣',
                group: '8',
            },
            '1FA83': {
                id: '1FA83',
                emoji: '🪃',
                group: '8',
            },
            '1F3F9': {
                id: '1F3F9',
                emoji: '🏹',
                group: '8',
            },
            '1F6E1_FE0F': {
                id: '1F6E1_FE0F',
                emoji: '🛡️',
                group: '8',
            },
            '1FA9A': {
                id: '1FA9A',
                emoji: '🪚',
                group: '8',
            },
            '1F527': {
                id: '1F527',
                emoji: '🔧',
                group: '8',
            },
            '1FA9B': {
                id: '1FA9B',
                emoji: '🪛',
                group: '8',
            },
            '1F529': {
                id: '1F529',
                emoji: '🔩',
                group: '8',
            },
            '2699_FE0F': {
                id: '2699_FE0F',
                emoji: '⚙️',
                group: '8',
            },
            '1F5DC_FE0F': {
                id: '1F5DC_FE0F',
                emoji: '🗜️',
                group: '8',
            },
            '2696_FE0F': {
                id: '2696_FE0F',
                emoji: '⚖️',
                group: '8',
            },
            '1F9AF': {
                id: '1F9AF',
                emoji: '🦯',
                group: '8',
            },
            '1F517': {
                id: '1F517',
                emoji: '🔗',
                group: '8',
            },
            '26D3_FE0F_200D_1F4A5': {
                id: '26D3_FE0F_200D_1F4A5',
                emoji: '⛓️‍💥',
                group: '8',
            },
            '26D3_FE0F': {
                id: '26D3_FE0F',
                emoji: '⛓️',
                group: '8',
            },
            '1FA9D': {
                id: '1FA9D',
                emoji: '🪝',
                group: '8',
            },
            '1F9F0': {
                id: '1F9F0',
                emoji: '🧰',
                group: '8',
            },
            '1F9F2': {
                id: '1F9F2',
                emoji: '🧲',
                group: '8',
            },
            '1FA9C': {
                id: '1FA9C',
                emoji: '🪜',
                group: '8',
            },
            '2697_FE0F': {
                id: '2697_FE0F',
                emoji: '⚗️',
                group: '8',
            },
            '1F9EA': {
                id: '1F9EA',
                emoji: '🧪',
                group: '8',
            },
            '1F9EB': {
                id: '1F9EB',
                emoji: '🧫',
                group: '8',
            },
            '1F9EC': {
                id: '1F9EC',
                emoji: '🧬',
                group: '8',
            },
            '1F52C': {
                id: '1F52C',
                emoji: '🔬',
                group: '8',
            },
            '1F52D': {
                id: '1F52D',
                emoji: '🔭',
                group: '8',
            },
            '1F4E1': {
                id: '1F4E1',
                emoji: '📡',
                group: '8',
            },
            '1F489': {
                id: '1F489',
                emoji: '💉',
                group: '8',
            },
            '1FA78': {
                id: '1FA78',
                emoji: '🩸',
                group: '8',
            },
            '1F48A': {
                id: '1F48A',
                emoji: '💊',
                group: '8',
            },
            '1FA79': {
                id: '1FA79',
                emoji: '🩹',
                group: '8',
            },
            '1FA7C': {
                id: '1FA7C',
                emoji: '🩼',
                group: '8',
            },
            '1FA7A': {
                id: '1FA7A',
                emoji: '🩺',
                group: '8',
            },
            '1FA7B': {
                id: '1FA7B',
                emoji: '🩻',
                group: '8',
            },
            '1F6AA': {
                id: '1F6AA',
                emoji: '🚪',
                group: '8',
            },
            '1F6D7': {
                id: '1F6D7',
                emoji: '🛗',
                group: '8',
            },
            '1FA9E': {
                id: '1FA9E',
                emoji: '🪞',
                group: '8',
            },
            '1FA9F': {
                id: '1FA9F',
                emoji: '🪟',
                group: '8',
            },
            '1F6CF_FE0F': {
                id: '1F6CF_FE0F',
                emoji: '🛏️',
                group: '8',
            },
            '1F6CB_FE0F': {
                id: '1F6CB_FE0F',
                emoji: '🛋️',
                group: '8',
            },
            '1FA91': {
                id: '1FA91',
                emoji: '🪑',
                group: '8',
            },
            '1F6BD': {
                id: '1F6BD',
                emoji: '🚽',
                group: '8',
            },
            '1FAA0': {
                id: '1FAA0',
                emoji: '🪠',
                group: '8',
            },
            '1F6BF': {
                id: '1F6BF',
                emoji: '🚿',
                group: '8',
            },
            '1F6C1': {
                id: '1F6C1',
                emoji: '🛁',
                group: '8',
            },
            '1FAA4': {
                id: '1FAA4',
                emoji: '🪤',
                group: '8',
            },
            '1FA92': {
                id: '1FA92',
                emoji: '🪒',
                group: '8',
            },
            '1F9F4': {
                id: '1F9F4',
                emoji: '🧴',
                group: '8',
            },
            '1F9F7': {
                id: '1F9F7',
                emoji: '🧷',
                group: '8',
            },
            '1F9F9': {
                id: '1F9F9',
                emoji: '🧹',
                group: '8',
            },
            '1F9FA': {
                id: '1F9FA',
                emoji: '🧺',
                group: '8',
            },
            '1F9FB': {
                id: '1F9FB',
                emoji: '🧻',
                group: '8',
            },
            '1FAA3': {
                id: '1FAA3',
                emoji: '🪣',
                group: '8',
            },
            '1F9FC': {
                id: '1F9FC',
                emoji: '🧼',
                group: '8',
            },
            '1FAE7': {
                id: '1FAE7',
                emoji: '🫧',
                group: '8',
            },
            '1FAA5': {
                id: '1FAA5',
                emoji: '🪥',
                group: '8',
            },
            '1F9FD': {
                id: '1F9FD',
                emoji: '🧽',
                group: '8',
            },
            '1F9EF': {
                id: '1F9EF',
                emoji: '🧯',
                group: '8',
            },
            '1F6D2': {
                id: '1F6D2',
                emoji: '🛒',
                group: '8',
            },
            '1F6AC': {
                id: '1F6AC',
                emoji: '🚬',
                group: '8',
            },
            '26B0_FE0F': {
                id: '26B0_FE0F',
                emoji: '⚰️',
                group: '8',
            },
            '1FAA6': {
                id: '1FAA6',
                emoji: '🪦',
                group: '8',
            },
            '26B1_FE0F': {
                id: '26B1_FE0F',
                emoji: '⚱️',
                group: '8',
            },
            '1F9FF': {
                id: '1F9FF',
                emoji: '🧿',
                group: '8',
            },
            '1FAAC': {
                id: '1FAAC',
                emoji: '🪬',
                group: '8',
            },
            '1F5FF': {
                id: '1F5FF',
                emoji: '🗿',
                group: '8',
            },
            '1FAA7': {
                id: '1FAA7',
                emoji: '🪧',
                group: '8',
            },
            '1FAAA': {
                id: '1FAAA',
                emoji: '🪪',
                group: '8',
            },
            '1F3E7': {
                id: '1F3E7',
                emoji: '🏧',
                group: '9',
            },
            '1F6AE': {
                id: '1F6AE',
                emoji: '🚮',
                group: '9',
            },
            '1F6B0': {
                id: '1F6B0',
                emoji: '🚰',
                group: '9',
            },
            '267F': {
                id: '267F',
                emoji: '♿',
                group: '9',
            },
            '1F6B9': {
                id: '1F6B9',
                emoji: '🚹',
                group: '9',
            },
            '1F6BA': {
                id: '1F6BA',
                emoji: '🚺',
                group: '9',
            },
            '1F6BB': {
                id: '1F6BB',
                emoji: '🚻',
                group: '9',
            },
            '1F6BC': {
                id: '1F6BC',
                emoji: '🚼',
                group: '9',
            },
            '1F6BE': {
                id: '1F6BE',
                emoji: '🚾',
                group: '9',
            },
            '1F6C2': {
                id: '1F6C2',
                emoji: '🛂',
                group: '9',
            },
            '1F6C3': {
                id: '1F6C3',
                emoji: '🛃',
                group: '9',
            },
            '1F6C4': {
                id: '1F6C4',
                emoji: '🛄',
                group: '9',
            },
            '1F6C5': {
                id: '1F6C5',
                emoji: '🛅',
                group: '9',
            },
            '26A0_FE0F': {
                id: '26A0_FE0F',
                emoji: '⚠️',
                group: '9',
            },
            '1F6B8': {
                id: '1F6B8',
                emoji: '🚸',
                group: '9',
            },
            '26D4': {
                id: '26D4',
                emoji: '⛔',
                group: '9',
            },
            '1F6AB': {
                id: '1F6AB',
                emoji: '🚫',
                group: '9',
            },
            '1F6B3': {
                id: '1F6B3',
                emoji: '🚳',
                group: '9',
            },
            '1F6AD': {
                id: '1F6AD',
                emoji: '🚭',
                group: '9',
            },
            '1F6AF': {
                id: '1F6AF',
                emoji: '🚯',
                group: '9',
            },
            '1F6B1': {
                id: '1F6B1',
                emoji: '🚱',
                group: '9',
            },
            '1F6B7': {
                id: '1F6B7',
                emoji: '🚷',
                group: '9',
            },
            '1F4F5': {
                id: '1F4F5',
                emoji: '📵',
                group: '9',
            },
            '1F51E': {
                id: '1F51E',
                emoji: '🔞',
                group: '9',
            },
            '2622_FE0F': {
                id: '2622_FE0F',
                emoji: '☢️',
                group: '9',
            },
            '2623_FE0F': {
                id: '2623_FE0F',
                emoji: '☣️',
                group: '9',
            },
            '2B06_FE0F': {
                id: '2B06_FE0F',
                emoji: '⬆️',
                group: '9',
            },
            '2197_FE0F': {
                id: '2197_FE0F',
                emoji: '↗️',
                group: '9',
            },
            '27A1_FE0F': {
                id: '27A1_FE0F',
                emoji: '➡️',
                group: '9',
            },
            '2198_FE0F': {
                id: '2198_FE0F',
                emoji: '↘️',
                group: '9',
            },
            '2B07_FE0F': {
                id: '2B07_FE0F',
                emoji: '⬇️',
                group: '9',
            },
            '2199_FE0F': {
                id: '2199_FE0F',
                emoji: '↙️',
                group: '9',
            },
            '2B05_FE0F': {
                id: '2B05_FE0F',
                emoji: '⬅️',
                group: '9',
            },
            '2196_FE0F': {
                id: '2196_FE0F',
                emoji: '↖️',
                group: '9',
            },
            '2195_FE0F': {
                id: '2195_FE0F',
                emoji: '↕️',
                group: '9',
            },
            '2194_FE0F': {
                id: '2194_FE0F',
                emoji: '↔️',
                group: '9',
            },
            '21A9_FE0F': {
                id: '21A9_FE0F',
                emoji: '↩️',
                group: '9',
            },
            '21AA_FE0F': {
                id: '21AA_FE0F',
                emoji: '↪️',
                group: '9',
            },
            '2934_FE0F': {
                id: '2934_FE0F',
                emoji: '⤴️',
                group: '9',
            },
            '2935_FE0F': {
                id: '2935_FE0F',
                emoji: '⤵️',
                group: '9',
            },
            '1F503': {
                id: '1F503',
                emoji: '🔃',
                group: '9',
            },
            '1F504': {
                id: '1F504',
                emoji: '🔄',
                group: '9',
            },
            '1F519': {
                id: '1F519',
                emoji: '🔙',
                group: '9',
            },
            '1F51A': {
                id: '1F51A',
                emoji: '🔚',
                group: '9',
            },
            '1F51B': {
                id: '1F51B',
                emoji: '🔛',
                group: '9',
            },
            '1F51C': {
                id: '1F51C',
                emoji: '🔜',
                group: '9',
            },
            '1F51D': {
                id: '1F51D',
                emoji: '🔝',
                group: '9',
            },
            '1F6D0': {
                id: '1F6D0',
                emoji: '🛐',
                group: '9',
            },
            '269B_FE0F': {
                id: '269B_FE0F',
                emoji: '⚛️',
                group: '9',
            },
            '1F549_FE0F': {
                id: '1F549_FE0F',
                emoji: '🕉️',
                group: '9',
            },
            '2721_FE0F': {
                id: '2721_FE0F',
                emoji: '✡️',
                group: '9',
            },
            '2638_FE0F': {
                id: '2638_FE0F',
                emoji: '☸️',
                group: '9',
            },
            '262F_FE0F': {
                id: '262F_FE0F',
                emoji: '☯️',
                group: '9',
            },
            '271D_FE0F': {
                id: '271D_FE0F',
                emoji: '✝️',
                group: '9',
            },
            '2626_FE0F': {
                id: '2626_FE0F',
                emoji: '☦️',
                group: '9',
            },
            '262A_FE0F': {
                id: '262A_FE0F',
                emoji: '☪️',
                group: '9',
            },
            '262E_FE0F': {
                id: '262E_FE0F',
                emoji: '☮️',
                group: '9',
            },
            '1F54E': {
                id: '1F54E',
                emoji: '🕎',
                group: '9',
            },
            '1F52F': {
                id: '1F52F',
                emoji: '🔯',
                group: '9',
            },
            '1FAAF': {
                id: '1FAAF',
                emoji: '🪯',
                group: '9',
            },
            '2648': {
                id: '2648',
                emoji: '♈',
                group: '9',
            },
            '2649': {
                id: '2649',
                emoji: '♉',
                group: '9',
            },
            '264A': {
                id: '264A',
                emoji: '♊',
                group: '9',
            },
            '264B': {
                id: '264B',
                emoji: '♋',
                group: '9',
            },
            '264C': {
                id: '264C',
                emoji: '♌',
                group: '9',
            },
            '264D': {
                id: '264D',
                emoji: '♍',
                group: '9',
            },
            '264E': {
                id: '264E',
                emoji: '♎',
                group: '9',
            },
            '264F': {
                id: '264F',
                emoji: '♏',
                group: '9',
            },
            '2650': {
                id: '2650',
                emoji: '♐',
                group: '9',
            },
            '2651': {
                id: '2651',
                emoji: '♑',
                group: '9',
            },
            '2652': {
                id: '2652',
                emoji: '♒',
                group: '9',
            },
            '2653': {
                id: '2653',
                emoji: '♓',
                group: '9',
            },
            '26CE': {
                id: '26CE',
                emoji: '⛎',
                group: '9',
            },
            '1F500': {
                id: '1F500',
                emoji: '🔀',
                group: '9',
            },
            '1F501': {
                id: '1F501',
                emoji: '🔁',
                group: '9',
            },
            '1F502': {
                id: '1F502',
                emoji: '🔂',
                group: '9',
            },
            '25B6_FE0F': {
                id: '25B6_FE0F',
                emoji: '▶️',
                group: '9',
            },
            '23E9': {
                id: '23E9',
                emoji: '⏩',
                group: '9',
            },
            '23ED_FE0F': {
                id: '23ED_FE0F',
                emoji: '⏭️',
                group: '9',
            },
            '23EF_FE0F': {
                id: '23EF_FE0F',
                emoji: '⏯️',
                group: '9',
            },
            '25C0_FE0F': {
                id: '25C0_FE0F',
                emoji: '◀️',
                group: '9',
            },
            '23EA': {
                id: '23EA',
                emoji: '⏪',
                group: '9',
            },
            '23EE_FE0F': {
                id: '23EE_FE0F',
                emoji: '⏮️',
                group: '9',
            },
            '1F53C': {
                id: '1F53C',
                emoji: '🔼',
                group: '9',
            },
            '23EB': {
                id: '23EB',
                emoji: '⏫',
                group: '9',
            },
            '1F53D': {
                id: '1F53D',
                emoji: '🔽',
                group: '9',
            },
            '23EC': {
                id: '23EC',
                emoji: '⏬',
                group: '9',
            },
            '23F8_FE0F': {
                id: '23F8_FE0F',
                emoji: '⏸️',
                group: '9',
            },
            '23F9_FE0F': {
                id: '23F9_FE0F',
                emoji: '⏹️',
                group: '9',
            },
            '23FA_FE0F': {
                id: '23FA_FE0F',
                emoji: '⏺️',
                group: '9',
            },
            '23CF_FE0F': {
                id: '23CF_FE0F',
                emoji: '⏏️',
                group: '9',
            },
            '1F3A6': {
                id: '1F3A6',
                emoji: '🎦',
                group: '9',
            },
            '1F505': {
                id: '1F505',
                emoji: '🔅',
                group: '9',
            },
            '1F506': {
                id: '1F506',
                emoji: '🔆',
                group: '9',
            },
            '1F4F6': {
                id: '1F4F6',
                emoji: '📶',
                group: '9',
            },
            '1F6DC': {
                id: '1F6DC',
                emoji: '🛜',
                group: '9',
            },
            '1F4F3': {
                id: '1F4F3',
                emoji: '📳',
                group: '9',
            },
            '1F4F4': {
                id: '1F4F4',
                emoji: '📴',
                group: '9',
            },
            '2640_FE0F': {
                id: '2640_FE0F',
                emoji: '♀️',
                group: '9',
            },
            '2642_FE0F': {
                id: '2642_FE0F',
                emoji: '♂️',
                group: '9',
            },
            '26A7_FE0F': {
                id: '26A7_FE0F',
                emoji: '⚧️',
                group: '9',
            },
            '2716_FE0F': {
                id: '2716_FE0F',
                emoji: '✖️',
                group: '9',
            },
            '2795': {
                id: '2795',
                emoji: '➕',
                group: '9',
            },
            '2796': {
                id: '2796',
                emoji: '➖',
                group: '9',
            },
            '2797': {
                id: '2797',
                emoji: '➗',
                group: '9',
            },
            '1F7F0': {
                id: '1F7F0',
                emoji: '🟰',
                group: '9',
            },
            '267E_FE0F': {
                id: '267E_FE0F',
                emoji: '♾️',
                group: '9',
            },
            '203C_FE0F': {
                id: '203C_FE0F',
                emoji: '‼️',
                group: '9',
            },
            '2049_FE0F': {
                id: '2049_FE0F',
                emoji: '⁉️',
                group: '9',
            },
            '2753': {
                id: '2753',
                emoji: '❓',
                group: '9',
            },
            '2754': {
                id: '2754',
                emoji: '❔',
                group: '9',
            },
            '2755': {
                id: '2755',
                emoji: '❕',
                group: '9',
            },
            '2757': {
                id: '2757',
                emoji: '❗',
                group: '9',
            },
            '3030_FE0F': {
                id: '3030_FE0F',
                emoji: '〰️',
                group: '9',
            },
            '1F4B1': {
                id: '1F4B1',
                emoji: '💱',
                group: '9',
            },
            '1F4B2': {
                id: '1F4B2',
                emoji: '💲',
                group: '9',
            },
            '2695_FE0F': {
                id: '2695_FE0F',
                emoji: '⚕️',
                group: '9',
            },
            '267B_FE0F': {
                id: '267B_FE0F',
                emoji: '♻️',
                group: '9',
            },
            '269C_FE0F': {
                id: '269C_FE0F',
                emoji: '⚜️',
                group: '9',
            },
            '1F531': {
                id: '1F531',
                emoji: '🔱',
                group: '9',
            },
            '1F4DB': {
                id: '1F4DB',
                emoji: '📛',
                group: '9',
            },
            '1F530': {
                id: '1F530',
                emoji: '🔰',
                group: '9',
            },
            '2B55': {
                id: '2B55',
                emoji: '⭕',
                group: '9',
            },
            '2705': {
                id: '2705',
                emoji: '✅',
                group: '9',
            },
            '2611_FE0F': {
                id: '2611_FE0F',
                emoji: '☑️',
                group: '9',
            },
            '2714_FE0F': {
                id: '2714_FE0F',
                emoji: '✔️',
                group: '9',
            },
            '274C': {
                id: '274C',
                emoji: '❌',
                group: '9',
            },
            '274E': {
                id: '274E',
                emoji: '❎',
                group: '9',
            },
            '27B0': {
                id: '27B0',
                emoji: '➰',
                group: '9',
            },
            '27BF': {
                id: '27BF',
                emoji: '➿',
                group: '9',
            },
            '303D_FE0F': {
                id: '303D_FE0F',
                emoji: '〽️',
                group: '9',
            },
            '2733_FE0F': {
                id: '2733_FE0F',
                emoji: '✳️',
                group: '9',
            },
            '2734_FE0F': {
                id: '2734_FE0F',
                emoji: '✴️',
                group: '9',
            },
            '2747_FE0F': {
                id: '2747_FE0F',
                emoji: '❇️',
                group: '9',
            },
            '00A9_FE0F': {
                id: '00A9_FE0F',
                emoji: '©️',
                group: '9',
            },
            '00AE_FE0F': {
                id: '00AE_FE0F',
                emoji: '®️',
                group: '9',
            },
            '2122_FE0F': {
                id: '2122_FE0F',
                emoji: '™️',
                group: '9',
            },
            '0023_FE0F_20E3': {
                id: '0023_FE0F_20E3',
                emoji: '#️⃣',
                group: '9',
            },
            '002A_FE0F_20E3': {
                id: '002A_FE0F_20E3',
                emoji: '*️⃣',
                group: '9',
            },
            '0030_FE0F_20E3': {
                id: '0030_FE0F_20E3',
                emoji: '0️⃣',
                group: '9',
            },
            '0031_FE0F_20E3': {
                id: '0031_FE0F_20E3',
                emoji: '1️⃣',
                group: '9',
            },
            '0032_FE0F_20E3': {
                id: '0032_FE0F_20E3',
                emoji: '2️⃣',
                group: '9',
            },
            '0033_FE0F_20E3': {
                id: '0033_FE0F_20E3',
                emoji: '3️⃣',
                group: '9',
            },
            '0034_FE0F_20E3': {
                id: '0034_FE0F_20E3',
                emoji: '4️⃣',
                group: '9',
            },
            '0035_FE0F_20E3': {
                id: '0035_FE0F_20E3',
                emoji: '5️⃣',
                group: '9',
            },
            '0036_FE0F_20E3': {
                id: '0036_FE0F_20E3',
                emoji: '6️⃣',
                group: '9',
            },
            '0037_FE0F_20E3': {
                id: '0037_FE0F_20E3',
                emoji: '7️⃣',
                group: '9',
            },
            '0038_FE0F_20E3': {
                id: '0038_FE0F_20E3',
                emoji: '8️⃣',
                group: '9',
            },
            '0039_FE0F_20E3': {
                id: '0039_FE0F_20E3',
                emoji: '9️⃣',
                group: '9',
            },
            '1F51F': {
                id: '1F51F',
                emoji: '🔟',
                group: '9',
            },
            '1F520': {
                id: '1F520',
                emoji: '🔠',
                group: '9',
            },
            '1F521': {
                id: '1F521',
                emoji: '🔡',
                group: '9',
            },
            '1F522': {
                id: '1F522',
                emoji: '🔢',
                group: '9',
            },
            '1F523': {
                id: '1F523',
                emoji: '🔣',
                group: '9',
            },
            '1F524': {
                id: '1F524',
                emoji: '🔤',
                group: '9',
            },
            '1F170_FE0F': {
                id: '1F170_FE0F',
                emoji: '🅰️',
                group: '9',
            },
            '1F18E': {
                id: '1F18E',
                emoji: '🆎',
                group: '9',
            },
            '1F171_FE0F': {
                id: '1F171_FE0F',
                emoji: '🅱️',
                group: '9',
            },
            '1F191': {
                id: '1F191',
                emoji: '🆑',
                group: '9',
            },
            '1F192': {
                id: '1F192',
                emoji: '🆒',
                group: '9',
            },
            '1F193': {
                id: '1F193',
                emoji: '🆓',
                group: '9',
            },
            '2139_FE0F': {
                id: '2139_FE0F',
                emoji: 'ℹ️',
                group: '9',
            },
            '1F194': {
                id: '1F194',
                emoji: '🆔',
                group: '9',
            },
            '24C2_FE0F': {
                id: '24C2_FE0F',
                emoji: 'Ⓜ️',
                group: '9',
            },
            '1F195': {
                id: '1F195',
                emoji: '🆕',
                group: '9',
            },
            '1F196': {
                id: '1F196',
                emoji: '🆖',
                group: '9',
            },
            '1F17E_FE0F': {
                id: '1F17E_FE0F',
                emoji: '🅾️',
                group: '9',
            },
            '1F197': {
                id: '1F197',
                emoji: '🆗',
                group: '9',
            },
            '1F17F_FE0F': {
                id: '1F17F_FE0F',
                emoji: '🅿️',
                group: '9',
            },
            '1F198': {
                id: '1F198',
                emoji: '🆘',
                group: '9',
            },
            '1F199': {
                id: '1F199',
                emoji: '🆙',
                group: '9',
            },
            '1F19A': {
                id: '1F19A',
                emoji: '🆚',
                group: '9',
            },
            '1F201': {
                id: '1F201',
                emoji: '🈁',
                group: '9',
            },
            '1F202_FE0F': {
                id: '1F202_FE0F',
                emoji: '🈂️',
                group: '9',
            },
            '1F237_FE0F': {
                id: '1F237_FE0F',
                emoji: '🈷️',
                group: '9',
            },
            '1F236': {
                id: '1F236',
                emoji: '🈶',
                group: '9',
            },
            '1F22F': {
                id: '1F22F',
                emoji: '🈯',
                group: '9',
            },
            '1F250': {
                id: '1F250',
                emoji: '🉐',
                group: '9',
            },
            '1F239': {
                id: '1F239',
                emoji: '🈹',
                group: '9',
            },
            '1F21A': {
                id: '1F21A',
                emoji: '🈚',
                group: '9',
            },
            '1F232': {
                id: '1F232',
                emoji: '🈲',
                group: '9',
            },
            '1F251': {
                id: '1F251',
                emoji: '🉑',
                group: '9',
            },
            '1F238': {
                id: '1F238',
                emoji: '🈸',
                group: '9',
            },
            '1F234': {
                id: '1F234',
                emoji: '🈴',
                group: '9',
            },
            '1F233': {
                id: '1F233',
                emoji: '🈳',
                group: '9',
            },
            '3297_FE0F': {
                id: '3297_FE0F',
                emoji: '㊗️',
                group: '9',
            },
            '3299_FE0F': {
                id: '3299_FE0F',
                emoji: '㊙️',
                group: '9',
            },
            '1F23A': {
                id: '1F23A',
                emoji: '🈺',
                group: '9',
            },
            '1F235': {
                id: '1F235',
                emoji: '🈵',
                group: '9',
            },
            '1F534': {
                id: '1F534',
                emoji: '🔴',
                group: '9',
            },
            '1F7E0': {
                id: '1F7E0',
                emoji: '🟠',
                group: '9',
            },
            '1F7E1': {
                id: '1F7E1',
                emoji: '🟡',
                group: '9',
            },
            '1F7E2': {
                id: '1F7E2',
                emoji: '🟢',
                group: '9',
            },
            '1F535': {
                id: '1F535',
                emoji: '🔵',
                group: '9',
            },
            '1F7E3': {
                id: '1F7E3',
                emoji: '🟣',
                group: '9',
            },
            '1F7E4': {
                id: '1F7E4',
                emoji: '🟤',
                group: '9',
            },
            '26AB': {
                id: '26AB',
                emoji: '⚫',
                group: '9',
            },
            '26AA': {
                id: '26AA',
                emoji: '⚪',
                group: '9',
            },
            '1F7E5': {
                id: '1F7E5',
                emoji: '🟥',
                group: '9',
            },
            '1F7E7': {
                id: '1F7E7',
                emoji: '🟧',
                group: '9',
            },
            '1F7E8': {
                id: '1F7E8',
                emoji: '🟨',
                group: '9',
            },
            '1F7E9': {
                id: '1F7E9',
                emoji: '🟩',
                group: '9',
            },
            '1F7E6': {
                id: '1F7E6',
                emoji: '🟦',
                group: '9',
            },
            '1F7EA': {
                id: '1F7EA',
                emoji: '🟪',
                group: '9',
            },
            '1F7EB': {
                id: '1F7EB',
                emoji: '🟫',
                group: '9',
            },
            '2B1B': {
                id: '2B1B',
                emoji: '⬛',
                group: '9',
            },
            '2B1C': {
                id: '2B1C',
                emoji: '⬜',
                group: '9',
            },
            '25FC_FE0F': {
                id: '25FC_FE0F',
                emoji: '◼️',
                group: '9',
            },
            '25FB_FE0F': {
                id: '25FB_FE0F',
                emoji: '◻️',
                group: '9',
            },
            '25FE': {
                id: '25FE',
                emoji: '◾',
                group: '9',
            },
            '25FD': {
                id: '25FD',
                emoji: '◽',
                group: '9',
            },
            '25AA_FE0F': {
                id: '25AA_FE0F',
                emoji: '▪️',
                group: '9',
            },
            '25AB_FE0F': {
                id: '25AB_FE0F',
                emoji: '▫️',
                group: '9',
            },
            '1F536': {
                id: '1F536',
                emoji: '🔶',
                group: '9',
            },
            '1F537': {
                id: '1F537',
                emoji: '🔷',
                group: '9',
            },
            '1F538': {
                id: '1F538',
                emoji: '🔸',
                group: '9',
            },
            '1F539': {
                id: '1F539',
                emoji: '🔹',
                group: '9',
            },
            '1F53A': {
                id: '1F53A',
                emoji: '🔺',
                group: '9',
            },
            '1F53B': {
                id: '1F53B',
                emoji: '🔻',
                group: '9',
            },
            '1F4A0': {
                id: '1F4A0',
                emoji: '💠',
                group: '9',
            },
            '1F518': {
                id: '1F518',
                emoji: '🔘',
                group: '9',
            },
            '1F533': {
                id: '1F533',
                emoji: '🔳',
                group: '9',
            },
            '1F532': {
                id: '1F532',
                emoji: '🔲',
                group: '9',
            },
            '1F3C1': {
                id: '1F3C1',
                emoji: '🏁',
                group: '10',
            },
            '1F6A9': {
                id: '1F6A9',
                emoji: '🚩',
                group: '10',
            },
            '1F38C': {
                id: '1F38C',
                emoji: '🎌',
                group: '10',
            },
            '1F3F4': {
                id: '1F3F4',
                emoji: '🏴',
                group: '10',
            },
            '1F3F3_FE0F': {
                id: '1F3F3_FE0F',
                emoji: '🏳️',
                group: '10',
            },
            '1F3F3_FE0F_200D_1F308': {
                id: '1F3F3_FE0F_200D_1F308',
                emoji: '🏳️‍🌈',
                group: '10',
            },
            '1F3F3_FE0F_200D_26A7_FE0F': {
                id: '1F3F3_FE0F_200D_26A7_FE0F',
                emoji: '🏳️‍⚧️',
                group: '10',
            },
            '1F3F4_200D_2620_FE0F': {
                id: '1F3F4_200D_2620_FE0F',
                emoji: '🏴‍☠️',
                group: '10',
            },
            '1F1E6_1F1E8': {
                id: '1F1E6_1F1E8',
                emoji: '🇦🇨',
                group: '10',
            },
            '1F1E6_1F1E9': {
                id: '1F1E6_1F1E9',
                emoji: '🇦🇩',
                group: '10',
            },
            '1F1E6_1F1EA': {
                id: '1F1E6_1F1EA',
                emoji: '🇦🇪',
                group: '10',
            },
            '1F1E6_1F1EB': {
                id: '1F1E6_1F1EB',
                emoji: '🇦🇫',
                group: '10',
            },
            '1F1E6_1F1EC': {
                id: '1F1E6_1F1EC',
                emoji: '🇦🇬',
                group: '10',
            },
            '1F1E6_1F1EE': {
                id: '1F1E6_1F1EE',
                emoji: '🇦🇮',
                group: '10',
            },
            '1F1E6_1F1F1': {
                id: '1F1E6_1F1F1',
                emoji: '🇦🇱',
                group: '10',
            },
            '1F1E6_1F1F2': {
                id: '1F1E6_1F1F2',
                emoji: '🇦🇲',
                group: '10',
            },
            '1F1E6_1F1F4': {
                id: '1F1E6_1F1F4',
                emoji: '🇦🇴',
                group: '10',
            },
            '1F1E6_1F1F6': {
                id: '1F1E6_1F1F6',
                emoji: '🇦🇶',
                group: '10',
            },
            '1F1E6_1F1F7': {
                id: '1F1E6_1F1F7',
                emoji: '🇦🇷',
                group: '10',
            },
            '1F1E6_1F1F8': {
                id: '1F1E6_1F1F8',
                emoji: '🇦🇸',
                group: '10',
            },
            '1F1E6_1F1F9': {
                id: '1F1E6_1F1F9',
                emoji: '🇦🇹',
                group: '10',
            },
            '1F1E6_1F1FA': {
                id: '1F1E6_1F1FA',
                emoji: '🇦🇺',
                group: '10',
            },
            '1F1E6_1F1FC': {
                id: '1F1E6_1F1FC',
                emoji: '🇦🇼',
                group: '10',
            },
            '1F1E6_1F1FD': {
                id: '1F1E6_1F1FD',
                emoji: '🇦🇽',
                group: '10',
            },
            '1F1E6_1F1FF': {
                id: '1F1E6_1F1FF',
                emoji: '🇦🇿',
                group: '10',
            },
            '1F1E7_1F1E6': {
                id: '1F1E7_1F1E6',
                emoji: '🇧🇦',
                group: '10',
            },
            '1F1E7_1F1E7': {
                id: '1F1E7_1F1E7',
                emoji: '🇧🇧',
                group: '10',
            },
            '1F1E7_1F1E9': {
                id: '1F1E7_1F1E9',
                emoji: '🇧🇩',
                group: '10',
            },
            '1F1E7_1F1EA': {
                id: '1F1E7_1F1EA',
                emoji: '🇧🇪',
                group: '10',
            },
            '1F1E7_1F1EB': {
                id: '1F1E7_1F1EB',
                emoji: '🇧🇫',
                group: '10',
            },
            '1F1E7_1F1EC': {
                id: '1F1E7_1F1EC',
                emoji: '🇧🇬',
                group: '10',
            },
            '1F1E7_1F1ED': {
                id: '1F1E7_1F1ED',
                emoji: '🇧🇭',
                group: '10',
            },
            '1F1E7_1F1EE': {
                id: '1F1E7_1F1EE',
                emoji: '🇧🇮',
                group: '10',
            },
            '1F1E7_1F1EF': {
                id: '1F1E7_1F1EF',
                emoji: '🇧🇯',
                group: '10',
            },
            '1F1E7_1F1F1': {
                id: '1F1E7_1F1F1',
                emoji: '🇧🇱',
                group: '10',
            },
            '1F1E7_1F1F2': {
                id: '1F1E7_1F1F2',
                emoji: '🇧🇲',
                group: '10',
            },
            '1F1E7_1F1F3': {
                id: '1F1E7_1F1F3',
                emoji: '🇧🇳',
                group: '10',
            },
            '1F1E7_1F1F4': {
                id: '1F1E7_1F1F4',
                emoji: '🇧🇴',
                group: '10',
            },
            '1F1E7_1F1F6': {
                id: '1F1E7_1F1F6',
                emoji: '🇧🇶',
                group: '10',
            },
            '1F1E7_1F1F7': {
                id: '1F1E7_1F1F7',
                emoji: '🇧🇷',
                group: '10',
            },
            '1F1E7_1F1F8': {
                id: '1F1E7_1F1F8',
                emoji: '🇧🇸',
                group: '10',
            },
            '1F1E7_1F1F9': {
                id: '1F1E7_1F1F9',
                emoji: '🇧🇹',
                group: '10',
            },
            '1F1E7_1F1FB': {
                id: '1F1E7_1F1FB',
                emoji: '🇧🇻',
                group: '10',
            },
            '1F1E7_1F1FC': {
                id: '1F1E7_1F1FC',
                emoji: '🇧🇼',
                group: '10',
            },
            '1F1E7_1F1FE': {
                id: '1F1E7_1F1FE',
                emoji: '🇧🇾',
                group: '10',
            },
            '1F1E7_1F1FF': {
                id: '1F1E7_1F1FF',
                emoji: '🇧🇿',
                group: '10',
            },
            '1F1E8_1F1E6': {
                id: '1F1E8_1F1E6',
                emoji: '🇨🇦',
                group: '10',
            },
            '1F1E8_1F1E8': {
                id: '1F1E8_1F1E8',
                emoji: '🇨🇨',
                group: '10',
            },
            '1F1E8_1F1E9': {
                id: '1F1E8_1F1E9',
                emoji: '🇨🇩',
                group: '10',
            },
            '1F1E8_1F1EB': {
                id: '1F1E8_1F1EB',
                emoji: '🇨🇫',
                group: '10',
            },
            '1F1E8_1F1EC': {
                id: '1F1E8_1F1EC',
                emoji: '🇨🇬',
                group: '10',
            },
            '1F1E8_1F1ED': {
                id: '1F1E8_1F1ED',
                emoji: '🇨🇭',
                group: '10',
            },
            '1F1E8_1F1EE': {
                id: '1F1E8_1F1EE',
                emoji: '🇨🇮',
                group: '10',
            },
            '1F1E8_1F1F0': {
                id: '1F1E8_1F1F0',
                emoji: '🇨🇰',
                group: '10',
            },
            '1F1E8_1F1F1': {
                id: '1F1E8_1F1F1',
                emoji: '🇨🇱',
                group: '10',
            },
            '1F1E8_1F1F2': {
                id: '1F1E8_1F1F2',
                emoji: '🇨🇲',
                group: '10',
            },
            '1F1E8_1F1F3': {
                id: '1F1E8_1F1F3',
                emoji: '🇨🇳',
                group: '10',
            },
            '1F1E8_1F1F4': {
                id: '1F1E8_1F1F4',
                emoji: '🇨🇴',
                group: '10',
            },
            '1F1E8_1F1F5': {
                id: '1F1E8_1F1F5',
                emoji: '🇨🇵',
                group: '10',
            },
            '1F1E8_1F1F7': {
                id: '1F1E8_1F1F7',
                emoji: '🇨🇷',
                group: '10',
            },
            '1F1E8_1F1FA': {
                id: '1F1E8_1F1FA',
                emoji: '🇨🇺',
                group: '10',
            },
            '1F1E8_1F1FB': {
                id: '1F1E8_1F1FB',
                emoji: '🇨🇻',
                group: '10',
            },
            '1F1E8_1F1FC': {
                id: '1F1E8_1F1FC',
                emoji: '🇨🇼',
                group: '10',
            },
            '1F1E8_1F1FD': {
                id: '1F1E8_1F1FD',
                emoji: '🇨🇽',
                group: '10',
            },
            '1F1E8_1F1FE': {
                id: '1F1E8_1F1FE',
                emoji: '🇨🇾',
                group: '10',
            },
            '1F1E8_1F1FF': {
                id: '1F1E8_1F1FF',
                emoji: '🇨🇿',
                group: '10',
            },
            '1F1E9_1F1EA': {
                id: '1F1E9_1F1EA',
                emoji: '🇩🇪',
                group: '10',
            },
            '1F1E9_1F1EC': {
                id: '1F1E9_1F1EC',
                emoji: '🇩🇬',
                group: '10',
            },
            '1F1E9_1F1EF': {
                id: '1F1E9_1F1EF',
                emoji: '🇩🇯',
                group: '10',
            },
            '1F1E9_1F1F0': {
                id: '1F1E9_1F1F0',
                emoji: '🇩🇰',
                group: '10',
            },
            '1F1E9_1F1F2': {
                id: '1F1E9_1F1F2',
                emoji: '🇩🇲',
                group: '10',
            },
            '1F1E9_1F1F4': {
                id: '1F1E9_1F1F4',
                emoji: '🇩🇴',
                group: '10',
            },
            '1F1E9_1F1FF': {
                id: '1F1E9_1F1FF',
                emoji: '🇩🇿',
                group: '10',
            },
            '1F1EA_1F1E6': {
                id: '1F1EA_1F1E6',
                emoji: '🇪🇦',
                group: '10',
            },
            '1F1EA_1F1E8': {
                id: '1F1EA_1F1E8',
                emoji: '🇪🇨',
                group: '10',
            },
            '1F1EA_1F1EA': {
                id: '1F1EA_1F1EA',
                emoji: '🇪🇪',
                group: '10',
            },
            '1F1EA_1F1EC': {
                id: '1F1EA_1F1EC',
                emoji: '🇪🇬',
                group: '10',
            },
            '1F1EA_1F1ED': {
                id: '1F1EA_1F1ED',
                emoji: '🇪🇭',
                group: '10',
            },
            '1F1EA_1F1F7': {
                id: '1F1EA_1F1F7',
                emoji: '🇪🇷',
                group: '10',
            },
            '1F1EA_1F1F8': {
                id: '1F1EA_1F1F8',
                emoji: '🇪🇸',
                group: '10',
            },
            '1F1EA_1F1F9': {
                id: '1F1EA_1F1F9',
                emoji: '🇪🇹',
                group: '10',
            },
            '1F1EA_1F1FA': {
                id: '1F1EA_1F1FA',
                emoji: '🇪🇺',
                group: '10',
            },
            '1F1EB_1F1EE': {
                id: '1F1EB_1F1EE',
                emoji: '🇫🇮',
                group: '10',
            },
            '1F1EB_1F1EF': {
                id: '1F1EB_1F1EF',
                emoji: '🇫🇯',
                group: '10',
            },
            '1F1EB_1F1F0': {
                id: '1F1EB_1F1F0',
                emoji: '🇫🇰',
                group: '10',
            },
            '1F1EB_1F1F2': {
                id: '1F1EB_1F1F2',
                emoji: '🇫🇲',
                group: '10',
            },
            '1F1EB_1F1F4': {
                id: '1F1EB_1F1F4',
                emoji: '🇫🇴',
                group: '10',
            },
            '1F1EB_1F1F7': {
                id: '1F1EB_1F1F7',
                emoji: '🇫🇷',
                group: '10',
            },
            '1F1EC_1F1E6': {
                id: '1F1EC_1F1E6',
                emoji: '🇬🇦',
                group: '10',
            },
            '1F1EC_1F1E7': {
                id: '1F1EC_1F1E7',
                emoji: '🇬🇧',
                group: '10',
            },
            '1F1EC_1F1E9': {
                id: '1F1EC_1F1E9',
                emoji: '🇬🇩',
                group: '10',
            },
            '1F1EC_1F1EA': {
                id: '1F1EC_1F1EA',
                emoji: '🇬🇪',
                group: '10',
            },
            '1F1EC_1F1EB': {
                id: '1F1EC_1F1EB',
                emoji: '🇬🇫',
                group: '10',
            },
            '1F1EC_1F1EC': {
                id: '1F1EC_1F1EC',
                emoji: '🇬🇬',
                group: '10',
            },
            '1F1EC_1F1ED': {
                id: '1F1EC_1F1ED',
                emoji: '🇬🇭',
                group: '10',
            },
            '1F1EC_1F1EE': {
                id: '1F1EC_1F1EE',
                emoji: '🇬🇮',
                group: '10',
            },
            '1F1EC_1F1F1': {
                id: '1F1EC_1F1F1',
                emoji: '🇬🇱',
                group: '10',
            },
            '1F1EC_1F1F2': {
                id: '1F1EC_1F1F2',
                emoji: '🇬🇲',
                group: '10',
            },
            '1F1EC_1F1F3': {
                id: '1F1EC_1F1F3',
                emoji: '🇬🇳',
                group: '10',
            },
            '1F1EC_1F1F5': {
                id: '1F1EC_1F1F5',
                emoji: '🇬🇵',
                group: '10',
            },
            '1F1EC_1F1F6': {
                id: '1F1EC_1F1F6',
                emoji: '🇬🇶',
                group: '10',
            },
            '1F1EC_1F1F7': {
                id: '1F1EC_1F1F7',
                emoji: '🇬🇷',
                group: '10',
            },
            '1F1EC_1F1F8': {
                id: '1F1EC_1F1F8',
                emoji: '🇬🇸',
                group: '10',
            },
            '1F1EC_1F1F9': {
                id: '1F1EC_1F1F9',
                emoji: '🇬🇹',
                group: '10',
            },
            '1F1EC_1F1FA': {
                id: '1F1EC_1F1FA',
                emoji: '🇬🇺',
                group: '10',
            },
            '1F1EC_1F1FC': {
                id: '1F1EC_1F1FC',
                emoji: '🇬🇼',
                group: '10',
            },
            '1F1EC_1F1FE': {
                id: '1F1EC_1F1FE',
                emoji: '🇬🇾',
                group: '10',
            },
            '1F1ED_1F1F0': {
                id: '1F1ED_1F1F0',
                emoji: '🇭🇰',
                group: '10',
            },
            '1F1ED_1F1F2': {
                id: '1F1ED_1F1F2',
                emoji: '🇭🇲',
                group: '10',
            },
            '1F1ED_1F1F3': {
                id: '1F1ED_1F1F3',
                emoji: '🇭🇳',
                group: '10',
            },
            '1F1ED_1F1F7': {
                id: '1F1ED_1F1F7',
                emoji: '🇭🇷',
                group: '10',
            },
            '1F1ED_1F1F9': {
                id: '1F1ED_1F1F9',
                emoji: '🇭🇹',
                group: '10',
            },
            '1F1ED_1F1FA': {
                id: '1F1ED_1F1FA',
                emoji: '🇭🇺',
                group: '10',
            },
            '1F1EE_1F1E8': {
                id: '1F1EE_1F1E8',
                emoji: '🇮🇨',
                group: '10',
            },
            '1F1EE_1F1E9': {
                id: '1F1EE_1F1E9',
                emoji: '🇮🇩',
                group: '10',
            },
            '1F1EE_1F1EA': {
                id: '1F1EE_1F1EA',
                emoji: '🇮🇪',
                group: '10',
            },
            '1F1EE_1F1F1': {
                id: '1F1EE_1F1F1',
                emoji: '🇮🇱',
                group: '10',
            },
            '1F1EE_1F1F2': {
                id: '1F1EE_1F1F2',
                emoji: '🇮🇲',
                group: '10',
            },
            '1F1EE_1F1F3': {
                id: '1F1EE_1F1F3',
                emoji: '🇮🇳',
                group: '10',
            },
            '1F1EE_1F1F4': {
                id: '1F1EE_1F1F4',
                emoji: '🇮🇴',
                group: '10',
            },
            '1F1EE_1F1F6': {
                id: '1F1EE_1F1F6',
                emoji: '🇮🇶',
                group: '10',
            },
            '1F1EE_1F1F7': {
                id: '1F1EE_1F1F7',
                emoji: '🇮🇷',
                group: '10',
            },
            '1F1EE_1F1F8': {
                id: '1F1EE_1F1F8',
                emoji: '🇮🇸',
                group: '10',
            },
            '1F1EE_1F1F9': {
                id: '1F1EE_1F1F9',
                emoji: '🇮🇹',
                group: '10',
            },
            '1F1EF_1F1EA': {
                id: '1F1EF_1F1EA',
                emoji: '🇯🇪',
                group: '10',
            },
            '1F1EF_1F1F2': {
                id: '1F1EF_1F1F2',
                emoji: '🇯🇲',
                group: '10',
            },
            '1F1EF_1F1F4': {
                id: '1F1EF_1F1F4',
                emoji: '🇯🇴',
                group: '10',
            },
            '1F1EF_1F1F5': {
                id: '1F1EF_1F1F5',
                emoji: '🇯🇵',
                group: '10',
            },
            '1F1F0_1F1EA': {
                id: '1F1F0_1F1EA',
                emoji: '🇰🇪',
                group: '10',
            },
            '1F1F0_1F1EC': {
                id: '1F1F0_1F1EC',
                emoji: '🇰🇬',
                group: '10',
            },
            '1F1F0_1F1ED': {
                id: '1F1F0_1F1ED',
                emoji: '🇰🇭',
                group: '10',
            },
            '1F1F0_1F1EE': {
                id: '1F1F0_1F1EE',
                emoji: '🇰🇮',
                group: '10',
            },
            '1F1F0_1F1F2': {
                id: '1F1F0_1F1F2',
                emoji: '🇰🇲',
                group: '10',
            },
            '1F1F0_1F1F3': {
                id: '1F1F0_1F1F3',
                emoji: '🇰🇳',
                group: '10',
            },
            '1F1F0_1F1F5': {
                id: '1F1F0_1F1F5',
                emoji: '🇰🇵',
                group: '10',
            },
            '1F1F0_1F1F7': {
                id: '1F1F0_1F1F7',
                emoji: '🇰🇷',
                group: '10',
            },
            '1F1F0_1F1FC': {
                id: '1F1F0_1F1FC',
                emoji: '🇰🇼',
                group: '10',
            },
            '1F1F0_1F1FE': {
                id: '1F1F0_1F1FE',
                emoji: '🇰🇾',
                group: '10',
            },
            '1F1F0_1F1FF': {
                id: '1F1F0_1F1FF',
                emoji: '🇰🇿',
                group: '10',
            },
            '1F1F1_1F1E6': {
                id: '1F1F1_1F1E6',
                emoji: '🇱🇦',
                group: '10',
            },
            '1F1F1_1F1E7': {
                id: '1F1F1_1F1E7',
                emoji: '🇱🇧',
                group: '10',
            },
            '1F1F1_1F1E8': {
                id: '1F1F1_1F1E8',
                emoji: '🇱🇨',
                group: '10',
            },
            '1F1F1_1F1EE': {
                id: '1F1F1_1F1EE',
                emoji: '🇱🇮',
                group: '10',
            },
            '1F1F1_1F1F0': {
                id: '1F1F1_1F1F0',
                emoji: '🇱🇰',
                group: '10',
            },
            '1F1F1_1F1F7': {
                id: '1F1F1_1F1F7',
                emoji: '🇱🇷',
                group: '10',
            },
            '1F1F1_1F1F8': {
                id: '1F1F1_1F1F8',
                emoji: '🇱🇸',
                group: '10',
            },
            '1F1F1_1F1F9': {
                id: '1F1F1_1F1F9',
                emoji: '🇱🇹',
                group: '10',
            },
            '1F1F1_1F1FA': {
                id: '1F1F1_1F1FA',
                emoji: '🇱🇺',
                group: '10',
            },
            '1F1F1_1F1FB': {
                id: '1F1F1_1F1FB',
                emoji: '🇱🇻',
                group: '10',
            },
            '1F1F1_1F1FE': {
                id: '1F1F1_1F1FE',
                emoji: '🇱🇾',
                group: '10',
            },
            '1F1F2_1F1E6': {
                id: '1F1F2_1F1E6',
                emoji: '🇲🇦',
                group: '10',
            },
            '1F1F2_1F1E8': {
                id: '1F1F2_1F1E8',
                emoji: '🇲🇨',
                group: '10',
            },
            '1F1F2_1F1E9': {
                id: '1F1F2_1F1E9',
                emoji: '🇲🇩',
                group: '10',
            },
            '1F1F2_1F1EA': {
                id: '1F1F2_1F1EA',
                emoji: '🇲🇪',
                group: '10',
            },
            '1F1F2_1F1EB': {
                id: '1F1F2_1F1EB',
                emoji: '🇲🇫',
                group: '10',
            },
            '1F1F2_1F1EC': {
                id: '1F1F2_1F1EC',
                emoji: '🇲🇬',
                group: '10',
            },
            '1F1F2_1F1ED': {
                id: '1F1F2_1F1ED',
                emoji: '🇲🇭',
                group: '10',
            },
            '1F1F2_1F1F0': {
                id: '1F1F2_1F1F0',
                emoji: '🇲🇰',
                group: '10',
            },
            '1F1F2_1F1F1': {
                id: '1F1F2_1F1F1',
                emoji: '🇲🇱',
                group: '10',
            },
            '1F1F2_1F1F2': {
                id: '1F1F2_1F1F2',
                emoji: '🇲🇲',
                group: '10',
            },
            '1F1F2_1F1F3': {
                id: '1F1F2_1F1F3',
                emoji: '🇲🇳',
                group: '10',
            },
            '1F1F2_1F1F4': {
                id: '1F1F2_1F1F4',
                emoji: '🇲🇴',
                group: '10',
            },
            '1F1F2_1F1F5': {
                id: '1F1F2_1F1F5',
                emoji: '🇲🇵',
                group: '10',
            },
            '1F1F2_1F1F6': {
                id: '1F1F2_1F1F6',
                emoji: '🇲🇶',
                group: '10',
            },
            '1F1F2_1F1F7': {
                id: '1F1F2_1F1F7',
                emoji: '🇲🇷',
                group: '10',
            },
            '1F1F2_1F1F8': {
                id: '1F1F2_1F1F8',
                emoji: '🇲🇸',
                group: '10',
            },
            '1F1F2_1F1F9': {
                id: '1F1F2_1F1F9',
                emoji: '🇲🇹',
                group: '10',
            },
            '1F1F2_1F1FA': {
                id: '1F1F2_1F1FA',
                emoji: '🇲🇺',
                group: '10',
            },
            '1F1F2_1F1FB': {
                id: '1F1F2_1F1FB',
                emoji: '🇲🇻',
                group: '10',
            },
            '1F1F2_1F1FC': {
                id: '1F1F2_1F1FC',
                emoji: '🇲🇼',
                group: '10',
            },
            '1F1F2_1F1FD': {
                id: '1F1F2_1F1FD',
                emoji: '🇲🇽',
                group: '10',
            },
            '1F1F2_1F1FE': {
                id: '1F1F2_1F1FE',
                emoji: '🇲🇾',
                group: '10',
            },
            '1F1F2_1F1FF': {
                id: '1F1F2_1F1FF',
                emoji: '🇲🇿',
                group: '10',
            },
            '1F1F3_1F1E6': {
                id: '1F1F3_1F1E6',
                emoji: '🇳🇦',
                group: '10',
            },
            '1F1F3_1F1E8': {
                id: '1F1F3_1F1E8',
                emoji: '🇳🇨',
                group: '10',
            },
            '1F1F3_1F1EA': {
                id: '1F1F3_1F1EA',
                emoji: '🇳🇪',
                group: '10',
            },
            '1F1F3_1F1EB': {
                id: '1F1F3_1F1EB',
                emoji: '🇳🇫',
                group: '10',
            },
            '1F1F3_1F1EC': {
                id: '1F1F3_1F1EC',
                emoji: '🇳🇬',
                group: '10',
            },
            '1F1F3_1F1EE': {
                id: '1F1F3_1F1EE',
                emoji: '🇳🇮',
                group: '10',
            },
            '1F1F3_1F1F1': {
                id: '1F1F3_1F1F1',
                emoji: '🇳🇱',
                group: '10',
            },
            '1F1F3_1F1F4': {
                id: '1F1F3_1F1F4',
                emoji: '🇳🇴',
                group: '10',
            },
            '1F1F3_1F1F5': {
                id: '1F1F3_1F1F5',
                emoji: '🇳🇵',
                group: '10',
            },
            '1F1F3_1F1F7': {
                id: '1F1F3_1F1F7',
                emoji: '🇳🇷',
                group: '10',
            },
            '1F1F3_1F1FA': {
                id: '1F1F3_1F1FA',
                emoji: '🇳🇺',
                group: '10',
            },
            '1F1F3_1F1FF': {
                id: '1F1F3_1F1FF',
                emoji: '🇳🇿',
                group: '10',
            },
            '1F1F4_1F1F2': {
                id: '1F1F4_1F1F2',
                emoji: '🇴🇲',
                group: '10',
            },
            '1F1F5_1F1E6': {
                id: '1F1F5_1F1E6',
                emoji: '🇵🇦',
                group: '10',
            },
            '1F1F5_1F1EA': {
                id: '1F1F5_1F1EA',
                emoji: '🇵🇪',
                group: '10',
            },
            '1F1F5_1F1EB': {
                id: '1F1F5_1F1EB',
                emoji: '🇵🇫',
                group: '10',
            },
            '1F1F5_1F1EC': {
                id: '1F1F5_1F1EC',
                emoji: '🇵🇬',
                group: '10',
            },
            '1F1F5_1F1ED': {
                id: '1F1F5_1F1ED',
                emoji: '🇵🇭',
                group: '10',
            },
            '1F1F5_1F1F0': {
                id: '1F1F5_1F1F0',
                emoji: '🇵🇰',
                group: '10',
            },
            '1F1F5_1F1F1': {
                id: '1F1F5_1F1F1',
                emoji: '🇵🇱',
                group: '10',
            },
            '1F1F5_1F1F2': {
                id: '1F1F5_1F1F2',
                emoji: '🇵🇲',
                group: '10',
            },
            '1F1F5_1F1F3': {
                id: '1F1F5_1F1F3',
                emoji: '🇵🇳',
                group: '10',
            },
            '1F1F5_1F1F7': {
                id: '1F1F5_1F1F7',
                emoji: '🇵🇷',
                group: '10',
            },
            '1F1F5_1F1F8': {
                id: '1F1F5_1F1F8',
                emoji: '🇵🇸',
                group: '10',
            },
            '1F1F5_1F1F9': {
                id: '1F1F5_1F1F9',
                emoji: '🇵🇹',
                group: '10',
            },
            '1F1F5_1F1FC': {
                id: '1F1F5_1F1FC',
                emoji: '🇵🇼',
                group: '10',
            },
            '1F1F5_1F1FE': {
                id: '1F1F5_1F1FE',
                emoji: '🇵🇾',
                group: '10',
            },
            '1F1F6_1F1E6': {
                id: '1F1F6_1F1E6',
                emoji: '🇶🇦',
                group: '10',
            },
            '1F1F7_1F1EA': {
                id: '1F1F7_1F1EA',
                emoji: '🇷🇪',
                group: '10',
            },
            '1F1F7_1F1F4': {
                id: '1F1F7_1F1F4',
                emoji: '🇷🇴',
                group: '10',
            },
            '1F1F7_1F1F8': {
                id: '1F1F7_1F1F8',
                emoji: '🇷🇸',
                group: '10',
            },
            '1F1F7_1F1FA': {
                id: '1F1F7_1F1FA',
                emoji: '🇷🇺',
                group: '10',
            },
            '1F1F7_1F1FC': {
                id: '1F1F7_1F1FC',
                emoji: '🇷🇼',
                group: '10',
            },
            '1F1F8_1F1E6': {
                id: '1F1F8_1F1E6',
                emoji: '🇸🇦',
                group: '10',
            },
            '1F1F8_1F1E7': {
                id: '1F1F8_1F1E7',
                emoji: '🇸🇧',
                group: '10',
            },
            '1F1F8_1F1E8': {
                id: '1F1F8_1F1E8',
                emoji: '🇸🇨',
                group: '10',
            },
            '1F1F8_1F1E9': {
                id: '1F1F8_1F1E9',
                emoji: '🇸🇩',
                group: '10',
            },
            '1F1F8_1F1EA': {
                id: '1F1F8_1F1EA',
                emoji: '🇸🇪',
                group: '10',
            },
            '1F1F8_1F1EC': {
                id: '1F1F8_1F1EC',
                emoji: '🇸🇬',
                group: '10',
            },
            '1F1F8_1F1ED': {
                id: '1F1F8_1F1ED',
                emoji: '🇸🇭',
                group: '10',
            },
            '1F1F8_1F1EE': {
                id: '1F1F8_1F1EE',
                emoji: '🇸🇮',
                group: '10',
            },
            '1F1F8_1F1EF': {
                id: '1F1F8_1F1EF',
                emoji: '🇸🇯',
                group: '10',
            },
            '1F1F8_1F1F0': {
                id: '1F1F8_1F1F0',
                emoji: '🇸🇰',
                group: '10',
            },
            '1F1F8_1F1F1': {
                id: '1F1F8_1F1F1',
                emoji: '🇸🇱',
                group: '10',
            },
            '1F1F8_1F1F2': {
                id: '1F1F8_1F1F2',
                emoji: '🇸🇲',
                group: '10',
            },
            '1F1F8_1F1F3': {
                id: '1F1F8_1F1F3',
                emoji: '🇸🇳',
                group: '10',
            },
            '1F1F8_1F1F4': {
                id: '1F1F8_1F1F4',
                emoji: '🇸🇴',
                group: '10',
            },
            '1F1F8_1F1F7': {
                id: '1F1F8_1F1F7',
                emoji: '🇸🇷',
                group: '10',
            },
            '1F1F8_1F1F8': {
                id: '1F1F8_1F1F8',
                emoji: '🇸🇸',
                group: '10',
            },
            '1F1F8_1F1F9': {
                id: '1F1F8_1F1F9',
                emoji: '🇸🇹',
                group: '10',
            },
            '1F1F8_1F1FB': {
                id: '1F1F8_1F1FB',
                emoji: '🇸🇻',
                group: '10',
            },
            '1F1F8_1F1FD': {
                id: '1F1F8_1F1FD',
                emoji: '🇸🇽',
                group: '10',
            },
            '1F1F8_1F1FE': {
                id: '1F1F8_1F1FE',
                emoji: '🇸🇾',
                group: '10',
            },
            '1F1F8_1F1FF': {
                id: '1F1F8_1F1FF',
                emoji: '🇸🇿',
                group: '10',
            },
            '1F1F9_1F1E6': {
                id: '1F1F9_1F1E6',
                emoji: '🇹🇦',
                group: '10',
            },
            '1F1F9_1F1E8': {
                id: '1F1F9_1F1E8',
                emoji: '🇹🇨',
                group: '10',
            },
            '1F1F9_1F1E9': {
                id: '1F1F9_1F1E9',
                emoji: '🇹🇩',
                group: '10',
            },
            '1F1F9_1F1EB': {
                id: '1F1F9_1F1EB',
                emoji: '🇹🇫',
                group: '10',
            },
            '1F1F9_1F1EC': {
                id: '1F1F9_1F1EC',
                emoji: '🇹🇬',
                group: '10',
            },
            '1F1F9_1F1ED': {
                id: '1F1F9_1F1ED',
                emoji: '🇹🇭',
                group: '10',
            },
            '1F1F9_1F1EF': {
                id: '1F1F9_1F1EF',
                emoji: '🇹🇯',
                group: '10',
            },
            '1F1F9_1F1F0': {
                id: '1F1F9_1F1F0',
                emoji: '🇹🇰',
                group: '10',
            },
            '1F1F9_1F1F1': {
                id: '1F1F9_1F1F1',
                emoji: '🇹🇱',
                group: '10',
            },
            '1F1F9_1F1F2': {
                id: '1F1F9_1F1F2',
                emoji: '🇹🇲',
                group: '10',
            },
            '1F1F9_1F1F3': {
                id: '1F1F9_1F1F3',
                emoji: '🇹🇳',
                group: '10',
            },
            '1F1F9_1F1F4': {
                id: '1F1F9_1F1F4',
                emoji: '🇹🇴',
                group: '10',
            },
            '1F1F9_1F1F7': {
                id: '1F1F9_1F1F7',
                emoji: '🇹🇷',
                group: '10',
            },
            '1F1F9_1F1F9': {
                id: '1F1F9_1F1F9',
                emoji: '🇹🇹',
                group: '10',
            },
            '1F1F9_1F1FB': {
                id: '1F1F9_1F1FB',
                emoji: '🇹🇻',
                group: '10',
            },
            '1F1F9_1F1FC': {
                id: '1F1F9_1F1FC',
                emoji: '🇹🇼',
                group: '10',
            },
            '1F1F9_1F1FF': {
                id: '1F1F9_1F1FF',
                emoji: '🇹🇿',
                group: '10',
            },
            '1F1FA_1F1E6': {
                id: '1F1FA_1F1E6',
                emoji: '🇺🇦',
                group: '10',
            },
            '1F1FA_1F1EC': {
                id: '1F1FA_1F1EC',
                emoji: '🇺🇬',
                group: '10',
            },
            '1F1FA_1F1F2': {
                id: '1F1FA_1F1F2',
                emoji: '🇺🇲',
                group: '10',
            },
            '1F1FA_1F1F3': {
                id: '1F1FA_1F1F3',
                emoji: '🇺🇳',
                group: '10',
            },
            '1F1FA_1F1F8': {
                id: '1F1FA_1F1F8',
                emoji: '🇺🇸',
                group: '10',
            },
            '1F1FA_1F1FE': {
                id: '1F1FA_1F1FE',
                emoji: '🇺🇾',
                group: '10',
            },
            '1F1FA_1F1FF': {
                id: '1F1FA_1F1FF',
                emoji: '🇺🇿',
                group: '10',
            },
            '1F1FB_1F1E6': {
                id: '1F1FB_1F1E6',
                emoji: '🇻🇦',
                group: '10',
            },
            '1F1FB_1F1E8': {
                id: '1F1FB_1F1E8',
                emoji: '🇻🇨',
                group: '10',
            },
            '1F1FB_1F1EA': {
                id: '1F1FB_1F1EA',
                emoji: '🇻🇪',
                group: '10',
            },
            '1F1FB_1F1EC': {
                id: '1F1FB_1F1EC',
                emoji: '🇻🇬',
                group: '10',
            },
            '1F1FB_1F1EE': {
                id: '1F1FB_1F1EE',
                emoji: '🇻🇮',
                group: '10',
            },
            '1F1FB_1F1F3': {
                id: '1F1FB_1F1F3',
                emoji: '🇻🇳',
                group: '10',
            },
            '1F1FB_1F1FA': {
                id: '1F1FB_1F1FA',
                emoji: '🇻🇺',
                group: '10',
            },
            '1F1FC_1F1EB': {
                id: '1F1FC_1F1EB',
                emoji: '🇼🇫',
                group: '10',
            },
            '1F1FC_1F1F8': {
                id: '1F1FC_1F1F8',
                emoji: '🇼🇸',
                group: '10',
            },
            '1F1FD_1F1F0': {
                id: '1F1FD_1F1F0',
                emoji: '🇽🇰',
                group: '10',
            },
            '1F1FE_1F1EA': {
                id: '1F1FE_1F1EA',
                emoji: '🇾🇪',
                group: '10',
            },
            '1F1FE_1F1F9': {
                id: '1F1FE_1F1F9',
                emoji: '🇾🇹',
                group: '10',
            },
            '1F1FF_1F1E6': {
                id: '1F1FF_1F1E6',
                emoji: '🇿🇦',
                group: '10',
            },
            '1F1FF_1F1F2': {
                id: '1F1FF_1F1F2',
                emoji: '🇿🇲',
                group: '10',
            },
            '1F1FF_1F1FC': {
                id: '1F1FF_1F1FC',
                emoji: '🇿🇼',
                group: '10',
            },
            '1F3F4_E0067_E0062_E0065_E006E_E0067_E007F': {
                id: '1F3F4_E0067_E0062_E0065_E006E_E0067_E007F',
                emoji: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
                group: '10',
            },
            '1F3F4_E0067_E0062_E0073_E0063_E0074_E007F': {
                id: '1F3F4_E0067_E0062_E0073_E0063_E0074_E007F',
                emoji: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
                group: '10',
            },
            '1F3F4_E0067_E0062_E0077_E006C_E0073_E007F': {
                id: '1F3F4_E0067_E0062_E0077_E006C_E0073_E007F',
                emoji: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
                group: '10',
            },
        }),
        [featuredEmojis]
    )
}
