import { defaultTheme } from './default-theme'
import { AdminTheme } from './theme'

export const purpleHazeTheme: AdminTheme = {
    ...defaultTheme,
    ThemeColor: 'hsla(263, 94%, 66%, 1)',
    InteractivePrimary: 'hsla(263, 94%, 66%, 1)',
    InteractivePrimaryHover: 'hsla(263, 94%, 46%, 1)',
    InteractivePrimaryActive: 'hsla(263, 94%, 33%, 1)',
    InteractiveSecondary: 'hsla(263, 94%, 100%, 0)',
    InteractiveSecondaryHover: 'hsla(263, 94%, 66%, 0.1)',
    InteractiveSecondaryActive: 'hsla(263, 94%, 66%, 0.3)',
    FocusOutline: 'hsla(263, 94%, 66%, 0.3)',
    FontColor: 'hsla(0, 0%, 100%, 1)',
    BackgroundColor: 'hsla(263, 94%, 66%, 0.1)',
    SurfaceColor: 'hsla(0, 0%, 100%, 1)',
    Cover1: '#E6DEFF',
    Cover2: '#C8B3FF',
    Cover3: '#AE88FD',
    BookingPageAvatarBackground: '#AE88FD',
    AnimationColor: [149 / 255.0, 87 / 255.0, 250 / 255.0],
}
