import React, { FunctionComponent, HtmlHTMLAttributes } from 'react'
import styled from '@emotion/styled'
import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AdminTheme } from '../../../theme/theme'
import { useAdminTheme } from '../../../hooks/use-admin-theme'

type Variant = 'ContentWarning' | 'ContentSuccess' | 'ContentDanger' | 'ContentTertiary' | 'BackgroundSuccessPrimary'

export const FaContainer = styled.div(
    ({ theme, variant, size }: { theme: AdminTheme; variant?: Variant; size?: number }) => ({
        color: variant ? theme[variant] : undefined,
        height: size ? theme.Spacing(size) : 24,
        width: size ? theme.Spacing(size) : 24,
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        flex: '0 0 auto',
    })
)

interface IconProps {
    icon: IconProp
    spin?: boolean
    size?: SizeProp
    containerSize?: number
    variant?: Variant
}

export const Icon: FunctionComponent<IconProps & HtmlHTMLAttributes<HTMLDivElement>> = ({
    icon,
    spin,
    size,
    containerSize,
    ...rest
}) => {
    const theme = useAdminTheme()
    return (
        <FaContainer {...rest} theme={theme} size={containerSize}>
            <FontAwesomeIcon icon={icon} spin={spin} size={size} />
        </FaContainer>
    )
}
