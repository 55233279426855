import { Availability, LocationType } from '../auth/types'

export enum AvailbilityMode {
    Default = 0,
    CustomOpeningHours = 1,
    Specific = 2,
}

export enum TimezoneMode {
    Local = 0,
    Automatic = 1,
}

export enum TimeIncrementMode {
    Default = 0,
    Custom = 1,
}

export interface FixTimeSlot {
    date: string
    open: string
    close: string
    id?: number
}

export interface Service {
    id?: number
    name: string
    description: string
    duration: number
    breakAfter: number
    priceText: string
    priceValue?: number
    active: boolean
    approvalRequired: boolean
    imagePublicId?: string
    duplicateImage?: boolean
    availabilityMode: AvailbilityMode
    openingHours: Availability[]
    fixTimeSlots: FixTimeSlot[] | undefined
    isResetedByCancel?: boolean
    timezoneMode: TimezoneMode
    timeIncrementMode: TimeIncrementMode
    timeIncrement: number
    evenOddOpeningWeeks: boolean
    visibleOnBookingPage: boolean
    address: string
    link: string
    locationType: LocationType
    isAddressPublic: boolean
    categoryId: string | null
    reminderEmail1Timing: number
    reminderEmail2Timing: number | null
}

export const createEmptyService = (serviceType: string, categoryId: string | null) => {
    return {
        name: '',
        duration: 60,
        breakAfter: 0,
        description: '',
        active: true,
        priceText: '',
        approvalRequired: false,
        imagePublicId: `icon::${serviceType}`,
        availabilityMode: AvailbilityMode.Default,
        openingHours: [],
        fixTimeSlots: undefined,
        timezoneMode: TimezoneMode.Local,
        timeIncrementMode: TimeIncrementMode.Default,
        timeIncrement: 0,
        evenOddOpeningWeeks: false,
        visibleOnBookingPage: true,
        address: '',
        link: '',
        locationType: LocationType.Default,
        isAddressPublic: false,
        categoryId: categoryId,
        reminderEmail1Timing: 24,
        reminderEmail2Timing: null,
    } as Service
}
