import { ReactNode } from 'react'
import { ReactComponent as Facebook } from '../../icons/social/facebook.svg'
import { ReactComponent as Instagram } from '../../icons/social/instagram.svg'
import { ReactComponent as Linkedin } from '../../icons/social/linkedin.svg'
import { ReactComponent as Other } from '../../icons/social/other.svg'
import { ReactComponent as Patreon } from '../../icons/social/patreon.svg'
import { ReactComponent as Pinterest } from '../../icons/social/pinterest.svg'
import { ReactComponent as Tiktok } from '../../icons/social/tiktok.svg'
import { ReactComponent as Website } from '../../icons/social/website.svg'
import { ReactComponent as X } from '../../icons/social/x.svg'
import { ReactComponent as Youtube } from '../../icons/social/youtube.svg'

export function getSocialLink(type: string): ReactNode {
    switch (type) {
        case 'facebook':
            return <Facebook />
        case 'instagram':
            return <Instagram />
        case 'linkedin':
            return <Linkedin />
        case 'patreon':
            return <Patreon />
        case 'pinterest':
            return <Pinterest />
        case 'tiktok':
            return <Tiktok />
        case 'website':
            return <Website />
        case 'x':
            return <X />
        case 'youtube':
            return <Youtube />
        default:
            return <Other />
    }
}
