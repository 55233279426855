import { Reducer } from 'react'
import { AuthAction, AuthActionTypes } from './actions'
import { AuthState, Business, FeatureFlag, FeatureFlagName } from './types'

const INITIAL_AUTH_STATE: AuthState = {
    isAuthenticated: false,
    isLoading: false,
}

export const authReducer: Reducer<AuthState, AuthAction> = (state, action) => {
    let nextState = state ? state : INITIAL_AUTH_STATE

    switch (action.type) {
        case AuthActionTypes.UPDATE_BUSINESS:
            return {
                ...state,
                business: {
                    ...state.business,
                    ...action.payload,
                } as Business,
            }
        case AuthActionTypes.UPDATE_BUSINESS_TIMEZONE:
            return {
                ...state,
                business: {
                    ...(state.business as Business),
                    timezone: action.payload,
                },
            }
        case AuthActionTypes.UPDATE_BUSINESS_LANGUAGE:
            return {
                ...state,
                business: {
                    ...(state.business as Business),
                    languageCode: action.payload,
                    bookingPageLanguage: action.payload,
                },
            }
        case AuthActionTypes.REMOVE_PROFILE_IMAGE:
            return {
                ...state,
                business: {
                    ...(state.business as Business),
                    profileImagePublicId: undefined,
                },
            }
        case AuthActionTypes.REMOVE_COVER_IMAGE:
            return {
                ...state,
                business: {
                    ...(state.business as Business),
                    coverImagePublicId: undefined,
                },
            }
        case AuthActionTypes.SET_LOADING:
            return {
                ...state,
                isLoading: action.payload,
            }
        case AuthActionTypes.SET_ACCESS_TOKEN:
            return {
                ...state,
                accessToken: action.payload?.accessToken,
                isAuthenticated: !!action.payload?.accessToken,
                tokenExpiration: action.payload?.expiresIn
                    ? Math.floor(Date.now() / 1000) + action.payload?.expiresIn
                    : undefined,
            }
        case AuthActionTypes.SET_FEATURE_FLAGS:
            return {
                ...state,
                featureFlags: action.payload
                    ? new Map<FeatureFlagName, FeatureFlag>(Object.entries(action.payload) as any)
                    : undefined,
            }

        default:
            return nextState
    }
}
