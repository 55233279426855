import { AdminTheme, ColorPalette } from './theme'

const Spacing = (x: number) => `${8 * x}px`

export const defaultColorPalette: ColorPalette = {
    Primary_900: '#002B8F',
    Primary_800: '#003DCC',
    Primary_700: '#3772FF',
    Primary_600: '#477EFF',
    Primary_500: '#5C8DFF',
    Primary_400: '#85A9FF',
    Primary_300: '#ADC6FF',
    Primary_200: '#D6E2FF',
    Primary_100: '#EBF1FF',
    Primary_50: '#F7F9FF',
    Primary_0: '#FFFFFF',

    Neutral_900: '#000000',
    Neutral_800: '#25282D',
    Neutral_700: '#4C535D',
    Neutral_600: '#6B7582',
    Neutral_500: '#9AA8BB',
    Neutral_400: '#B3BFCF',
    Neutral_300: '#CED8E3',
    Neutral_200: '#DEE4EC',
    Neutral_100: '#F0F2F5',
    Neutral_50: '#F7F8FA',
    Neutral_0: '#FFFFFF',

    Secondary1_900: '#057659',
    Secondary1_800: '#048B63',
    Secondary1_700: '#07A67D',
    Secondary1_600: '#09D7A4',
    Secondary1_500: '#14F5BD',
    Secondary1_400: '#4FF8CD',
    Secondary1_300: '#89FADE',
    Secondary1_200: '#B1FCE9',
    Secondary1_100: '#D8FDF4',
    Secondary1_50: '#EAFFFA',
    Secondary1_0: '#FFFFFF',

    Secondary2_900: '#E0A500',
    Secondary2_800: '#F5B400',
    Secondary2_700: '#FFBE0B',
    Secondary2_600: '#FFCE47',
    Secondary2_500: '#FFD970',
    Secondary2_400: '#FFDE85',
    Secondary2_300: '#FFE499',
    Secondary2_200: '#FFE9AD',
    Secondary2_100: '#FFF4D6',
    Secondary2_50: '#FFFAEB',
    Secondary2_0: '#FFFFFF',

    Secondary3_900: '#8F006D',
    Secondary3_800: '#A3007D',
    Secondary3_700: '#B30089',
    Secondary3_600: '#CC009C',
    Secondary3_500: '#FF70DE',
    Secondary3_400: '#FF99E7',
    Secondary3_300: '#FFC2F1',
    Secondary3_200: '#FFD6F5',
    Secondary3_100: '#FFEBFA',
    Secondary3_50: '#FFF3FC',
    Secondary3_0: '#FFFFFF',

    Secondary4_900: '#C8040E',
    Secondary4_800: '#DC040F',
    Secondary4_700: '#FB3640',
    Secondary4_600: '#FC5F67',
    Secondary4_500: '#FC737A',
    Secondary4_400: '#FD878D',
    Secondary4_300: '#FDAFB3',
    Secondary4_200: '#FED7D9',
    Secondary4_100: '#FFEBEC',
    Secondary4_50: '#FFF2F3',
    Secondary4_0: '#FFFFFF',

    Opacity_100: '#3772FF',
    Opacity_90: 'rgba(55, 114, 255, 0.9)',
    Opacity_70: 'rgba(55, 114, 255, 0.7)',
    Opacity_50: 'rgba(55, 114, 255, 0.5)',
    Opacity_30: 'rgba(55, 114, 255, 0.3)',
    Opacity_10: 'rgba(55, 114, 255, 0.1)',
}

export const defaultTheme: AdminTheme = {
    ...defaultColorPalette,

    Elevation_0: 'none',
    Elevation_100:
        '0px 0px 2px rgba(29, 48, 73, 0.16), 0px 2px 2px rgba(29, 48, 73, 0.04), 0px 1px 2px rgba(29, 48, 73, 0.04)',
    Elevation_200:
        '0px 2px 4px rgba(29, 48, 73, 0.08), 0px 2px 4px rgba(29, 48, 73, 0.08), 0px 4px 8px rgba(29, 48, 73, 0.04)',
    Elevation_300:
        '0px 4px 8px rgba(29, 48, 73, 0.16), 0px 2px 4px rgba(29, 48, 73, 0.08), 0px 8px 16px rgba(29, 48, 73, 0.08)',

    BookingPageAdminBarBackground: 'hsla(0, 0%, 100%, 0.9)',
    BookingPageAvatarBackground: '#5C8DFF',
    BookingPageBorderStyle: {
        boxShadow:
            '0px 2px 4px rgba(29, 48, 73, 0.08), 0px 2px 4px rgba(29, 48, 73, 0.08), 0px 4px 8px rgba(29, 48, 73, 0.04)',
    },

    BookingPageCardHoverBorderStyle: {
        boxShadow:
            '0px 4px 8px rgba(29, 48, 73, 0.16), 0px 2px 4px rgba(29, 48, 73, 0.08), 0px 8px 16px rgba(29, 48, 73, 0.08)',
    },

    DefaultFontFamily:
        'font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;',

    HeadingLarge: `
        font-style: normal;
        font-weight: 600;
        font-size: 36px;
        line-height: 44px;
    `,
    HeadingMedium: `
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
    `,
    HeadingSmall: `
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
        `,
    BodyLarge: `
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 28px;
        `,
    BodyLargeSemibold: `
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 28px;
        `,
    BodyMedium: `
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
        `,
    BodyMediumSemibold: `
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
        `,
    BodySmall: `
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
        `,
    BodySmallSemibold: `
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
        `,

    _HeadingLarge: {
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '36px',
        lineHeight: '44px',
    },
    _HeadingMedium: {
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '24px',
        lineHeight: '32px',
    },
    _HeadingSmall: {
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '20px',
        lineHeight: '30px',
    },
    _BodyLarge: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '18px',
        lineHeight: '28px',
    },
    _BodyLargeSemibold: {
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '18px',
        lineHeight: '28px',
    },
    _BodyMedium: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
    },
    _BodyMediumSemibold: {
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '24px',
    },
    _BodySmall: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '20px',
    },
    _BodySmallSemibold: {
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '20px',
    },

    Caption: `
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
        `,
    CaptionSemibold: `
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
        `,

    _Caption: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '16px',
    },
    _CaptionSemibold: {
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '12px',
        lineHeight: '16px',
    },

    Code: `
            font-family: "SF Mono", monospace;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
        `,

    BookingPageBreakPoint: '@media (min-width: 1130px)',
    BookingPageBreakPointReverse: '@media (max-width: 1129px)',
    BreakPoint: '@media (min-width: 821px)',
    BreakPointReverse: '@media (max-width: 820px)',

    ThemeColor: '#3772FF',

    // Interactive
    //    Primary
    InteractivePrimary: 'hsla(222, 100%, 61%, 1)',
    InteractivePrimaryHover: 'hsla(222, 100%, 40%, 1)',
    InteractivePrimaryActive: 'hsla(222, 100%, 28%, 1)',
    //    Secondary
    InteractiveSecondary: 'hsla(222, 100%, 100%, 0)',
    InteractiveSecondaryHover: 'hsla(222, 100%, 61%, 0.1)',
    InteractiveSecondaryActive: 'hsla(222, 100%, 61%, 0.3)',
    //    Neutral
    InteractiveNeutral: '#FFFFFF',
    InteractiveNeutralHover: '#F0F2F5',
    InteractiveNeutralActive: '#DEE4EC',
    //    Pro
    InteractivePro: '#FFEBFA',
    InteractiveProHover: '#FFD6F5',
    InteractiveProActive: '#FFC2F1',
    //    Booking page card
    InteractiveBookingPageCard: '#F0F2F5',
    InteractiveBookingPageCardHover: '#DEE4EC',
    InteractiveBookingPageCardActive: '#CED8E3',

    FocusOutline: 'hsla(222, 100%, 61%, 0.3)',

    FontColor: 'hsla(0, 0%, 100%, 1)',

    ContentPrimary: '#25282D',
    ContentSecondary: '#4C535D',
    ContentTertiary: '#6B7582',
    ContentSuccess: '#07A67D',
    ContentBrand: '#3772FF',
    ContentDisabled: '#B3BFCF',
    ContentWarning: '#BB8902',
    ContentDanger: '#FB3640',
    ContentPro: '#B30089',
    ContentInfo: defaultColorPalette.Primary_800,
    ContentInverted: defaultColorPalette.Primary_0,

    BackgroundColor: '#F7F8FA',
    BackgroundSecondary: '#F7F8FA',
    BackgroundInfoSecondary: '#EBF1FF',
    BackgroundOnboarding: 'linear-gradient(270deg, #3746FF 0%, #16ADD1 100%)',
    BackgroundSuccessPrimary: '#07A67D',
    BackgroundSuccessSecondary: '#EAFFFA',
    BackgroundWarningPrimary: defaultColorPalette.Secondary2_700,
    BackgroundWarningSecondary: defaultColorPalette.Secondary2_50,
    BackgroundDangerSecondary: defaultColorPalette.Secondary4_50,
    BackgroundNeutralSecondary: '#F0F2F5',
    BackgroundProSecondary: defaultColorPalette.Secondary3_100,
    BackgroundInverted: '#25282D',
    GradientBusiness: `linear-gradient(90deg, ${defaultColorPalette.Primary_700} 0%, #FF3ECF 100%)`,
    GradientBusinessRepeated: `linear-gradient(90deg, ${defaultColorPalette.Secondary3_500} 0%, ${defaultColorPalette.Primary_700} 50%, #FF3ECF 100%)`,

    SurfaceColor: 'hsla(0, 0%, 100%, 1)',

    Cover1: '#EBF1FF',
    Cover2: '#ADC6FF',
    Cover3: '#5C8DFF',

    BorderBrand: '#3772FF',
    BorderPrimary: defaultColorPalette.Neutral_200,
    BroderSecondary: defaultColorPalette.Neutral_400,
    BorderTertiary: '#6B7582',
    BorderPro: '#B30089',
    BorderInverted: defaultColorPalette.Primary_0,

    BrandPrimary: '#3772FF',

    AnimationColor: [56.1 / 255.0, 115.77 / 255.0, 255 / 255.0],

    Spacing,

    ButtonBorderRadius: Spacing(1),
}
