import { useMemo } from 'react'
import { BillingDetails } from '../../pages/admin/subscription/main/BillingDetailsForm'
import { useAuthorizedApi } from './use-authorized-api'

export function useBillingDetailsApi() {
    const { get, post } = useAuthorizedApi()

    return useMemo(() => {
        return {
            get: () => get<BillingDetails | undefined>('/api/admin/billingdetails'),
            save: (billingDetails: BillingDetails) => post<BillingDetails>('/api/admin/billingdetails', billingDetails),
        }
    }, [get, post])
}
