import { useCallback } from "react"
import { useDispatch } from "react-redux"
import { AdminActionTypes } from "../../store/admin/actions"
import { createAction } from "../../store/utils/create-action"


export function useSetAdminMenuOpen() {
    const dispatch = useDispatch()
    return useCallback(
        (open: boolean) => {
            dispatch(createAction(AdminActionTypes.SET_ADMIN_MENU_OPEN, open))
        },
        [dispatch]
    )
}