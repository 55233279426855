import moment from 'moment-timezone'
import { FC, useCallback, useEffect, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Business } from '../../../store/auth/types'
import { EventPublicDto } from '../../../store/events/types'
import { ContactInfo, ContactInfoScreen } from '../components/ContactInfoScreen'
import { EventSummaryCard } from './EventSummaryCard'
import { useBeforeUnload } from '../../../hooks/use-before-unload'

export const EventContactInfo: FC<{
    contactInfo: ContactInfo
    setContactInfo: (contactInfo: ContactInfo) => void
    business: Business
    event: EventPublicDto
    answers: string
    isEmbed?: boolean
}> = ({ contactInfo, setContactInfo, business, event, answers, isEmbed }) => {
    const { handle, eventId, occurrenceId } = useParams<{ handle: string; eventId: string; occurrenceId?: string }>()
    const clientTimezone = useMemo(() => moment.tz.guess(), [])

    const history = useHistory()
    useBeforeUnload()

    useEffect(() => {
        if (answers === '[]' && event.form) {
            history.push(`/${isEmbed ? 'embed' : 'book'}/${handle}/events/${eventId}/dates`)
        }
    }, [answers, event.form, eventId, handle, history, isEmbed])

    const onSubmit = useCallback(
        async (clientInfo) => {
            setContactInfo(clientInfo)
            history.push(`/${isEmbed ? 'embed' : 'book'}/${handle}/events/${eventId}/${occurrenceId}/overview`)
        },
        [eventId, handle, history, isEmbed, occurrenceId, setContactInfo]
    )

    const selectedOccurrence = event.occurrences.find((occurrence) => occurrence.id === occurrenceId)

    if (!selectedOccurrence) {
        return <>Error</>
    }

    return (
        <ContactInfoScreen
            onSubmit={onSubmit}
            isEmbed={isEmbed}
            business={business}
            maxSeatsPerBooking={selectedOccurrence.maxSeatsPerBooking}
            summaryCard={
                <EventSummaryCard
                    event={event}
                    occurrence={selectedOccurrence}
                    business={business}
                    clientTimezone={clientTimezone}
                />
            }
            contactInfo={contactInfo}
        />
    )
}
