import { FC } from 'react'
import { Trans } from 'react-i18next'

export const HourDayDisplay: FC<{ hours: number; bold?: boolean }> = ({ hours, bold }) => {
    const canCancelUntil = hours % 24 === 0 ? hours / 24 : hours
    const isDay = hours % 24 === 0

    const Tag: keyof JSX.IntrinsicElements = bold ? 'b' : 'span'

    return (
        <>
            {isDay ? (
                <Trans ns="bookingpage" count={canCancelUntil}>
                    You can cancel at least{' '}
                    <Tag style={{ display: 'contents' }}>{{ canCancelUntil: canCancelUntil }} day</Tag> before the
                    appointment!
                </Trans>
            ) : (
                <Trans ns="bookingpage" count={canCancelUntil}>
                    You can cancel at least{' '}
                    <Tag style={{ display: 'contents' }}>{{ canCancelUntil: canCancelUntil }} hour</Tag> before the
                    appointment!
                </Trans>
            )}
        </>
    )
}
