import styled, { CreateStyledComponent } from '@emotion/styled'
import { withTheme } from '@emotion/react'
import { NavLink } from 'react-router-dom'
import { AdminTheme } from '../../../theme/theme'
import { FaContainer } from '../comopnents/Icon'

function asSecondaryButton<ComponentProps extends {}, SpecificComponentProps extends {}>(
    component: CreateStyledComponent<ComponentProps, SpecificComponentProps>
) {
    return withTheme(
        component(({ theme }: { theme: AdminTheme }) => ({
            position: 'relative',
            textDecoration: 'none',
            outline: 'none',
            ...theme._BodyMedium,
            color: theme.ThemeColor,
            backgroundColor: theme.InteractiveSecondary,
            padding: `${theme.Spacing(1)} ${theme.Spacing(3)}`,
            lineHeight: '22px',
            border: `1px solid ${theme.ThemeColor}`,

            borderRadius: theme.ButtonBorderRadius,

            cursor: 'pointer',

            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',

            '&:hover': {
                backgroundColor: theme.InteractiveSecondaryHover,
            },

            '&:focus-visible': {
                boxShadow: `0 0 0 4px ${theme.FocusOutline}`,
            },

            '&:active': {
                boxShadow: 'none',
                backgroundColor: theme.InteractiveSecondaryActive,
            },

            '&.danger': {
                color: theme.Secondary4_700,
                borderColor: theme.Secondary4_700,
                '&:hover': {
                    color: theme.Secondary4_800,
                    borderColor: theme.Secondary4_800,
                    backgroundColor: theme.Secondary4_100,
                },

                '&:focus-visible': {
                    boxShadow: `0 0 0 4px ${theme.Opacity_30}`,
                    backgroundColor: theme.Secondary4_100,
                },

                '&:active': {
                    boxShadow: 'none',
                    color: theme.Secondary4_900,
                    borderColor: theme.Secondary4_900,
                    backgroundColor: theme.Secondary4_200,
                },
            },

            '&:disabled, &.disabled': {
                cursor: 'auto',
                color: `${theme.Neutral_300} !important`,
                borderColor: `${theme.Neutral_300} !important`,
                backgroundColor: 'transparent !important',
            },
            [FaContainer as any]: {
                height: '22px',
                width: 'auto',
                marginLeft: '-2px',
                marginRight: theme.Spacing(1),
                '&.end-icon': {
                    marginRight: '-2px',
                    marginLeft: theme.Spacing(1),
                },
            },
        }))
    )
}

export const SecondaryButton = asSecondaryButton(styled.button)

export const SecondaryButtonLink = asSecondaryButton(styled.a)

export const SecondaryButtonNavLink = asSecondaryButton(styled(NavLink))
