export const emailProviders = [
    'gmail.com',
    'yahoo.com',
    'hotmail.com',
    'outlook.com',
    'icloud.com',
    'aol.com',
    'protonmail.com',
    'zoho.com',
    'gmx.com',
    'fastmail.com',
    'tutanota.com',
    'mail.com',
    'yandex.com',
    'inbox.com',
    'hey.com',
    'lycos.com',
    'hushmail.com',
    'me.com',
    'bluewin.ch',
    'seznam.cz',
    'tiscali.it',
    'web.de',
    'freenet.de',
    'orange.fr',
    'virginmedia.com',
    'qq.com',
    'freemail.hu',
    'citromail.hu',
    't-online.hu',
    'vipmail.hu',
    'upcmail.hu',
    'yahoo.ro',
    'hotmail.ro',
    'mail.ru',
    'outlook.ro',
    'romtelecom.ro',
    'rdslink.ro',
    'clicknet.ro',
    'azet.sk',
    'post.sk',
    'zoznam.sk',
    'centrum.sk',
    'atlas.sk',
    'pobox.sk',
    'upcmail.sk',
    'slovanet.sk',
]
