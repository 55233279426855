import { put, select, takeEvery } from 'redux-saga/effects'
import { FinishSubTaskAction, GettingStartedActionTypes } from '../actions'
import { GettingStartedState, EMPTY_PROGRESS, createEmptyTaskProgress } from '../types'
import { getGuideProgress } from './helpers/get-guide-progress'
import { getNextTask } from './helpers/get-next-task'
import { createAction2 } from '../../utils/create-action'
import { captureException } from '../../../appInsights'

export function* finishSubTask(): any {
    yield takeEvery(GettingStartedActionTypes.FINISH_SUBTASK, function* (action: FinishSubTaskAction) {
        try {
            const state: GettingStartedState = yield select((state) => state.gettingStarted)

            const task = state.currentGuide?.tasks.find((task) => task.id === action.payload.taskId)
            const subTask = task?.subTasks.find((subTask) => action.payload.subTaskIds.includes(subTask.id))
            if (task && subTask) {
                const progress = {
                    ...(state.progress || EMPTY_PROGRESS),
                }

                const taskProgress = {
                    ...(progress.tasks.find((task) => task.taskId === action.payload.taskId) ||
                        createEmptyTaskProgress(action.payload.taskId)),
                }

                // Add the subtask to the list of completed subtasks
                taskProgress.completedSubTasks = [
                    ...taskProgress.completedSubTasks,
                    ...action.payload.subTaskIds,
                ].filter((id, index, array) => array.indexOf(id) === index)

                const subTasks = task?.subTasks.map((subTask) => subTask.id).sort()

                // Check if all the subtasks are completed, if so, mark the task as done
                taskProgress.done =
                    taskProgress.done || taskProgress.completedSubTasks.sort().join(',') === subTasks?.join(',')
                progress.tasks = [...progress.tasks.filter((task) => task.taskId !== taskProgress.taskId), taskProgress]

                // If the task is done, find the next task in the queue
                if (taskProgress.done && task) {
                    const nextTask = state.currentGuide ? getNextTask(task.id, state.currentGuide) : undefined
                    progress.activeTask = nextTask?.id || progress.activeTask
                }

                // Update the task completeness percentage
                progress.progress = state.currentGuide ? getGuideProgress(progress.tasks, state.currentGuide) : 0

                yield put(createAction2({ type: GettingStartedActionTypes.UPDATE_PROGRESS, payload: progress }))
                yield put(createAction2({ type: GettingStartedActionTypes.SAVE_CHANGES }))
            }
        } catch (e) {
            captureException(e)
        }
    })
}
