import { FinishTaskAction, GettingStartedActionTypes, SkipTaskAction } from '../actions'
import { EMPTY_PROGRESS, GettingStartedState, createEmptyTaskProgress } from '../types'
import { put, select, takeEvery } from 'redux-saga/effects'
import { getNextTask } from './helpers/get-next-task'
import { getGuideProgress } from './helpers/get-guide-progress'
import { createAction2 } from '../../utils/create-action'
import { captureException } from '../../../appInsights'

export function* finishOrSkipTask(): any {
    yield takeEvery(
        [GettingStartedActionTypes.SKIP_TASK, GettingStartedActionTypes.FINISH_TASK],
        function* (action: FinishTaskAction | SkipTaskAction) {
            try {
                const state: GettingStartedState = yield select((state) => state.gettingStarted)
                const progress = {
                    ...(state.progress || EMPTY_PROGRESS),
                }
                const taskProgress = {
                    ...(progress.tasks.find((task) => task.taskId === action.payload) ||
                        createEmptyTaskProgress(action.payload)),
                }
                taskProgress.skipped = action.type === GettingStartedActionTypes.SKIP_TASK
                taskProgress.done = action.type === GettingStartedActionTypes.FINISH_TASK
                progress.tasks = [...progress.tasks.filter((task) => task.taskId !== taskProgress.taskId), taskProgress]

                const nextTask = state.currentGuide ? getNextTask(action.payload, state.currentGuide) : undefined
                progress.activeTask = nextTask?.id || progress.activeTask

                progress.progress = state.currentGuide ? getGuideProgress(progress.tasks, state.currentGuide) : 0

                yield put(createAction2({ type: GettingStartedActionTypes.UPDATE_PROGRESS, payload: progress }))
                yield put(createAction2({ type: GettingStartedActionTypes.SAVE_CHANGES }))
            } catch (e) {
                captureException(e)
            }
        }
    )
}
