import styled from '@emotion/styled'
import { ReactComponent as Ribbon } from '../../icons/Ribbon.svg'
import { FC, useCallback } from 'react'

const StyledMinupRibbon = styled(Ribbon)(() => ({
    position: 'absolute',
    zIndex: 1,
    top: -4,
    right: -8,
    pointerEvents: 'none',
    '&.clickable': {
        'path, circle': {
            pointerEvents: 'auto',
            cursor: 'pointer',
        },
    },
}))

export const RIBBON_EVENT_NAME = 'openRibbon'
export const MINUP_RIBBON_ID = 'minup-ribbon'

export const MinupRibbon: FC = () => {
    const handleClick = useCallback(() => {
        const event = new CustomEvent(RIBBON_EVENT_NAME, {
            detail: { open: true },
        })
        window.dispatchEvent(event)
    }, [])

    return <StyledMinupRibbon id={MINUP_RIBBON_ID} onClick={handleClick} />
}
