import { defaultTheme } from './default-theme'
import { AdminTheme } from './theme'

export const barbieTheme: AdminTheme = {
    ...defaultTheme,
    ThemeColor: 'hsla(315, 98%, 44%, 1)',
    InteractivePrimary: 'hsla(315, 98%, 44%, 1)',
    InteractivePrimaryHover: 'hsla(315, 98%, 31%, 1)',
    InteractivePrimaryActive: 'hsla(315, 98%, 22%, 1)',
    InteractiveSecondary: 'hsla(315, 98%, 44%, 0)',
    InteractiveSecondaryHover: 'hsla(315, 98%, 44%, 0.1)',
    InteractiveSecondaryActive: 'hsla(315, 98%, 44%, 0.3)',
    FocusOutline: 'hsla(315, 98%, 44%, 0.3)',
    FontColor: 'hsla(0, 0%, 100%, 1)',
    BackgroundColor: 'hsla(315, 98%, 44%, 0.1)',
    SurfaceColor: 'hsla(0, 0%, 100%, 1)',
    Cover1: '#FFE4F9',
    Cover2: '#FF9DE3',
    Cover3: '#FF62D0',
    BookingPageAvatarBackground: '#FF62D0',
    AnimationColor: [222 / 255.0, 2 / 255.0, 167 / 255.0],
}
