import { takeEvery } from 'redux-saga/effects'
import { AuthActionTypes } from '../../auth/actions'
import { CLOSE_GETTING_STARTED_PREFIX } from '../../../pages/admin/in-app-messages/GettingStartedGuide'

export function* resetFabStateOnLogin(): any {
    yield takeEvery(AuthActionTypes.AUTH_RESET, function () {
        for (const key in localStorage) {
            if (key.startsWith(CLOSE_GETTING_STARTED_PREFIX)) {
                localStorage.removeItem(key)
            }
        }
    })
}
