import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { withSpacing } from '../../../helpers/with-spacing'

export const ModalContent = withTheme(
    withSpacing(
        styled.div({
            display: 'flex',
        }),
        { px: 3, py: 0 }
    )
)
