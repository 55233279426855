import { Action, ActionWithoutPayload } from '../utils/create-action'
import { CalendarEntry } from './types'

export enum CalendarActionTypes {
    SET_ENTRIES = '[calendar] set entries',
    CREATE = '[calendar] create',
    UPDATE = '[calendar] update',
    DELETE = '[calendar] delete',
    SET_IS_LOADING = '[calendar] set is loading',
    ENTRY_OPENED = '[calendar] entry opened',
}

type SetCalendarEntriesAction = Action<typeof CalendarActionTypes.SET_ENTRIES, CalendarEntry[]>
type CreateCalendarEntryAction = Action<typeof CalendarActionTypes.CREATE, CalendarEntry | CalendarEntry[]>
type UpdateCalendarEntryAction = Action<typeof CalendarActionTypes.UPDATE, CalendarEntry>
type DeleteCalendarEntryAction = Action<typeof CalendarActionTypes.DELETE, CalendarEntry['objectId']>
type SetIsLoadingAction = Action<typeof CalendarActionTypes.SET_IS_LOADING, boolean>
type CalendarEntryOpenedAction = ActionWithoutPayload<typeof CalendarActionTypes.ENTRY_OPENED>

export type CalendarAction =
    | CreateCalendarEntryAction
    | UpdateCalendarEntryAction
    | DeleteCalendarEntryAction
    | SetCalendarEntriesAction
    | SetIsLoadingAction
    | CalendarEntryOpenedAction
