import styled from '@emotion/styled'
import { faSpinner, faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { ButtonHTMLAttributes, FC, HtmlHTMLAttributes, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Flex } from '../../helpers/Flex'
import { DropDownOptionList, StyledInput, StyledFontAwesomeIcon } from './DropDownInputBase'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { InputlikeDiv } from './Input'
import { v4 as uuid } from 'uuid'
import React from 'react'
import { withTheme } from '@emotion/react'
import { AdminTheme } from '../../../theme/theme'
import { withSpacing } from '../../helpers/with-spacing'

const AutocompleteContainer = styled(Flex)`
    position: relative;
`

const SectionHeaderContainer = withTheme(
    withSpacing(
        styled(Flex)(({ theme }: { theme: AdminTheme }) => ({
            justifyContent: 'flex-start',
            ...theme._CaptionSemibold,
            color: theme.ContentTertiary,
            borderTop: `1px solid ${theme.BorderPrimary}`,
            '&:first-child': {
                borderTop: 'none',
            },
        })),
        { px: 2, py: 1.5 }
    )
)

const SectionHeader: FC<{ item: AutocompleteItem }> = ({ item }) => {
    return <SectionHeaderContainer>{item.label}</SectionHeaderContainer>
}

export interface AutocompleteItem<T = any> {
    label: string
    value: string | number | undefined
    extra?: T
    children?: AutocompleteItem<T>[]
}

interface AutocompleteInputProps {
    options?: AutocompleteItem[]
    required?: boolean
    value?: any
    noMatchString?: string
    inputClassName?: string
    disableAutocomplete?: boolean
    containerStyle?: React.CSSProperties
    dropdownWidth?: number
    dropdownContainerStyle?: React.CSSProperties
    getOptionsAsync?: (query: string) => Promise<AutocompleteItem[]>
    renderItem?: (item: AutocompleteItem) => JSX.Element
    ButtonComponent?: (props: { open: boolean } & ButtonHTMLAttributes<HTMLButtonElement>) => JSX.Element
    onChange?: (item: AutocompleteItem) => void
}

function isEmpty(value: any): boolean {
    return value === '' || value === undefined || value === null
}

function flatten(items: AutocompleteItem[]): AutocompleteItem[] {
    return items.reduce(
        (flatList, item) => [...flatList, ...(item.children ? flatten(item.children) : [item])],
        [] as AutocompleteItem[]
    )
}

export const AutocompleteInput: FC<Omit<HtmlHTMLAttributes<HTMLInputElement>, 'onChange'> & AutocompleteInputProps> = ({
    value,
    options,
    getOptionsAsync,
    onChange,
    renderItem,
    noMatchString,
    onBlur,
    className,
    inputClassName,
    disableAutocomplete,
    containerStyle,
    dropdownWidth,
    dropdownContainerStyle,
    ButtonComponent,
    placeholder,
    ...rest
}) => {
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [filteredItems, setFilteredItems] = useState<(JSX.Element | null)[]>([])
    const [timeoutId, setTimeoutId] = useState()
    const [listboxId] = useState('listbox-' + uuid())
    const flattenedOptions = useMemo(() => flatten(options || []), [options])
    const selectedOption = useMemo(() => {
        return flattenedOptions.find((o) => o.value === value || (isEmpty(o.value) && isEmpty(value)))
    }, [flattenedOptions, value])

    const containerRef = useRef<HTMLDivElement>(null)
    const elementRef = useRef<HTMLElement>(null)

    const openList = useCallback(() => {
        setOpen(true)
    }, [])

    const toggleList = useCallback(() => {
        setOpen((open) => !open)
    }, [])

    const closeList = useCallback(() => {
        setTimeout(() => {
            setOpen(false)
        }, 10)
    }, [])

    const selectItem = useCallback(
        (item) => {
            onChange && onChange(item)
            closeList()
            ;(elementRef.current as any)?.focus()
        },
        [closeList, onChange]
    )

    const onBlurCb = useCallback(
        (e) => {
            if (
                !e.relatedTarget ||
                ((e.relatedTarget as HTMLElement).getAttribute('role') !== 'option' &&
                    !(e.relatedTarget as HTMLElement).closest('[role=option]'))
            ) {
                closeList()
                onBlur && onBlur(e)
            }
        },
        [closeList, onBlur]
    )

    const createListItem = useCallback(
        (item: AutocompleteItem, key: string | number) => {
            return item.children ? (
                <React.Fragment key={key}>
                    <SectionHeader item={item} />
                    {item.children.map((child, i) => createListItem(child, `${key}-${i}`))}
                </React.Fragment>
            ) : (
                <li
                    key={key}
                    role="option"
                    aria-selected={item.value === value}
                    tabIndex={-1}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter' || e.key === ' ') {
                            selectItem(item)
                        }
                    }}
                    onBlur={onBlurCb}
                    onClick={() => selectItem(item)}
                >
                    {renderItem ? renderItem(item) : item.label}
                </li>
            )
        },
        [onBlurCb, renderItem, selectItem, value]
    )

    const onInputChange = useCallback(
        (e: any) => {
            const newValue = e.currentTarget.value
            onChange && onChange({ label: newValue, value: undefined } as any)
            if (getOptionsAsync) {
                if (timeoutId) {
                    clearTimeout(timeoutId)
                }
                setTimeoutId(
                    setTimeout(async () => {
                        setLoading(true)
                        const items = await getOptionsAsync(newValue)
                        setFilteredItems(items.map((item, i) => createListItem(item, i)))
                        setLoading(false)
                    }, 300) as any
                )
            }
            openList()
        },
        [createListItem, getOptionsAsync, onChange, openList, timeoutId]
    )

    useEffect(() => {
        if (options) {
            const filter = (items: AutocompleteItem[]): AutocompleteItem[] => {
                return items.filter((item, i) => {
                    let show = disableAutocomplete || !value
                    if (!show && !item.children) {
                        show = item.label.toLocaleUpperCase().includes((('' + value) as string).toLocaleUpperCase())
                    }
                    if (!show && item.children?.length) {
                        item.children = filter(item.children)
                        show = item.children?.length > 0
                    }
                    return show
                })
            }

            setFilteredItems(filter(options).map((option, i) => createListItem(option, i)))
        }
    }, [createListItem, disableAutocomplete, options, renderItem, selectItem, value])

    const { t } = useTranslation('admin')

    const navigate = useCallback(
        (e) => {
            if (e.key === 'ArrowDown') {
                if (!containerRef.current?.contains(document.activeElement) || !document.activeElement) {
                    if (containerRef.current?.firstElementChild) {
                        ;(containerRef.current?.firstElementChild as any)?.focus()
                    }
                } else {
                    const next = document.activeElement.nextElementSibling as any
                    if (next) {
                        next.focus()
                    } else {
                        ;(containerRef.current?.firstElementChild as any)?.focus()
                    }
                }
                e.preventDefault()
            }
            if (e.key === 'ArrowUp') {
                if (!containerRef.current?.contains(document.activeElement) || !document.activeElement) {
                    if (containerRef.current?.firstElementChild) {
                        ;(containerRef.current?.firstElementChild as any)?.focus()
                    }
                } else {
                    const prev = document.activeElement.previousElementSibling as any
                    if (prev) {
                        prev.focus()
                    } else {
                        ;(containerRef.current?.lastElementChild as any)?.focus()
                    }
                }
                e.preventDefault()
            }
            if (e.key === 'Escape') {
                closeList()
            }
        },
        [closeList]
    )

    return (
        <AutocompleteContainer grow={1} className={className} onKeyDown={navigate} style={containerStyle}>
            {disableAutocomplete ? (
                ButtonComponent ? (
                    <ButtonComponent
                        role="combobox"
                        open={open}
                        tabIndex={0}
                        onBlur={onBlurCb}
                        onClick={toggleList}
                        aria-expanded={open}
                        aria-controls={listboxId}
                    />
                ) : (
                    <InputlikeDiv
                        {...rest}
                        tabIndex={0}
                        onBlur={onBlurCb}
                        onClick={toggleList}
                        onKeyPress={(e) => e.key === ' ' && toggleList()}
                        className={`${inputClassName} ${open ? 'open' : ''}`}
                        style={{
                            ...rest.style,
                            paddingRight: '32px',
                        }}
                        ref={elementRef as any}
                        role="combobox"
                        aria-expanded={open}
                        aria-controls={listboxId}
                    >
                        {selectedOption ? selectedOption.label : <span className="subtle">{placeholder}</span>}
                    </InputlikeDiv>
                )
            ) : (
                <StyledInput
                    {...rest}
                    onFocus={openList}
                    onBlur={onBlurCb}
                    value={value}
                    onChange={onInputChange}
                    className={`${inputClassName} ${open ? 'open' : ''}`}
                    ref={elementRef as any}
                    role="combobox"
                    aria-expanded={open}
                    aria-controls={listboxId}
                    autoComplete={'off'}
                    placeholder={placeholder}
                />
            )}
            {ButtonComponent ? null : (
                <StyledFontAwesomeIcon
                    icon={open ? faChevronUp : faChevronDown}
                    onClick={!open ? openList : (onBlurCb as any)}
                />
            )}
            {(open || loading) && (
                <DropDownOptionList
                    id={listboxId}
                    ref={containerRef as any}
                    role={'listbox'}
                    style={dropdownContainerStyle}
                    dropdownWidth={dropdownWidth}
                    inputRef={elementRef}
                >
                    {loading ? (
                        <li>
                            <FontAwesomeIcon icon={faSpinner} spin />
                        </li>
                    ) : filteredItems?.length ? (
                        filteredItems
                    ) : (
                        <li className="no-match">{noMatchString || t('No match')}</li>
                    )}
                </DropDownOptionList>
            )}
        </AutocompleteContainer>
    )
}
