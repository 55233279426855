import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { AdminTheme } from '../../../theme/theme'
import { Flex } from '../../../components/helpers/Flex'

export const SummaryCardContainer = withTheme(
    styled.div(({ theme }: { theme: AdminTheme }) => ({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
        gap: theme.Spacing(1),
        width: '100%',
        padding: theme.Spacing(2),
        backgroundColor: theme.BackgroundSecondary,
        borderRadius: theme.Spacing(1),
        img: {
            height: '100%',
            width: '100%',
            backgroundColor: theme.InteractiveSecondaryHover,
            border: `1px solid ${theme.BorderPrimary}`,
            borderRadius: theme.Spacing(1),
        },

        '.details-container': {
            display: 'flex',
            width: '100%',
            flex: 1,
            gap: theme.Spacing(2),
            flexWrap: 'wrap',
            '.service-summary': {
                flex: 1,
                span: {
                    color: theme.ContentSecondary,
                },
            },
            '.details-summary': {
                display: 'flex',
                flexDirection: 'column',
                width: 'max-content',
                gap: theme.Spacing(1),
            },
        },

        'span.show-hide-button': {
            color: theme.ThemeColor,
            cursor: 'pointer',
        },
    }))
)

export const ServiceDetailsContainer = withTheme(
    styled(Flex)(({ theme }: { theme: AdminTheme }) => ({
        gap: theme.Spacing(1),
    }))
)
