import { ActionWithoutPayload } from '../utils/create-action'

export enum BookingPageActionTypes {
    BOOKING_SUCCESSFUL = '[booking page] booking successful',
    SHARE = '[booking page] share',
}

type BookingSuccessfulAction = ActionWithoutPayload<typeof BookingPageActionTypes.BOOKING_SUCCESSFUL>
type ShareAction = ActionWithoutPayload<typeof BookingPageActionTypes.SHARE>

export type BookingPageActions = BookingSuccessfulAction | ShareAction
