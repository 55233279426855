import React, { FunctionComponent } from 'react'
import styled from '@emotion/styled'
import logo from '../../../icons/AnimatedLogo.svg'
import { withTheme } from '@emotion/react'
import { fromTheme } from '../../../theme/theme'

const LoadingScreenContainer = withTheme(styled.div`
    position: absolute;
    background: rgba(255, 255, 255, 0.6);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: ${fromTheme('Neutral_600')};
    &.no-background {
        background: none;
    }
`)

const AnimatedLogo = styled.img`
    width: 40px;
    margin: 24px;
`

export const LoadingScreen: FunctionComponent<
    React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
> = ({ children, ...rest }) => (
    <LoadingScreenContainer {...rest}>
        <AnimatedLogo src={logo} alt="Loading..." />
        {children}
    </LoadingScreenContainer>
)
