import { call, put, select, take } from 'redux-saga/effects'
import { GettingStartedActionTypes } from '../actions'
import { authorizedFetch } from '../../utils/authorized-fetch'
import { GettingStartedState } from '../types'
import { createAction2 } from '../../utils/create-action'

export function* saveChanges(): any {
    while (true) {
        yield take(GettingStartedActionTypes.SAVE_CHANGES)
        const state: { gettingStarted: GettingStartedState } = yield select((state) => state)
        const messageId = state.gettingStarted.progress?.messageId

        const completed =
            state.gettingStarted.currentGuide?.tasks.every((task) =>
                state.gettingStarted.progress?.tasks.some(
                    (progress) => progress.taskId === task.id && (progress.done || progress.skipped)
                )
            ) || false

        if (messageId) {
            yield call(
                authorizedFetch,
                `/api/admin/inAppMessages/updateProgress/${encodeURIComponent(messageId)}`,
                'POST',
                {
                    viewed: completed,
                    tasks: state.gettingStarted.progress?.tasks || [],
                }
            )
        }

        if (messageId && completed) {
            yield put(
                createAction2({
                    type: GettingStartedActionTypes.SET_MESSAGE_VIEWED,
                    payload: { messageId, sync: false },
                })
            )
        }
    }
}
