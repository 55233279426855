import { v4 as uuid } from 'uuid'

export interface Form {
    id?: string
    businessId?: number
    serviceId?: number
    eventId?: string
    serviceName?: number
    numberOfQuestions: number
    description: string
    questions: string
    type: 'services' | 'events'
    onlyForFirstTimeCustomers: boolean
}

export function createEmptyForm(type: 'services' | 'events'): Form {
    return {
        id: undefined,
        businessId: undefined,
        serviceId: undefined,
        serviceName: undefined,
        numberOfQuestions: 1,
        description: '',
        questions: `[{
            "id": "${uuid()}",
            "question": "",
            "type": "short",
            "required": false
        }]`,
        type,
        onlyForFirstTimeCustomers: false,
    }
}

export enum QuestionType {
    SHORT = 'short',
    MULTI_CHOICE = 'multi',
}

export interface Option {
    id: string
    value: string
}

export interface Question {
    id: string
    type: QuestionType
    required: boolean
    question: string
    isMulti?: boolean
    options?: Option[]
}
