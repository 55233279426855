import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FC, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { ModalBackground } from '../../../../components/ui-kit/admin/modal/ModalBackground'
import { ModalContainer } from '../../../../components/ui-kit/admin/modal/ModalBody'
import { CloseModalButton } from '../../../../components/ui-kit/admin/modal/ModalCloseButton'
import { ModalContent } from '../../../../components/ui-kit/admin/modal/ModalContent'
import { SecondaryButton } from '../../../../components/ui-kit/button/secondary'
import { useModal } from '../../../../hooks/use-modal'
import { useSubscriptionInfo } from '../../../../hooks/use-subscription-info'
import { BillingCycle } from '../../../../store/auth/types'
import { SubscriptionCheckoutContent } from './SubscriptionCheckout'

export const SubscriptionCheckoutPopup: FC<{ onClose: () => void; onSuccess: () => void }> = ({
    onClose,
    onSuccess,
}) => {
    const { t } = useTranslation('admin')
    const { billingCycle } = useSubscriptionInfo()
    const [cycle, setCycle] = useState<BillingCycle>(billingCycle || 'annual')

    const confirmation = useModal()
    const onCloseModal = useCallback(async () => {
        if (
            await confirmation.show({
                title: t('You have unsaved changes.'),
                text: t('Are you sure you want to close this window?'),
                cancelButtonText: t('Cancel'),
                primaryButtonComponent: SecondaryButton,
                primaryButtonText: t('Close'),
                primaryButtonClass: 'danger',
            })
        ) {
            onClose()
        }
    }, [confirmation, onClose, t])

    return (
        <ModalBackground>
            <ModalContainer width={868} style={{ minHeight: 500 }}>
                <ModalContent style={{ padding: 0 }}>
                    <CloseModalButton style={{ zIndex: 1 }} type="button" icon={faTimes} onClick={onCloseModal} />
                    <SubscriptionCheckoutContent
                        billingCycle={cycle}
                        setBillingCycle={setCycle}
                        onSuccess={onSuccess}
                    />
                </ModalContent>
            </ModalContainer>
        </ModalBackground>
    )
}
