import { FC, FormEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Flex } from '../../../../components/helpers/Flex'
import { AdminPageContent } from '../../../../components/admin/layout/AdminPageContent'
import { AdminMain } from '../../../../components/admin/layout/AdminMain'
import { useBusiness } from '../../../../hooks/use-business'
import { CardContainer } from '../../../../components/ui-kit/card/CardContainer'
import {
    faCheck,
    faCreditCard,
    faExclamationCircle,
    faFire,
    faRocket,
    faSpinner,
    faTimes,
} from '@fortawesome/free-solid-svg-icons'
import { CardInput } from '../../../../components/admin/subscription/CardInput'
import { Icon } from '../../../../components/ui-kit/comopnents/Icon'
import { InputGroup } from '../../../../components/ui-kit/comopnents/InputGroup'
import { RequiredIndicator } from '../../../../components/ui-kit/comopnents/RequiredIndicator'
import { BillingDetails, BillingDetailsForm } from '../main/BillingDetailsForm'
import { CountryCode } from '@revolut/checkout/types/types'
import { useBillingDetailsApi } from '../../../../hooks/apis/use-billing-details-api'
import { useToast } from '../../../../hooks/use-toast'
import i18n from '../../../../i18n'
import RevolutCheckout, { RevolutCheckoutCardField } from '@revolut/checkout'
import { Input } from '../../../../components/ui-kit/comopnents/Input'
import { PaymentStartDto, useSubscriptionApi } from '../../../../hooks/apis/use-subscription-api'
import { AuthActionTypes } from '../../../../store/auth/actions'
import { createAction } from '../../../../store/utils/create-action'
import { InvoiceStatus } from '../main/InvoiceHistory'
import { useDispatch } from 'react-redux'
import styled from '@emotion/styled'
import { withTheme } from '@emotion/react'
import { AdminTheme } from '../../../../theme/theme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { HorizontalDivider } from '../../../../components/ui-kit/admin/HorizontalDivider'
import { classNames } from '../../../../services/class-names'
import { useHistory } from 'react-router-dom'
import { LoadingScreen } from '../../../../components/ui-kit/comopnents/LoadingScreen'
import { AdminPageHeader } from '../../../../components/admin/layout/AdminPageHeader'
import { Column } from '../../../../components/admin/layout/Column'
import { SegmentOption, SegmentedPicker } from '../../../../components/ui-kit/admin/SegmentedPicker'
import { BillingCycle, Business, SubscriptionTier } from '../../../../store/auth/types'
import { useGetErrorMessage } from '../../../../services/getErrorMessage'
import { useFormatCurrency } from '../../../../hooks/use-format-currency'
import { LocalDate } from '../../../../components/helpers/LocalDate'
import { TextButton, TextButtonLink } from '../../../../components/ui-kit/button/TextButton'
import { LightButton } from '../../../../components/ui-kit/button/LightButton'
import { AdminCurrency } from '../../../../components/helpers/Currency'
import { NeutralButton } from '../../../../components/ui-kit/button/neutral'
import { NeutralIconButton } from '../../../../components/ui-kit/button/NeutralIconButton'
import { useMatchMedia } from '../../../../hooks/use-match-media'
import { Callout } from '../../../../components/ui-kit/comopnents/Callout'

const SubscriptionBreakpointMedia = '(min-width: 1280px)'
const SubscriptionBreakpoint = `@media${SubscriptionBreakpointMedia}`

const CheckoutContainer = styled.div(({ paymentDetailsOpen }: { paymentDetailsOpen: boolean }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    '.first-col': {
        width: '100%',
    },
    '.second-col': {
        width: '100%',
    },
    [SubscriptionBreakpoint]: {
        '.first-col': {
            display: 'inline-flex',
            width: 'calc(100% - 372px)',
        },
        '.second-col': {
            display: 'inline-flex',
            width: '372px',
        },
    },
}))

const BillingDetailsContainer = withTheme(
    styled(Column)(({ theme }: { theme: AdminTheme }) => ({
        maxWidth: '100%',
        [SubscriptionBreakpoint]: {
            paddingRight: theme.Spacing(4),
            borderRight: `1px solid ${theme.Neutral_300}`,
        },
    }))
)

const SummaryContainer = withTheme(
    styled.div(({ theme }: { theme: AdminTheme }) => ({
        flex: '0 0 auto',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.Spacing(3),
        marginTop: theme.Spacing(3),
        [SubscriptionBreakpoint]: {
            paddingLeft: theme.Spacing(4),
            marginTop: 0,
        },
    }))
)
const StyledIconContainer = withTheme(
    styled.div(({ theme }: { theme: AdminTheme }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '48px',
        height: '48px',
        fontSize: '32px',
        borderRadius: '50%',
        color: theme.Secondary3_900,
        backgroundColor: theme.Secondary3_100,
    }))
)

const StyledCardContainer = withTheme(
    styled(CardContainer)(({ theme }: { theme: AdminTheme }) => ({
        maxWidth: '100%',
        padding: theme.Spacing(4),
    }))
)

const StyledUnorderedList = withTheme(
    styled.ul(({ theme }: { theme: AdminTheme }) => ({
        margin: 0,
        paddingLeft: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: theme.Spacing(1.5),
        listStyle: 'none',

        svg: {
            color: theme.Secondary3_500,
        },
    }))
)

const FlexLabel = withTheme(
    styled.label(({ theme }: { theme: AdminTheme }) => ({
        display: 'flex',
        alignItems: 'center',
        gap: theme.Spacing(1),

        input: {
            margin: 0,
            width: 16,
            height: 16,
        },
    }))
)

const Coupon = withTheme(
    styled(Column)(({ theme }: { theme: AdminTheme }) => ({
        position: 'relative',
        padding: `${theme.Spacing(1.5)} ${theme.Spacing(2)}`,
        borderRadius: 8,
        backgroundColor: theme.BackgroundWarningSecondary,
        '.close-button': {
            position: 'absolute',
            right: theme.Spacing(2),
        },
    }))
)

const CouponIcon = withTheme(
    styled(Flex)(({ theme, size }: { theme: AdminTheme; size: number }) => ({
        width: size,
        height: size,
        borderRadius: '50%',
        backgroundColor: theme.BackgroundWarningPrimary,
        svg: {
            color: theme.BackgroundWarningSecondary,
        },
    }))
)

const InlineSpan = styled.span(() => ({
    display: 'inline-flex',
}))

const CouponInputContainer = withTheme(
    styled.div(({ theme }: { theme: AdminTheme }) => ({
        maxWidth: '100%',
        display: 'none',
        flexDirection: 'row',
        gap: theme.Spacing(2),
        input: {
            flex: '1 1 0',
            minWidth: 0,
        },
        '&.show': {
            display: 'flex',
        },
    }))
)

const Summary: FC<{
    loading: boolean
    priceInfo: PaymentStartDto
    couponCode: string | undefined
    setCouponCode: (value: string | undefined) => void
    billingCycle: BillingCycle
    setBillingCycle: (billingCycle: BillingCycle) => void
    paymentDetailsOpen: boolean
    cardValid: boolean
    submitting: boolean
    updating: boolean
    business: Business
    targetSubscription: SubscriptionTier
}> = ({
    loading,
    priceInfo,
    couponCode,
    setCouponCode,
    billingCycle,
    setBillingCycle,
    paymentDetailsOpen,
    cardValid,
    submitting,
    updating,
    business,
    targetSubscription,
}) => {
    const { t } = useTranslation('admin')
    const billingCycleOptions = useMemo<SegmentOption<BillingCycle>[]>(
        () => [
            {
                value: 'annual',
                label: <Trans ns="admin">Annual</Trans>,
            },
            {
                value: 'monthly',
                label: <Trans ns="admin">Monthly</Trans>,
            },
        ],
        []
    )
    const formatCurrency = useFormatCurrency(business.languageCode)

    const [couponInputValue, setCouponInputValue] = useState<string>('')
    const [showCouponInput, setShowCouponInput] = useState(false)
    const resetCoupon = useCallback(() => {
        setCouponCode('')
        setCouponInputValue('')
    }, [setCouponCode])

    useEffect(() => {
        if (!couponCode) {
            setCouponInputValue('')
        }
    }, [couponCode])

    const isMobileView = !useMatchMedia(SubscriptionBreakpointMedia)

    return (
        <SummaryContainer className="second-col">
            {loading ? <LoadingScreen /> : null}
            <h3 className="large semibold">
                <Trans ns="admin">Summary</Trans>
            </h3>
            <Flex gap={1.5}>
                <StyledIconContainer>
                    <FontAwesomeIcon icon={faFire} />
                </StyledIconContainer>
                <Flex grow={1} flexDirection="column" alignItems="flex-start" justifyContent="space-between">
                    <strong>Minup Pro</strong>
                    <span className="small grey">
                        {billingCycle === 'monthly' ? (
                            <Trans ns="admin">Monthly plan</Trans>
                        ) : (
                            <Trans ns="admin">Annual plan</Trans>
                        )}
                    </span>
                </Flex>
                <Flex flexDirection="column" alignItems="flex-end" justifyContent="space-between">
                    <span className={classNames(priceInfo?.discount ? 'line-through' : null)}>
                        {formatCurrency(
                            priceInfo.priceBeforeDiscount ? priceInfo.priceBeforeDiscount : priceInfo.price
                        )}
                    </span>
                    {priceInfo.discount ? (
                        <strong className="success">{t(priceInfo.discount)}</strong>
                    ) : (
                        <span>&nbsp;</span>
                    )}
                </Flex>
            </Flex>
            <StyledUnorderedList className="only-desktop">
                <li>
                    <FontAwesomeIcon icon={faCheck} className="mrs" />
                    <Trans ns="admin">Google Calendar sync</Trans>
                </li>
                <li>
                    <FontAwesomeIcon icon={faCheck} className="mrs" />
                    <Trans ns="admin">Group services</Trans>
                </li>
                <li>
                    <FontAwesomeIcon icon={faCheck} className="mrs" />
                    <Trans ns="admin">Custom email templates</Trans>
                </li>
                <li>
                    <FontAwesomeIcon icon={faCheck} className="mrs" />
                    <Trans ns="admin">Client management</Trans>
                </li>
                <li>
                    <TextButtonLink className="link" target="_blank" href={t('landing_page_pricing_url')}>
                        <Trans ns="admin">And many more</Trans>
                    </TextButtonLink>
                </li>
            </StyledUnorderedList>
            <Column>
                <SegmentedPicker grow options={billingCycleOptions} value={billingCycle} onChange={setBillingCycle} />
            </Column>
            <Column spacing={3}>
                {priceInfo.coupon ? (
                    <Coupon spacing={1}>
                        <NeutralIconButton
                            type="button"
                            className="small close-button"
                            icon={faTimes}
                            onClick={resetCoupon}
                        />
                        <CouponIcon size={24} style={{ fontSize: 12.3 }}>
                            <FontAwesomeIcon icon={priceInfo.coupon.icon || 'tag'} />
                        </CouponIcon>
                        <span className="caption semibold">{priceInfo.coupon.code}</span>
                        <span className="caption">{priceInfo.coupon.description}</span>
                    </Coupon>
                ) : (
                    <>
                        {!showCouponInput ? (
                            <div>
                                <TextButton type="button" onClick={() => setShowCouponInput(true)}>
                                    <Trans ns="admin">Add coupon</Trans>
                                </TextButton>
                            </div>
                        ) : null}
                        <CouponInputContainer className={showCouponInput ? 'show' : undefined}>
                            <Input
                                placeholder={t('Coupon code')}
                                value={couponInputValue}
                                onChange={(e) => {
                                    setCouponInputValue(e.target.value)
                                }}
                            />
                            <NeutralButton
                                type="button"
                                onClick={() => {
                                    setCouponCode(couponInputValue)
                                }}
                            >
                                <Trans ns="admin">Apply</Trans>
                            </NeutralButton>
                        </CouponInputContainer>
                    </>
                )}
            </Column>
            <Column spacing={3}>
                <Flex justifyContent="space-between">
                    {targetSubscription === 'pro-trial' ? (
                        <>
                            <span>
                                <Trans ns="admin">Price after trial</Trans>
                            </span>
                            <InlineSpan>
                                <AdminCurrency value={priceInfo.renewPrice} />
                                {priceInfo.coupon ? (
                                    <CouponIcon size={20} className="mls" style={{ fontSize: 10 }}>
                                        <FontAwesomeIcon icon={priceInfo.coupon.icon || 'tag'} />
                                    </CouponIcon>
                                ) : null}
                            </InlineSpan>
                        </>
                    ) : (
                        <>
                            <span>
                                <Trans ns="admin">Subtotal</Trans>
                            </span>
                            <InlineSpan>
                                <AdminCurrency value={priceInfo.price} />
                                {priceInfo.coupon ? (
                                    <CouponIcon size={20} className="mls" style={{ fontSize: 10 }}>
                                        <FontAwesomeIcon icon={priceInfo.coupon.icon || 'tag'} />
                                    </CouponIcon>
                                ) : null}
                            </InlineSpan>
                        </>
                    )}
                </Flex>
                <Flex justifyContent="space-between">
                    <span>
                        <Trans ns="admin">VAT</Trans>
                    </span>
                    <span>{priceInfo.vatPercent}%</span>
                </Flex>
            </Column>
            <HorizontalDivider className="p0" />
            <div>
                <Flex justifyContent="space-between" className="mbs">
                    <strong className="large semibold">
                        <Trans ns="admin">Total due today</Trans>
                    </strong>
                    <strong className="heading small">
                        <AdminCurrency value={priceInfo.grossPrice} />
                    </strong>
                </Flex>
                <div className="small grey">
                    <Trans ns="admin">
                        Renews automatically on{' '}
                        <strong>
                            <LocalDate dateTime={priceInfo.renewDate} />
                        </strong>{' '}
                        for <strong>{{ renewPrice: formatCurrency(priceInfo.renewPrice) }}</strong> +{' '}
                        {{ vatPercent: priceInfo.vatPercent }}% VAT.
                    </Trans>
                </div>
            </div>
            {!isMobileView && paymentDetailsOpen ? (
                <Column>
                    <InputGroup>
                        <FlexLabel style={{ fontWeight: 'normal', fontSize: '16px' }}>
                            <input type="checkbox" required />
                            <div>
                                <Trans ns="admin">
                                    I have read and accept the{' '}
                                    <a target="_blank" rel="noreferrer" href={t('privacy_policy_url')}>
                                        Privacy Policy
                                    </a>{' '}
                                    and{' '}
                                    <a target="_blank" rel="noreferrer" href={t('terms_and_conditions_url')}>
                                        General Terms & Conditions
                                    </a>
                                </Trans>
                                <RequiredIndicator />
                            </div>
                        </FlexLabel>
                    </InputGroup>
                    <LightButton variant="Pro" disabled={cardValid === false || submitting || updating}>
                        {submitting ? (
                            <>
                                <Icon className="mrs" spin icon={faSpinner} />
                                <Trans ns="admin">Processing...</Trans>
                            </>
                        ) : business.subscriptionTier === 'pro' && business.isCancelPending ? (
                            <>
                                <Icon className="mrs" icon={faRocket} />
                                <Trans ns="admin">Renew</Trans>
                            </>
                        ) : business.proTrialAvailable ? (
                            <>
                                <Icon className="mrs only-desktop" icon={faRocket} />
                                <Trans ns="admin">Start free trial</Trans>
                            </>
                        ) : (
                            <>
                                <Icon className="mrs" icon={faRocket} />
                                <Trans ns="admin">Upgrade</Trans>
                            </>
                        )}
                    </LightButton>
                </Column>
            ) : null}
        </SummaryContainer>
    )
}

export const SubscriptionCheckout: FC<{
    billingCycle: BillingCycle
    setBillingCycle: (billingCycle: BillingCycle) => void
}> = ({ billingCycle, setBillingCycle }) => {
    const business = useBusiness()!
    const history = useHistory()

    const onSuccess = useCallback(
        (subscriptionTier: SubscriptionTier) => {
            history.push(`/admin/subscription/success?target=${subscriptionTier}`)
        },
        [history]
    )

    const isTrial = business.subscriptionTier === 'free' && business.proTrialAvailable

    return (
        <AdminMain className="admin-main">
            <AdminPageHeader
                backLink="/admin/subscription"
                title={
                    business.subscriptionTier === 'free' ? (
                        isTrial ? (
                            <Trans ns="admin">Start free trial</Trans>
                        ) : (
                            <Trans ns="admin">Upgrade</Trans>
                        )
                    ) : business.isCancelPending ? (
                        <Trans ns="admin">Renew</Trans>
                    ) : (
                        <Trans ns="admin">Subscription</Trans>
                    )
                }
            />
            <AdminPageContent style={{ paddingTop: 0, maxWidth: 1014 }}>
                <SubscriptionCheckoutContent
                    billingCycle={billingCycle}
                    setBillingCycle={setBillingCycle}
                    onSuccess={onSuccess}
                />
            </AdminPageContent>
        </AdminMain>
    )
}

export const SubscriptionCheckoutContent: FC<{
    billingCycle: BillingCycle
    setBillingCycle: (billingCycle: BillingCycle) => void
    onSuccess?: (subscriptionTier: SubscriptionTier) => void
}> = ({ billingCycle, setBillingCycle, onSuccess }) => {
    const business = useBusiness()!
    const [billingAddress, setBillingAddress] = useState<BillingDetails>({
        billingName: '',
        country: 'HU',
        city: '',
        streetAddress: '',
        zipCode: '',
        taxID: '',
    })
    const { t } = useTranslation('admin')
    const cardFieldRef = useRef<HTMLDivElement | null>(null)
    const cardRef = useRef<RevolutCheckoutCardField | null>(null)
    const billingDetailsApi = useBillingDetailsApi()
    const [nameOnCard, setNameOnCard] = useState('')
    const [cardValid, setCardvalid] = useState<boolean>(true)
    const [cardReady, setCardReady] = useState<boolean>(false)
    const [submitting, setSubmitting] = useState(false)
    const { startPro, startTrial, finalizePayment, updateOrder } = useSubscriptionApi()
    const dispatch = useDispatch()
    const toast = useToast()
    const [loading, setLoading] = useState(true)
    const [updating, setUpdating] = useState(false)

    const [priceInfo, setPriceInfo] = useState<PaymentStartDto>()

    const [paymentDetailsOpen, setPaymentDeteailsOpen] = useState(false)
    const [couponCode, setCouponCode] = useState<string | undefined>(undefined)

    const firstLoad = useRef(true)

    const getErrorMessage = useGetErrorMessage()
    const isMobileView = !useMatchMedia(SubscriptionBreakpointMedia)

    useEffect(() => {
        if (!priceInfo?.paymentId) {
            setLoading(true)
            ;(async () => {
                try {
                    const billingDetailsResponse = await billingDetailsApi.get()
                    const billingDetails = billingDetailsResponse.data
                    if (billingDetails) {
                        setBillingAddress(billingDetails)
                    }

                    let request
                    if (business.subscriptionTier === 'free' && business.proTrialAvailable) {
                        request = await startTrial(
                            billingCycle,
                            billingDetails?.country || 'HU',
                            billingDetails?.taxID || ''
                        )
                    } else {
                        request = await startPro(
                            billingCycle,
                            billingDetails?.country || 'HU',
                            billingDetails?.taxID || ''
                        )
                    }
                    const response = await request
                    setPriceInfo(response.data)
                    setLoading(false)
                } catch (e) {
                    toast.error(await getErrorMessage(e))
                }
            })()
        }
    }, [
        billingAddress.country,
        billingAddress.taxID,
        billingCycle,
        billingDetailsApi,
        business.isCancelPending,
        business.proTrialAvailable,
        business.subscriptionTier,
        couponCode,
        getErrorMessage,
        priceInfo?.invoiceId,
        priceInfo?.paymentId,
        startPro,
        startTrial,
        t,
        toast,
        updateOrder,
    ])

    const updatePrices = useCallback(async () => {
        setUpdating(true)
        try {
            const response = await updateOrder(
                business.subscriptionTier === 'free' && business.proTrialAvailable
                    ? 'pro-trial'
                    : business.isCancelPending
                      ? 'pro-resume'
                      : 'pro',
                billingCycle,
                billingAddress.country,
                billingAddress.taxID || '',
                priceInfo?.invoiceId,
                couponCode
            )
            setPriceInfo(response.data)
        } catch (e) {
            toast.error(await getErrorMessage(e, { coupon: couponCode }))
        }
        setUpdating(false)
    }, [
        billingAddress.country,
        billingAddress.taxID,
        billingCycle,
        business.isCancelPending,
        business.proTrialAvailable,
        business.subscriptionTier,
        couponCode,
        getErrorMessage,
        priceInfo?.invoiceId,
        toast,
        updateOrder,
    ])

    useEffect(() => {
        if (priceInfo?.invoiceId) {
            if (firstLoad.current) {
                firstLoad.current = false
                return
            }
            const timeout = setTimeout(() => {
                updatePrices()
            }, 300)
            return () => clearTimeout(timeout)
        }
    }, [priceInfo?.invoiceId, updatePrices])

    const onPaymentSuccess = useCallback(async () => {
        try {
            if (!priceInfo?.invoiceId) {
                throw new Error('No invoice id found')
            }
            const invoiceResponse = await billingDetailsApi.get()
            setBillingAddress(
                invoiceResponse.data ||
                    ({
                        billingName: '',
                        country: 'HU',
                        city: '',
                        streetAddress: '',
                        zipCode: '',
                        taxID: '',
                    } as BillingDetails)
            )

            let response
            do {
                response = await finalizePayment(priceInfo?.invoiceId)
            } while (response.data.status !== InvoiceStatus.Successful)
            dispatch(createAction(AuthActionTypes.UPDATE_BUSINESS, response.data))

            if (onSuccess) {
                onSuccess(response.data.subscriptionTier)
            }
        } catch (e) {
            toast.error(t('Payment failed'))
        }
    }, [priceInfo?.invoiceId, billingDetailsApi, dispatch, onSuccess, finalizePayment, toast, t])

    const initRevolutCardField = useCallback(async () => {
        if (!loading && priceInfo?.paymentId && priceInfo?.invoiceId && cardFieldRef.current) {
            const instance = await RevolutCheckout(
                priceInfo?.paymentId,
                process.env.REACT_APP_IS_REVOLUT_SANDBOX ? 'sandbox' : undefined
            )
            cardRef.current = instance.createCardField({
                locale: i18n.language as any,
                target: cardFieldRef.current!,
                savePaymentMethodFor: 'merchant',
                async onSuccess() {
                    try {
                        await onPaymentSuccess()
                    } finally {
                        setSubmitting(false)
                    }
                },
                onStatusChange(status) {
                    setCardReady(status.completed)
                },
                onValidation(errors) {
                    setCardvalid(errors.length === 0)
                },
                onError(message) {
                    setSubmitting(false)
                    toast.error(t('Could not process payment'), t(`revolut_error_${message.type}`))
                    console.error(message.type)
                    console.error(message)
                },
            })
        }
    }, [loading, onPaymentSuccess, priceInfo?.invoiceId, priceInfo?.paymentId, t, toast])

    useEffect(() => {
        initRevolutCardField()
    }, [initRevolutCardField])

    const submitForm = useCallback(
        async (e: FormEvent) => {
            e.preventDefault()
            if (!cardReady) {
                cardRef.current?.validate()
                return
            }
            if (!cardValid) {
                return
            }
            setSubmitting(true)
            await billingDetailsApi.save(billingAddress)
            cardRef.current?.submit({
                name: nameOnCard,
                email: business.email,
                billingAddress: {
                    countryCode: billingAddress.country as CountryCode,
                    postcode: billingAddress.zipCode,
                },
            })
        },
        [billingAddress, billingDetailsApi, business.email, cardReady, cardValid, nameOnCard]
    )

    const openPaymentDetails = useCallback(
        async (e) => {
            e.preventDefault()
            setPaymentDeteailsOpen(true)
            setTimeout(() => initRevolutCardField())
        },
        [initRevolutCardField]
    )

    const onSetBillingCycle = useCallback(
        (value: BillingCycle) => {
            setBillingCycle(value)
            setCouponCode('')
        },
        [setBillingCycle]
    )

    useEffect(() => {
        if (paymentDetailsOpen) {
            setTimeout(() => {
                cardFieldRef.current?.scrollIntoView({ behavior: 'smooth' })
            })
        }
    }, [paymentDetailsOpen])

    const isTrial = business.subscriptionTier === 'free' && business.proTrialAvailable

    const paymentDetailsSecion = useMemo(
        () =>
            paymentDetailsOpen ? (
                <Column grow>
                    <h3 className="large semibold">
                        <Trans ns="admin">Payment details</Trans>
                    </h3>

                    <InputGroup>
                        <label htmlFor="nameOnCard">
                            <Trans ns="admin">Name on card</Trans>
                            <RequiredIndicator />
                        </label>
                        <Input
                            autoFocus
                            id="nameOnCard"
                            pattern="[\S]+ [\S]+.*"
                            value={nameOnCard}
                            required
                            onChange={(e) => setNameOnCard(e.currentTarget.value)}
                        />
                    </InputGroup>

                    <InputGroup>
                        <label>
                            <Trans ns="admin">Card</Trans>
                            <RequiredIndicator />
                        </label>
                        <CardInput className={cardValid ? undefined : 'error'} ref={cardFieldRef} />
                    </InputGroup>
                </Column>
            ) : (
                <Flex className="w100" flexDirection="column" alignItems="stretch" gap={3}>
                    {/* Temporarily disable subscription */}
                    <Callout variant="Secondary4" className="only-desktop">
                        <Flex alignItems="flex-start" justifyContent="flex-start" flexDirection="column">
                            <Icon icon={faExclamationCircle} className="icon top" />
                            <Trans ns="admin">
                                Our payment system is currently unavailable, so we can’t process new subscriptions right
                                now. We apologize for the inconvenience and are working to fix the issue as soon as
                                possible!
                            </Trans>
                        </Flex>
                    </Callout>
                    <LightButton variant="Pro" type="button" onClick={openPaymentDetails} disabled={true || updating}>
                        <Icon className="mrs" icon={faCreditCard} />
                        <Trans ns="admin">Add payment details</Trans>
                    </LightButton>
                </Flex>
            ),
        [cardValid, nameOnCard, openPaymentDetails, paymentDetailsOpen, updating]
    )

    return (
        <>
            {loading ? (
                <LoadingScreen />
            ) : (
                <StyledCardContainer className="p" id="subscription-checkout">
                    <form onSubmit={submitForm} style={{ display: 'contents' }}>
                        <CheckoutContainer paymentDetailsOpen={paymentDetailsOpen}>
                            <BillingDetailsContainer className="first-col">
                                <h3 className="large semibold">
                                    <Trans ns="admin">Billing details</Trans>
                                </h3>
                                {/* Temporarily disable subscription */}
                                <Callout variant="Secondary4" className="only-mobile">
                                    <Flex alignItems="flex-start" justifyContent="flex-start" flexDirection="column">
                                        <Icon icon={faExclamationCircle} className="icon top" />
                                        <Trans ns="admin">
                                            Our payment system is currently unavailable, so we can’t process new
                                            subscriptions right now. We apologize for the inconvenience and are working
                                            to fix the issue as soon as possible!
                                        </Trans>
                                    </Flex>
                                </Callout>

                                <p>
                                    {business.proTrialAvailable ? (
                                        <>
                                            <Trans ns="admin">
                                                You'll <b>not be charged</b> until your free trial ends.
                                            </Trans>{' '}
                                            <Trans ns="admin">
                                                You can <b>cancel anytime</b> during your trial.
                                            </Trans>{' '}
                                            <Trans ns="admin">
                                                We'll <b>remind you 7 days before</b> your trial ends.
                                            </Trans>
                                        </>
                                    ) : (
                                        <>
                                            👉&nbsp;
                                            <Trans ns="admin">
                                                Make sure your billing details are correct. We'll use this info to
                                                generate an invoice for you.
                                            </Trans>
                                        </>
                                    )}
                                </p>
                                <BillingDetailsForm
                                    value={billingAddress as any}
                                    onChange={(values) => setBillingAddress(values)}
                                />
                                {isMobileView ? null : paymentDetailsSecion}
                            </BillingDetailsContainer>
                            {priceInfo && (business.subscriptionTier === 'free' || business.isCancelPending) ? (
                                <Summary
                                    loading={updating}
                                    priceInfo={priceInfo}
                                    couponCode={couponCode}
                                    setCouponCode={setCouponCode}
                                    billingCycle={billingCycle}
                                    setBillingCycle={onSetBillingCycle}
                                    paymentDetailsOpen={paymentDetailsOpen}
                                    cardValid={cardValid}
                                    submitting={submitting}
                                    updating={updating}
                                    business={business}
                                    targetSubscription={isTrial ? 'pro-trial' : 'pro'}
                                />
                            ) : null}
                            {isMobileView ? (
                                <Flex className="w100" my={3}>
                                    {paymentDetailsSecion}
                                </Flex>
                            ) : null}
                            {isMobileView && paymentDetailsOpen ? (
                                <Column>
                                    <InputGroup>
                                        <FlexLabel style={{ fontWeight: 'normal', fontSize: '16px' }}>
                                            <input type="checkbox" required />
                                            <div>
                                                <Trans ns="admin">
                                                    I have read and accept the{' '}
                                                    <a target="_blank" rel="noreferrer" href={t('privacy_policy_url')}>
                                                        Privacy Policy
                                                    </a>{' '}
                                                    and{' '}
                                                    <a
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        href={t('terms_and_conditions_url')}
                                                    >
                                                        General Terms & Conditions
                                                    </a>
                                                </Trans>
                                                <RequiredIndicator />
                                            </div>
                                        </FlexLabel>
                                    </InputGroup>
                                    <LightButton variant="Pro" disabled={cardValid === false || submitting || updating}>
                                        {submitting ? (
                                            <>
                                                <Icon className="mrs" spin icon={faSpinner} />
                                                <Trans ns="admin">Processing...</Trans>
                                            </>
                                        ) : business.subscriptionTier === 'pro' && business.isCancelPending ? (
                                            <>
                                                <Icon className="mrs" icon={faRocket} />
                                                <Trans ns="admin">Renew</Trans>
                                            </>
                                        ) : business.proTrialAvailable ? (
                                            <>
                                                <Icon className="mrs only-desktop" icon={faRocket} />
                                                <Trans ns="admin">Start free trial</Trans>
                                            </>
                                        ) : (
                                            <>
                                                <Icon className="mrs" icon={faRocket} />
                                                <Trans ns="admin">Upgrade</Trans>
                                            </>
                                        )}
                                    </LightButton>
                                </Column>
                            ) : null}
                        </CheckoutContainer>
                    </form>
                </StyledCardContainer>
            )}
        </>
    )
}
