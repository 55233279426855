import moment from 'moment'
import { FC, useMemo } from 'react'

export const UtcTimestamp: FC<{ tz?: string; format: string; timestamp: number }> = ({ tz, format, timestamp }) => {
    const t = useMemo(() => {
        let m = moment.unix(timestamp).utc()
        if (tz) {
            m.tz(tz)
        }
        return m
    }, [timestamp, tz])
    return <>{t.format(format)}</>
}
