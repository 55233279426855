import { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react'
import { Trans } from 'react-i18next'
import { useOnClickOutside } from '../../hooks/use-on-click-outside'
import { Flex } from '../helpers/Flex'
import { ThemeProvider, withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { AdminTheme } from '../../theme/theme'
import { classNames } from '../../services/class-names'
import { EmojiContainer } from '../ui-kit/admin/EmojiContainer'
import { useEmojiGroups, useEmojis } from '../../hooks/use-emojis'
import { TextButton } from '../ui-kit/button/TextButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImage } from '@fortawesome/free-solid-svg-icons'
import { useFreeSolidIconCategories, useIcons } from '../../hooks/use-icons'
import { createTheme } from '../../theme/ThemeProvider'

const PickerContainer = withTheme(
    styled(Flex)(({ theme }: { theme: AdminTheme }) => ({
        minWidth: 294,
        padding: theme.Spacing(0.5),
        backgroundColor: theme.Neutral_0,
        border: `1px solid ${theme.Neutral_200}`,
        borderRadius: 8,
        boxShadow: theme.Elevation_300,
        zIndex: 10,
        position: 'absolute',
        top: 75,
        cursor: 'default',
    }))
)

const PickerHeader = withTheme(
    styled(Flex)(({ theme }: { theme: AdminTheme }) => ({
        padding: `${theme.Spacing(1.5)} ${theme.Spacing(2)} 0 ${theme.Spacing(2)}`,
        span: {
            cursor: 'pointer',
            position: 'relative',
            paddingBottom: theme.Spacing(1),
        },
    }))
)

const PickerContent = withTheme(
    styled(Flex)(({ theme }: { theme: AdminTheme }) => ({
        padding: `0 ${theme.Spacing(2)}`,
        margin: `${theme.Spacing(1.5)} 0`,
        height: 200,
        overflow: 'auto',
        gap: theme.Spacing(1.5),
    }))
)

const IconsContainer = withTheme(
    styled.div(({ theme }: { theme: AdminTheme }) => ({
        display: 'grid',
        gridTemplateColumns: 'repeat(8, 1fr)',
        gap: theme.Spacing(0.5),
    }))
)

const GroupTitle = withTheme(
    styled.h2(({ theme }: { theme: AdminTheme }) => ({
        gridColumn: 'span 8',
        color: theme.ContentSecondary,
    }))
)

const ClickableIconContainer = withTheme(
    styled(EmojiContainer)(({ theme }: { theme: AdminTheme }) => ({
        cursor: 'pointer',
        padding: 6,
        borderRadius: 4,
        '&:hover': {
            backgroundColor: theme.InteractiveNeutralHover,
        },
        '&:active': {
            backgroundColor: theme.InteractiveNeutralActive,
        },
    }))
)

const PickerDivider = withTheme(
    styled.div(({ theme }: { theme: AdminTheme }) => ({
        width: '100%',
        height: 1,
        borderBottom: `1px solid ${theme.BorderPrimary}`,
    }))
)

const ImagePickerHeader = withTheme(
    styled.nav(({ theme }: { theme: AdminTheme }) => ({
        width: '100%',
        overflowX: 'auto',
        overflowY: 'hidden',
        flex: '0 0 auto',
        display: 'flex',
        gap: `${theme.Spacing(1.5)}`,
    }))
)

const ImageTypes = withTheme(
    styled.span(({ theme }: { theme: AdminTheme }) => ({
        color: theme.ContentTertiary,
    }))
)

const ImagePickerHeaderItem = withTheme(
    styled.div(({ theme }: { theme: AdminTheme }) => ({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'center',
        position: 'relative',
        color: theme.ContentTertiary,

        '&.active': {
            color: theme.ContentPrimary,
            '::before': {
                content: '" "',
                display: 'inline-block',
                position: 'absolute',
                left: 0,
                right: 0,
                bottom: 0,
                height: 5,
                marginBottom: -6,
                borderTopWidth: 5,
                borderTopStyle: 'solid',
                borderTopColor: theme.BackgroundInverted,
                borderRadius: 4,
            },
        },
    }))
)

const PickerOptions: FunctionComponent<{
    selected: 'image' | 'icons' | 'emojis'
    setSelected: (value: 'image' | 'icons' | 'emojis') => void
}> = ({ selected, setSelected }) => {
    const onKeyDown = useCallback(
        (e, type: 'image' | 'icons' | 'emojis') => {
            if (e.key === 'Enter' || e.key === ' ') {
                setSelected(type)
            }
        },
        [setSelected]
    )

    return (
        <ImagePickerHeader>
            <ImagePickerHeaderItem
                className={classNames(selected === 'image' ? 'active' : null)}
                tabIndex={0}
                onClick={() => setSelected('image')}
                onKeyDown={(e) => onKeyDown(e, 'image')}
            >
                <span className="small">
                    <Trans ns="admin">Image</Trans>
                </span>
            </ImagePickerHeaderItem>
            <ImagePickerHeaderItem
                className={classNames(selected === 'icons' ? 'active' : null)}
                tabIndex={0}
                onClick={() => setSelected('icons')}
                onKeyDown={(e) => onKeyDown(e, 'icons')}
            >
                <span className={'small'}>
                    <Trans ns="admin">Icons</Trans>
                </span>
            </ImagePickerHeaderItem>
            <ImagePickerHeaderItem
                className={classNames(selected === 'emojis' ? 'active' : null)}
                tabIndex={0}
                onClick={() => setSelected('emojis')}
                onKeyDown={(e) => onKeyDown(e, 'emojis')}
            >
                <span className={'small'}>
                    <Trans ns="admin">Emojis</Trans>
                </span>
            </ImagePickerHeaderItem>
        </ImagePickerHeader>
    )
}

export const ImagePicker: FunctionComponent<{
    type: 'image' | 'icons' | 'emojis'
    onUpload: (e: any) => void
    onIconSelected?: (src: string) => Promise<boolean>
    onClose: () => void
}> = ({ type, onUpload, onIconSelected, onClose }) => {
    const pickerRef = useRef<HTMLDivElement>(null)
    const [selected, setSelected] = useState<'image' | 'icons' | 'emojis'>(type)
    const emojiGroups = useEmojiGroups()
    const emojis = useEmojis()
    const theme = createTheme('minup')
    const icons = useIcons()
    const iconCategories = useFreeSolidIconCategories()
    const [saving, setSaving] = useState<string>()

    useEffect(() => {
        if (pickerRef.current) {
            pickerRef.current.focus()
        }
    }, [])

    const onClickOutside = useCallback(() => {
        onClose()
    }, [onClose])

    useOnClickOutside(pickerRef, onClickOutside, 'mouseup')

    const handleIconSelect = useCallback(
        async (type: 'icon' | 'emoji', id: string) => {
            if (saving) {
                return
            }
            setSaving(id)
            const src = `${type}::${id}`
            if (onIconSelected) {
                await onIconSelected(src)
            }
            setSaving(undefined)
            onClose()
        },
        [onClose, onIconSelected, saving]
    )

    const handleIconPress = useCallback(
        (e, type: 'icon' | 'emoji', id: string) => {
            if (e.key === 'Enter' || e.key === ' ') {
                if (saving) {
                    return
                }
                handleIconSelect(type, id)
            }
        },
        [handleIconSelect, saving]
    )

    const handleUpload = useCallback(
        async (e) => {
            onUpload(e)
            onClose()
        },
        [onClose, onUpload]
    )

    return (
        <ThemeProvider theme={theme}>
            <PickerContainer
                className="image-picker"
                flexDirection="column"
                alignItems="flex-start"
                ref={pickerRef as any}
                tabIndex={0}
            >
                <PickerHeader gap={1.5}>
                    <PickerOptions selected={selected} setSelected={setSelected} />
                </PickerHeader>
                <PickerDivider />
                <PickerContent
                    className="w100"
                    flexDirection="column"
                    alignItems={selected === 'image' ? 'center' : 'flex-start'}
                    justifyContent={selected === 'image' ? 'center' : 'flex-start'}
                >
                    {selected === 'image' ? (
                        <Flex flexDirection="column" gap={1.5}>
                            <FontAwesomeIcon icon={faImage} color={theme.ContentSecondary} style={{ fontSize: 24 }} />
                            <TextButton onClick={handleUpload} tabIndex={0} type="button">
                                <Trans ns="admin">Upload image</Trans>
                            </TextButton>
                            <ImageTypes className="caption">
                                <Trans ns="admin">JPEG, PNG, SVG...</Trans>
                            </ImageTypes>
                        </Flex>
                    ) : selected === 'icons' ? (
                        <>
                            {Object.entries(iconCategories).map(([categoryId, categoryName]) => {
                                const iconsForCategory = Object.values(icons).filter((icon) =>
                                    icon.category.includes(categoryId as keyof typeof stringToUTF8)
                                )
                                return iconsForCategory.length ? (
                                    <IconsContainer key={categoryId}>
                                        <GroupTitle className="caption semibold">{categoryName}</GroupTitle>
                                        {iconsForCategory.map((icon) => (
                                            <ClickableIconContainer
                                                key={icon.id}
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                                tabIndex={0}
                                                onClick={() => handleIconSelect('icon', icon.id)}
                                                onKeyDown={(e) => handleIconPress(e, 'icon', icon.id)}
                                                role="img"
                                            >
                                                {saving === icon.id ? (
                                                    <FontAwesomeIcon
                                                        icon={'spinner'}
                                                        spin
                                                        color={theme.ContentSecondary}
                                                        aria-label="Saving, please wait"
                                                        aria-live="polite"
                                                    />
                                                ) : (
                                                    <FontAwesomeIcon
                                                        icon={icon.icon as any}
                                                        color={theme.ContentSecondary}
                                                        aria-label={`Icon: ${icon.icon[1].replace('-', ' ')}`}
                                                    />
                                                )}
                                            </ClickableIconContainer>
                                        ))}
                                    </IconsContainer>
                                ) : null
                            })}
                        </>
                    ) : (
                        <>
                            {Object.entries(emojiGroups).map(([groupId, groupName]) => {
                                const emojisForGroup = Object.values(emojis).filter((emoji) => emoji.group === groupId)
                                return emojisForGroup.length ? (
                                    <IconsContainer key={groupId}>
                                        <GroupTitle className="caption semibold">{groupName}</GroupTitle>
                                        {emojisForGroup.map((emoji) => (
                                            <ClickableIconContainer
                                                key={emoji.id}
                                                tabIndex={0}
                                                onClick={() => handleIconSelect('emoji', emoji.id)}
                                                onKeyDown={(e) => handleIconPress(e, 'emoji', emoji.id)}
                                                role="img"
                                            >
                                                {saving === emoji.id ? (
                                                    <FontAwesomeIcon
                                                        icon={'spinner'}
                                                        spin
                                                        color={theme.ContentSecondary}
                                                        aria-label="Saving, please wait"
                                                        aria-live="polite"
                                                    />
                                                ) : (
                                                    emoji.emoji
                                                )}
                                            </ClickableIconContainer>
                                        ))}
                                    </IconsContainer>
                                ) : null
                            })}
                        </>
                    )}
                </PickerContent>
            </PickerContainer>
        </ThemeProvider>
    )
}
