import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { fromTheme } from '../../../theme/theme'

export const HorizontalDivider = withTheme(
    styled.div`
        width: 100%;
        &::before {
            display: block;
            content: '';
            border-bottom: 1px solid ${fromTheme('BorderPrimary')};
        }
    `
)
