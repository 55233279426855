import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { FC, ComponentProps } from 'react'
import { AdminTheme } from '../../../theme/theme'

const GoogleMapsLinkContainer = withTheme(
    styled.div(({ theme }: { theme: AdminTheme }) => ({
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        gap: theme.Spacing(1),
        '.icon': {
            color: theme.ContentSecondary,
            fontSize: 16,
        },
        a: {
            textDecoration: 'underline',
        },
    }))
)

export const GoogleMapsLink: FC<{ address: string } & ComponentProps<typeof GoogleMapsLinkContainer>> = ({
    address,
    children,
    ...rest
}) => {
    const link = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`
    return (
        <GoogleMapsLinkContainer {...rest}>
            📍
            <a href={link} target="_blank" rel="noreferrer">
                {children}
            </a>
        </GoogleMapsLinkContainer>
    )
}
