import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { AdminTheme } from '../../../../theme/theme'

export const ModalBackground = withTheme(
    styled.div(({ theme, fullScreen }: { theme: AdminTheme; fullScreen?: boolean }) => ({
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 9999,
        background: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.Spacing(2),

        ...(fullScreen && {
            [theme.BreakPointReverse]: {
                padding: 0,
            },
        }),
    }))
)
