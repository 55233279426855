import { FC, ReactNode, useEffect } from 'react'
import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { faCheck, faExclamationCircle, faInfoCircle, faTimes } from '@fortawesome/free-solid-svg-icons'
import { useDispatch, useSelector } from 'react-redux'
import { Flex } from '../../components/helpers/Flex'
import { NeutralIconButton } from '../../components/ui-kit/button/NeutralIconButton'
import { Icon } from '../../components/ui-kit/comopnents/Icon'
import { NotificationActionTypes } from '../../store/notifications/actions'
import { NotificationsState, Notification, NotificationType } from '../../store/notifications/types'
import { createAction } from '../../store/utils/create-action'
import { fromTheme } from '../../theme/theme'
import { useTranslation } from 'react-i18next'
import { useCurrentMessage } from '../../store/getting-started/hooks'

function getNotificationIcon(type: NotificationType): ReactNode | undefined {
    switch (type) {
        case NotificationType.Info:
            return <Icon icon={faInfoCircle} />
        case NotificationType.Success:
            return <Icon icon={faCheck} />
        case NotificationType.Warning:
            return <Icon icon={faExclamationCircle} />
        case NotificationType.Error:
            return <Icon icon={faExclamationCircle} />
    }
}

const NotificationContainer = styled.div`
    z-index: 99999;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    padding: 0 16px;
    &.with-fab {
        right: 70px;
    }
`

const ToastContainer = withTheme(styled.div`
    position: relative;
    background-color: white;
    border-radius: 8px;
    display: flex;
    padding-right: 56px;
    margin-bottom: 16px;
    box-shadow: ${fromTheme('Elevation_300')};

    width: 100%;
    ${fromTheme('BreakPoint')} {
        width: auto;
        min-width: 400px;
    }

    h3 {
        ${fromTheme('BodySmallSemibold')};
    }

    p {
        ${fromTheme('BodySmall')};
    }
    overflow: hidden;
    pointer-events: initial;

    .toast-content {
        padding: 10px 0;
        flex-wrap: wrap;
        .toast-text {
        }
    }
`)

const ToastIconContainer = withTheme(styled.div`
    width: 64px;
    flex-grow: 0;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;

    &.type-${NotificationType.Info} {
        background-color: ${fromTheme('Primary_700')};
    }

    &.type-${NotificationType.Success} {
        background-color: ${fromTheme('Secondary1_700')};
    }

    &.type-${NotificationType.Warning} {
        background-color: ${fromTheme('Secondary2_700')};
    }

    &.type-${NotificationType.Error} {
        background-color: ${fromTheme('Secondary4_700')};
    }

    &.type-${NotificationType.Black} {
        background-color: ${fromTheme('BackgroundInverted')};
    }

    &.small {
        width: 48px;
        min-height: 48px;
    }
`)

const CloseButtonContainer = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    padding-right: 16px;
`

const Toast: FC<{ notification: Notification }> = ({ notification }) => {
    const dispatch = useDispatch()
    const { t } = useTranslation('admin')

    useEffect(() => {
        if (notification.closeAfter) {
            const timeout = setTimeout(
                () => dispatch(createAction(NotificationActionTypes.REMOVE_NOTIFICATION, notification.id)),
                notification.closeAfter * 1000
            )
            return () => clearTimeout(timeout)
        }
    }, [notification.id, dispatch, notification.closeAfter])

    return (
        <ToastContainer
            id={`toast-${notification.id}`}
            role="alert"
            aria-live="assertive"
            aria-relevant="additions removals"
            aria-atomic="true"
            tabIndex={0}
        >
            <ToastIconContainer className={'mrm type-' + notification.type + (notification.message ? '' : ' small')}>
                {notification.icon || getNotificationIcon(notification.type)}
            </ToastIconContainer>
            <Flex className="toast-content" alignItems="center" justifyContent="space-between">
                <Flex className="toast-text mrm" flexDirection="column" alignItems="flex-start">
                    {notification.message && <h3>{notification.title}</h3>}
                    <p>{notification.message || notification.title}</p>
                </Flex>
                {notification.cta && <Flex>{notification.cta}</Flex>}
            </Flex>
            <CloseButtonContainer>
                <NeutralIconButton
                    aria-label={t('Close')}
                    icon={faTimes}
                    onClick={() => dispatch(createAction(NotificationActionTypes.REMOVE_NOTIFICATION, notification.id))}
                />
            </CloseButtonContainer>
        </ToastContainer>
    )
}

export const Notifications: FC = () => {
    const notifications = useSelector(
        (state: { notifications: NotificationsState }) => state.notifications.notifications
    )

    const currentMessage = useCurrentMessage()
    // We don't show the onboarding FAB on the subscription page
    const hasFab =
        currentMessage?.type === 'getting-started' && !window.location.pathname.startsWith('/admin/subscription')

    return (
        <NotificationContainer className={hasFab ? 'with-fab' : ''}>
            {(notifications || []).reverse().map((n, i) => (
                <Toast key={n.id || i} notification={n} />
            ))}
        </NotificationContainer>
    )
}
