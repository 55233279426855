import { FC, ReactNode, ComponentProps, useMemo } from 'react'
import { Trans } from 'react-i18next'
import { useSetAdminMenuOpen } from '../../../hooks/admin/use-set-admin-menu-open'
import { useSubscriptionInfo } from '../../../hooks/use-subscription-info'
import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { AdminTheme } from '../../../theme/theme'
import { LightButtonNavLink } from '../../ui-kit/button/LightButton'

const LightButtonNavLinkContainer = withTheme(
    styled(LightButtonNavLink)(({ theme }: { theme: AdminTheme }) => ({
        padding: `${theme.Spacing(1)} ${theme.Spacing(2)}`,
        gap: theme.Spacing(1),
    }))
)

export const UpgradeButton: FC<
    { trialtext?: ReactNode; straigthToCheckout?: boolean } & Omit<
        ComponentProps<typeof LightButtonNavLinkContainer>,
        'className' | 'variant' | 'to'
    >
> = ({ trialtext, straigthToCheckout, ...rest }) => {
    const { proTrialAvailable } = useSubscriptionInfo()
    const setMenuOpen = useSetAdminMenuOpen()

    const link = useMemo(() => {
        return straigthToCheckout
            ? proTrialAvailable
                ? '/admin/subscription/checkout/trial'
                : '/admin/subscription/checkout/pro'
            : '/admin/subscription'
    }, [proTrialAvailable, straigthToCheckout])

    return (
        <LightButtonNavLinkContainer
            className={'centered'}
            variant="Pro"
            to={link}
            onClick={() => setMenuOpen(false)}
            {...rest}
        >
            {proTrialAvailable ? <Trans ns="admin">Start Free Trial</Trans> : <Trans ns="admin">Upgrade</Trans>}
        </LightButtonNavLinkContainer>
    )
}
