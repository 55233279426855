import { ChangeEvent, FC, HTMLAttributes, ReactNode, useCallback, useMemo, useRef, useState } from 'react'
import { ThemeProvider, withTheme } from '@emotion/react'
import styled from '@emotion/styled/macro'
import { AdminTheme, fromTheme } from '../../../theme/theme'
import defaultAvatar from '../../../icons/DefaultAvatar.jpg'
import animatedLogo from '../../../icons/AnimatedLogo.svg'
import { Icon } from './Icon'
import { useTranslation } from 'react-i18next'
import { useModal } from '../../../hooks/use-modal'
import { faCamera, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { ImagePicker } from '../../admin/ImagePicker'
import { createTheme } from '../../../theme/ThemeProvider'
import { useBusiness } from '../../../hooks/use-business'
import { AvatarImage } from './AvatarImage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { classNames } from '../../../services/class-names'

export const DefaultAvatar = defaultAvatar

export enum ImageSize {
    Large = 'large',
    Medium = 'medium',
    Small = 'small',
    XSmall = 'xsmall',
    XXSmall = 'xxsmall',
    PreviewDesktop = 'preview-desktop',
    PreviewMobile = 'preview-mobile',
}

interface AvatarProps {
    src?: string
    size?: ImageSize
    mobileSize?: ImageSize
    alt?: string
    circular?: boolean
    initialsFrom?: string
}

const AvatarContainer = withTheme(styled.div`
    position: relative;
    flex-shrink: 0;
    &.booking-avatar {
        .image-container {
            border: 2px solid ${fromTheme('BorderInverted')};
            color: ${fromTheme('ContentInverted')};
        }
    }
    & > .image-container {
        border-radius: 8px;
        border: 2px solid ${fromTheme('Neutral_0')};
        width: 100%;
        height: 100%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;

        & > .initials {
            background-color: ${fromTheme('BookingPageAvatarBackground')};

            overflow: hidden;
        }

        & > img {
            box-sizing: border-box;
            height: 100%;
            width: 100%;
            aspect-ratio: 1;
            font-size: 0;
        }
        & > img,
        & > .initials {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        & > .initials {
            width: 100%;
        }
    }
    &.circular {
        .image-container {
            &,
            img,
            .initials {
                border-radius: 50%;
            }
        }
    }
    width: 120px;
    height: 120px;
    color: white;

    &.m-large {
        width: 140px;
        height: 140px;
    }

    &.m-small {
        width: 64px;
        height: 64px;
    }

    &.m-xsmall {
        width: 56px;
        height: 56px;
    }

    &.m-xxsmall {
        width: 40px;
        height: 40px;
    }

    &.m-small,
    &.m-xsmall {
        .initials {
            font-size: 20px;
            font-weight: 600;
            line-height: 30px;
        }
    }

    &.m-medium,
    &.m-large {
        .initials {
            font-size: 36px;
            font-weight: 700;
            line-height: 44px;
        }
    }

    &.preview-desktop {
        width: 110px;
        height: 110px;
        .initials {
            font-size: 33px;
            font-weight: 700;
            line-height: 40px;
        }
    }

    &.preview-mobile {
        width: 80px;
        height: 80px;
        .initials {
            font-size: 24px;
            font-weight: 600;
            line-height: 29px;
        }
    }

    ${fromTheme('BreakPoint')} {
        &.large {
            width: 140px;
            height: 140px;
        }

        &.small {
            width: 64px;
            height: 64px;
        }

        &.xsmall {
            width: 56px;
            height: 56px;
        }

        &.xxsmall {
            width: 40px;
            height: 40px;
        }

        &.small,
        &.xsmall {
            .initials {
                font-size: 20px;
                font-weight: 600;
                line-height: 30px;
            }
        }

        &.medium,
        &.large {
            .initials {
                font-size: 36px;
                font-weight: 700;
                line-height: 44px;
            }
        }
    }
`)

const RemoveButton = withTheme(styled.button`
    font-size: 12px;
    background-color: ${fromTheme('Secondary4_100')};
    padding: 0;
    margin: 0;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid white;
    color: ${fromTheme('Secondary4_700')};
    cursor: pointer;
    width: 20px;
    height: 20px;
    &:focus {
        outline: none;
    }
`)

export const Avatar: FC<HTMLAttributes<HTMLDivElement> & AvatarProps> = ({
    src,
    alt = 'Avatar',
    circular,
    initialsFrom,
    size,
    mobileSize,
    className,
    onClick,
    ...rest
}) => {
    const initials = useMemo(() => {
        if (!initialsFrom?.trim()) {
            return undefined
        }

        const words = initialsFrom.trim().split(' ')
        if (words.length === 2) {
            return words.map((w) => w[0].toLocaleUpperCase()).join('')
        } else {
            return words[0][0].toLocaleUpperCase()
        }
    }, [initialsFrom])

    const renderImage = useCallback(
        (src: string) => {
            return <img className="avatar-image" src={src} alt={alt} />
        },
        [alt]
    )

    return (
        <AvatarContainer
            aria-label={alt}
            className={
                'avatar ' +
                (circular ? 'circular ' : '') +
                (size ? size : 'medium') +
                ' ' +
                (mobileSize || size ? 'm-' + (mobileSize || size) : 'm-medium') +
                ' ' +
                className
            }
        >
            <div className="image-container">
                {src ? (
                    <AvatarImage src={src} emojiSize={36} renderImage={renderImage} />
                ) : initials ? (
                    <span className="avatar-image initials">{initials}</span>
                ) : (
                    <img className="avatar-image" src={defaultAvatar} alt={alt} />
                )}
            </div>
        </AvatarContainer>
    )
}

interface AdminAvatarProps extends AvatarProps {
    hasUpload?: boolean
    uploading?: boolean
    imagePicker?: boolean
    cornerIcon?: ReactNode
    onImageSelected?: (src: string) => void
    onIconSelected?: (src: string) => Promise<boolean>
    onRemoveImage?: () => void
}

function getContainerSize(size: ImageSize): number {
    switch (size) {
        case ImageSize.Large:
            return 140
        case ImageSize.Medium:
            return 120
        case ImageSize.Small:
            return 64
        case ImageSize.XSmall:
            return 56
        case ImageSize.XXSmall:
            return 40
        case ImageSize.PreviewDesktop:
            return 110
        case ImageSize.PreviewMobile:
            return 80
    }
}

function getUploadButtonSize(size: ImageSize): number {
    switch (size) {
        case ImageSize.Large:
            return 40
        case ImageSize.Medium:
            return 36
        case ImageSize.Small:
            return 24
        case ImageSize.XSmall:
            return 20
        case ImageSize.XXSmall:
            return 20
        case ImageSize.PreviewDesktop:
            return 36
        case ImageSize.PreviewMobile:
            return 20
    }
}

const AdminAvatarContainer = withTheme(
    styled.div(({ theme, circular, size }: { size: ImageSize; circular?: boolean; theme: AdminTheme }) => ({
        position: 'relative',
        width: getContainerSize(size),
        '&:focus-visible': {
            outline: 'none',
            overflow: 'visible',
            '.avatar-image': {
                border: 'none',
                boxShadow: '0 0 0 4px rgba(55, 114, 255, 0.3)',
            },
        },

        '&.has-upload': {
            cursor: 'pointer',
        },

        '.upload-indicator': {
            position: 'absolute',

            width: getUploadButtonSize(size),
            height: getUploadButtonSize(size),

            color: theme.BrandPrimary,
            backgroundColor: theme.Primary_200,
            border: `1px solid ${theme.Neutral_0}`,
            borderRadius: circular ? '50%' : 4,

            bottom: -4,
            right: -4,

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',

            transition: 'transform 100ms linear',

            boxShadow: theme.Elevation_300,
            '& > img': {
                height: getUploadButtonSize(size) / 2,
            },
        },

        '&:hover > .upload-indicator': {
            transform: 'scale(1.1)',
        },

        '.remove-indicator': {
            boxSizing: 'border-box',
            [theme.BreakPoint]: {
                display: 'none',
            },
            position: 'absolute',
            top: -4,
            right: -4,
            button: {
                transition: 'transform 100ms linear',
                '&:hover, &: focus': {
                    boxShadow: '0 0 0 4px rgba(55, 114, 255, 0.3)',
                    transform: 'scale(1.1)',
                },
            },
        },

        '&:hover, &:focus-visible, &:focus-within': {
            '.remove-indicator': {
                display: 'block',
            },
        },
    }))
)

export const AdminAvatar: FC<HTMLAttributes<HTMLDivElement> & AdminAvatarProps> = ({
    hasUpload,
    uploading,
    imagePicker,
    cornerIcon,
    onImageSelected,
    onIconSelected,
    onRemoveImage,
    onClick,
    src,
    alt,
    size,
    circular,
    ...rest
}) => {
    const business = useBusiness()
    const theme = createTheme(business?.theme || 'minup', business?.customTheme)

    const { t } = useTranslation('admin')
    const confirmation = useModal()
    const uploadRef = useRef<HTMLInputElement | null>(null)
    const [showImagePicker, setShowImagePicker] = useState(false)

    const onFileSelected = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            if (e.target.files && e.target.files.length > 0) {
                const reader = new FileReader()
                reader.addEventListener('load', () => onImageSelected && onImageSelected(reader.result as string))
                reader.readAsDataURL(e.target.files[0])
            }
        },
        [onImageSelected]
    )

    const clickHandler = useCallback(
        (e, upload: boolean) => {
            if (imagePicker && !upload) {
                return setShowImagePicker(true)
            }
            hasUpload && uploadRef.current?.click()
            onClick && onClick(e)
        },
        [hasUpload, imagePicker, onClick]
    )

    const onRemove = useCallback(
        async (e: any) => {
            e.preventDefault()
            e.stopPropagation()
            if (
                onRemoveImage &&
                (await confirmation.show({
                    title: t('Are you sure you want to remove this image?'),
                    primaryButtonClass: 'danger',
                    primaryButtonText: t('Remove'),
                    cancelButtonText: t('Cancel'),
                }))
            ) {
                onRemoveImage()
            }
        },
        [confirmation, onRemoveImage, t]
    )

    return (
        <AdminAvatarContainer
            size={size || ImageSize.Medium}
            circular={circular}
            aria-label={alt}
            tabIndex={0}
            role={hasUpload ? 'button' : 'presentation'}
            className={classNames(hasUpload ? 'has-upload ' : undefined)}
            {...rest}
            onKeyPress={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                    clickHandler(e, false)
                }
            }}
            onClick={(e) => clickHandler(e, false)}
        >
            {hasUpload && (
                <input
                    type="file"
                    accept="image/*"
                    style={{ display: 'none' }}
                    ref={uploadRef}
                    onChange={onFileSelected}
                />
            )}
            <ThemeProvider theme={theme}>
                <Avatar src={src} size={size} circular={circular} {...rest} />
            </ThemeProvider>
            {showImagePicker && (
                <ImagePicker
                    type={src?.startsWith('icon') ? 'icons' : src?.startsWith('emoji') ? 'emojis' : 'image'}
                    onUpload={(e) => {
                        clickHandler(e, true)
                    }}
                    onIconSelected={onIconSelected}
                    onClose={() => setShowImagePicker(false)}
                />
            )}
            {hasUpload && (
                <>
                    <span className="upload-indicator">
                        {uploading ? (
                            <img src={animatedLogo} alt="" />
                        ) : (
                            <FontAwesomeIcon icon={faCamera} style={{ fontSize: '14px' }} />
                        )}
                    </span>
                    {src && onRemoveImage && (
                        <span className="remove-indicator">
                            <RemoveButton
                                type="button"
                                onClick={onRemove}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter' || e.key === ' ') {
                                        onRemove(e)
                                    }
                                }}
                                title={t('Remove image')}
                                aria-label={t('Remove image')}
                            >
                                <Icon icon={faTrashAlt} />
                            </RemoveButton>
                        </span>
                    )}
                </>
            )}
            {cornerIcon && <span className="upload-indicator">{cornerIcon}</span>}
        </AdminAvatarContainer>
    )
}
