import { darkTheme } from './dark-theme'
import { AdminTheme } from './theme'

export const midnightTheme: AdminTheme = {
    ...darkTheme,
    // Background
    BackgroundColor: '#18181B',
    BackgroundSecondary: 'hsla(240, 6%, 10%, 1)',
    // Content
    FontColor: 'hsla(234, 24%, 25%, 1)',
    ContentInverted: 'hsla(234, 24%, 25%, 1)',
    ContentDanger: 'hsla(357, 97%, 76%, 1)',
    // Border
    BroderSecondary: 'hsla(215, 10%, 33%, 1)',
    BorderPrimary: 'hsla(215, 10%, 33%, 1)',
    BorderInverted: 'hsla(215, 10%, 33%, 1)',
    BookingPageAdminBarBackground: 'hsla(218, 10%, 16%, 0.9)',
    BookingPageBorderStyle: { border: `none` },
    BookingPageCardHoverBorderStyle: {
        border: `1px solid hsla(215, 20%, 67%, 1)`,
    },
    // Other
    ThemeColor: 'hsla(225, 61%, 74%, 1)',
    InteractivePrimary: 'hsla(225, 61%, 74%, 1)',
    InteractivePrimaryHover: 'hsla(221, 63%, 82%, 1)',
    InteractivePrimaryActive: 'hsla(230, 56%, 67%, 1)',
    InteractiveSecondary: 'hsla(234, 24%, 25%, 1)',
    InteractiveSecondaryHover: 'hsla(234, 24%, 39%, 1)',
    InteractiveSecondaryActive: 'hsla(234, 24%, 25%, 1)',
    InteractiveNeutral: 'hsla(215, 10%, 33%, 1)',
    InteractiveNeutralHover: 'hsla(214, 10%, 46%, 1)',
    InteractiveNeutralActive: 'hsla(218, 10%, 16%, 1)',
    FocusOutline: 'hsla(225, 61%, 74%, 0.3)',
    SurfaceColor: 'hsla(218, 10%, 16%, 1)',
    Cover1: 'hsla(240, 10%, 14%, 1)',
    Cover2: 'hsla(240, 7%, 24%, 1)',
    Cover3: 'hsla(240, 7%, 27%, 1)',
    BookingPageAvatarBackground: 'hsla(225, 61%, 74%, 1)',
    AnimationColor: [148 / 255.0, 168 / 255.0, 229 / 255.0],
}
