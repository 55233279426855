import styled from '@emotion/styled/macro'
import axios from 'axios'
import { FunctionComponent, Suspense, useEffect, useMemo, useState } from 'react'
import { Route, Switch, useParams } from 'react-router'
import { Business } from '../../store/auth/types'
import { LoadingScreen } from '../../components/ui-kit/comopnents/LoadingScreen'
import { ReactComponent as Cover } from '../../icons/BookingCover.svg'
import { withTheme } from '@emotion/react'
import { AdminTheme } from '../../theme/theme'
import { ServiceSelector } from './ServiceSelector'
import { CancelAppointment } from './CancelAppointment'
import { Avatar, ImageSize } from '../../components/ui-kit/comopnents/Avatar'
import { NotFoundPage } from './NotFound'
import i18n from '../../i18n'
import { Notifications } from '../admin/Notifications'
import DOMPurify from 'dompurify'
import { EventPage } from './events/EventPage'
import { BookingPageCover } from './components/BookingPageCover'
import { CancelEvent } from './events/CancelEvent'
import { OccurrencePublicDto } from '../../store/events/types'
import { UpcomingEventsColumn } from './events/UpcomingEventsColumn'
import { EventSelector } from './events/EventSelector'
import './dom-purify-setup'
import { getImageUrl } from '../../services/get-image-url'
import { Flex } from '../../components/helpers/Flex'
import React from 'react'
import { useTheme } from '../../theme/ThemeProvider'
import { GoogleMapsLink } from '../../components/ui-kit/comopnents/GoogleMapsLink'
import { SocialLinks } from '../../components/ui-kit/comopnents/SocialLinks'
import { MinupRibbon } from '../../components/helpers/MinupRibbon'
import { ServicePage } from './services/ServicePage'

const AdminAndLock = React.lazy(() => import('./components/AdminAndLock'))
const OuterContainer = withTheme(
    styled.div(({ theme }: { theme: AdminTheme }) => ({
        height: '100%',
        overflowY: 'scroll',
        backgroundColor: theme.BackgroundColor,
    }))
)

const BookingPageContainer = withTheme(
    styled.div(({ theme }: { theme: AdminTheme }) => ({
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'stretch',
        flexDirection: 'column',
        rowGap: 0,
        height: '100%',
        [theme.BreakPoint]: {
            display: 'grid',
            alignContent: 'start',
            justifyContent: 'center',
            gridTemplateAreas: "'header' 'events' 'main' '.'",
            padding: `${theme.Spacing(3)} 0`,
            gridTemplateColumns: '740px auto',
            gridTemplateRows: 'auto auto',
        },

        [theme.BookingPageBreakPoint]: {
            gridTemplateAreas: "'header events' 'main events' '. events'",
        },
    }))
)

const BookingPageHeader = withTheme(
    styled.div(({ theme }: { theme: AdminTheme }) => ({
        position: 'relative',
        backgroundColor: theme.SurfaceColor,
        color: theme.ContentPrimary,
        gridArea: 'header',
        overflow: 'hidden',
        flex: '1 0 auto',
        [theme.BreakPointReverse]: {
            borderBottom: `1px solid ${theme.BroderSecondary}`,
        },
        [theme.BreakPoint]: {
            overflow: 'visible',
            marginBottom: theme.Spacing(3),
            borderRadius: theme.Spacing(1),
            ...theme.BookingPageBorderStyle,
        },
    }))
)

const BookingPageHeaderContent = withTheme(
    styled.div(({ theme }: { theme: AdminTheme }) => ({
        padding: '50px 24px 20px 24px',
        '& > p': {
            padding: '12px 0',
        },
        '& > span': {
            color: theme.ThemeColor,
        },
        [theme.BreakPoint]: {
            paddingBottom: 40,
        },
    }))
)

const BookingPageContent = withTheme(
    styled.div(({ theme }: { theme: AdminTheme }) => ({
        position: 'relative',
        backgroundColor: theme.SurfaceColor,
        color: theme.ContentPrimary,
        gridArea: 'main',
        overflowY: 'scroll',
        overflowX: 'hidden',
        [theme.BreakPointReverse]: {
            borderBottom: `1px solid ${theme.BroderSecondary}`,
        },
        [theme.BreakPoint]: {
            minHeight: 'auto',
            overflow: 'visible',
            borderRadius: theme.Spacing(1),
            ...theme.BookingPageBorderStyle,
        },
    }))
)

const BookingPageWithStickyFooter = withTheme(
    styled(BookingPageContent)(({ theme, noHeight }: { theme: AdminTheme; noHeight?: boolean }) => ({
        flexDirection: 'column',
        height: noHeight ? undefined : '100%',
        display: noHeight ? 'contents' : 'flex',
        '> div': {
            backgroundColor: theme.SurfaceColor,
        },
        [theme.BreakPoint]: {
            display: 'flex',
            height: 'auto',
            '> div:first-of-type': {
                borderRadius: theme.Spacing(1),
            },
        },
    }))
)

const BookingPageEventsContainer = withTheme(
    styled.div(({ theme }: { theme: AdminTheme }) => ({
        gridArea: 'events',
        [theme.BookingPageBreakPoint]: {
            width: 380,
        },
    }))
)

export const BookingPage: FunctionComponent = () => {
    const params = useParams<{ handle: string }>()
    const [loading, setLoading] = useState(true)
    const [business, setBusiness] = useState<Business>()
    const [events, setEvents] = useState<OccurrencePublicDto[]>([])
    const description = useMemo(() => {
        const purified = DOMPurify.sanitize(business?.description || '')
        const test = document.createElement('span')
        test.innerHTML = purified
        return test.textContent?.trim() ? purified : ''
    }, [business?.description])
    const themePreview = useMemo(() => {
        const urlParams = new URLSearchParams(window.location.search)
        return urlParams.get('themePreview')
    }, [])

    useTheme(themePreview || business?.theme || 'minup', business?.customTheme)

    useEffect(() => {
        ;(async () => {
            await i18n.loadNamespaces('bookingpage')
            try {
                const handle = encodeURIComponent(params.handle)
                if (handle) {
                    const [businessResponse, eventsResponse] = await Promise.all([
                        axios.get(`/api/book/byhandle/${handle}`),
                        axios.get(`/api/book/byhandle/${handle}/events`),
                    ])
                    setBusiness(businessResponse.data)
                    setEvents(eventsResponse.data)
                    if (businessResponse.data && i18n.language !== businessResponse.data.bookingPageLanguage) {
                        await i18n.changeLanguage(businessResponse.data.bookingPageLanguage)
                    }
                }
            } catch {}
            setLoading(false)
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.handle])
    return loading ? (
        <LoadingScreen />
    ) : business ? (
        <OuterContainer>
            <Suspense fallback={null}>
                <AdminAndLock />
            </Suspense>
            <BookingPageContainer>
                <Route path={['/book/:handle', '/book/:handle/category/:categoryId']} exact>
                    <BookingPageHeader>
                        {!business.hideMinupBranding ? <MinupRibbon /> : null}
                        <BookingPageCover>
                            {business.coverImagePublicId ? (
                                <img
                                    id="cover"
                                    role="presentation"
                                    alt=""
                                    src={getImageUrl(business.coverImagePublicId, 'bookinpagecoverdesktop')}
                                />
                            ) : (
                                <Cover id="cover" />
                            )}
                            <Avatar
                                className="booking-avatar"
                                src={getImageUrl(business.profileImagePublicId, 'bookingpageavatar')}
                                initialsFrom={business.name}
                                size={ImageSize.Medium}
                                alt=""
                                circular
                            />
                        </BookingPageCover>
                        <BookingPageHeaderContent>
                            <Flex justifyContent="space-between">
                                <h1>{business.name}</h1>
                                <SocialLinks className="only-desktop" socialLinks={business.socialLinks} />
                            </Flex>
                            {description ? (
                                <p className="small" dangerouslySetInnerHTML={{ __html: description }} />
                            ) : null}
                            {business.address ? (
                                <GoogleMapsLink className="small" address={business.address}>
                                    {business.address}
                                </GoogleMapsLink>
                            ) : null}
                            <SocialLinks socialLinks={business.socialLinks} className="only-mobile mtm" />
                        </BookingPageHeaderContent>
                    </BookingPageHeader>
                </Route>
                <Route path="/book/:handle" exact>
                    {events.length && business?.services?.length ? (
                        <BookingPageEventsContainer>
                            <UpcomingEventsColumn business={business} events={events} />
                        </BookingPageEventsContainer>
                    ) : null}
                </Route>
                <Switch>
                    <Route path={['/book/:handle', '/book/:handle/category/:categoryId']} exact>
                        <BookingPageWithStickyFooter noHeight>
                            {!business?.services?.length && events.length ? (
                                <EventSelector business={business} events={events} />
                            ) : (
                                <ServiceSelector business={business} hasEvents={events.length > 0} />
                            )}
                        </BookingPageWithStickyFooter>
                    </Route>
                    <Route path="/book/:handle/service/:serviceId">
                        <BookingPageWithStickyFooter>
                            <ServicePage business={business} />
                        </BookingPageWithStickyFooter>
                    </Route>
                    <Route path="/book/:handle/events/:eventId/:occurrenceId?">
                        <BookingPageWithStickyFooter>
                            <EventPage business={business} />
                        </BookingPageWithStickyFooter>
                    </Route>
                    <Route path="/book/:handle/cancel/:cancellationToken">
                        <BookingPageContent className="p">
                            <CancelAppointment business={business} />
                        </BookingPageContent>
                    </Route>
                    <Route path="/book/:handle/event/cancel/:cancellationToken">
                        <BookingPageContent className="p">
                            <CancelEvent business={business} />
                        </BookingPageContent>
                    </Route>
                </Switch>
            </BookingPageContainer>
            <Notifications />
        </OuterContainer>
    ) : (
        <NotFoundPage />
    )
}
