import axios, { AxiosRequestConfig } from 'axios'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { AuthState } from '../../store/auth/types'

export const useAuthorizedApi = () => {
    const accessToken = useSelector((state: { auth: AuthState }) => state.auth.accessToken)
    return useMemo(() => {
        const addAuthorizationHeader = (config?: AxiosRequestConfig): AxiosRequestConfig => {
            const authorizedConfig = config || {}
            authorizedConfig.headers = {
                ...authorizedConfig.headers,
                Authorization: 'Bearer ' + accessToken,
            }
            return authorizedConfig
        }

        return {
            get: async <T = any>(url: string, config?: AxiosRequestConfig) =>
                axios.get<T>(url, addAuthorizationHeader(config)),
            post: async <T = any>(url: string, data?: any, config?: AxiosRequestConfig) =>
                axios.post<T>(url, data, addAuthorizationHeader(config)),
            put: async <T = any>(url: string, data?: any, config?: AxiosRequestConfig) =>
                axios.put<T>(url, data, addAuthorizationHeader(config)),
            patch: async <T = any>(url: string, data?: any, config?: AxiosRequestConfig) =>
                axios.patch<T>(url, data, addAuthorizationHeader(config)),
            _delete: async <T = any>(url: string, data?: any, config?: AxiosRequestConfig) =>
                axios.delete<T>(url, { ...addAuthorizationHeader(config), data }),
        }
    }, [accessToken])
}
