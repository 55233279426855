import { useMemo } from 'react'

export function useFeaturedEmojis() {
    return useMemo(
        () => ({
            other: {
                id: 'other',
                emoji: '🎟️',
                group: '0',
            },
            coaching: {
                id: 'coaching',
                emoji: '🛟',
                group: '0',
            },
            consulting: {
                id: 'consulting',
                emoji: '💼',
                group: '0',
            },
            fitness: {
                id: 'fitness',
                emoji: '🏋️',
                group: '0',
            },
            'beauty-salon': {
                id: 'beauty-salon',
                emoji: '🧴',
                group: '0',
            },
            'nail-salon': {
                id: 'nail-salon',
                emoji: '💅',
                group: '0',
            },
            barbershop: {
                id: 'barbershop',
                emoji: '💇',
                group: '0',
            },
            massage: {
                id: 'massage',
                emoji: '🪷',
                group: '0',
            },
            'tattoo-artist': {
                id: 'tattoo-artist',
                emoji: '🐉',
                group: '0',
            },
            photography: {
                id: 'photography',
                emoji: '📷',
                group: '0',
            },
            education: {
                id: 'education',
                emoji: '🎓',
                group: '0',
            },
            healthcare: {
                id: 'healthcare',
                emoji: '🩺',
                group: '0',
            },
            automotive: {
                id: 'automotive',
                emoji: '🚙',
                group: '0',
            },
            store: {
                id: 'store',
                emoji: '🛍️',
                group: '0',
            },
        }),
        []
    )
}
