import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { AdminTheme } from '../../../theme/theme'

export const BookingPageEventCover = withTheme(
    styled.div(({ theme }: { theme: AdminTheme }) => ({
        position: 'relative',
        width: '100%',
        display: 'flex',
        flex: 'none',
        justifyContent: 'center',
        alignItems: 'flex-start',
        overflow: 'hidden',

        [theme.BreakPoint]: {
            overflow: 'visible',
        },

        '#cover': {
            aspectRatio: '740/240',
            width: '100%',
            '#shape-1': {
                fill: theme.Cover1,
            },
            '#shape-2': {
                fill: theme.Cover2,
            },
            '#shape-3': {
                fill: theme.Cover3,
            },

            [theme.BreakPoint]: {
                borderTopLeftRadius: theme.Spacing(1),
                borderTopRightRadius: theme.Spacing(1),
            },
        },
    }))
)
