import { eventChannel } from 'redux-saga'
import { call, fork, put, take, takeEvery } from 'redux-saga/effects'
import { authorizedFetch } from '../utils/authorized-fetch'
import { createAction2 } from '../utils/create-action'
import { AdminActionTypes } from './actions'
import { AuthActionTypes } from '../auth/actions'

function timer(secs: number) {
    return eventChannel((emitter) => {
        const iv = setInterval(() => {
            emitter(false)
        }, secs * 1000)
        return () => {
            clearInterval(iv)
        }
    })
}

function* checkLiveChatStatus(forceReload: boolean): any {
    if (forceReload || document.hasFocus()) {
        try {
            const response: { hasLiveAgents: boolean } = yield call(
                authorizedFetch,
                '/api/admin/LiveChat/hasLiveAgents'
            )
            yield put(
                createAction2({ type: AdminActionTypes.SET_LIVE_AGENT_AVAILABLE, payload: response.hasLiveAgents })
            )
        } catch (e) {
            console.error(e)
            yield put(createAction2({ type: AdminActionTypes.SET_LIVE_AGENT_AVAILABLE, payload: false }))
        }
    }
}

function* liveChatUpdateSaga(): any {
    const channel = yield call(timer, 60)
    yield call(checkLiveChatStatus, true)
    yield takeEvery(channel, checkLiveChatStatus)
}

export function* liveChatRootSaga() {
    yield take(AuthActionTypes.AUTH_FINISHED)
    yield fork(liveChatUpdateSaga)
}
