import { defaultTheme } from './default-theme'
import { AdminTheme } from './theme'

export const pineForestTheme: AdminTheme = {
    ...defaultTheme,
    ThemeColor: 'hsla(159, 37%, 40%, 1)',
    InteractivePrimary: 'hsla(159, 37%, 40%, 1)',
    InteractivePrimaryHover: 'hsla(159, 37%, 28%, 1)',
    InteractivePrimaryActive: 'hsla(159, 20%, 20%, 1)',
    InteractiveSecondary: 'hsla(159, 37%, 100%, 0)',
    InteractiveSecondaryHover: 'hsla(159, 37%, 40%, 0.1)',
    InteractiveSecondaryActive: 'hsla(159, 37%, 40%, 0.3)',
    FocusOutline: 'hsla(159, 37%, 40%, 0.3)',
    FontColor: 'hsla(0, 0%, 100%, 1)',
    BackgroundColor: 'hsla(159, 37%, 40%, 0.1)',
    SurfaceColor: 'hsla(0, 0%, 100%, 1)',
    Cover1: '#DCEFE5',
    Cover2: '#90C5AF',
    Cover3: '#60A78C',
    BookingPageAvatarBackground: '#60A78C',
    AnimationColor: [64 / 255.0, 140 / 255.0, 113 / 255.0],
}
