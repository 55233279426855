import { fork, takeEvery } from 'redux-saga/effects'
import { AuthActionTypes } from '../../auth/actions'
import { loadMessages } from './load-messages'
import { saveChanges } from './save-changes'
import { GettingStartedActionTypes } from '../actions'
import { setMessageViewed } from './set-message-viewed'
import { BookingPageActionTypes } from '../../booking-page/actions'
import {
    FINISH_SETUP_SUB_TASK_IDS,
    FINISH_SETUP_TASK_IDS,
    GUIDE_IDS,
} from '../../../pages/admin/in-app-messages/guides/constants'
import { CalendarActionTypes } from '../../calendar/actions'
import { finishSubTaskOnAction } from './finish-subtask-on-action'
import { finishOrSkipTask } from './finish-or-skip-task'
import { finishSubTask } from './finish-subtask'
import { showNotificationDotForGuide } from './show-notification-dot-for-guide'
import { resetFabStateOnLogin } from './reset-fab-state-on-login'

export function* gettingStartedRootSaga() {
    yield fork(saveChanges)

    yield fork(resetFabStateOnLogin)

    yield takeEvery([AuthActionTypes.AUTH_FINISHED, AuthActionTypes.UPDATE_BUSINESS_LANGUAGE], loadMessages)

    yield takeEvery(GettingStartedActionTypes.SET_MESSAGE_VIEWED, setMessageViewed)

    yield fork(finishOrSkipTask)

    yield fork(finishSubTask)

    yield fork(showNotificationDotForGuide)

    yield fork(
        finishSubTaskOnAction,
        BookingPageActionTypes.BOOKING_SUCCESSFUL,
        GUIDE_IDS.FINISH_SETUP,
        FINISH_SETUP_TASK_IDS.BOOK_AN_APPOINTMENT,
        [FINISH_SETUP_SUB_TASK_IDS.GO_TO_BOOKING_PAGE, FINISH_SETUP_SUB_TASK_IDS.SELECT_AND_BOOK_AN_APPOINTMENT]
    )

    yield fork(
        finishSubTaskOnAction,
        BookingPageActionTypes.SHARE,
        GUIDE_IDS.FINISH_SETUP,
        FINISH_SETUP_TASK_IDS.SHARE_YOUR_BOOKINGPAGE,
        [FINISH_SETUP_SUB_TASK_IDS.GO_TO_BOOKING_PAGE_SHARE, FINISH_SETUP_SUB_TASK_IDS.CLICK_ON_SHARE]
    )

    yield fork(
        finishSubTaskOnAction,
        CalendarActionTypes.ENTRY_OPENED,
        GUIDE_IDS.FINISH_SETUP,
        FINISH_SETUP_TASK_IDS.VIEW_BOOKED_APPOINTMENT,
        [FINISH_SETUP_SUB_TASK_IDS.OPEN_BOOKED_APPOINTMENT]
    )
}
