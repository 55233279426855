import { Guide, TaskProgress } from '../../types'

export function getGuideProgress(taskProgress: TaskProgress[], guide: Guide) {
    return taskProgress.reduce((sum, task) => {
        let add = 0
        if (task.done || task.skipped) {
            add = guide.tasks.find((t) => t.id === task.taskId)?.percentage || 0
        }
        return sum + add
    }, 0)
}
