import { ComponentClass, ComponentProps, FC, ReactNode, useCallback, useMemo, useRef } from 'react'
import { PopupWithIllustration } from '../../../../components/ui-kit/admin/modal/PopupWithIllustration'
import { useBusiness, useSetBusiness } from '../../../../hooks/use-business'
import { Trans, useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { ModalHeader } from '../../../../components/ui-kit/admin/modal/ModalHeader'
import { Flex } from '../../../../components/helpers/Flex'
import { ModalContent } from '../../../../components/ui-kit/admin/modal/ModalContent'
import { ModalFooter } from '../../../../components/ui-kit/admin/modal/ModalFooter'
import { PrimaryButton } from '../../../../components/ui-kit/button/primary'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faFire, faTimes } from '@fortawesome/free-solid-svg-icons'
import { useSubscriptionInfo } from '../../../../hooks/use-subscription-info'
import { CloseModalButton } from '../../../../components/ui-kit/admin/modal/ModalCloseButton'
import { LightButton } from '../../../../components/ui-kit/button/LightButton'
import { withTheme } from '@emotion/react'
import { AdminTheme } from '../../../../theme/theme'
import { TextButton, TextButtonLink } from '../../../../components/ui-kit/button/TextButton'
import { Icon } from '../../../../components/ui-kit/comopnents/Icon'
import { ProFeature, ProFeatureId, useProFeatures } from '../pro-features/ProFeaturesList'
import { useSubscriptionPopup } from './SubscriptionPopupContext'

const TextGradient = styled.span(({ long }: { long?: boolean }) => ({
    backgroundImage: `linear-gradient(90deg, #3771ff, #f141d2 ${long ? '160%' : ''})`,
    backgroundClip: 'text',
    color: 'transparent',
}))

const FlexContent = withTheme(
    styled(Flex)(({ theme }: { theme: AdminTheme }) => ({
        gap: theme.Spacing(2.5),
        [theme.BreakPoint]: {
            gap: theme.Spacing(4),
        },
    }))
)

const StyledModalHeader = withTheme(
    styled(ModalHeader)(({ theme }: { theme: AdminTheme }) => ({
        padding: `0`,
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: theme.Spacing(2),
    }))
)

const StyledModalContent = withTheme(
    styled(ModalContent)(({ theme }: { theme: AdminTheme }) => ({
        padding: `0`,
        flexDirection: 'column',
        gap: theme.Spacing(4),
        overflow: 'auto',
        scrollbarWidth: 'auto',
    }))
)

const StyledUnorderedList = withTheme(
    styled.ul(({ theme }: { theme: AdminTheme }) => ({
        margin: 0,
        paddingLeft: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        listStyle: 'none',
        gap: theme.Spacing(1.5),
        svg: {
            color: theme.Secondary3_500,
        },
    }))
)

const StyledModalFooter = withTheme(
    styled(ModalFooter)(({ theme }: { theme: AdminTheme }) => ({
        width: '100%',
        marginTop: theme.Spacing(1.5),
        padding: `0`,
        flexDirection: 'column-reverse',
        gap: theme.Spacing(2),
        [theme.BreakPoint]: {
            flexDirection: 'row',
            justifyContent: 'flex-end',
            marginTop: 0,
        },
    }))
)

const Description: FC<{ feature: ReactNode; proTrialAvailable: boolean | undefined }> = ({
    feature,
    proTrialAvailable,
}) => {
    return (
        <p className="large semibold">
            {proTrialAvailable ? (
                <Trans ns="admin">
                    <TextGradient long>{{ feature }}</TextGradient> is a Pro feature. No worries, just start a free
                    trial and pick up where you left off.
                </Trans>
            ) : (
                <Trans ns="admin">
                    <TextGradient long>{{ feature }}</TextGradient> is a Pro feature. Upgrade to Minup Pro and pick up
                    where you left off.
                </Trans>
            )}
        </p>
    )
}

export const SubscriptionPopupButton: FC<
    {
        proFeaturesUsed: ProFeatureId[]
        onOpenClose?: (open: boolean) => void
        ButtonComponent?: ComponentClass | FC
    } & ComponentProps<typeof PrimaryButton>
> = ({ proFeaturesUsed, onOpenClose, onClick, children, ButtonComponent = PrimaryButton, type, ...rest }) => {
    const { subscriptionTier } = useSubscriptionInfo()
    const buttonRef = useRef<HTMLButtonElement>()
    const updateBusiness = useSetBusiness()
    const subscriptionPopup = useSubscriptionPopup()

    const onStart = useCallback(
        async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            e.preventDefault()
            onOpenClose?.(true)
            updateBusiness({ billingCycle: 'monthly' })
            const success = await subscriptionPopup.show(proFeaturesUsed)
            onOpenClose?.(false)
            if (success) {
                buttonRef.current?.click()
            }
        },
        [onOpenClose, proFeaturesUsed, subscriptionPopup, updateBusiness]
    )

    return subscriptionTier !== 'free' || !proFeaturesUsed.length ? (
        <ButtonComponent type={type} onClick={onClick} ref={buttonRef as any} {...rest}>
            {children}
        </ButtonComponent>
    ) : (
        <LightButton variant="Pro" onClick={onStart} {...rest} type="button">
            <Icon icon={faFire} />
            {children}
        </LightButton>
    )
}

export const SubscriptionPopup: FC<{
    filteredFeatures: ProFeature[]
    onClose: () => void
    onContinue: () => void
}> = ({ filteredFeatures, onClose, onContinue }) => {
    const business = useBusiness()
    const { t } = useTranslation('admin')
    const features = useProFeatures()

    const [firstProFeature, ...restProFeatures] = useMemo(() => {
        const additionalFeatures = features.filter((feature) => !filteredFeatures.includes(feature)).slice(0, 3)
        const combinedFeatures = [...filteredFeatures, ...additionalFeatures]
        const uniqueCombinedFeatures = Array.from(new Set(combinedFeatures.map((feature) => feature.id))).map((id) =>
            combinedFeatures.find((feature) => feature.id === id)
        )

        return uniqueCombinedFeatures
    }, [features, filteredFeatures])

    return (
        <PopupWithIllustration
            trialAvailable={business?.proTrialAvailable}
            mobileTitle={
                business?.proTrialAvailable ? (
                    <Trans ns="admin">Start your free trial</Trans>
                ) : (
                    <Trans ns="admin">Upgrade to Minup Pro</Trans>
                )
            }
            content={
                <Flex
                    grow={1}
                    shrink={1}
                    basis={0}
                    flexDirection="column"
                    alignItems="flex-start"
                    justifyContent="space-between"
                    dpx={4}
                    dpy={4}
                >
                    <CloseModalButton style={{ zIndex: 1 }} type="button" icon={faTimes} onClick={onClose} />
                    <FlexContent flexDirection="column" alignItems="flex-start" className="w100">
                        <StyledModalHeader>
                            <h1 className="only-desktop mbm">
                                {business?.proTrialAvailable ? (
                                    <Trans ns="admin">Start your free trial</Trans>
                                ) : (
                                    <Trans ns="admin">Upgrade to Minup Pro</Trans>
                                )}
                            </h1>
                            <Description
                                feature={firstProFeature?.title}
                                proTrialAvailable={business?.proTrialAvailable}
                            />

                            {business?.proTrialAvailable ? (
                                <TextGradient className="large semibold">
                                    <p>
                                        <Trans ns="admin">Free for 14 days. No commitments. Cancel anytime.</Trans>
                                    </p>
                                </TextGradient>
                            ) : null}
                        </StyledModalHeader>
                        <StyledModalContent>
                            <div>
                                <h1 className="large semibold mbm">
                                    <Trans ns="admin">What else is in Minup Pro?</Trans>
                                </h1>
                                <StyledUnorderedList className="large">
                                    {restProFeatures.map((feature, i) => (
                                        <li key={i}>
                                            <FontAwesomeIcon icon={faCheck} className="mrs" />
                                            {feature?.title}
                                        </li>
                                    ))}
                                    <li>
                                        <TextButtonLink target="_blank" href={t('landing_page_pricing_url')}>
                                            <Trans ns="admin">And many more</Trans>
                                        </TextButtonLink>
                                    </li>
                                </StyledUnorderedList>
                            </div>
                        </StyledModalContent>
                    </FlexContent>
                    <StyledModalFooter>
                        <TextButton className="neutral" onClick={onClose}>
                            <Trans ns="admin">No, thanks</Trans>
                        </TextButton>
                        <LightButton variant="Pro" type="button" onClick={onContinue}>
                            <Trans ns="admin">Continue</Trans>
                        </LightButton>
                    </StyledModalFooter>
                </Flex>
            }
        />
    )
}
