import { captureException } from '../../appInsights'

export function tryParse(json: string | undefined, defaultValue: any = undefined) {
    try {
        return json ? JSON.parse(json) : defaultValue
    } catch (e) {
        captureException(e)
        return defaultValue
    }
}
