import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { FC, ReactNode, useEffect } from 'react'
import { AdminTheme } from '../../../theme/theme'
import { TextButton } from '../../../components/ui-kit/button/TextButton'
import { Icon } from '../../../components/ui-kit/comopnents/Icon'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { Trans } from 'react-i18next'

const FooterContainer = withTheme(
    styled.div(({ theme, forceSpaceBetween }: { theme: AdminTheme; forceSpaceBetween?: boolean }) => ({
        position: 'sticky',
        bottom: 0,
        right: 0,
        left: 0,
        display: 'flex',
        padding: theme.Spacing(2.5),
        borderTopWidth: 1,
        borderTopStyle: 'solid',
        borderTopColor: theme.BorderPrimary,
        backgroundColor: theme.SurfaceColor,
        '.continue': {
            flexGrow: 1,
        },

        justifyContent: forceSpaceBetween ? 'space-between' : undefined,

        [theme.BreakPoint]: {
            padding: theme.Spacing(3),
            justifyContent: 'space-between',
            borderBottomLeftRadius: 8,
            borderBottomRightRadius: 8,
            '.continue': {
                flexGrow: 0,
            },
        },
    }))
)
export const Footer: FC<{
    nextButton: ReactNode
    summary?: ReactNode
    forceSpaceBetween?: boolean
    scrollOnAppear?: boolean
    back?: () => void
}> = ({ nextButton, summary, forceSpaceBetween, scrollOnAppear, back }) => {
    useEffect(() => {
        if (scrollOnAppear) {
            document.getElementById('booking-page-footer')?.scrollIntoView({ behavior: 'smooth' })
        }
    }, [scrollOnAppear])
    return (
        <FooterContainer id="booking-page-footer" forceSpaceBetween={forceSpaceBetween}>
            {back ? (
                <TextButton onClick={back} className="only-desktop">
                    <Icon icon={faChevronLeft} className="mrs" />
                    <Trans ns="bookingpage">Back</Trans>
                </TextButton>
            ) : (
                summary
            )}
            {nextButton}
        </FooterContainer>
    )
}
