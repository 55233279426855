import { FC, useRef } from 'react'
import { Service } from '../../../store/services/types'
import { ModalBackground } from '../../../components/ui-kit/admin/modal/ModalBackground'
import { ModalContainer } from '../../../components/ui-kit/admin/modal/ModalBody'
import { ModalHeader } from '../../../components/ui-kit/admin/modal/ModalHeader'
import { ModalContent } from '../../../components/ui-kit/admin/modal/ModalContent'
import { CloseModalButton } from '../../../components/ui-kit/admin/modal/ModalCloseButton'
import { Trans } from 'react-i18next'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { Column } from '../../../components/admin/layout/Column'
import { SummaryCard } from './SummaryCard'
import { Business } from '../../../store/auth/types'
import { useOnClickOutside } from '../../../hooks/use-on-click-outside'

export const SummaryListPopup: FC<{ business: Business; services: Service[]; onClose: () => void }> = ({
    business,
    services,
    onClose,
}) => {
    const modalRef = useRef()

    useOnClickOutside(modalRef, onClose)

    return (
        <ModalBackground>
            <ModalContainer ref={modalRef as any} width={500}>
                <ModalHeader>
                    <h3>
                        <Trans ns="bookingpage">Selected services</Trans>
                    </h3>
                    <CloseModalButton onClick={onClose} icon={faTimes} />
                </ModalHeader>
                <ModalContent pb={3}>
                    <Column grow>
                        {services.map((service) => (
                            <SummaryCard hideDetails service={service} additionalServices={[]} business={business} />
                        ))}
                    </Column>
                </ModalContent>
            </ModalContainer>
        </ModalBackground>
    )
}
