import { Action, ActionWithoutPayload } from '../utils/create-action'
import { GettingStartedProgress, InAppMessage } from './types'

export enum GettingStartedActionTypes {
    FINISH_TASK = '[getting started] finish task',
    FINISH_SUBTASK = '[getting started] finish sub task',
    SKIP_TASK = '[getting started] skip task',
    SET_ACTIVE_TASK = '[getting started] set active task',
    SET_CURRENT_PROGRESS = '[getting started] set current progress',
    SET_MESSAGES = '[getting started] set messages',
    SET_MESSAGE_VIEWED = '[getting started] set message viewed',
    UPDATE_PROGRESS = '[getting started] update progress',
    SAVE_CHANGES = '[getting started] save changes',
}

export type FinishTaskAction = Action<typeof GettingStartedActionTypes.FINISH_TASK, string>
export type FinishSubTaskAction = Action<
    typeof GettingStartedActionTypes.FINISH_SUBTASK,
    { taskId: string; subTaskIds: string[] }
>
type SetActiveTaskAction = Action<typeof GettingStartedActionTypes.SET_ACTIVE_TASK, string>
export type SkipTaskAction = Action<typeof GettingStartedActionTypes.SKIP_TASK, string>
type SetCurrentProgressAction = Action<
    typeof GettingStartedActionTypes.SET_CURRENT_PROGRESS,
    undefined | GettingStartedProgress
>
type SetMessagesAction = Action<typeof GettingStartedActionTypes.SET_MESSAGES, InAppMessage[]>
export type SetMessageViewedAction = Action<
    typeof GettingStartedActionTypes.SET_MESSAGE_VIEWED,
    { messageId: string; sync: boolean }
>
type UpdateProgressAction = Action<typeof GettingStartedActionTypes.UPDATE_PROGRESS, GettingStartedProgress>
type SaveChanges = ActionWithoutPayload<typeof GettingStartedActionTypes.SAVE_CHANGES>

export type GettingStartedAction =
    | FinishTaskAction
    | FinishSubTaskAction
    | SkipTaskAction
    | SetCurrentProgressAction
    | SetActiveTaskAction
    | SetMessagesAction
    | SetMessageViewedAction
    | UpdateProgressAction
    | SaveChanges
