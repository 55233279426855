import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { FC, useCallback, useMemo, useState } from 'react'
import { Flex } from '../../components/helpers/Flex'
import { Business } from '../../store/auth/types'
import { AdminTheme } from '../../theme/theme'
import { ReactComponent as EmptyStateIllustration } from '../../icons/TimeListFilterZero.svg'
import { ReactComponent as NoServicesIllustration } from '../../icons/noService.svg'
import { Trans, useTranslation } from 'react-i18next'
import { SearchToggleInput } from '../../components/ui-kit/comopnents/InputWithIconsAndAnimation'
import { classNames } from '../../services/class-names'
import { ServiceList } from './ServiceList'
import { useHistory, useParams } from 'react-router-dom'
import { NeutralIconButtonNavLink } from '../../components/ui-kit/button/NeutralIconButton'
import { faArrowLeft, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { Service } from '../../store/services/types'
import { PrimaryButton } from '../../components/ui-kit/button/primary'
import { Footer } from './components/Footer'
import { Content } from './components/Content'
import { formatMinutes } from '../../services/format-minutes'
import { Currency } from '../../components/helpers/Currency'
import { Column } from '../../components/admin/layout/Column'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { asClickable } from '../../components/helpers/as-clickable'
import i18n from '../../i18n'

const Container = styled('div')(() => ({
    width: '100%',
}))

const ServiceSelectorTitleContainer = withTheme(
    styled(Flex)(({ theme }: { theme: AdminTheme }) => ({
        position: 'relative',
        minHeight: 42,
        h2: {
            color: theme.ContentPrimary,
        },
    }))
)

const NoServicesContainer = withTheme(
    styled(Flex)(({ theme }: { theme: AdminTheme }) => ({
        padding: `${theme.Spacing(4)} 0`,
        borderRadius: 8,
        backgroundColor: theme.SurfaceColor,

        color: theme.ContentPrimary,

        '.not-found-illustration': {
            filter: 'drop-shadow(0px 4px 8px rgba(29, 48, 73, 0.04)) drop-shadow(0px 2px 4px rgba(29, 48, 73, 0.08)) drop-shadow(0px 2px 4px rgba(29, 48, 73, 0.08))',
        },

        '.no-services-illustration': {
            borderRadius: 10,
            boxShadow: theme.Elevation_200,
        },
    }))
)

interface NoServicesProps {
    searchUsed?: boolean
    className?: string
}

export const NoServices: FC<NoServicesProps> = ({ searchUsed, className }) => {
    return (
        <NoServicesContainer
            className={classNames(className, 'w100', 'text-centered')}
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
        >
            {searchUsed ? (
                <Flex gap={1.5} flexDirection="column">
                    <EmptyStateIllustration width={54} height={54} className="not-found-illustration" />
                    <Flex gap={0.5} flexDirection="column">
                        <h4>
                            <Trans ns="bookingpage">No services found</Trans>
                        </h4>
                        <Trans ns="bookingpage">We couldn't find any services matching your search.</Trans>
                    </Flex>
                </Flex>
            ) : (
                <Flex gap={3} flexDirection="column">
                    <Flex gap={1.5} flexDirection="column">
                        {Array.from({ length: 2 }).map((_, i) => (
                            <NoServicesIllustration width={137.25} height={36} className="no-services-illustration" />
                        ))}
                    </Flex>
                    <Trans ns="bookingpage">No available services at the moment.</Trans>
                </Flex>
            )}
        </NoServicesContainer>
    )
}

interface ServiceSelectorProps {
    business: Business
    embedded?: boolean
    hasEvents?: boolean
    hideTitle?: boolean
}

export const ServiceSelector: FC<ServiceSelectorProps> = ({ business, embedded, hasEvents, hideTitle }) => {
    const [searchTerm, setSearchTerm] = useState('')
    const { categoryId } = useParams<{ categoryId?: string }>()

    const filteredServices = useMemo(
        () =>
            business.services.filter(
                (service) =>
                    (categoryId === undefined || service.categoryId === categoryId) &&
                    service.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
            ),
        [business.services, categoryId, searchTerm]
    )

    const selectedCategory = useMemo(() => {
        return business.serviceCategories.find((category) => category.id === categoryId)
    }, [business.serviceCategories, categoryId])

    const [selectedServices, setSelectedServices] = useState<Service[]>([])
    const onToggleService = useCallback((service: Service) => {
        setSelectedServices((current) => {
            if (current.includes(service)) {
                return current.filter((s) => s !== service)
            } else {
                return [...current, service]
            }
        })
    }, [])

    const { handle } = useParams<{ handle: string }>()
    const { t } = useTranslation('bookingpage')
    const history = useHistory()
    const onContinue = useCallback(() => {
        const [first, ...rest] = selectedServices
        if (!first) {
            return
        }
        const query = new URLSearchParams()
        for (const service of rest) {
            query.append('additionalServiceIds', `${service.id}`)
        }
        const url = `/${embedded ? 'embed' : 'book'}/${handle}/service/${first.id}?${query.toString()}`
        history.push(url)
    }, [embedded, handle, history, selectedServices])

    const summary = useMemo(() => {
        const title =
            selectedServices.length === 1 ? (
                selectedServices[0].name
            ) : (
                <Trans ns="bookingpage">{{ n: selectedServices.length }} services</Trans>
            )
        let sumDuration = 0
        let sumPrice = 0
        for (const service of selectedServices) {
            sumDuration += service.duration
            sumPrice += service.priceValue || 0
        }
        const ClickableIcon = asClickable(FontAwesomeIcon)
        return (
            <Column spacing={0.5}>
                <Flex gap={1} justifyContent="flex-start" alignItems="center">
                    <strong>{title}</strong>
                    <ClickableIcon
                        icon={faTimesCircle}
                        onClick={() => setSelectedServices([])}
                        aria-label={t('Clear selected services')}
                    />
                </Flex>
                <span>
                    {formatMinutes(sumDuration)}
                    {sumPrice > 0 ? (
                        <>
                            |{' '}
                            <Currency value={sumPrice} currency={business.currencyCode} languageCode={i18n.language} />
                        </>
                    ) : null}
                </span>
            </Column>
        )
    }, [business.currencyCode, selectedServices, t])

    return (
        <>
            <Content>
                <Container role="list">
                    {hideTitle ? null : embedded ? (
                        <Flex className="mb">
                            <h2>
                                <Trans ns="bookingpage">Book an appointment</Trans>
                            </h2>
                        </Flex>
                    ) : (
                        <ServiceSelectorTitleContainer justifyContent="space-between" className="mb">
                            {selectedCategory ? (
                                <Flex gap={1}>
                                    <NeutralIconButtonNavLink
                                        icon={faArrowLeft}
                                        to={`/${embedded ? 'embed' : 'book'}/${business.urlHandle}`}
                                    />
                                    <h2>{selectedCategory.name}</h2>
                                </Flex>
                            ) : (
                                <h2>
                                    {hasEvents ? (
                                        <Trans ns="bookingpage">1-on-1 services</Trans>
                                    ) : (
                                        <Trans ns="bookingpage">Book an appointment</Trans>
                                    )}
                                </h2>
                            )}
                            {business.services.length ? (
                                <SearchToggleInput searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
                            ) : null}
                        </ServiceSelectorTitleContainer>
                    )}
                    {filteredServices && filteredServices.length ? (
                        <ServiceList
                            filtered={searchTerm.length > 0}
                            business={business}
                            services={filteredServices}
                            embedded={embedded}
                            categoryId={categoryId}
                            selectedServices={selectedServices}
                            onToggleService={onToggleService}
                        />
                    ) : !filteredServices.length && business.services.length ? (
                        <NoServices className="mt" searchUsed />
                    ) : (
                        <NoServices className="mt" />
                    )}
                </Container>
            </Content>
            {business.allowMultipleServiceBooking && selectedServices.length > 0 ? (
                <Footer
                    scrollOnAppear
                    forceSpaceBetween
                    nextButton={
                        <Flex>
                            <PrimaryButton className="continue" onClick={onContinue}>
                                <Trans ns="bookingpage">Continue</Trans>
                            </PrimaryButton>
                        </Flex>
                    }
                    summary={summary}
                />
            ) : null}
        </>
    )
}
