import { keyframes, withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { AdminTheme } from '../../../../theme/theme'
import { withSpacing } from '../../../helpers/with-spacing'

const AppearAnimation = keyframes`
    from {
        transform: scale(0);
    }
    to {
        transform: scale(1);
    }
`

export const ModalContainer = withTheme(
    withSpacing(
        styled.div(
            ({
                width,
                theme,
                fullScreen,
                allowOverflow,
            }: {
                width?: number | string
                theme: AdminTheme
                fullScreen?: boolean
                allowOverflow?: boolean
            }) => ({
                position: 'relative',
                ...theme.BookingPageCardHoverBorderStyle,
                backgroundColor: theme.SurfaceColor,
                borderRadius: theme.Spacing(1.5),
                maxWidth: '100%',
                maxHeight: '100%',
                overflow: allowOverflow ? 'visible' : 'auto',
                width,
                animationName: AppearAnimation,
                animationDuration: '0.3s',
                transform: 'initial',

                ...(fullScreen && {
                    [theme.BreakPointReverse]: {
                        borderRadius: 0,
                        width: '100%',
                        height: '100%',
                    },
                }),
            })
        )
    )
)
