import React, { FC, useCallback, useContext, useMemo, useState } from 'react'
import { Business } from '../store/auth/types'
import { Service, TimezoneMode } from '../store/services/types'
import moment from 'moment-timezone'

// Default to automatic
const guessed = moment.tz.guess()
const ClientTimezoneContext = React.createContext({
    clientTimezone: guessed,
    guessed: guessed,
    changeToBusinessTimezone: () => {},
    changeToClientTimezone: () => {},
    overridden: false,
    different: false,
    timezoneMode: TimezoneMode.Automatic,
})

export const ClientTimezoneProvider: FC<{ business: Business; service: Service }> = ({
    children,
    business,
    service,
}) => {
    const timezoneMode = service.timezoneMode
    const [changed, setChanged] = useState(false)

    const TZ_SETTING_SESSION_STORAGE_KEY = useMemo(
        () => `minup_client_timezone_setting_${service.id}_${business.urlHandle}`,
        [business.urlHandle, service.id]
    )

    const stored = useMemo(
        () => sessionStorage.getItem(TZ_SETTING_SESSION_STORAGE_KEY),
        [TZ_SETTING_SESSION_STORAGE_KEY]
    )
    const guessed = useMemo(() => moment.tz.guess(), [])
    const tz = useMemo(
        () => stored || (service.timezoneMode === TimezoneMode.Local ? business.timezone : guessed),
        [business.timezone, guessed, service.timezoneMode, stored]
    )

    const [timezone, setTimezone] = useState(tz)

    const changeToBusinessTimezone = useCallback(
        (skipStorage?: boolean) => {
            setTimezone(business.timezone)
            setChanged(true)
            if (!skipStorage) {
                sessionStorage.setItem(TZ_SETTING_SESSION_STORAGE_KEY, business.timezone)
            }
        },
        [TZ_SETTING_SESSION_STORAGE_KEY, business.timezone]
    )

    const changeToClientTimezone = useCallback(
        (skipStorage?: boolean) => {
            setTimezone(guessed)
            setChanged(true)
            if (!skipStorage) {
                sessionStorage.setItem(TZ_SETTING_SESSION_STORAGE_KEY, guessed)
            }
        },
        [TZ_SETTING_SESSION_STORAGE_KEY, guessed]
    )

    return (
        <ClientTimezoneContext.Provider
            value={{
                clientTimezone: timezone,
                guessed,
                changeToBusinessTimezone,
                changeToClientTimezone,
                overridden: changed,
                different: guessed !== business.timezone,
                timezoneMode,
            }}
        >
            {children}
        </ClientTimezoneContext.Provider>
    )
}

export function useClientTimezone() {
    return useContext(ClientTimezoneContext)
}
