import { useEffect } from 'react'

export function useBeforeUnload(condition = true) {
    useEffect(() => {
        if (condition) {
            const handleBeforeUnload = (event: BeforeUnloadEvent) => {
                event.preventDefault()
                event.returnValue = ''
            }

            window.addEventListener('beforeunload', handleBeforeUnload)

            return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload)
            }
        }
    }, [condition])
}
