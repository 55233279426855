import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { FC, useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { AutocompleteInput, AutocompleteItem } from '../../../../components/ui-kit/comopnents/AutocompleteInput'
import { Input } from '../../../../components/ui-kit/comopnents/Input'
import { InputGroup } from '../../../../components/ui-kit/comopnents/InputGroup'
import { RequiredIndicator } from '../../../../components/ui-kit/comopnents/RequiredIndicator'
import { COUNTRY_CODES } from '../../../../dictionaries/country-codes'
import { AdminTheme } from '../../../../theme/theme'

export interface BillingDetails {
    id?: number
    billingName: string
    country: string
    city: string
    streetAddress: string
    zipCode: string
    taxID?: string
}

interface BillingDetailsFormProps {
    value: BillingDetails
    onChange: (value: BillingDetails) => void
}

const Container = withTheme(
    styled('div')(({ theme }: { theme: AdminTheme }) => ({
        display: 'grid',
        gridTemplateAreas: `
            'billingName'
            'country'
            'city'
            'streetAddress'
            'zipCode'
            'taxId'
        `,
        gap: theme.Spacing(2),
        rowGap: theme.Spacing(3),
        [theme.BreakPoint]: {
            gridTemplateColumns: '2fr 1fr 1fr 2fr',
            gridTemplateAreas: `
                'billingName billingName billingName billingName'
                'country country city city'
                'streetAddress streetAddress streetAddress zipCode'
                'taxId taxId taxId taxId'
            `,
        },
    }))
)

export const BillingDetailsForm: FC<BillingDetailsFormProps> = ({ value, onChange }) => {
    const { t } = useTranslation('admin')
    const onChangeCb = useCallback(
        (val: string, property: keyof BillingDetails) => {
            onChange({
                ...value,
                [property]: val,
            })
        },
        [onChange, value]
    )

    const countries = useMemo((): AutocompleteItem[] => {
        return COUNTRY_CODES.map((code) => ({
            label: t('country_' + code),
            value: code,
        })).sort((item1, item2) => (item1.label || '').localeCompare(item2.label))
    }, [t])

    return (
        <Container>
            <InputGroup style={{ gridArea: 'billingName' }}>
                <label htmlFor="billingName">
                    <Trans ns="admin">Billing name</Trans>
                    <RequiredIndicator />
                </label>
                <Input
                    id="billingName"
                    value={value.billingName}
                    required
                    onChange={(e) => onChangeCb(e.currentTarget.value, 'billingName')}
                />
            </InputGroup>
            <InputGroup style={{ gridArea: 'country' }}>
                <label htmlFor="country">
                    <Trans ns="admin">Country</Trans>
                    <RequiredIndicator />
                </label>
                <AutocompleteInput
                    disableAutocomplete
                    options={countries}
                    id="country"
                    value={value.country}
                    required
                    onChange={(e: any) => {
                        onChangeCb(e.value, 'country')
                    }}
                />
            </InputGroup>
            <InputGroup style={{ gridArea: 'city' }}>
                <label htmlFor="city">
                    <Trans ns="admin">City</Trans>
                    <RequiredIndicator />
                </label>
                <Input
                    id="city"
                    value={value.city}
                    required
                    onChange={(e) => onChangeCb(e.currentTarget.value, 'city')}
                />
            </InputGroup>
            <InputGroup style={{ gridArea: 'streetAddress' }}>
                <label htmlFor="streetAddress">
                    <Trans ns="admin">Street address</Trans>
                    <RequiredIndicator />
                </label>
                <Input
                    id="streetAddress"
                    value={value.streetAddress}
                    required
                    onChange={(e) => onChangeCb(e.currentTarget.value, 'streetAddress')}
                />
            </InputGroup>
            <InputGroup style={{ gridArea: 'zipCode' }}>
                <label htmlFor="zipCode">
                    <Trans ns="admin">Zip code</Trans>
                    <RequiredIndicator />
                </label>
                <Input
                    id="zipCode"
                    value={value.zipCode}
                    required
                    onChange={(e) => onChangeCb(e.currentTarget.value, 'zipCode')}
                />
            </InputGroup>
            <InputGroup style={{ gridArea: 'taxId' }}>
                <label htmlFor="taxId">
                    <Trans ns="admin">Tax ID</Trans>
                </label>
                <Input
                    id="taxId"
                    value={value.taxID}
                    onChange={(e) => onChangeCb(e.currentTarget.value, 'taxID')}
                    placeholder={t('XX000000000')}
                />
                <span className="small subtle">
                    <Trans ns="admin">Required for businesses</Trans>
                </span>
            </InputGroup>
        </Container>
    )
}
